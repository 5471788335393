export const moveInDateValidation = (value: string): boolean => {
  const minDate = new Date(new Date().setDate(new Date().getDate() - 14));
  const maxDate = new Date(new Date().setMonth(new Date().getMonth() + 3));
  const newDate = new Date(value);

  return (
    newDate.getTime() >= minDate.getTime() &&
    newDate.getTime() <= maxDate.getTime()
  );
};

export const moveOutDateValidation = (value: string): boolean => {
  const minDate = new Date();
  const maxDate = new Date(new Date().setMonth(new Date().getMonth() + 3));
  const newDate = new Date(value);

  return (
    newDate.getTime() > minDate.getTime() &&
    newDate.getTime() <= maxDate.getTime()
  );
};
