/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
// this rule appears to be too aggressive https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/issues/718

import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import InputLabel from '@flick/front_end_patterns/src/js/forms/form_fields_label';
import Dialog from '../common/dialog';
import sendAnalytics from '../../utils/analytics';

const IcpFormDialog = ({ onSelect, dialogRef }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const onSubmit = (data) => {
    dialogRef.current.hide();
    onSelect(data.icpNumber);
    sendAnalytics('Enter ICP manually', 'Submitted');
  };

  useEffect(() => {
    new InputLabel().start();
  }, []);

  return (
    <Dialog id="icp-dialog" dialogRef={dialogRef}>
      <h2>What&apos;s your ICP number?</h2>
      <p>
        You can find this number on your meter or a previous bill &mdash; it&apos;s 15 characters
        long.
      </p>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="form_group">
          <div className={errors.icpNumber ? 'form_row form_row_error' : 'form_row'}>
            <div className={errors.icpNumber ? 'form_field form_field_error' : 'form_field'}>
              <div>
                <input
                  type="text"
                  name="icp_number"
                  id="icp_number"
                  {...register('icpNumber', {
                    required: true,
                    minLength: 15,
                    maxLength: 15,
                    pattern: /^[0-9A-Za-z]+$/,
                  })}
                  aria-required
                />
                <label htmlFor="icp_number">ICP number</label>
              </div>
              {errors.icpNumber?.type === 'required' && (
                <span className="form_field_helper form_field_helper_error">
                  Something is missing...
                </span>
              )}
              {(errors.icpNumber?.type === 'maxLength' ||
                errors.icpNumber?.type === 'minLength') && (
                <span className="form_field_helper form_field_helper_error">
                  Your ICP number should be 15 characters.
                </span>
              )}
              {errors.icpNumber?.type === 'pattern' && (
                <span className="form_field_helper form_field_helper_error">
                  Your ICP number should only contain letters and numbers.
                </span>
              )}
            </div>
            <br />
          </div>
          <div className="form_row form_actions" data-bias="center">
            <button className="button button_primary" type="submit">
              Check ICP
            </button>
          </div>
        </div>
      </form>
    </Dialog>
  );
};

IcpFormDialog.propTypes = {
  onSelect: PropTypes.func.isRequired,
  dialogRef: PropTypes.object.isRequired,
};

export default IcpFormDialog;
