import "../styles/BillSmootherSettingsPage.scss";

import { Button, Form, SecondaryButton } from "@flick/fep-library";
import { ReactComponent as ForwardIcon } from "app/assets/images/icon_nav_arrow_forward.svg";
import { BasicInput } from "components/BasicInput";
import { PauseBillSmootherDialog } from "components/BillSmoother/PauseBillSmootherDialog";
import { StopBillSmootherDialog } from "components/BillSmoother/StopBillSmootherDialog";
import { CheckBoxInput } from "components/CheckBoxInput";
import {
  useCreateBillSmootherConfigMutation,
  useUpdateBillSmootherConfigMutation,
} from "queries/paymentSmootherApi";
import React, { ChangeEvent, FormEvent, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAppSelector } from "reduxUtils/hook";
import { BILL_SMOOTHER_STATE } from "utils/constants";
import { jwtClient } from "utils/jwtClient";
import { sendAnalyticsEvent } from "utils/sendAnalyticsEvent";

type TagBuilderProps = {
  active: boolean;
  enrolled: boolean;
};

/**
 * @description Build a tag indicating the status of the bill smoother.
 */
const TagBuilder: React.FC<TagBuilderProps> = ({ active, enrolled }) => {
  let tagType = "";
  let tagText;
  if (enrolled && active) {
    tagText = "active";
    tagType = "tag--success";
  } else if (enrolled && !active) {
    tagType = "tag--warning";
    tagText = "paused";
  } else {
    tagText = "inactive";
  }

  return (
    <span data-testid="billSmootherTag" className={`tag ${tagType}`}>
      Bill Smoother {tagText}
    </span>
  );
};

/**
 * @description Change the bill smoother status.
 */
const changeBillSmootherStatus = (
  enrolled: boolean,
  active: boolean,
): string => {
  if (enrolled && active) return "Pause?";
  if (enrolled && !active) return "Resume?";
  return "";
};

/**
 * @description The bill smoother settings page.
 */
export const BillSmootherSettingsPage: React.FC = () => {
  const navigate = useNavigate();

  const { icpNumber } = useAppSelector((store) => store.currentAccount);
  const { billingEntityData } = useAppSelector(
    (store) => store.billingEntityData,
  );
  const { billSmootherConfig: billSmootherConfigState } = useAppSelector(
    (store) => store.billSmootherConfig,
  );

  const [billSmootherData, setBillSmootherData] = useState(
    billSmootherConfigState,
  );
  const [bannerText, setBannerText] = useState(null);
  const [hidePauseBillSmootherDialog, setHidePauseBillSmootherDialog] =
    useState(true);
  const [hideStopBillSmootherDialog, setHideStopBillSmootherDialog] =
    useState(true);
  const [matchThresholdAndWithdrawal, setMatchThresholdAndWithdrawal] =
    useState(false);

  const [createBillSmootherConfig] = useCreateBillSmootherConfigMutation();
  const [updateBillSmootherConfig] = useUpdateBillSmootherConfigMutation();

  useMemo(() => {
    if (billSmootherConfigState) setBillSmootherData(billSmootherConfigState);
  }, [billSmootherConfigState]);

  const handleFieldChange = (event: ChangeEvent) => {
    const { value, id } = event.target as HTMLInputElement;
    if (matchThresholdAndWithdrawal) {
      setBillSmootherData({
        ...billSmootherData,
        contribution_threshold: value,
        withdrawal_trigger: value,
      });
    } else {
      setBillSmootherData({
        ...billSmootherData,
        [id]: value,
      });
    }
  };

  const handleCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target as HTMLInputElement;
    setMatchThresholdAndWithdrawal(checked);

    if (checked && billSmootherData) {
      setBillSmootherData({
        ...billSmootherData,
        withdrawal_trigger: billSmootherData.contribution_threshold,
      });
    }
  };

  const handleClick = async (
    event: React.MouseEvent<HTMLButtonElement> | FormEvent<HTMLFormElement>,
    data: {
      active?: boolean;
      enrolled?: boolean;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      [key: string]: any;
    },
    newBannerText: string,
  ) => {
    event.preventDefault();

    // active and enrolled are both false for customers that were once enrolled
    // but have stopped using Bill Smoother. We strip them out for subsequent re-enrollments
    // because otherwise their false values stop successfully reenabling this feature.
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { active = false, enrolled = false, ...createData } = data;

    const updatedBillSmootherConfig = billSmootherData?.enrolled
      ? await updateBillSmootherConfig({
          jwtClient,
          billingEntityId: billingEntityData?.id,
          data: data as BillSmootherConfig,
        })
      : await createBillSmootherConfig({
          jwtClient,
          billingEntityId: billingEntityData?.id,
          data: createData,
        });

    // if update bill smoother fails, reset to current data stored in redux store
    // @TODO need to handle it more elegate
    if (updatedBillSmootherConfig.error) {
      setBannerText("cannot update");
      setBillSmootherData(billSmootherConfigState);
      return;
    }

    sendAnalyticsEvent("bill_smoother", {
      icpNumber,
      event_category: "Bill Smoother",
      event_action: newBannerText,
      minimum_payment: updatedBillSmootherConfig.data.contribution_threshold,
      bill_threshold: updatedBillSmootherConfig.data.withdrawal_trigger,
    });
    setBannerText(newBannerText);
    setHidePauseBillSmootherDialog(true);
    setHideStopBillSmootherDialog(true);
    window.scrollTo(0, 0);
  };

  return (
    <div className="page_section page_section--single_column">
      <section className="page_section__column page_section__column--1">
        <div className="customer_tools_section">
          {bannerText && (
            <div
              id="notification_dismissable"
              className={`notification_banner notification_${bannerText === "cannot update" ? "error" : "success"} icon`}
              data-testid="billSmootherSettingsBanner"
            >
              <p className="heading">Bill Smoother {bannerText}!</p>
            </div>
          )}
          <h1 className="h2 page__heading">
            Set up your Bill Smoother account
          </h1>
          <p>
            Worked out your average bill spend? Use it as a guide for the
            amounts you enter below.
          </p>
          <>
            <div className="customer_tools_section__item--borderless customer_tools_section__item_container">
              <TagBuilder
                enrolled={billSmootherData?.enrolled}
                active={billSmootherData?.active}
              />
              {billSmootherData?.enrolled && (
                <>
                  <div id="page_content">
                    <SecondaryButton
                      onClick={(event) =>
                        billSmootherData.active
                          ? setHidePauseBillSmootherDialog(false)
                          : handleClick(
                              event,
                              { active: !billSmootherData.active },
                              !billSmootherData.active
                                ? BILL_SMOOTHER_STATE.activated
                                : BILL_SMOOTHER_STATE.paused,
                            )
                      }
                    >
                      {changeBillSmootherStatus(
                        billSmootherData.enrolled,
                        billSmootherData.active,
                      )}
                    </SecondaryButton>
                  </div>
                  {!hidePauseBillSmootherDialog && (
                    <PauseBillSmootherDialog
                      handleDialogClose={() =>
                        setHidePauseBillSmootherDialog(true)
                      }
                      hidePauseBillSmootherDialog={hidePauseBillSmootherDialog}
                      handleClick={handleClick}
                      billSmootherData={billSmootherData}
                    />
                  )}
                </>
              )}
            </div>
            <article className="customer_tools_section__link_container">
              <Link
                className="customer_tools_section__link"
                to="/bills/bill-smoother/more-information"
              >
                How does Bill Smoother work?
              </Link>
              <ForwardIcon />
            </article>
            <section className="customer_tools_section__item">
              <h2 className="h3">Settings</h2>
              <p>
                Please note that changes may not take effect immediately. If
                your next billing cycle is on the following Tuesday, you'll need
                to change your Bill Smoother settings before Sunday for the
                change to show on your upcoming bill.
              </p>
              <Form
                data-validate
                data-synthetic-submit
                onSubmit={(event) =>
                  handleClick(
                    event,
                    billSmootherData,
                    billSmootherData?.enrolled
                      ? BILL_SMOOTHER_STATE.updated
                      : BILL_SMOOTHER_STATE.activated,
                  )
                }
              >
                <div className="spacer">
                  <div className="input_with_prefix">
                    <p className="prefix">$</p>
                    <BasicInput
                      dataTestId="contributionThresholdInput"
                      hint="('Minimum payment amount') Use your average bill spend
                          as a guide for this amount. To consistently pay the
                          same amount at each bill, make this amount the same as
                          the trigger amount below."
                      inputMode="numeric"
                      inputWidth="full"
                      label="Minimum amount I want to contribute at each power bill"
                      name="contribution_threshold"
                      required
                      handleInputChange={handleFieldChange}
                      type="number"
                      value={billSmootherData?.contribution_threshold || ""}
                    />
                  </div>
                  <CheckBoxInput
                    isChecked={matchThresholdAndWithdrawal}
                    label="Make the minimum payment and trigger amount the same"
                    name="same_minimum_payment_and_trigger_amount"
                    handleOnChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      handleCheckbox(e)
                    }
                  />
                </div>
                <div className="input_with_prefix">
                  <p className="prefix">$</p>
                  <BasicInput
                    dataTestId="withdrawalTriggerInput"
                    hint="('Trigger amount') This amount triggers Bill Smoother
                          to use your Bill Smoother credit to help pay your
                          bill. It'll contribute the portion of your bill that's
                          above this amount.
                          "
                    label="Use my credit for power bills over"
                    inputWidth="full"
                    name="withdrawal_trigger"
                    required
                    handleInputChange={handleFieldChange}
                    type="number"
                    value={billSmootherData?.withdrawal_trigger || ""}
                  />
                </div>
                <Button extraClasses="button--expanded spacer" type="submit">
                  {billSmootherData?.enrolled
                    ? "Update settings"
                    : "Set up Bill Smoother"}
                </Button>
                <Button
                  buttonColor="outline"
                  extraClasses="button--expanded"
                  onClick={() => navigate("/bills")}
                >
                  Cancel
                </Button>
              </Form>
              {billSmootherData?.enrolled && (
                <div className="customer_tools_section">
                  <div className="customer_tools_section__item customer_tools_section__item--borders">
                    <h3 className="h4">Stop using Bill Smoother?</h3>
                    <p>
                      Once you stop using Bill Smoother, your credit will pay
                      for future bills until it's used up.
                    </p>
                    <div id="page_content">
                      <Button
                        buttonColor="danger"
                        extraClasses="button--expanded spacer"
                        onClick={() => setHideStopBillSmootherDialog(false)}
                      >
                        Stop using Bill Smoother
                      </Button>
                    </div>
                    {!hideStopBillSmootherDialog && (
                      <StopBillSmootherDialog
                        handleDialogClose={() =>
                          setHideStopBillSmootherDialog(true)
                        }
                        hideStopBillSmootherDialog={hideStopBillSmootherDialog}
                        handleClick={handleClick}
                      />
                    )}
                  </div>
                </div>
              )}
            </section>
          </>
        </div>
      </section>
    </div>
  );
};
