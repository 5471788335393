import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

const initialState: AnnouncementBanner | null = null;

const announcementBannerSlice = createSlice({
  name: "announcementBanner",
  initialState,
  reducers: {
    setAnnouncementBanner: (
      state,
      action: PayloadAction<AnnouncementBanner>,
    ) => {
      const {
        type,
        name,
        params = undefined,
        modifier = undefined,
        additionalContent = undefined,
        enableDesktopMode = true,
        enableNativeMode = true,
      } = action.payload;
      state = {
        type,
        name,
        modifier,
        params,
        additionalContent,
        enableDesktopMode,
        enableNativeMode,
        hideBanner: false,
      };
      return state;
    },

    clearAnnouncementBanner: (state) => {
      state = null;
      return state;
    },

    hideAnnouncementBanner: (state, action: PayloadAction<boolean>) => {
      return { ...state, hideBanner: action.payload };
    },
  },
});

export const {
  setAnnouncementBanner,
  clearAnnouncementBanner,
  hideAnnouncementBanner,
} = announcementBannerSlice.actions;
export const announcementBannerSliceReducer = announcementBannerSlice.reducer;
