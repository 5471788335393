import React, { useContext, useState } from "react";
import { isEmptyValue, isValidInput } from "utils/forms/forms";
import { closeDateValidation } from "utils/validations/closeDateValidation";

import content from "./static/content.json";
const { validationDate, validationRequired } = content;

interface CloseAccountContext {
  closedDate?: FormField;
  isFormValid?: boolean;
}

const initialContext: CloseAccountContext = {};

const CloseAccountFormContext = React.createContext(initialContext);

const initialFormFieldState = {
  error: "",
  isRequired: true,
  isValid: false,
  value: "",
};

export const useCloseAccountFormContext = () =>
  useContext(CloseAccountFormContext);

export const CloseAccountFormContextProvider = ({
  children,
}: {
  children: JSX.Element;
}): JSX.Element => {
  const [closedDate, setClosedDate] = useState(initialFormFieldState);

  const setValueClosedDate = (value: string) => {
    const isSpecified = !isEmptyValue(value);
    const isValid = isSpecified && closeDateValidation(value);
    let error = "";
    error = isValid ? "" : validationDate;
    error = isSpecified ? error : validationRequired;
    setClosedDate((prev) => ({
      ...prev,
      isValid: isSpecified && isValid,
      error,
      value,
    }));
  };

  const allInputsValid = [isValidInput(closedDate)].every(Boolean);

  return (
    <CloseAccountFormContext.Provider
      value={{
        closedDate: {
          fieldValue: closedDate,
          handleOnChange: setValueClosedDate,
        },
        isFormValid: allInputsValid,
      }}
    >
      {children}
    </CloseAccountFormContext.Provider>
  );
};
