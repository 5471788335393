/**
 * Converts the time from 24 hour format into 12 hour format
 */
export const convertTimeFrom24To12Format = (
  timeIn24HourFormat: string,
): string => {
  const [sHours, minutes] = timeIn24HourFormat
    .match(/([0-9]{1,2}):([0-9]{2})/)
    .slice(1);
  const period = +sHours < 12 ? "am" : "pm";
  const hours = +sHours % 12 || 12;

  return `${hours}:${minutes}${period}`;
};
