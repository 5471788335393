/* eslint-disable jsx-a11y/anchor-is-valid */
import "styles/BillListPage.scss";

import { BillList } from "components/BillList";
import { BillSmootherDefault } from "components/BillSmoother/BillSmootherDefault";
import { BillSmootherSummary } from "components/BillSmoother/BillSmootherSummary";
import { CurrentYearCostsSection } from "components/CurrentYearCostsSection";
import { NoBillDataFallback } from "components/fallback/NoBillDataFallback";
import { Loader } from "components/Loader";
import {
  useGetBillingBalanceQuery,
  useLazyGetInvoiceTimelineQuery,
} from "queries/billingApi";
import { expireTime } from "queries/queries.utils";
import React, { useEffect, useMemo, useState } from "react";
import { useAppSelector } from "reduxUtils/hook";
import { jwtClient } from "utils/jwtClient";
import { refetchOnScroll } from "utils/refetchOnScroll";
import { sendAnalyticsEvent } from "utils/sendAnalyticsEvent";

/**
 * @description A list of all of a customers bills from most recent to the least recent
 */
export const BillListPage: React.FC = () => {
  const { billingEntityData } = useAppSelector(
    (store) => store.billingEntityData,
  );
  const { customerNumber, icpNumber, active } = useAppSelector(
    (store) => store.currentAccount,
  );
  const { billSmootherConfig: billSmootherConfigState } = useAppSelector(
    (store) => store.billSmootherConfig,
  );

  const [storedTimelineBills, setStoredTimelineBills] = useState([]);
  const [infiniteScrollCount, setInfiniteScrollCount] = useState(0);

  const nextEndDate = useMemo(() => {
    return Array.isArray(storedTimelineBills) && storedTimelineBills.length > 0
      ? new Date(
          storedTimelineBills[
            storedTimelineBills.length - 1
          ].attributes.invoice_date,
        )
      : new Date();
  }, [storedTimelineBills]);
  /**
   * This returns invoices that have been sent to the customer already.
   * There is a gap between collecting the data for an invoice and the send date,
   * so the latest bill may not display for several days after the relevant period.
   */
  const [
    trigger,
    {
      data: timelineData,
      isLoading: isTimelineLoading,
      isFetching: isTimelineFetching,
    },
  ] = useLazyGetInvoiceTimelineQuery();

  useEffect(() => {
    // For users with multiple accounts
    // We reset and retrigger the API when the user changes accounts.
    // on first trigger, endDate is always current local date
    setStoredTimelineBills([]);
    setInfiniteScrollCount(0);
    if (customerNumber && active) {
      const endDate = new Date().toString();
      trigger({ customerNumber, jwtClient, endDate: endDate });
    }
  }, [customerNumber]);

  const { data: billSmootherBalance } = useGetBillingBalanceQuery(
    { customerNumber, jwtClient },
    {
      skip:
        !billSmootherConfigState ||
        !billSmootherConfigState.enrolled ||
        !customerNumber,
      refetchOnMountOrArgChange: expireTime,
    },
  );

  useEffect(() => {
    let nextDate = nextEndDate;
    if (timelineData?.length) {
      sendAnalyticsEvent("scroll", {
        icpNumber,
        scroll_number: infiniteScrollCount,
      });
      setInfiniteScrollCount(infiniteScrollCount + 1);
      nextDate = new Date(
        timelineData[timelineData.length - 1].attributes.invoice_date,
      );

      const timelineBills = [...timelineData];

      // This removes a duplicate bill if the last listed invoice date is included in the next lot of bills
      const nextDateIsInTimeline =
        new Date(timelineBills[0].attributes.invoice_date).getTime() ===
        nextEndDate.getTime();

      if (nextDateIsInTimeline) {
        timelineBills.shift();
      }

      setStoredTimelineBills(storedTimelineBills.concat(timelineBills));
    }
    const refetchInvoiceTimeline = async () => {
      await trigger({
        customerNumber,
        jwtClient,
        endDate: nextDate.toString(),
      });
    };
    // Ensures event listener actually dismounts and updates timelineData query key to trigger refetch
    return refetchOnScroll(timelineData, refetchInvoiceTimeline);
  }, [timelineData]);

  return (
    <div className="page_section">
      <section className="page_section__column page_section__column--1">
        <CurrentYearCostsSection
          billingEntityData={billingEntityData}
          cardChildren={
            !billSmootherConfigState?.enrolled && <BillSmootherDefault />
          }
        />
        {billSmootherConfigState?.enrolled && (
          <BillSmootherSummary
            billSmootherBalance={billSmootherBalance?.total_credits?.amount}
          />
        )}
      </section>

      <section className="page_section__column page_section__column--2">
        <h2 className="page__heading">Bills</h2>
        {!customerNumber && <NoBillDataFallback />}
        <nav>
          <ul className="customer_tools_list">
            {isTimelineLoading && active ? (
              <li
                className="loading-section"
                data-testid="list-loading-indicator"
              >
                <Loader />
              </li>
            ) : (
              <>
                {billingEntityData && <BillList bills={storedTimelineBills} />}
                <li
                  className={
                    isTimelineFetching
                      ? "loading-section"
                      : "loading-section--invisible"
                  }
                  data-testid="timeline-fetching-loading-indicator"
                >
                  <Loader />
                </li>
              </>
            )}
          </ul>
        </nav>
      </section>
    </div>
  );
};
