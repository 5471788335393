import { JwtClient } from "utils/jwtClient";
export interface ApiCallParams {
  path: string;
  queryParams?: Record<string, string | number>;
}

export interface BaseQueryParams extends ApiCallParams {
  jwtClient: JwtClient;
}

// JWTs last for one hour so expire a query before the current JWT or refresh token
export const expireTime = 1000 * 60 * 59;
