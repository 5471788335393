import React, { useEffect } from "react";
import { Navigate, useParams } from "react-router-dom";

export const AdminAccount: React.FC = () => {
  const { accountNumber } = useParams();

  useEffect(() => {
    window.sessionStorage.setItem("accountOverrideNumber", accountNumber);
  }, []);

  return <Navigate to="/" />;
};
