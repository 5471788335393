import { DateInput } from "components/DateInput";
import React from "react";

import { getTwoYearsAgo, getYesterdayDate } from "./CustomPeriodSelector.utils";

type CustomPeriodDateProps = {
  billingStartDate?: string;
  date: string;
  error: string | null;
  handleDateChanged: (newDate: string) => void;
  label: string;
  name: string;
};

export const CustomPeriodDate: React.FC<CustomPeriodDateProps> = ({
  billingStartDate,
  date,
  error,
  handleDateChanged,
  label,
  name,
}) => {
  const { twoYearsAgoString } = getTwoYearsAgo();
  const { yesterdayDateString } = getYesterdayDate();

  const minDate =
    billingStartDate && billingStartDate > twoYearsAgoString
      ? billingStartDate
      : twoYearsAgoString;

  return (
    <div className="custom_period_selector__date_field">
      <DateInput
        errorMessage={error}
        handleDateChange={(value) => value && handleDateChanged(value)}
        label={label}
        max={yesterdayDateString}
        min={minDate}
        name={name}
        required
        value={date}
      />
    </div>
  );
};
