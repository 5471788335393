import "./RecentBills.scss";

import { ReactComponent as ForwardIcon } from "app/assets/images/icon_nav_arrow_forward.svg";
import { useGetInvoiceTimelineQuery } from "queries/billingApi";
import { expireTime } from "queries/queries.utils";
import React from "react";
import { Link } from "react-router-dom";
import { useAppSelector } from "reduxUtils/hook";
import { AVAILABLE_PERIODS } from "utils/constants";
import { jwtClient } from "utils/jwtClient";
import { capitalize } from "utils/text";

import { BillItem } from "../BillItem";
import { NoBillDataFallback } from "../fallback/NoBillDataFallback";
import { LoadingSection } from "../LoadingSection";

const getInvoiceDuration = (
  active: boolean,
  invoice: InvoiceSummary,
): string => {
  return active && invoice.attributes?.billing_period?.duration
    ? AVAILABLE_PERIODS[invoice.attributes.billing_period.duration].periodName
    : "period";
};

export const RecentBills: React.FC = () => {
  const { customerNumber, active } = useAppSelector(
    (store) => store.currentAccount,
  );

  const { data: timelineData, isFetching: isTimelineFetching } =
    useGetInvoiceTimelineQuery(
      { jwtClient, customerNumber },
      { skip: !customerNumber, refetchOnMountOrArgChange: expireTime },
    );

  if (isTimelineFetching && customerNumber) return <LoadingSection />;

  if (!timelineData || timelineData.length === 0) {
    return <NoBillDataFallback />;
  }

  const [lastInvoice = undefined, invoiceBefore = undefined] =
    timelineData.slice(0, 2);

  return (
    <nav className="recent_bills">
      <ul className="customer_tools_list customer_tools_list--endless">
        <li className="customer_tools_list__item">
          {lastInvoice && (
            <BillItem
              bill={lastInvoice.attributes}
              label={
                <strong>Last {getInvoiceDuration(active, lastInvoice)}</strong>
              }
              componentTitle="Bills"
            />
          )}
        </li>
        {invoiceBefore && (
          <li className="customer_tools_list__item">
            <BillItem
              bill={invoiceBefore.attributes}
              label={
                <strong>
                  {capitalize(getInvoiceDuration(active, invoiceBefore))} before
                </strong>
              }
              componentTitle="Bills"
            />
          </li>
        )}
        <li className="customer_tools_list__item">
          <Link
            to="/bills"
            className="customer_tools_list__link recent_bills__link recent_bills__link--with-icon"
            state={{ previousPath: window.location.pathname }}
          >
            <strong>All bills</strong>
            <ForwardIcon />
          </Link>
        </li>
      </ul>
    </nav>
  );
};
