import { decodeState } from "auth/generateCodeChallenge";
import React, { useEffect } from "react";
import { Navigate, useSearchParams } from "react-router-dom";
import { useAppDispatch } from "reduxUtils/hook";
import { LOGOUT_ACTION } from "utils/constants";
import { jwtClient } from "utils/jwtClient";

type AuthCallbackProps = {
  setIsAuthenticated: (value: boolean) => void;
};

export const AuthCallback: React.FC<AuthCallbackProps> = ({
  setIsAuthenticated,
}) => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  // hitting the `auth/callback` route with no url params is a post log out redirect
  if (searchParams.size === 0) {
    jwtClient.setJwt(null);
    dispatch({ type: LOGOUT_ACTION });
    return <Navigate to="/welcome" />;
  }

  const stateCodeFromURL = searchParams.get("state");
  const code = searchParams.get("code");
  const codeVerifier = window.sessionStorage.getItem("code_verifier");
  const initialCode = window.localStorage.getItem("state_code");
  const decryptedState = decodeState(stateCodeFromURL);
  const decryptedStateCode = decryptedState?.stateCode;

  useEffect(() => {
    const getTokenWithAuthorizationCode = async () => {
      await jwtClient.verifyStateCode(initialCode, decryptedStateCode);
      await jwtClient.getTokenWithAuthorizationCode(code, codeVerifier);
      setIsAuthenticated(jwtClient.isAuthenticated());
    };
    if (!jwtClient.isAuthenticated()) {
      getTokenWithAuthorizationCode();
    }
  }, []);

  return <Navigate to={decryptedState?.from ?? "/"} />;
};
