import React, { ReactNode, useContext, useState } from "react";
import { BANK_ACCOUNT_MAX_LENGTH } from "utils/constants";
import { isEmptyValue, isValidInput } from "utils/forms/forms";
import { alphaNumericAndSpaceRegex } from "utils/text";
import { bankAccountValidation } from "utils/validations/bankAccountValidation";
import { validationFunctionHelper } from "utils/validations/validationFunctionHelper";

import content from "./static/content.json";
const {
  accountNameRequiredError,
  bankAccountNumberInvalidError,
  bankAccountNumberRequiredError,
  confirmAuthorityRequiredError,
  termsAndConditionsRequiredError,
} = content;

type NewPaymentMethodContext = {
  accountName?: FormField;
  bankAccountNumber?: FormField;
  code?: FormField;
  confirmAuthority?: FormField;
  isDirectDebitValid: boolean;
  particulars?: FormField;
  paymentMethod?: FormField;
  reference?: FormField;
  termsAndConditions?: FormField;
  resetFormFields: () => void;
};

const NewPaymentMethodFormContext =
  React.createContext<NewPaymentMethodContext>({} as NewPaymentMethodContext);

const initialFormFieldState = {
  error: "",
  isRequired: true,
  isValid: false,
  value: "",
};

const accountNameMaxLength = 50;
const particularsMaxLength = 12;

const validationAccountName = (value: string) => {
  return !isEmptyValue(value);
};

const validationBankAccountNumber = (value: string) => {
  return validationFunctionHelper({
    value,
    validationFunction: bankAccountValidation,
    requiredError: bankAccountNumberRequiredError,
    invalidError: bankAccountNumberInvalidError,
  });
};

export const useNewPaymentMethodFormContext = () =>
  useContext(NewPaymentMethodFormContext);

type NewPaymentMethodFormContextProviderProps = {
  children: ReactNode;
};

export const NewPaymentMethodFormContextProvider: React.FC<
  NewPaymentMethodFormContextProviderProps
> = ({ children }) => {
  const [accountName, setAccountName] = useState(initialFormFieldState);
  const [bankAccountNumber, setBankAccountNumber] = useState(
    initialFormFieldState,
  );
  const [code, setCode] = useState({
    ...initialFormFieldState,
    isRequired: false,
  });
  const [confirmAuthority, setConfirmAuthority] = useState({
    ...initialFormFieldState,
    value: false,
  });
  const [particulars, setParticulars] = useState({
    ...initialFormFieldState,
    isRequired: false,
  });
  const [reference, setReference] = useState({
    ...initialFormFieldState,
    isRequired: false,
  });
  const [paymentMethod, setPaymentMethod] = useState(initialFormFieldState);
  const [termsAndConditions, setTermsAndConditions] = useState({
    ...initialFormFieldState,
    value: false,
  });

  const resetFormFields = () => {
    setAccountName(initialFormFieldState);
    setBankAccountNumber(initialFormFieldState);
    setParticulars(initialFormFieldState);
    setCode({ ...initialFormFieldState, isRequired: false });
    setParticulars({ ...initialFormFieldState, isRequired: false });
    setReference({ ...initialFormFieldState, isRequired: false });
    setConfirmAuthority({ ...initialFormFieldState, value: false });
    setTermsAndConditions({ ...initialFormFieldState, value: false });
  };

  const setValueAccountName = (value: string) => {
    if (value.length <= accountNameMaxLength) {
      setAccountName((prev) => ({
        ...prev,
        isValid: true,
        error: validationAccountName(value) ? "" : accountNameRequiredError,
        value,
      }));
    }
  };

  const setValueBankAccountNumber = (value: string, setError: boolean) => {
    const fieldInError = !!bankAccountNumber.error;
    const error = validationBankAccountNumber(value);
    if (value.replaceAll("-", "").length <= BANK_ACCOUNT_MAX_LENGTH) {
      setBankAccountNumber((prev) => ({
        ...prev,
        isValid: true,
        error: fieldInError || setError ? error : "",
        value: value.replace(/[^0-9 -]/g, ""), // allow numbers and dashes
      }));
    }
  };

  const setValueCode = (value: string) => {
    setCode((prev) => ({
      ...prev,
      isValid: true,
      value: alphaNumericAndSpaceRegex(value),
    }));
  };

  const setValueConfirmAuthority = () => {
    setConfirmAuthority((prev) => {
      const value = !prev.value;
      return {
        ...prev,
        isValid: value,
        error: !value ? confirmAuthorityRequiredError : "",
        value,
      };
    });
  };

  const setValueParticulars = (value: string) => {
    if (value.length <= particularsMaxLength) {
      setParticulars((prev) => ({
        ...prev,
        isValid: true,
        value: alphaNumericAndSpaceRegex(value),
      }));
    }
  };

  const setValueReference = (value: string) => {
    setReference((prev) => ({
      ...prev,
      isValid: true,
      value: alphaNumericAndSpaceRegex(value),
    }));
  };

  const setValuePaymentMethod = (value: string) => {
    resetFormFields(); // clear fields on payment method change

    setPaymentMethod((prev) => ({
      ...prev,
      isValid: true,
      error: "",
      value,
    }));
  };

  const setValueTermsAndConditions = () => {
    setTermsAndConditions((prev) => {
      const value = !prev.value;
      return {
        ...prev,
        isValid: value,
        error: !value ? termsAndConditionsRequiredError : "",
        value,
      };
    });
  };

  const inputsDirectDebitValid = [
    isValidInput(accountName),
    isValidInput(bankAccountNumber),
    isValidInput(code),
    isValidInput(confirmAuthority),
    isValidInput(particulars),
    isValidInput(termsAndConditions),
  ].every(Boolean);

  return (
    <NewPaymentMethodFormContext.Provider
      value={{
        accountName: {
          fieldValue: accountName,
          handleOnChange: (value) => setValueAccountName(value),
          handleOnBlur: () => setValueAccountName(accountName.value),
        },
        bankAccountNumber: {
          fieldValue: bankAccountNumber,
          handleOnChange: (value) => setValueBankAccountNumber(value, false),
          handleOnBlur: () =>
            setValueBankAccountNumber(bankAccountNumber.value, true),
        },
        code: {
          fieldValue: code,
          handleOnChange: setValueCode,
        },
        confirmAuthority: {
          fieldValue: confirmAuthority,
          handleOnChange: setValueConfirmAuthority,
        },
        isDirectDebitValid: inputsDirectDebitValid,
        particulars: {
          fieldValue: particulars,
          handleOnChange: setValueParticulars,
        },
        reference: {
          fieldValue: reference,
          handleOnChange: setValueReference,
        },
        paymentMethod: {
          fieldValue: paymentMethod,
          handleOnChange: setValuePaymentMethod,
        },
        termsAndConditions: {
          fieldValue: termsAndConditions,
          handleOnChange: setValueTermsAndConditions,
        },
        resetFormFields,
      }}
    >
      {children}
    </NewPaymentMethodFormContext.Provider>
  );
};
