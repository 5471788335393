import "./CheckboxInput.scss";

import React from "react";

type CheckBoxInputProps = {
  errorMessage?: string;
  extraClasses?: string;
  handleOnChange: (event?: React.ChangeEvent<HTMLInputElement>) => void;
  hint?: string;
  isChecked: boolean;
  label: string | JSX.Element;
  name: string;
  required?: boolean;
};

export const CheckBoxInput: React.FC<CheckBoxInputProps> = ({
  errorMessage,
  extraClasses,
  handleOnChange,
  hint,
  isChecked,
  label,
  name,
  required,
}) => {
  return (
    <div className="checkbox_confirm">
      <input
        checked={isChecked}
        className={extraClasses ? extraClasses : ""}
        id={`${name}-checkbox`}
        name={name}
        onChange={handleOnChange}
        required={required}
        type="checkbox"
        value={`${isChecked}`}
      />
      <label htmlFor={`${name}-checkbox`}>
        <span>{label}</span>
      </label>
      {errorMessage && (
        <p className="input__helper input__helper--error">{errorMessage}</p>
      )}
      {hint && <span className="input__helper">{hint}</span>}
    </div>
  );
};
