import { ROUTE_INFO } from "./constants";

/**
 * update Route Info based on current route
 * if current route matches (bills | usage | account) then set relevant info
 * else fallback to defaul
 */
export const pathnameBrand = (
  pathname: string,
): { background: string; name: string } => {
  const currentPathKey = Object.keys(ROUTE_INFO).find((path) =>
    pathname.includes(path),
  );

  return currentPathKey ? ROUTE_INFO[currentPathKey] : ROUTE_INFO.default;
};
