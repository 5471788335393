/**
 * @description caculate summary percentage of given period usage to be displayed in IndividualDayDetail component
 * @param { Array<ChartUsage> } usageData
 * @returns {string} represent summary percentage of given period usage
 */
export const timeOfUseSummary = (usageData: ChartUsage[]): string => {
  if (!usageData || !Array.isArray(usageData) || usageData.length === 0)
    return "-";
  const summedData = usageData.reduce(
    (acc, item) => {
      if (item.usageType === "offpeak") acc.offpeak += item.value;
      if (item.usageType === "peak") acc.peak += item.value;
      return acc;
    },
    {
      peak: 0,
      offpeak: 0,
    },
  );

  // Check if both summedData offpeak and peak values are 0. This check is requied as 0 divide 0 is undefined. We want to return "-" instead of undefined if this is the case.
  if (summedData.offpeak === 0 && summedData.peak === 0) return "-";

  const summedUsagePercentage = (
    (summedData.offpeak / (summedData.peak + summedData.offpeak)) *
    100
  ).toFixed(0);

  return summedUsagePercentage;
};
