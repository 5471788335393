export const formattedUserDetails = (
  userDetails: UserDetailsTypes,
): UserDetails => {
  const { data } = userDetails || {};

  return {
    preferredName: data?.attributes?.preferred_name || "",
    email: data?.attributes?.email || "",
  };
};
