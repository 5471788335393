/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable import/no-default-export */
import FlickForm from "./flick_form";

export default function FormValidator() {
  var fv = this,
    DATA_ATTR_VALIDATE = "data-validate";

  fv.forms = [];

  fv.start = function () {
    for (var i = 0; i < document.forms.length; i++) {
      var formItem = document.forms[i];
      if (formItem.hasAttribute(DATA_ATTR_VALIDATE)) {
        fv.forms.push(new FlickForm(formItem));
      }
    }
  };

  return fv;
}
