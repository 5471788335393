import { barBackgroundColour, barHoverBackgroundColour } from "utils/charts";

export const prepareGroupedDataForBarChart = (
  chartConfig: GroupedBarChartConfig,
): BarChartConfig => {
  const barChartConfig: BarChartConfig = {
    labels: chartConfig.labels,
    datasets: chartConfig.datasets.map((dataset) => ({
      label: dataset.label,
      data: dataset.data,
      status: dataset.status,
      backgroundColor: barBackgroundColour(dataset.barColour),
      hoverBackgroundColor: barHoverBackgroundColour(
        dataset.hoverBackgroundColour,
      ),
      stack: dataset.stack,
      barPercentage: 0.9,
      categoryPercentage: 0.5,
      datalabels: {
        offset: 1,
        align: "end",
      },
    })),
  };

  return barChartConfig;
};
