import { Card, CardBody, CardHeader } from "@flick/fep-library";
import { ReactComponent as ForwardIcon } from "app/assets/images/icon_nav_arrow_forward.svg";
import { BillSmootherTransactions } from "components/BillSmoother/BillSmootherTransactions";
import { ComponentItemFallback } from "components/fallback/ComponentItemFallback";
import { LoadingSection } from "components/LoadingSection";
import {
  useGetBillingBalanceQuery,
  useLazyGetBillSmootherTransactionsQuery,
} from "queries/billingApi";
import { expireTime } from "queries/queries.utils";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { useAppSelector } from "reduxUtils/hook";
import { NZD } from "utils/currency";
import { jwtClient } from "utils/jwtClient";
import { refetchOnScroll } from "utils/refetchOnScroll";

export const BillSmootherPage: React.FC = () => {
  const { customerNumber } = useAppSelector((store) => store.currentAccount);
  const navigate = useNavigate();
  const { billSmootherConfig } = useAppSelector(
    (store) => store.billSmootherConfig,
  );

  const [storedTransactions, setStoredTransactions] = useState([]);

  const nextEndDate = useMemo(() => {
    return Array.isArray(storedTransactions) && storedTransactions.length > 0
      ? new Date(
          storedTransactions[
            storedTransactions.length - 1
          ].attributes.invoice_date,
        )
      : new Date();
  }, [storedTransactions]);

  const { data: billSmootherBalance } = useGetBillingBalanceQuery(
    { customerNumber, jwtClient },
    {
      skip:
        !billSmootherConfig || !billSmootherConfig?.enrolled || !customerNumber,
      refetchOnMountOrArgChange: expireTime,
    },
  );

  const [
    trigger,
    {
      data: transactions,
      isFetching: isTransactionFetching,
      isSuccess: isTransactionSuccess,
      isError: isTransactionError,
    },
  ] = useLazyGetBillSmootherTransactionsQuery();

  useEffect(() => {
    if (!billSmootherConfig?.enrolled) {
      navigate("/bills");
      return;
    }
    if (billSmootherConfig?.enrolled && customerNumber && nextEndDate) {
      trigger({ customerNumber, jwtClient, endDate: nextEndDate.toString() });
    }
  }, [billSmootherConfig]);

  useEffect(() => {
    let nextDate = nextEndDate;
    if (
      JSON.stringify(transactions) !== JSON.stringify(storedTransactions) &&
      transactions?.length
    ) {
      nextDate = new Date(
        transactions[transactions.length - 1].attributes.timestamps.created_at,
      );
      const newTransactions = storedTransactions.concat(transactions);
      // remove any duplicated transaction id
      const cleanupTransactions = newTransactions.filter(
        (transaction, index, self) =>
          index === self.findIndex((t) => t.id === transaction.id),
      );
      setStoredTransactions(cleanupTransactions);
    }
    const refetchBillSmootherTimeline = async () => {
      await trigger({
        customerNumber,
        jwtClient,
        endDate: nextDate.toString(),
      });
    };
    return refetchOnScroll(transactions, refetchBillSmootherTimeline);
  }, [transactions]);

  return (
    <div className="page_section">
      <section className="page_section__column page_section__column--1">
        <Card cardBorderStyle="shadow" cardPaddingType="compact">
          <CardHeader headingColor="black">
            <h2 className="h4">Your Bill Smoother account</h2>
          </CardHeader>

          <CardBody>
            <article className="customer_tools_section">
              {billSmootherBalance &&
                !isNaN(billSmootherBalance.total_credits?.amount) && (
                  <>
                    <section>
                      <h3 className="customer_tools_section__text">
                        Account credit
                      </h3>
                      <span className="h2" data-testid="billSmootherValue">
                        {NZD.format(billSmootherBalance.total_credits.amount)}
                      </span>
                    </section>
                    <span className="spacer"></span>
                  </>
                )}
              <section className="customer_tools_section__item customer_tools_section__item--borders">
                <h3 className="h4">Settings</h3>
                <span className="spacer"></span>
                <h4 className="h6">Minimum payment amount:</h4>
                <p
                  className="customer_tools_section__text"
                  data-testid="contribution_threshold"
                >
                  <strong aria-hidden className="text__colour--green">
                    +{" "}
                  </strong>
                  You‘re contributing at least{" "}
                  <strong className="text__colour--green">
                    {billSmootherConfig?.contribution_threshold
                      ? NZD.format(+billSmootherConfig?.contribution_threshold)
                      : "--.--"}
                  </strong>{" "}
                  at every bill
                </p>
                <span className="spacer"></span>
                <h4 className="h6">When to use your Bill Smoother credit:</h4>
                <p
                  className="customer_tools_section__text"
                  data-testid="withdrawal_trigger"
                >
                  <strong aria-hidden className="text__colour--crimson">
                    -{" "}
                  </strong>
                  Help pay for bills over{" "}
                  <strong className="text__colour--crimson">
                    {billSmootherConfig?.withdrawal_trigger
                      ? NZD.format(+billSmootherConfig?.withdrawal_trigger)
                      : "--.--"}
                  </strong>
                </p>
              </section>
              <section className="customer_tools_section__link_container">
                <Link
                  className="customer_tools_section__link"
                  to="/bills/bill-smoother/settings"
                >
                  <strong>Your Bill Smoother settings</strong>
                </Link>
                <ForwardIcon />
              </section>
              <section className="customer_tools_section__link_container">
                <Link
                  to="/bills/bill-smoother/more-information"
                  className="customer_tools_section__link"
                >
                  <strong>How does Bill Smoother work?</strong>
                </Link>
                <ForwardIcon />
              </section>
            </article>
          </CardBody>
        </Card>
      </section>
      <section className="page_section_column page_section__column--2">
        <article>
          <h2 className="page__heading">Transactions</h2>
          {(isTransactionFetching || !billSmootherConfig) && <LoadingSection />}

          {isTransactionError && (
            <>
              <div className="spacer" />
              <ComponentItemFallback componentTitle="Bill Smoother transactions" />
            </>
          )}

          {isTransactionSuccess && (
            <BillSmootherTransactions
              startDate={new Date()}
              transactions={storedTransactions}
            />
          )}
        </article>
      </section>
    </div>
  );
};
