import { format } from "date-fns/format";
import { isEqual } from "date-fns/isEqual";
import { subDays } from "date-fns/subDays";

/**
 * @description prepare aggregate data for AggregratedUsageSection
 */
export const prepareDailyUsageData = (
  aggregatesData: Aggregates,
  startDate: Date,
  timePeriodDifference: number,
): DailyUsage[] => {
  if (
    !aggregatesData ||
    !Array.isArray(aggregatesData.aggregates) ||
    aggregatesData.aggregates.length === 0
  ) {
    return [];
  }

  /**
   * Aggregates will contain an extra billing cycle of data at the beginning to use in the comparisons, but we don't want to include those dates in the array.
   */
  const aggregatesForPeriod = aggregatesData.aggregates.filter(
    (a) => new Date(a.start_at) >= startDate,
  );

  return aggregatesForPeriod.map((aggregate) => {
    const date = new Date(aggregate.start_at);
    const comparisonPeriod = aggregatesData.aggregates.find((a) =>
      isEqual(new Date(a.start_at), subDays(date, timePeriodDifference)),
    );

    return {
      date,
      weekday: format(date, "EEE"),
      ordinal: format(date, "d"),
      status: aggregate.status,
      previousStatus: comparisonPeriod?.status || "missing",
      thisPeriod: {
        consumption: Number(aggregate?.usage?.import_value || 0),
        spend: Number(aggregate?.tax_inclusive_cost?.import_value || 0),
        generation: Number(aggregate?.usage?.export_value || 0),
        earned: Number(aggregate?.tax_inclusive_cost?.export_value || 0),
      },
      lastPeriod: {
        consumption: Number(comparisonPeriod?.usage?.import_value || 0),
        spend: Number(comparisonPeriod?.tax_inclusive_cost?.import_value || 0),
        generation: Number(comparisonPeriod?.usage?.export_value || 0),
        earned: Number(comparisonPeriod?.tax_inclusive_cost?.export_value || 0),
      },
    };
  });
};
