/**
 * @description Function that returns a filtered array of aggregates where status is not "missing". Used in CurrentBillPeriodSection and LastPeriodComparison components
 * @returns Aggregate[] | undefined
 */
export const getValidAggregateTotals = (
  aggregatesData: Aggregates,
): Aggregate[] | undefined => {
  if (
    !aggregatesData ||
    Array.isArray(!aggregatesData.aggregates) ||
    aggregatesData.aggregates.length === 0
  )
    return undefined;

  return aggregatesData.aggregates.filter(
    (aggregate) => aggregate.status !== "missing",
  );
};

/**
 * @description Function that takes the filtered array of validAggregateTotals and returns the summed total of each aggreagte cost value. Used in CurrentBillPeriodSection and LastPeriodComparison components
 * @returns number | undefined
 */
export const getAggregatesTotalValue = (
  validAggregateTotals: Aggregate[],
): number | undefined => {
  if (
    !validAggregateTotals ||
    Array.isArray(!validAggregateTotals) ||
    validAggregateTotals.length === 0
  )
    return undefined;

  return validAggregateTotals.reduce(
    (total, aggregate) =>
      total + parseFloat(`${aggregate.tax_inclusive_cost.value}`),
    0,
  );
};
