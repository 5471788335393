import { Button } from "@flick/fep-library";
import { errorReasonDetails } from "components/NotificationBanner/utils/errorReasons";
import { useAddCreditCardPaymentMutation } from "queries/customerApi";
import React from "react";
import ReactMarkdown from "react-markdown";
import { useLocation } from "react-router-dom";
import { setNotificationBanner } from "reducers/notificationBanner";
import { useAppDispatch, useAppSelector } from "reduxUtils/hook";
import {
  NotificationBannerNames,
  NotificationBannerTypes,
} from "utils/constants";
import { navigateLink, visitLink } from "utils/htmlLinks/visitLink";
import { jwtClient } from "utils/jwtClient";

import content from "./static/content.json";
const { cardButtonLabel, cardMethodInfoText } = content;

export const CreditCardPaymentForm: React.FC = () => {
  const { pathname } = useLocation();

  const { accountNumber, nativeRenderMode } = useAppSelector(
    (store) => store.currentAccount,
  );
  const dispatch = useAppDispatch();

  const [addCreditCardPayment] = useAddCreditCardPaymentMutation();

  /** For detail on windcave transactions see doc/architecture/decisions/0010-processing-credit-card-payments.md */
  const handleAddCardClicked = async () => {
    try {
      const data = await addCreditCardPayment({
        accountIdentifier: accountNumber,
        jwtClient,
        redirectUrl: `${process.env.REACT_APP_PUBLIC_URL}/account/billing`,
      }).unwrap();

      const windCaveUrl = data.attributes.url;
      if (windCaveUrl && data.id) {
        window.sessionStorage.setItem("windcaveTransactionId", data.id);

        if (nativeRenderMode) {
          visitLink({
            url: windCaveUrl,
          });
        } else {
          navigateLink(windCaveUrl);
        }
      }
    } catch (err) {
      // Display error banner if Billing Service fails to start WC transaction
      dispatch(
        setNotificationBanner({
          type: NotificationBannerTypes.error,
          name: NotificationBannerNames.addCardPayment,
          page: pathname,
          additionalContent: errorReasonDetails(err),
        }),
      );
    }
  };

  return (
    <div className="payment-method-container">
      <ReactMarkdown>{cardMethodInfoText}</ReactMarkdown>
      <Button
        extraClasses="button--expanded spacer"
        onClick={handleAddCardClicked}
      >
        {cardButtonLabel}
      </Button>
    </div>
  );
};
