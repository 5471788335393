/**
 * @description calculate total import cost change percentage between selected day and and same day of the previous period
 * @param usageData
 * @returns number | null - the calculated percentage change or null if usageData or thisPeriod/lastPeriod spend values are invalid
 */
export const calculateImportCostChangePercentage = (
  usageData: DailyUsage,
): number | null => {
  if (!usageData || !usageData.thisPeriod.spend || !usageData.lastPeriod.spend)
    return null;
  return 100 * (usageData.thisPeriod.spend / usageData.lastPeriod.spend - 1);
};

/**
 * @description calculate total export revenue change percentage between selected day and and same day of the previous period
 * @param usageData
 * @returns number | null - the calculated percentage change or null if usageData or thisPeriod/lastPeriod earned values are invalid
 */
export const calculateExportRevenueChangePercentage = (
  usageData: DailyUsage,
): number | null => {
  if (
    !usageData ||
    !usageData.thisPeriod.earned ||
    !usageData.lastPeriod.earned
  )
    return null;
  return 100 * (usageData.thisPeriod.earned / usageData.lastPeriod.earned - 1);
};
