import React from "react";

type NoAvailableDataProps = {
  startDate?: string;
};

/**
 * @description Fallback for when (AggregatedUsageSection | UsagePage) could not fetch data
 */
export const NoAvailableData: React.FC<NoAvailableDataProps> = () => (
  <div className="usage_page">
    <section className="page_section page_section--single_column">
      <section className="page_section__column">
        <h1 className="h2 page__heading">No usage data yet!</h1>
        <p>
          Your daily usage information can take a few days to appear here.
          Please check back soon!
        </p>
      </section>
    </section>
  </div>
);

/**
 * @description Fallback for when HomePage Daily usage could not fetch data
 */
export const NoAvailableDataHomePage: React.FC<NoAvailableDataProps> = () => (
  <div className="usage_page">
    <section className="page_section page_section--single_column">
      <section className="page_section__column">
        <h1 className="h2 page__heading">No usage data yet!</h1>
        <p>
          Your daily usage information will appear here after your first bill
          has arrived. Please check your usage tab or check back soon!
        </p>
      </section>
    </section>
  </div>
);
