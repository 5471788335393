/* eslint-disable react/no-unescaped-entities */
import React from 'react';

const IcpErrorModal = () => (
  <div className="address_bar_content address_bar_content_large">
    <h4 className="heading address_bar_title">
      Sorry we can't recognise that ICP number. Give us a call on 0800 435 425 or{' '}
      <a href="https://www.flickelectric.co.nz/contact-us/">contact us here.</a>
    </h4>
  </div>
);

export default IcpErrorModal;
