import { ReactComponent as ForwardIcon } from "app/assets/images/icon_nav_arrow_forward.svg";
import React, { ReactNode } from "react";
import { Link } from "react-router-dom";
import { NZD } from "utils/currency";

type ItemProps = {
  label: string | ReactNode;
  labelNote: string;
  value: number;
  valueNote?: string;
  href: string;
};

export const Item: React.FC<ItemProps> = ({
  label,
  labelNote,
  value,
  valueNote,
  href,
}) => {
  return (
    <>
      <Link
        to={href}
        state={{ previousPath: window.location.pathname }}
        className="customer_tools_list__link bill_list_item__link"
      >
        <div>
          <span className="sr-only">Bill for </span>
          {label}
          <br />
          <small className="customer_tools_list__text text__colour--muted">
            {labelNote}
          </small>
        </div>
        <div className="customer_tools_list__right_aligned_text_icon_content">
          <div className="customer_tools_list__right_aligned_text_icon_content--text">
            <p className="customer_tools_list__text">{NZD.format(value)}</p>
            {valueNote && (
              <small className="customer_tools_list__text text__colour--muted">
                {valueNote}
              </small>
            )}
          </div>
          <ForwardIcon />
        </div>
      </Link>
    </>
  );
};
