import { createApi } from "@reduxjs/toolkit/query/react";
import { JwtClient } from "utils/jwtClient";

import { expireTime } from "./queries.utils";

interface ApiCallParams {
  path: string;
  queryParams?: Record<string, string | number>;
  fetchParams?: RequestInit;
}

interface BaseQueryParams extends ApiCallParams {
  jwtClient: JwtClient;
}

// Define a base query function that calls jwtClient.apiCall
const apiCallBaseQuery = async ({ jwtClient, ...params }: BaseQueryParams) => {
  const response = await jwtClient.apiCall(params);
  if (
    params.path === "/payment_smoother/customer_configuration?" &&
    response.status === 404
  ) {
    return { data: null };
  }

  const {
    data: { attributes: billSmootherConfig },
  } = await response.json();
  return { data: billSmootherConfig };
};

export const paymentSmootherApi = createApi({
  reducerPath: "paymentSmootherApi",
  baseQuery: apiCallBaseQuery,
  endpoints: (builder) => ({
    getBillSmootherConfig: builder.query<
      BillSmootherConfig,
      { jwtClient: JwtClient; billingEntityId: string }
    >({
      query({ jwtClient, billingEntityId }) {
        return {
          jwtClient,
          path: "/payment_smoother/customer_configuration?",
          queryParams: {
            billable_party_ref: `/billing/billing_entities/${billingEntityId}`,
          },
          throwRollBar: false,
        };
      },
    }),
    createBillSmootherConfig: builder.mutation<
      BillSmootherConfig,
      {
        jwtClient: JwtClient;
        billingEntityId: string;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        data: { [key: string]: any }; // TODO investigate billSmootherConfig types throughout to app
      }
    >({
      query: ({ jwtClient, billingEntityId, data }) => {
        const body = {
          billable_party_ref: `/billing/billing_entities/${billingEntityId}`,
          ...data,
        };
        return {
          jwtClient,
          path: "/payment_smoother/customer_configuration",
          queryParams: {
            billable_party_ref: `/billing/billing_entities/${billingEntityId}`,
          },
          fetchParams: {
            method: "POST",
            body: JSON.stringify(body),
          },
          params: {
            headers: {
              Accept: "application/vnd.flick+json",
              "Content-Type": "application/vnd.flick+json",
            },
          },
        };
      },
    }),
    updateBillSmootherConfig: builder.mutation<
      BillSmootherConfig,
      {
        jwtClient: JwtClient;
        billingEntityId: string;
        data: BillSmootherConfig;
      }
    >({
      query: ({ jwtClient, billingEntityId, data }) => {
        const body = {
          billable_party_ref: `/billing/billing_entities/${billingEntityId}`,
          ...data,
        };
        return {
          jwtClient,
          path: "/payment_smoother/customer_configuration",
          queryParams: {
            billable_party_ref: `/billing/billing_entities/${billingEntityId}`,
          },
          fetchParams: {
            method: "PUT",
            body: JSON.stringify(body),
          },
          params: {
            headers: {
              Accept: "application/vnd.flick+json",
              "Content-Type": "application/vnd.flick+json",
            },
          },
        };
      },
    }),
  }),
  keepUnusedDataFor: expireTime,
});

export const {
  useGetBillSmootherConfigQuery,
  useCreateBillSmootherConfigMutation,
  useUpdateBillSmootherConfigMutation,
} = paymentSmootherApi;
export const paymentSmootherApiReducer = paymentSmootherApi.reducer;
