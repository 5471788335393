import React, { useContext, useState } from "react";
import { isEmptyValue, isValidInput } from "utils/forms/forms";
import { passwordValidation } from "utils/validations/passwordValidation";
import { validationFunctionHelper } from "utils/validations/validationFunctionHelper";

import content from "./static/content.json";
const {
  currentPasswordRequired,
  newPasswordBlankError,
  newPasswordInvalidError,
  confirmNewError,
} = content;

const initialFormFieldState = {
  error: "",
  isRequired: true,
  isValid: false,
  value: "",
};

type UpdatePasswordFormContext = {
  currentPassword?: FormField;
  newPassword?: FormField;
  confirmPassword?: FormField;
  isFormValid?: boolean;
};

const initialContext: UpdatePasswordFormContext = {};

const validationCurrentPassword = (value: string) => {
  return !isEmptyValue(value);
};

const validationNewPassword = (value: string, newPasswordValue?: string) => {
  return validationFunctionHelper({
    value,
    validationFunction: newPasswordValue
      ? () => value === newPasswordValue
      : passwordValidation,
    invalidError: newPasswordValue ? confirmNewError : newPasswordInvalidError,
    requiredError: newPasswordValue ? confirmNewError : newPasswordBlankError,
  });
};

const UpdatePasswordFormContext = React.createContext(initialContext);
export const useUpdatePasswordFormContext = () =>
  useContext(UpdatePasswordFormContext);

export const UpdatePasswordFormContextProvider = ({
  children,
}: {
  children: JSX.Element;
}): JSX.Element => {
  const [currentPassword, setCurrentPassword] = useState(initialFormFieldState);
  const [newPassword, setNewPassword] = useState(initialFormFieldState);
  const [confirmPassword, setConfirmPassword] = useState(initialFormFieldState);

  const setValueCurrentPassword = (value: string) => {
    setCurrentPassword((prev) => ({
      ...prev,
      isValid: true,
      error: validationCurrentPassword(value) ? "" : currentPasswordRequired,
      value,
    }));
  };

  const setValueNewPassword = (value: string, setError?: boolean) => {
    const fieldInError = newPassword.error;
    const error = validationNewPassword(value);
    setNewPassword((prev) => ({
      ...prev,
      error: fieldInError || setError ? error : "",
      isValid: !error,
      value,
    }));
  };

  const setValueConfirmPassword = (value: string, setError?: boolean) => {
    const fieldInError = confirmPassword.error;
    const error = validationNewPassword(value, newPassword.value);
    setConfirmPassword((prev) => ({
      ...prev,
      error: fieldInError || setError ? error : "",
      isValid: !error,
      value,
    }));
  };

  const allInputsValid = [
    isValidInput(currentPassword),
    isValidInput(newPassword),
    isValidInput(confirmPassword),
  ].every(Boolean);

  return (
    <UpdatePasswordFormContext.Provider
      value={{
        currentPassword: {
          fieldValue: currentPassword,
          handleOnChange: (value) => setValueCurrentPassword(value),
          handleOnBlur: () => setValueCurrentPassword(currentPassword.value),
        },
        newPassword: {
          fieldValue: newPassword,
          handleOnChange: (value) => setValueNewPassword(value, false),
          handleOnBlur: () => setValueNewPassword(newPassword.value, true),
        },
        confirmPassword: {
          fieldValue: confirmPassword,
          handleOnChange: (value) => setValueConfirmPassword(value, false),
          handleOnBlur: () =>
            setValueConfirmPassword(confirmPassword.value, true),
        },
        isFormValid: allInputsValid,
      }}
    >
      {children}
    </UpdatePasswordFormContext.Provider>
  );
};
