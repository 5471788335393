const sendAnalytics = (action = null, label = null, value = null) => {
  const params = { eventCategory: 'Plan chooser' };

  if (action) {
    params.eventAction = action;
  }
  if (label) {
    params.eventLabel = label;
  }
  if (value) {
    params.eventValue = value;
  }

  /* global ga */
  if (typeof window.ga !== 'undefined') {
    try {
      // https://stackoverflow.com/a/29434548
      const trackerName = ga.getAll()[0].get('name');
      window.ga(`${trackerName}.send`, 'event', params);
    } catch (error) {
      // ga blocked, fail gracefully
    }
  }
};

export const sendAnalyticsToDataLayer = (eventName = null, eventData = {}) => {
  const params = { event: eventName };
  Object.assign(params, eventData);
  /* ensure the datalayer is initialised */
  if (typeof window.dataLayer !== 'undefined') {
    try {
      window.dataLayer.push(params);
    } catch (error) {
      // ga blocked, fail gracefully
    }
  }
};

export const onMouseEnter = (planVariant) => {
  const plan = planVariant.includes('business') ? 'business' : planVariant;

  sendAnalytics('Price pin tooltip viewed', plan);
};

export default sendAnalytics;
