/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import "../styles/OffpeakDetailsSection.scss";

import { ReactComponent as ChevronDownSvg } from "app/assets/images/chevron_black.svg";
import { ReactComponent as DownArrowSvg } from "assets/icon_arrow_down.svg";
import { formatRelative } from "date-fns";
import React, { useMemo, useState } from "react";
import { useAppSelector } from "reduxUtils/hook";
import { calculateRemainingShiftTime } from "utils/offpeakCalculations/calculateRemainingShiftTime";
import { convertTimeFrom24To12Format } from "utils/offpeakCalculations/convertTimeFrom24To12Format";
import { findCurrentShift } from "utils/offpeakCalculations/findCurrentShift";
import { findCurrentRateCard } from "utils/rateCardHelpers/findCurrentRateCard";
import { findCurrentTimeTable } from "utils/rateCardHelpers/findCurrentTimetable";

import { ADD_NZ_GST } from "../utils/currency";
import { ComponentItemFallback } from "./fallback/ComponentItemFallback";
import { LoadingSection } from "./LoadingSection";

const shiftColour = (currentShiftPeriodType) =>
  currentShiftPeriodType === "Offpeak" ? "--green" : "--yellow";

export const OffpeakDetailsSection: React.FC = () => {
  const currentProduct = useAppSelector((store) => store.currentProduct);
  const { rateCards, isRateCardError, isRateCardLoading } = useAppSelector(
    (store) => store.rateCards,
  );

  const [showOffpeakTimes, setShowOffpeakTimes] = useState(false);

  //----- @TODO move all caculations onto the store, keep the component clean

  const currentDate = new Date();

  // Find the rate card where the current date falls between the start_at date and end_at date.
  // return undefined and cause everything to fall over if rateCard.attributes.product_name is not exist
  const currentRateCard = useMemo(() => {
    return currentProduct.normalisedName
      ? findCurrentRateCard(
          rateCards,
          currentProduct.normalisedName,
          currentDate,
        )
      : undefined;
  }, [rateCards, currentProduct.normalisedName]);

  // need to know what the current timetable is i.e. weekday or weekend timetable
  const currentTimetable = useMemo(
    () => findCurrentTimeTable(currentRateCard, currentDate),
    [currentRateCard],
  );
  const currentShiftDetails = useMemo(
    () => findCurrentShift(currentTimetable, currentDate),
    [currentTimetable],
  );

  const calculatedShiftTime = useMemo(
    () => calculateRemainingShiftTime(currentShiftDetails, currentDate),
    [currentShiftDetails],
  );

  // Timetable and Rates info for details/accordion section
  // Offpeak timetable data
  const offpeakRatesTimetables = useMemo(
    () =>
      currentRateCard?.attributes?.timetables?.find(
        (timetables) => timetables.rate_code === "Offpeak",
      ),
    [currentRateCard],
  );

  // Offpeak Weekday Shift Times
  const offpeakWeekdaysTimetable = useMemo(
    () =>
      offpeakRatesTimetables?.timetable?.find(
        (timetable) => timetable.weekends === false,
      ),
    [offpeakRatesTimetables],
  );

  // Offpeak Weekend Shift Times
  const offpeakWeekendTimetable = useMemo(
    () =>
      offpeakRatesTimetables?.timetable?.find(
        (timetable) => timetable.weekends === true,
      ),
    [offpeakRatesTimetables],
  );

  const shiftLastsAllDay = useMemo(
    () =>
      offpeakWeekendTimetable?.shifts?.[0]?.start_at ===
      offpeakWeekendTimetable?.shifts?.[0]?.end_at,
    [offpeakWeekendTimetable],
  );

  // Usage Rate data
  const usageRates = useMemo(
    () =>
      currentRateCard?.attributes?.usage_rates?.filter((usage_rates) =>
        usage_rates.rates?.find((rate) => rate.rate_code === "Offpeak"),
      ),
    [currentRateCard],
  );

  const offpeakRate = useMemo(
    () =>
      usageRates?.map((rates) =>
        rates.rates.find((rate) => rate.rate_code === "Offpeak"),
      ),
    [usageRates],
  );

  const offpeakTotalInclGst = useMemo(
    () => Number(offpeakRate?.[0]?.total) * ADD_NZ_GST,
    [offpeakRate],
  );

  const peakRate = useMemo(
    () =>
      usageRates?.map((rates) =>
        rates.rates.find((rate) => rate.rate_code === "Peak"),
      ),
    [usageRates],
  );

  const peakTotalInclGst = useMemo(
    () => Number(peakRate?.[0]?.total) * ADD_NZ_GST,
    [peakRate],
  );

  const offpeakSavingsPercentage = useMemo(
    () =>
      ((Number(peakTotalInclGst) - Number(offpeakTotalInclGst)) /
        Number(peakTotalInclGst)) *
      100,
    [peakTotalInclGst],
  );

  //----- @TODO move all caculations onto the store, keep the component clean
  if (isRateCardLoading || !currentRateCard) return <LoadingSection />;

  if (isRateCardError)
    return <ComponentItemFallback componentTitle={"Off peak details"} />;

  const onToggle = () => {
    setShowOffpeakTimes(!showOffpeakTimes);
  };

  return (
    <>
      {currentShiftDetails && calculatedShiftTime && (
        <section className="offpeak-details__shift-progress">
          <div className="offpeak-details__shift-progress-text">
            <p>
              <span
                className={`text_highlight text_highlight${shiftColour(currentShiftDetails?.currentShift?.rateCode)}`}
              >
                Off-peak{" "}
                {currentShiftDetails?.currentShift?.rateCode === "Offpeak"
                  ? "now"
                  : "later"}
              </span>
            </p>
            {currentShiftDetails?.currentShift?.rateCode &&
              calculatedShiftTime.timeRemainingInWords && (
                <p
                  className={`h4 text_highlight text_highlight${shiftColour(currentShiftDetails?.currentShift?.rateCode)}`}
                >
                  {currentShiftDetails?.currentShift?.rateCode === "Offpeak"
                    ? "Ends"
                    : "Starts"}{" "}
                  in {calculatedShiftTime.timeRemainingInWords}
                </p>
              )}
            <p className="small">
              {currentShiftDetails?.currentShift?.rateCode === "Offpeak"
                ? "Ends"
                : "Starts"}{" "}
              {formatRelative(
                new Date(currentShiftDetails.endDate),
                currentDate,
              )}
            </p>
          </div>
          {calculatedShiftTime && (
            <svg viewBox="0 0 36 36" className="progress__circle">
              <path
                className="progress__circle-outline"
                d="M18 2.0845
                        a 15.9155 15.9155 0 0 1 0 31.831
                        a 15.9155 15.9155 0 0 1 0 -31.831"
              />
              <path
                className={`progress__circle-outline progress__circle-outline${shiftColour(currentShiftDetails?.currentShift?.rateCode)}`}
                strokeDasharray={`${
                  100 - calculatedShiftTime.timeRemainingPercentage
                } 100`}
                d="M18 2.0845
                        a 15.9155 15.9155 0 0 1 0 31.831
                        a 15.9155 15.9155 0 0 1 0 -31.831"
              />
            </svg>
          )}
        </section>
      )}
      {offpeakTotalInclGst && (
        <>
          <section className="offpeak-details">
            <details className="customer_tools_list customer_tools_list--endless offpeak-details-accordion">
              <summary
                onClick={onToggle}
                className="customer_tools_list__item offpeak_toggle__item"
              >
                <p className="h6 offpeak-details-accordion__text customer_tools_list__text">
                  {showOffpeakTimes ? "Hide" : "Show"} my off-peak times
                </p>
                <ChevronDownSvg className="offpeak-details-accordion__icon" />
              </summary>
              <section className="offpeak-details-accordion__section customer_tools_list__item">
                <div className="offpeak-details-accordion__content">
                  <p className="h5">Weekdays</p>
                  {offpeakWeekdaysTimetable?.shifts?.map((shift, index) => (
                    <div key={`${index}`}>
                      <p className="offpeak-details-accordion__shifts">
                        {shift.start_at &&
                          convertTimeFrom24To12Format(shift.start_at)}{" "}
                        -{" "}
                        {shift.end_at &&
                          convertTimeFrom24To12Format(shift.end_at)}
                      </p>
                    </div>
                  ))}
                </div>
                <div className="offpeak-details-accordion__content">
                  <p className="h5">Weekends</p>
                  <p>{shiftLastsAllDay ? "All day" : " "}</p>
                </div>
              </section>
              <section>
                <div className="offpeak-details-accordion__section customer_tools_list__item">
                  <div className="offpeak-details-accordion__content">
                    <p>Off-peak rate</p>
                    <p className="h3">
                      {(Number(offpeakTotalInclGst) * 100).toFixed(3)}{" "}
                      <span className="h5">c/kWh</span>
                    </p>
                  </div>
                  <div className="offpeak-details-accordion__content">
                    <p>Peak rate</p>
                    <p className="h3">
                      {(Number(peakTotalInclGst) * 100).toFixed(3)}{" "}
                      <span className="h5">c/kWh</span>
                    </p>
                  </div>
                </div>
                <div>Rates include GST.</div>
                <div className="spacer" />
              </section>
              {offpeakSavingsPercentage > 0 && (
                <div className="header_section">
                  <DownArrowSvg className="arrow icon_colour--green" />
                  <p>
                    <span className="h5">
                      Save {offpeakSavingsPercentage.toFixed(0)}%
                    </span>{" "}
                    at off-peak times
                  </p>
                </div>
              )}
            </details>
          </section>
        </>
      )}
    </>
  );
};
