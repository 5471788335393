/* eslint-disable @typescript-eslint/no-unused-vars */
import { createApi } from "@reduxjs/toolkit/query/react";
import { BILL_QUERY_LIMIT } from "utils/constants";
import { ADD_NZ_GST } from "utils/currency";
import { JwtClient } from "utils/jwtClient";

import { formatBillingEntityAttributes } from "./billingEntity.utils";
import { expireTime } from "./queries.utils";

interface ApiCallParams {
  path: string;
  queryParams?: Record<string, string | number>;
}

interface BaseQueryParams extends ApiCallParams {
  jwtClient: JwtClient;
}

export type GetInvoiceTimelineParams = {
  jwtClient: JwtClient;
  customerNumber: string;
  endDate?: string;
};

export type GetInvoiceParams = {
  jwtClient: JwtClient;
  invoiceNumber: string | number;
};

export type GetLatestReceivableInvoiceParams = {
  jwtClient: JwtClient;
  customerNumber: string;
};

export type GetInvoiceResourceUrlsParams = {
  jwtClient: JwtClient;
  invoiceUUID: string;
};

export type GetBillingBalanceParams = {
  jwtClient: JwtClient;
  customerNumber: string;
};

export type GetBillSmootherTransactionsParams = {
  jwtClient: JwtClient;
  customerNumber: string;
  endDate: string;
};

export type GetOutstandingInvoicesParams = {
  jwtClient: JwtClient;
  customerNumber: string;
};

export type GetChargesParams = {
  customerNumber: string;
  endAt: string;
  jwtClient: JwtClient;
  startAt: string;
  supplyNodeRef: string;
};

export type PayOverdueBillParams = {
  jwtClient: JwtClient;
  invoiceID: string;
  invoiceNumber: string;
};

const sortedInvoiceTimeline = (bills: InvoiceSummary[]): InvoiceSummary[] => {
  return bills.sort(
    (a, b) =>
      +b.attributes.billing_period.period_started_at -
      +a.attributes.billing_period.period_started_at,
  );
};

// Define a base query function that calls jwtClient.apiCall
const apiCallBaseQuery = async ({ jwtClient, ...params }: BaseQueryParams) => {
  const response = await jwtClient.apiCall(params);
  const result = await response.json();

  // for invoice formatting we need the included field
  if (params.path.includes("/billing/invoices/")) {
    return { data: result };
  }

  return { data: result.data };
};

export const billingApi = createApi({
  reducerPath: "billingApi",
  baseQuery: apiCallBaseQuery,
  tagTypes: ["OutstandingInvoices", "BillingEntity"],
  endpoints: (builder) => ({
    getBillingEntity: builder.query<
      BillingEntity,
      { jwtClient: JwtClient; customerNumber: string }
    >({
      query: ({ jwtClient, customerNumber }) => {
        return {
          jwtClient,
          path: `/billing/customer_billing_entities/${customerNumber}`,
        };
      },
      transformResponse: (data) => {
        const { id, attributes } = data;
        const formattedAttributes = formatBillingEntityAttributes(attributes);
        return { id, ...formattedAttributes };
      },
      providesTags: ["BillingEntity"],
    }),
    getInvoiceTimeline: builder.query<
      InvoiceSummary[],
      GetInvoiceTimelineParams
    >({
      query: ({ jwtClient, customerNumber, endDate }) => {
        const endAt = endDate ? endDate : new Date().toString();
        return {
          jwtClient,
          path: `/billing/customer_billing_entities/${customerNumber}/invoice_timeline`,
          queryParams: {
            profile: "base,internal_only",
            end_at: endAt,
            limit: BILL_QUERY_LIMIT,
            show_payment_transactions: "true",
          },
        };
      },
      transformResponse: (data: InvoiceSummary[]) => {
        return sortedInvoiceTimeline(data);
      },
    }),
    getInvoice: builder.query<Invoice, GetInvoiceParams>({
      query: ({ jwtClient, invoiceNumber }) => {
        return {
          jwtClient,
          path: `/billing/invoices/${invoiceNumber}`,
          queryParams: {
            invoice_identifier: invoiceNumber,
            group_line_items: "true",
            swallow_invoiced_error: "true",
            optional_profiles: "with_payments",
          },
        };
      },
      transformResponse: ({ data, included }) => {
        const { id, attributes: bill } = data;
        return { id, bill, lineItems: included };
      },
    }),
    getLatestReceivableInvoice: builder.query<
      ReceivableInvoice,
      GetLatestReceivableInvoiceParams
    >({
      query: ({ jwtClient, customerNumber }) => {
        return {
          jwtClient,
          path: "/billing/invoices",
          queryParams: {
            customer_identifier: customerNumber,
          },
        };
      },
    }),
    getInvoiceResourceUrls: builder.query<
      InvoiceResourceUrls,
      GetInvoiceResourceUrlsParams
    >({
      query: ({ jwtClient, invoiceUUID }) => {
        return {
          jwtClient,
          path: `/billing/invoices/${invoiceUUID}/resource_urls`,
        };
      },
      transformResponse: ({ data }) => {
        const { attributes: resource_urls } = data;
        return { resource_urls };
      },
    }),
    getBillingBalance: builder.query<BillingBalance, GetBillingBalanceParams>({
      query: ({ jwtClient, customerNumber }) => {
        return {
          jwtClient,
          path: "/billing/balance",
          queryParams: {
            customer_identifier: customerNumber,
          },
        };
      },
      transformResponse: (data) => {
        return data.attributes;
      },
    }),
    getBillSmootherTransactions: builder.query<
      BillSmootherTransaction[],
      GetBillSmootherTransactionsParams
    >({
      query: ({ jwtClient, customerNumber, endDate }) => {
        const endAt = endDate ? endDate : new Date().toString();
        return {
          jwtClient,
          path: `/billing/customer_transactions`,
          queryParams: {
            customer_id: customerNumber,
            limit: BILL_QUERY_LIMIT,
            end_at: endAt,
            sort: "desc",
            account: "balance",
          },
        };
      },
    }),
    getOutstandingInvoices: builder.query<
      OutstandingInvoiceSummary[],
      GetOutstandingInvoicesParams
    >({
      query: ({ jwtClient, customerNumber }) => {
        return {
          jwtClient,
          path: `/billing/invoices/outstanding_invoices`,
          queryParams: {
            customer_identifier: customerNumber,
          },
        };
      },
      transformResponse: ({ data }) => {
        return data;
      },
      providesTags: ["OutstandingInvoices"],
    }),
    getCharges: builder.query<Charges, GetChargesParams>({
      query: ({ customerNumber, endAt, jwtClient, startAt, supplyNodeRef }) => {
        return {
          jwtClient,
          path: `/billing/charges/${customerNumber}`,
          queryParams: {
            customer_identifier: customerNumber,
            end_at: endAt,
            start_at: startAt,
            supply_node_ref: supplyNodeRef,
          },
        };
      },
      transformResponse: (data) => {
        const { attributes } = data;
        attributes.summed_charges.forEach((item: SummedCharges) => {
          item.total_cost = (Number(item.total_cost) * ADD_NZ_GST).toString();
        });
        return attributes;
      },
    }),
    payOverdueBill: builder.query<{ dps_uri: string }, PayOverdueBillParams>({
      query: ({ jwtClient, invoiceID, invoiceNumber }) => {
        return {
          jwtClient,
          path: `/billing/invoices/${invoiceID}/create_payment`,
          queryParams: {
            redirect_url: `${process.env.REACT_APP_PUBLIC_URL}/bills/${invoiceNumber}/`,
          },
          params: {
            headers: {
              Accept: "application/vnd.flick+json",
              "Content-Type": "application/vnd.flick+json",
            },
          },
        };
      },
    }),
  }),
  keepUnusedDataFor: expireTime,
});

export const {
  useGetBillingBalanceQuery,
  useGetBillingEntityQuery,
  useGetBillSmootherTransactionsQuery,
  useGetChargesQuery,
  useGetInvoiceQuery,
  useGetInvoiceTimelineQuery,
  useGetLatestReceivableInvoiceQuery,
  useGetOutstandingInvoicesQuery,
  useLazyPayOverdueBillQuery,
  useLazyGetInvoiceResourceUrlsQuery,
  useLazyGetInvoiceTimelineQuery,
  useLazyGetBillSmootherTransactionsQuery,
  useLazyGetOutstandingInvoicesQuery,
} = billingApi;
