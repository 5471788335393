import "./HelpPageStyles.scss";

import { ReactComponent as ExternalLinkIcon } from "assets/icon-open-external-link.svg";
import { AccountHeaderSection } from "components/AccountHeaderSection";
import React from "react";

import content from "./static/content.json";
const {
  contactUsFormLink,
  contactUsFormText,
  contactUsHeading,
  disputesContactText,
  disputesEmail,
  disputesHeading,
  disputesText,
  officeHoursMonFri,
  // officeHoursWeekend,
  officeHoursHeading,
  helpCentreLink,
  pageHeader,
} = content;

export const HelpPage = () => {
  return (
    <div className="help_details_page">
      <AccountHeaderSection label={pageHeader} />
      <section className="customer_tools_section">
        <article className="customer_tools_section__link_container customer_tools_section__item--borders">
          <a
            aria-label="Visit Flick help centre"
            className="customer_tools_section__link account_page__link--with-icon"
            href={helpCentreLink}
            target="_blank"
          >
            <ExternalLinkIcon className="nav_customer_tools__item_link_icon account_page__link__icon" />
            <p className="customer_tools_list__text">
              <strong>Flick Help Centre</strong>
            </p>
          </a>
        </article>
      </section>
      <h2 className="account_header__label_text heading">{contactUsHeading}</h2>
      <section className="customer_tools_section">
        <article className="customer_tools_section__link_container customer_tools_section__item--borders">
          <a
            aria-label="Visit Flick contact form"
            className="customer_tools_section__link account_page__link--with-icon"
            href={contactUsFormLink}
            target="_blank"
          >
            <ExternalLinkIcon className="nav_customer_tools__item_link_icon account_page__link__icon" />
            <p className="customer_tools_list__text">
              <strong>{contactUsFormText}</strong>
            </p>
          </a>
        </article>
      </section>
      <section>
        <h2 className="h5">{officeHoursHeading}</h2>
        <p>
          {officeHoursMonFri}
          {/* <br />
          {officeHoursWeekend} */}
        </p>
        <h2 className="h5">{disputesHeading}</h2>
        <p>{disputesText}</p>
        <p>
          {disputesContactText}
          <a
            aria-label={`Send email to ${disputesEmail}`}
            className="help_page_disputes_email text__colour--primary"
            href={`mailto:${disputesEmail}`}
          >
            <strong>{disputesEmail}</strong>
          </a>
        </p>
      </section>
    </div>
  );
};
