import React, { useContext, useState } from "react";
import { isValidInput } from "utils/forms/forms";
import { emailValidation } from "utils/validations/emailValidation";
import {
  mobilePhoneNumberValidation,
  phoneNumberValidation,
} from "utils/validations/phoneNumberValidation";
import { preferredNameValidation } from "utils/validations/preferredNameValidation";
import { validationFunctionHelper } from "utils/validations/validationFunctionHelper";

import { prefixRemovedNZNumber } from "./AccountDetailsPage.utils";
import content from "./static/content.json";
const {
  emailRequiredError,
  emailInvalidError,
  homeNumberRequired,
  phoneNumberInvalidError,
  preferredNameRequiredError,
  preferredNameInvalidError,
  mobileNumberRequired,
  mobileNumberInvalidError,
} = content;

interface AccountDetailsContext {
  email?: FormField;
  homeNumber?: FormField;
  isFormValid?: boolean;
  mobileNumber?: FormField;
  preferredName?: FormField;
}

const initialContext: AccountDetailsContext = {};

const AccountDetailsFormContext = React.createContext(initialContext);

const initialFormFieldState = {
  error: "",
  isRequired: true,
  isValid: false,
  value: "",
  extraProps: undefined,
};

const validationPreferredName = (value: string) => {
  return validationFunctionHelper({
    value,
    validationFunction: preferredNameValidation,
    requiredError: preferredNameRequiredError,
    invalidError: preferredNameInvalidError,
  });
};

const validationEmail = (value: string) => {
  return validationFunctionHelper({
    value,
    validationFunction: emailValidation,
    requiredError: emailRequiredError,
    invalidError: emailInvalidError,
  });
};

const validationHomeNumber = (value: string, isRequired?: boolean) => {
  return validationFunctionHelper({
    value,
    validationFunction: phoneNumberValidation,
    invalidError: phoneNumberInvalidError,
    ...(isRequired && { requiredError: homeNumberRequired }),
  });
};

const validationMobileNumber = (value: string) => {
  return validationFunctionHelper({
    value,
    validationFunction: mobilePhoneNumberValidation,
    requiredError: mobileNumberRequired,
    invalidError: mobileNumberInvalidError,
  });
};

export const useAccountDetailsFormContext = () =>
  useContext(AccountDetailsFormContext);

export const AccountDetailsFormContextProvider = ({
  children,
}: {
  children: JSX.Element;
}): JSX.Element => {
  const [preferredName, setPreferredName] = useState(initialFormFieldState);
  const [email, setEmail] = useState(initialFormFieldState);
  const [mobileNumbers, setMobileNumbers] = useState([initialFormFieldState]);
  const [homeNumbers, setHomeNumbers] = useState([
    { ...initialFormFieldState, isRequired: false },
  ]);

  /**
   * Note for future work: The form only supports updating existing, not creating new phone numbers.
   * This means we can only render inputs for existing numbers. ie if a customer has no optional home number
   * the home number input will not be present in the form
   */
  const addDynamicPhoneNumberInputs = ({ values, type }) => {
    const inputArray = values.map((pn: PhoneNumber) => {
      return {
        ...initialFormFieldState,
        extraProps: { id: pn.id },
        ...(type === "home" && { isRequired: !!pn.number }), // if a home number already exists, it is required
      };
    });
    if (type === "mobile") {
      setMobileNumbers(inputArray);
      return;
    }
    setHomeNumbers(inputArray);
  };

  const setValuePreferredName = (value: string, setError: boolean) => {
    const fieldInError = preferredName.error;
    const error = validationPreferredName(value);
    setPreferredName((prev) => ({
      ...prev,
      error: fieldInError || setError ? error : "",
      isValid: !error,
      value,
    }));
  };

  const setValueEmail = (value: string, setError: boolean) => {
    const fieldInError = email.error;
    const error = validationEmail(value);
    setEmail((prev) => ({
      ...prev,
      error: fieldInError || setError ? error : "",
      isValid: !error,
      value,
    }));
  };

  const setValueMobileNumber = (
    value: string,
    index: number,
    setError: boolean,
  ) => {
    const fieldInError = mobileNumbers[index]?.error || "";
    const error = validationMobileNumber(prefixRemovedNZNumber(value));
    setMobileNumbers((prev) => {
      prev[index] = {
        ...prev[index],
        error: fieldInError || setError ? error : "",
        isValid: !error,
        value,
      };
      return [...prev];
    });
  };

  const setValueHomeNumber = (
    value: string,
    index: number,
    setError: boolean,
  ) => {
    const fieldInError = homeNumbers[index]?.error || "";
    const error = validationHomeNumber(value, homeNumbers[index]?.isRequired);
    setHomeNumbers((prev) => {
      prev[index] = {
        ...prev[index],
        error: fieldInError || setError ? error : "",
        isValid: !error,
        value,
      };
      return [...prev];
    });
  };

  /**
   * @description Checking if all the inputs are valid to enable the submit button
   */
  const allInputsValid = [
    isValidInput(preferredName),
    isValidInput(email),
    ...[...mobileNumbers, ...homeNumbers].map((number: FormFieldDetails) =>
      isValidInput(number),
    ),
  ].every(Boolean);

  return (
    <AccountDetailsFormContext.Provider
      value={{
        preferredName: {
          fieldValue: preferredName,
          handleOnChange: (value) => setValuePreferredName(value, false),
          handleOnBlur: () => setValuePreferredName(preferredName.value, true),
        },
        email: {
          fieldValue: email,
          handleOnChange: (value) => setValueEmail(value, false),
          handleOnBlur: () => setValueEmail(email.value, true),
        },
        mobileNumber: {
          fieldValueArray: mobileNumbers,
          addDynamicInputs: addDynamicPhoneNumberInputs,
          handleOnChange: (value, index) =>
            setValueMobileNumber(value, index, false),
          handleOnBlur: (index) =>
            setValueMobileNumber(mobileNumbers[index].value, index, true),
        },
        homeNumber: {
          fieldValueArray: homeNumbers,
          addDynamicInputs: addDynamicPhoneNumberInputs,
          handleOnChange: (value, index) =>
            setValueHomeNumber(value, index, false),
          handleOnBlur: (index) =>
            setValueHomeNumber(homeNumbers[index].value, index, true),
        },
        isFormValid: allInputsValid,
      }}
    >
      {children}
    </AccountDetailsFormContext.Provider>
  );
};
