/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable import/no-default-export */
import {
  ValidationMessagePrefix,
  ValidationMessages,
} from "./field_validation_messages";

export default function FlickForm(elem) {
  var FORM_FIELD_ERROR_CLASS = "input--error",
    FORM_FIELD_HELPER_CLASS = "input__helper",
    FORM_FIELD_HELPER_ERROR_CLASS = "input__helper--error",
    ff = this;

  ff.elem = elem;

  function _getErrorMessageText(field, errorType) {
    var customErrorMessageAttribute =
      ValidationMessagePrefix + ValidationMessages[errorType].dataAttribute;
    if (field.hasAttribute(customErrorMessageAttribute)) {
      return field.getAttribute(customErrorMessageAttribute);
    }
    return ValidationMessages[errorType].message;
  }

  function _addFieldSpecificError(field) {
    _addErrorClasses(field);
    for (var keyName in field.validity) {
      if (keyName !== "valid" && field.validity[keyName]) {
        var errorMessage = document.createElement("span");
        if (field.id) errorMessage.setAttribute("for", field.id);
        errorMessage.classList.add(
          FORM_FIELD_HELPER_CLASS,
          FORM_FIELD_HELPER_ERROR_CLASS,
        );
        errorMessage.innerHTML = _getErrorMessageText(field, keyName);
        field.parentNode.parentNode.appendChild(errorMessage);
      }
    }
  }

  function _isNotAFormElement(field) {
    return (
      field.nodeName === "FIELDSET" ||
      field.disabled ||
      field.nodeName === "BUTTON"
    );
  }

  function _checkFieldState(field) {
    if (field.validity && !field.validity.valid) {
      if (field.type !== "radio") {
        _addFieldSpecificError(field);
      } else {
        var formScope = field.closest("form");
        if (formScope) {
          var arrayOfMatchingRadios = formScope.querySelectorAll(
            '[name="' + field.name + '"]',
          );
          if (
            arrayOfMatchingRadios[arrayOfMatchingRadios.length - 1] == field
          ) {
            _addFieldSpecificError(field);
          }
        }
      }
    }
  }

  function _handleChange(e) {
    var sourceElem = e.target;
    if (_isNotAFormElement(sourceElem)) return;
    _removeErrorUI(sourceElem);
    _checkFieldState(sourceElem);
  }

  function _addErrorClasses(targetElem) {
    targetElem.parentNode.classList.add(FORM_FIELD_ERROR_CLASS);
    targetElem.classList.add(FORM_FIELD_ERROR_CLASS);
  }

  function _removeErrorClasses(targetElem) {
    var testSelectors = [FORM_FIELD_ERROR_CLASS];
    for (var i in testSelectors) {
      var selector = testSelectors[i];
      var affectedElems = targetElem.querySelectorAll("." + selector);
      for (var j = 0; j < affectedElems.length; j++) {
        affectedElems[j].classList.remove(selector);
      }
    }
  }

  function _removeErrorUI(currentElem) {
    var targetNode =
      currentElem.nodeName === "FORM"
        ? currentElem
        : currentElem.closest(".form_row");
    _removeErrorClasses(targetNode);
    _removeAllErrorMessages(targetNode);
  }

  function _removeAllErrorMessages(parentElem) {
    var elems = parentElem.querySelectorAll(
      "." + FORM_FIELD_HELPER_ERROR_CLASS,
    );
    for (var i = 0; i < elems.length; i++) {
      var item = elems[i];
      item.parentNode.removeChild(item);
    }
  }

  function _handleSubmit(e) {
    e.preventDefault();
    var form = e.target;
    _removeAllErrorMessages(form);
    if (form.checkValidity()) {
      if (!form.hasAttribute("data-synthetic-submit")) form.submit();
    } else {
      for (var i = 0; i < form.length; i++) {
        var field = form[i];
        if (_isNotAFormElement(field)) continue;
        _checkFieldState(field);
      }
    }
  }

  function _ready() {
    ff.elem.setAttribute("novalidate", "");
    ff.elem.addEventListener("submit", _handleSubmit);
    ff.elem.addEventListener("change", _handleChange);
  }

  _ready();

  return ff;
}
