/* eslint-disable react/forbid-prop-types */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { calculateGstAdjustedAmount } from '../../utils/calculateGstAdjustedAmount';
import {
  CONTENT_SLUG_TO_BACKGROUND_MAPPINGS,
  ANALYTICS_VALUE_FALSE,
  ANALYTICS_BILL_ESTIMATE_SAVINGS,
  SAVINGS_THRESHOLD,
  ANALYTICS_VALUE_TRUE,
} from '../../utils/constants';
import { sendAnalyticsToDataLayer } from '../../utils/analytics';

export const BillEstimate = ({
  billEstimateLoading,
  billEstimateTotal,
  displayEstimatedSavings,
  planName,
  estimatedSavings,
  gstInclusion,
  icp,
}) => {
  const gstAdjustedBillEstimateTotal = calculateGstAdjustedAmount(billEstimateTotal, gstInclusion);
  const dollars = gstAdjustedBillEstimateTotal.toString().split('.')[0];
  const cents = `.${gstAdjustedBillEstimateTotal.toFixed(2).split('.')[1]}`;

  const savingsValue = calculateGstAdjustedAmount(estimatedSavings, gstInclusion).toFixed(2);

  useEffect(() => {
    if (billEstimateLoading) return;
    sendAnalyticsToDataLayer(ANALYTICS_BILL_ESTIMATE_SAVINGS, {
      gst_inclusion: gstInclusion,
      savings_value: savingsValue,
      savings_positive:
        estimatedSavings >= SAVINGS_THRESHOLD ? ANALYTICS_VALUE_TRUE : ANALYTICS_VALUE_FALSE,
      icp_number: icp,
      plan_name: planName,
    });
  }, [billEstimateLoading, estimatedSavings]);

  if (billEstimateLoading) return <div className="plan_card_component_loading" />;

  return (
    <div className="plan_card_bill">
      <h5 className="plan_card_bill_heading">Your bill estimate</h5>
      <div className="plan_card_value">
        <span className="plan_card_value_integer">{dollars}</span>
        <span className="plan_card_value_decimal">{cents}</span>
      </div>
      {displayEstimatedSavings && (
        <div className="plan_card_estimated_savings">
          <span
            className={`background--${CONTENT_SLUG_TO_BACKGROUND_MAPPINGS[planName]} plan_card_savings_banner`}
          >
            <span>
              You could save{' '}
              <strong>
                ${calculateGstAdjustedAmount(estimatedSavings, gstInclusion).toFixed(2)}*
              </strong>
            </span>
          </span>
        </div>
      )}
    </div>
  );
};

BillEstimate.propTypes = {
  billEstimateLoading: PropTypes.bool.isRequired,
  billEstimateTotal: PropTypes.number.isRequired,
  displayEstimatedSavings: PropTypes.bool.isRequired,
  planName: PropTypes.string.isRequired,
  estimatedSavings: PropTypes.string.isRequired,
  gstInclusion: PropTypes.string.isRequired,
  icp: PropTypes.string.isRequired,
};
