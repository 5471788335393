// todo move progress_bar styles from old lib
import React from "react";
import { useLocation } from "react-router-dom";
import { UNIT_CODE_TYPES } from "utils/constants";
import { NZD } from "utils/currency";
import { pathnameBrand } from "utils/pathnameBrand";

type ProgressComparisonChartProps = {
  data: ComparisonChartData[];
};

/**
 * Progress comparison chart renders progress bars for the specified time periods - this period, last period, and year ago period, allowing users to compare spend and usage values between each time period easily.
 */
export const ProgressComparisonChart: React.FC<
  ProgressComparisonChartProps
> = ({ data }) => {
  const { pathname } = useLocation();
  const { background } = pathnameBrand(pathname);

  const highestValue = Math.max(
    ...data.map((item: { value?: number }) => item.value),
  );

  /**
   * @description takes a value and turns it into a percentage value
   */
  const progressBarPercentage = (value?: number | null): number => {
    // display min width of 50% so the progress bar adjusts proportionally and doesn't cut text of when the comparison percentage width for individual bars are low (e.g width of 8% which on mobile ends up being tiny)
    // when value is 0 or a negative number, the minimum width of the progress bar will be 100%
    if (value <= 0 || value === null) return 100;
    return ((value / highestValue) * 100) / 2 + 50;
  };

  /**
   * @description Takes the unitType and the value and determines how the value needs to be shown in the progress bar
   */
  const determineUnitCode = (
    unitType: string,
    value: number | null,
  ): string => {
    switch (unitType) {
      case UNIT_CODE_TYPES.cost:
        return value !== null ? NZD.format(value) : "No data available";
      case UNIT_CODE_TYPES.usage:
        return value !== null ? `${value} kWh` : "No data available";
      default:
        return value !== null ? value.toFixed(2) : "No data available";
    }
  };

  return (
    <>
      {data.map((bar, index) => (
        <div
          key={`${index}-${bar.value}`}
          className="progress"
          style={{
            width: `${progressBarPercentage(bar.value)}%`,
          }}
        >
          {" "}
          <div
            className={`progress__text_right ${
              bar.currentPeriod &&
              `progress__bar ${bar.barColour ? `progress__bar--${bar.barColour}` : `progress__bar--${background}`}`
            }`}
          >
            <p className="small">{bar.label}</p>
            <p className="small">
              {determineUnitCode(bar.unitType, bar.value)}
            </p>
          </div>
        </div>
      ))}
    </>
  );
};
