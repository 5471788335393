/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { PRODUCT_NAMES } from '../../utils/constants';

export const PlanProofPoints = ({ copy, planVariant, theme }) => {
  const { flat, off_peak: offPeak } = PRODUCT_NAMES;

  return (
    <div className="plan_proof_points_section">
      {[flat, offPeak].includes(planVariant) && (
        <h4 className="plan_proof_points_section_title">This plan is for you if:</h4>
      )}
      <ul className={`list-check list-check--${theme}`}>
        {copy?.planProofPoints?.map((planProofPoint) => (
          <li key={`${copy.contentSlug}-${planProofPoint}`} className="list-check__item">
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
              <g fill="none" fillRule="evenodd">
                <g>
                  <g>
                    <g transform="translate(-155 -1715) translate(120 1680) translate(35 35)">
                      <circle
                        cx="15"
                        cy="15"
                        r="15"
                        fill="#EBC431"
                        className="list-check__item-tick"
                      />
                      <path stroke="#000" strokeWidth="4" d="M21.5 10.5L12.5 19.5 8.5 15.5" />
                    </g>
                  </g>
                </g>
              </g>
            </svg>
            <div className="list-check__item-text">{planProofPoint}</div>
          </li>
        ))}
      </ul>
    </div>
  );
};

PlanProofPoints.propTypes = {
  copy: PropTypes.object.isRequired,
  planVariant: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
};
