import { MutableRefObject } from "react";

/**
 * Set the previousPath param in sessionStorage
 * @param previousPath
 */
export const setNavTabInSession = (paramName: string, pathname: string) => {
  window.sessionStorage.setItem(paramName, pathname);
};

export const getNavTabFromPath = (pathname: string): string => {
  return pathname.split("/").filter(Boolean)[0] || "home";
};

export const clearPreviousNavTab = (
  prevTab: MutableRefObject<string | null>,
) => {
  prevTab.current = null;
  window.sessionStorage.removeItem("previousNavTab");
};

export const currentPathIsIndex = (pathName: string) => {
  return pathName.split("/").filter(Boolean).length < 2;
};
