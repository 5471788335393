import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { customerApi } from "queries/customerApi";

const accountDatasSlice = createSlice({
  name: "accountDatas",
  initialState: {
    accountDatas: [],
  },
  reducers: {
    setAccountDatas: (
      state,
      action: PayloadAction<{ accountDatas: AccountInfo[] }>,
    ) => {
      const { accountDatas } = action.payload;
      let formattedArray = accountDatas;
      if (accountDatas.length > 0) {
        formattedArray = [...accountDatas].sort(
          (account, nextAccount) =>
            Number(nextAccount?.attributes.active) -
            Number(account?.attributes.active),
        );
      }
      state.accountDatas = formattedArray;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      customerApi.endpoints.getAccountInfo.matchFulfilled,
      (state, { payload: accountDatas }) => {
        let formattedArray = accountDatas;
        if (accountDatas.length > 0) {
          formattedArray = [...accountDatas].sort(
            (account, nextAccount) =>
              Number(nextAccount?.attributes.active) -
              Number(account?.attributes.active),
          );
        }
        state.accountDatas = formattedArray;
      },
    );
  },
});

export const { setAccountDatas } = accountDatasSlice.actions;
export const accountDatasReducer = accountDatasSlice.reducer;
