import { hasInputValueChanged } from "utils/forms/forms";

// split the phone numbers into home and mobile arrays
export const getPhoneNumberArrays = (phoneNumbers: PhoneNumber[] = []) => {
  const mobileNumbers = [];
  const homeNumbers = [];
  phoneNumbers.forEach((number) => {
    if (number.numberType === "mobile") {
      mobileNumbers.push(number);
    } else {
      homeNumbers.push(number);
    }
  });

  return {
    mobileNumbers,
    homeNumbers,
  };
};

/**
 * @description formats phone number to  align the display of customer phone numbers across all of our services
 * @return {string} phone number without +64, 64 prefixes or leading whitespace
 */
export const prefixRemovedNZNumber = (number: string) => {
  return number.replace(/(^\+64)|(^64)|(^\s)/g, "0");
};

/**
 * @description phone number params sent to the api
 *  @return {string[]}  returns an arrays of phone numbers
 */
export const formatPhoneNumberParams = (
  phoneNumbers: FormFieldDetails[],
  type: string,
) => {
  return phoneNumbers.map((numberValue: FormFieldDetails) => {
    return {
      id: numberValue.extraProps.id,
      number: numberValue.value.replace(/[\s-]/g, ""),
      type,
    };
  });
};

/**
 * @description Takes the redux state values and the current form values and returns useful booleans variables to enable submit, and condtionally submit values
 */
type detectInputValuesChangedProps = {
  emailState: string;
  emailValue: string;
  preferredNameState: string;
  preferredNameValue: string;
  mobileNumbers: PhoneNumber[];
  mobileNumbersValue: FormFieldDetails[];
  homeNumbers: PhoneNumber[];
  homeNumbersValue: FormFieldDetails[];
  unconfirmedEmailState?: string;
};
export const detectInputValuesChanged = ({
  emailState,
  emailValue,
  preferredNameState,
  preferredNameValue,
  mobileNumbers,
  mobileNumbersValue,
  homeNumbers,
  homeNumbersValue,
  unconfirmedEmailState,
}: detectInputValuesChangedProps) => {
  const emailChanged =
    hasInputValueChanged(emailState, emailValue) &&
    hasInputValueChanged(unconfirmedEmailState, emailValue);

  const preferredNameChanged = hasInputValueChanged(
    preferredNameState,
    preferredNameValue,
  );

  const mobilephoneNumbersChanged = mobileNumbers
    .map(({ number }, index) =>
      hasInputValueChanged(number, mobileNumbersValue[index]?.value),
    )
    .includes(true);
  const homephoneNumbersChanged = homeNumbers
    .map(({ number }, index: number) =>
      hasInputValueChanged(number, homeNumbersValue[index]?.value),
    )
    .includes(true);

  const userDetailsChanged = emailChanged || preferredNameChanged;
  const phoneNumbersChanged =
    mobilephoneNumbersChanged || homephoneNumbersChanged;
  const anyInputChanged = userDetailsChanged || phoneNumbersChanged;

  return {
    anyInputChanged,
    emailChanged,
    homephoneNumbersChanged,
    mobilephoneNumbersChanged,
    phoneNumbersChanged,
    preferredNameChanged,
    userDetailsChanged,
  };
};
