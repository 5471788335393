/**
 * @typedef {Object} Timetable
 * @property {string} rate_code
 * @property {string} share
 * @property {string} ratio
 * @property {string[]} descriptions
 * @param {Object|null} plan, for an example see ../../spec/fixtures/mockOffpeakPlan
 * @returns {Timetable|null}
 */
export const findOffpeakTimetables = (plan) => {
  if (!plan) return null;

  return plan.estimate?.subscriptionRates?.timetables?.find(
    (timetable) => timetable.rate_code === 'Offpeak'
  );
};

/**
 * @description Takes a timetable array and formats it for display in plan.jsx component
 * @param {Array<string>|null} timetableDescriptions, most will currently follow this pattern ['Weekends: all day', 'Weekdays: 11:00am-5:00pm & 9:00pm-7:00am']
 * @returns {Array<{label:string, period:string}>|null}
 */
export const formatTimetables = (timetableDescriptions) => {
  if (!timetableDescriptions) return null;

  const timetables = [];

  timetableDescriptions.forEach((description) => {
    const label = description.split(' ')[0];
    const periodTime = description.split(' ').slice(1).join(' ');

    const sanitizedPeriodTime = periodTime.includes('all day')
      ? 'all day & night'
      : periodTime.replace(/:00/g, '');

    timetables.unshift({ label, period: sanitizedPeriodTime });
  });

  return timetables;
};
