import "styles/App.scss";

import { AdminAccount } from "components/AdminAccount";
import { AuthCallback } from "components/AuthCallback";
import { Header } from "components/Header";
import { PageWrapper } from "components/PageWrapper";
import { AccountDetailsPage } from "pages/AccountDetailsPage";
import { AccountPage } from "pages/AccountPage";
import { AddressDetailsPage } from "pages/AddressDetailsPage";
import { BillingDetailsPage } from "pages/BillingDetailsPage";
import { BillListPage } from "pages/BillListPage";
import { BillPage } from "pages/BillPage";
import { BillSmootherInformationPage } from "pages/BillSmootherInformationPage";
import { BillSmootherPage } from "pages/BillSmootherPage";
import { BillSmootherSettingsPage } from "pages/BillSmootherSettingsPage";
import { CloseAccountPage } from "pages/CloseAccountPage";
import { CurrentPlanPage } from "pages/CurrentPlanPage";
import { HelpPage } from "pages/HelpPage";
import { HomePage } from "pages/HomePage/HomePage";
import { LoadingPage } from "pages/LoadingPage";
import { MovingHousePage } from "pages/MovingHousePage";
import { NewPaymentMethodPage } from "pages/NewPaymentMethodPage";
import { ReferPage } from "pages/ReferPage/ReferPage";
import { SplashPage } from "pages/SplashPage/SplashPage";
import { UpdatePasswordPage } from "pages/UpdatePasswordPage";
import { UsagePage } from "pages/UsagePage";
import { useGetBillingEntityQuery } from "queries/billingApi";
import { useGetContractsQuery } from "queries/contractManagerApi";
import {
  useGetAccountDetailsQuery,
  useGetAccountInfoQuery,
} from "queries/customerApi";
import { useGetUserDetailsQuery } from "queries/identityApi";
import { useGetBillSmootherConfigQuery } from "queries/paymentSmootherApi";
import { useGetRateCardsQuery } from "queries/pricingRateCardApi";
import { useGetRatingPreferencesQuery } from "queries/ratingApi";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { setAnnouncementBanner } from "reducers/announcementBanner";
import { setNativeRenderMode } from "reducers/currentAccount";
import { clearCacheForAllApis } from "reduxUtils/clearCacheForAllApis";
import { useAppDispatch, useAppSelector } from "reduxUtils/hook";
import { selectIsAnyFetching } from "selectors/queriesInProgress";
import { RENDER_MODE } from "utils/constants";
import { jwtClient } from "utils/jwtClient";
import {
  filteredLinkContent,
  sendAnalyticsEvent,
} from "utils/sendAnalyticsEvent";

import mobileCarbonChanges from "./components/AnnouncementBanner/static/Mobile-planned-carbon-changes.json";

const MAIN_PAGES = [
  "/",
  "/bills",
  "/usage",
  "/account",
  "/account/billing",
  "/account/billing/new-payment-method",
  "/account/details",
  "/account/address",
  "/account/address/update",
  "/account/close-account",
  "/account/details/password",
  "/bills/bill-smoother",
  "/account/plan",
  "/account/help",
  "/account/friend-get-friend",
];

// Sets the value for the root route
const RootRouteWrapper = React.memo<{
  isAuthenticated: boolean;
  refreshToken?: string;
  nativeRenderMode: boolean;
}>(({ isAuthenticated, refreshToken }) => {
  const location = useLocation();
  const { from } = location.state || { from: { pathname: "/" } };
  if (isAuthenticated) {
    return ["/", "/home"].includes(from.pathname) ? (
      <HomePage />
    ) : (
      <Navigate to={from.pathname} state={{ from: "auth_redirect_url" }} />
    );
  }
  // If a user has a refresh token stored in localStorage,
  // render a LoadingPage instead of the SplashPage, while identity finishes authenticating.
  // If there is no refreshToken stored, just show the SplashPage
  return refreshToken ? (
    <LoadingPage />
  ) : (
    <SplashPage
      login={() => jwtClient.authenticateWithIdentity(from.pathname)}
      isAuthenticated={isAuthenticated}
    />
  );
});

/**
 * Default scroll to top between routes
 */
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export const App = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname, search } = location;
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const currentAccount = useAppSelector((store) => store.currentAccount);
  const currentProduct = useAppSelector((store) => store.currentProduct);
  const accountDetails = useAppSelector((store) => store.accountDetails);
  const { billingEntityData } = useAppSelector(
    (store) => store.billingEntityData,
  );

  const queriesInProgress = useSelector(selectIsAnyFetching);

  const localNativeRenderMode =
    window?.localStorage?.getItem("nativeRenderMode");
  const [nativeRenderMode] = useState(
    Boolean(localNativeRenderMode) ||
      searchParams.get("render_mode") === RENDER_MODE.native,
  );
  const [isAuthenticated, setIsAuthenticated] = useState(nativeRenderMode);
  const [nativeJwt, setNativeJwt] = useState(null);
  const [isDebug] = useState(searchParams.get("debug") === "true");
  const [authExpired, setAuthExpired] = useState(null);
  const refreshToken = window?.localStorage?.getItem("refreshToken");
  const accountOverrideNumber = window.sessionStorage.getItem(
    "accountOverrideNumber",
  );

  jwtClient.setOnAuthenticationChange(setIsAuthenticated);
  jwtClient.authenticatedInApp(nativeRenderMode);
  jwtClient.setNavigate(navigate);
  jwtClient.setDispatch(dispatch);
  jwtClient.setAuthExpired(setAuthExpired);

  useEffect(() => {
    const attemptLogin = async () => {
      await jwtClient.attemptLogin(
        refreshToken,
        navigate,
        pathname + search,
        /**
         * if native render debug mode, we bypass native app authenticated
         *  this will make sure debug mode use browser jwt token
         */
        isDebug ? () => setNativeJwt(true) : undefined,
      );
    };
    if (refreshToken) {
      attemptLogin();
    }

    if (nativeRenderMode && !Boolean(localNativeRenderMode)) {
      window?.localStorage?.setItem(
        "nativeRenderMode",
        String(nativeRenderMode),
      );
    }

    window["webNavigate"] = (path) => {
      navigate(path);
    };

    /**
     * if native render debug mode, we bypass native app authenticated
     *  this will make sure debug mode use browser jwt token
     */
    if (isDebug) {
      jwtClient.authenticatedInApp(false);
      return;
    }

    const handleNativeJwtChange = (event) => {
      setNativeJwt(event.detail);
    };

    // Add event listener for the custom event
    window.addEventListener("setNativeJwtChanged", handleNativeJwtChange);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("setNativeJwtChanged", handleNativeJwtChange);
    };
  }, []);

  /**
   * isAuthenticated default true in native mode
   * so we making sure query only run when nativeJwt is set from native browser
   */
  useGetAccountInfoQuery(
    { jwtClient, accountOverrideNumber },
    {
      refetchOnFocus: true,
      skip:
        (!nativeRenderMode && !isAuthenticated) ||
        (nativeRenderMode && isAuthenticated && !nativeJwt),
    },
  );

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(
        setAnnouncementBanner({
          type: mobileCarbonChanges.type,
          modifier: mobileCarbonChanges.modifier ?? null,
          name: "AnnouncementBanner",
          ...("enableDesktopMode" in mobileCarbonChanges && {
            enableDesktopMode: Boolean(mobileCarbonChanges.enableDesktopMode),
          }),
          ...("enableNativeMode" in mobileCarbonChanges && {
            enableNativeMode: Boolean(mobileCarbonChanges.enableNativeMode),
          }),
          params: {
            heading: mobileCarbonChanges.heading,
            bodyCopy: mobileCarbonChanges.bodyCopy,
          },
          additionalContent:
            mobileCarbonChanges.links &&
            filteredLinkContent(mobileCarbonChanges.links),
        }),
      );
    }
  }, [isAuthenticated]);

  useMemo(() => {
    window["jwtExpired"] = () => {
      if (Boolean(queriesInProgress) || authExpired === null) return null;
      return authExpired;
    };
  }, [authExpired, queriesInProgress]);

  useEffect(() => {
    if (nativeRenderMode !== currentAccount.nativeRenderMode) {
      dispatch(
        setNativeRenderMode({
          nativeRenderMode: nativeRenderMode,
        }),
      );
    }
  }, [dispatch, nativeRenderMode]);

  useEffect(() => {
    if (!Boolean(currentAccount.icpNumber)) return;
    sendAnalyticsEvent("page_view", { icpNumber: currentAccount.icpNumber });
  }, [pathname, currentAccount.icpNumber]);

  const shouldDisplayHeader =
    isAuthenticated && !currentAccount.nativeRenderMode;

  useGetContractsQuery(
    {
      jwtClient,
      customerNumber: currentAccount.customerNumber,
      supplyNodeRef: currentAccount.supplyNodeRef,
    },
    {
      skip: !Boolean(currentAccount.supplyNodeRef),
      refetchOnMountOrArgChange: true,
    },
  );

  useGetBillingEntityQuery(
    { customerNumber: currentAccount.customerNumber, jwtClient },
    {
      skip: !currentAccount.customerNumber,
      refetchOnMountOrArgChange: true,
    },
  );

  useGetBillSmootherConfigQuery(
    { jwtClient, billingEntityId: billingEntityData?.id },
    {
      skip: !billingEntityData || !billingEntityData.id,
      refetchOnMountOrArgChange: true,
    },
  );

  useGetAccountDetailsQuery(
    { jwtClient, accountNumber: currentAccount.accountNumber },
    {
      skip: !currentAccount.accountNumber,
      refetchOnMountOrArgChange: true,
    },
  );

  useGetUserDetailsQuery(
    {
      jwtClient,
      userId: accountDetails.userSub,
    },
    {
      skip: !accountDetails.userSub,
      refetchOnMountOrArgChange: true,
    },
  );

  useGetRatingPreferencesQuery(
    {
      supplyNodeRef: currentAccount.supplyNodeRef,
      jwtClient,
    },
    {
      skip: !currentAccount.supplyNodeRef,
      refetchOnMountOrArgChange: true,
    },
  );

  const { refetch: refetchRateCards } = useGetRateCardsQuery(
    {
      jwtClient,
      icpNumber: currentAccount.icpNumber,
      generationPriceContract: currentProduct?.contractName,
      usageType: currentAccount.usageType,
      locationClassification: currentAccount.locationClassification,
    },
    {
      skip:
        !currentAccount.icpNumber ||
        !currentProduct.contractName ||
        !currentAccount.usageType ||
        !currentAccount.locationClassification,
    },
  );

  useEffect(() => {
    if (
      !currentAccount.isSwitchingAccountComplete ||
      !currentProduct.isCurrentProductChanged ||
      !currentAccount.locationClassification ||
      !currentAccount.usageType
    ) {
      return;
    }
    refetchRateCards();
  }, [
    currentAccount.isSwitchingAccountComplete,
    currentProduct.isCurrentProductChanged,
  ]);

  useEffect(() => {
    if (!isAuthenticated) {
      clearCacheForAllApis();
    }
  }, [isAuthenticated]);

  if (isAuthenticated && !currentAccount.isFetchAccountCompleted) {
    return <LoadingPage />;
  }

  return (
    <>
      {shouldDisplayHeader && (
        <Header
          handleLogout={() => {
            window.sessionStorage.removeItem("accountOverrideNumber");
            jwtClient.clearJwt();
          }}
        />
      )}
      <ScrollToTop />
      <Routes>
        <Route
          path="/welcome"
          element={
            <SplashPage
              login={() => jwtClient.authenticateWithIdentity()}
              isAuthenticated={isAuthenticated}
            />
          }
        />
        <Route
          element={
            <PageWrapper
              isAuthenticated={isAuthenticated}
              userIsOnMainPage={MAIN_PAGES.includes(pathname)}
              nativeRenderMode={nativeRenderMode}
            >
              <Outlet />
            </PageWrapper>
          }
        >
          <Route
            path="auth/callback"
            element={<AuthCallback setIsAuthenticated={setIsAuthenticated} />}
          />
          <Route path="admin/:accountNumber" element={<AdminAccount />} />
          {isAuthenticated && (
            <>
              <Route path="bills">
                <Route index element={currentAccount && <BillListPage />} />
                <Route path="bill-smoother">
                  <Route index element={<BillSmootherPage />} />
                  <Route
                    path="settings"
                    element={<BillSmootherSettingsPage />}
                  />
                  <Route
                    path="more-information"
                    element={<BillSmootherInformationPage />}
                  />
                </Route>
                <Route path=":invoiceNumber" element={<BillPage />} />
              </Route>
              <Route path="choice" element={<h1>Choice</h1>} />
              <Route path="usage" element={<UsagePage />} />
              <Route path="account">
                <Route index element={<AccountPage />} />
                <Route path="address">
                  <Route index element={<AddressDetailsPage />} />
                  <Route path="update" element={<MovingHousePage />} />
                </Route>
                <Route path="billing">
                  <Route index element={<BillingDetailsPage />} />
                  <Route
                    path="new-payment-method"
                    element={<NewPaymentMethodPage />}
                  />
                </Route>
                <Route path="details">
                  <Route index element={<AccountDetailsPage />} />
                  <Route path="password" element={<UpdatePasswordPage />} />
                </Route>
                <Route path="close-account" element={<CloseAccountPage />} />
                <Route path="plan" element={<CurrentPlanPage />} />
                <Route path="help" element={<HelpPage />} />
                <Route path="friend-get-friend" element={<ReferPage />} />
              </Route>
              <Route path="settings" element={<h1>Settings</h1>} />
            </>
          )}
          <Route
            path="/"
            element={
              <RootRouteWrapper
                isAuthenticated={isAuthenticated}
                refreshToken={refreshToken}
                nativeRenderMode={nativeRenderMode}
              />
            }
          />
          <Route
            path="*"
            element={<Navigate to="/" state={{ from: location }} />}
          />
        </Route>
      </Routes>
    </>
  );
};
