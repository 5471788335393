export const generateCodeChallenge = async (
  codeVerifier: string,
): Promise<string> => {
  const digest = await crypto.subtle.digest(
    "SHA-256",
    new TextEncoder().encode(codeVerifier),
  );
  return window
    .btoa(String.fromCharCode(...new Uint8Array(digest)))
    .replace(/=/g, "")
    .replace(/\+/g, "-")
    .replace(/\//g, "_");
};

/**
 * generate unique secured state string with 32 digits
 */
export const generateStateCode = (length = 32): string => {
  const array = new Uint8Array(length);
  crypto.getRandomValues(array);
  return Array.from(array, (byte) => byte.toString(36).padStart(2, "0"))
    .join("")
    .slice(0, length);
};

export const encodeState = (stateObject: object) => {
  // Serialize the object to JSON string
  const jsonString = JSON.stringify(stateObject);

  // Convert JSON string to binary string
  const binaryString = Array.from(jsonString, (char) =>
    String.fromCharCode(char.charCodeAt(0)),
  ).join("");

  // Encode binary string to base64 removing URL-unsafe characters
  return btoa(binaryString)
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=+$/, "");
};

// Decode and deserialize an object from state
export const decodeState = (
  encodedState: string,
): { from: string; stateCode: string } => {
  // Decode base64 to binary string
  const binaryString = atob(encodedState);

  // Convert binary string to JSON string
  const jsonString = Array.from(binaryString, (char) =>
    String.fromCharCode(char.charCodeAt(0)),
  ).join("");

  // Deserialize JSON string to object
  return JSON.parse(jsonString);
};
