import React from "react";
/**
 * @description: Fallback for (RecentBill | BillListPage) when there is no bill data
 */
export const NoBillDataFallback: React.FC = () => {
  return (
    <>
      <h3 className="h5">No bill information available</h3>
      <p>We weren't able to retrieve any information about your bills.</p>
      <p>
        If you've signed up recently, you'll see more bill information here once
        your property has switched over to Flick and at least one billing period
        has passed.
      </p>
      <p>
        If you've been with Flick for a while, then this can be caused by a bad
        network connection or a problem with our servers. Please try again
        later.
      </p>
    </>
  );
};
