import { createApi } from "@reduxjs/toolkit/query/react";
import { endOfTomorrow, startOfTomorrow } from "date-fns";
import { JwtClient } from "utils/jwtClient";

import {
  formattedContractsData,
  formattedPlanDescriptions,
  formattedUpdatedCurrentPlan,
} from "./contractManager.utils";
import { BaseQueryParams, expireTime } from "./queries.utils";

/**
 * Define a base query function that calls jwtClient.apiCall
 */
const apiCallBaseQuery = async ({ jwtClient, ...params }: BaseQueryParams) => {
  const response = await jwtClient.apiCall(params);
  const result = await response.json();

  /** for contracts and plans formatting we need the included data returned from the api response
   */
  return { data: result };
};

type ContractsPromise = {
  contractName?: string;
  description?: string;
  endsAt?: string;
  id?: string;
  name?: string;
};

type GetContractsProps = {
  asAtDate?: Date;
  customerNumber: string;
  jwtClient: JwtClient;
  supplyNodeRef: string;
};

type UpdateCurrentPlanProps = {
  customerNumber: string;
  jwtClient: JwtClient;
  planId: string;
  supplyNodeRef: string;
};

/**
 * Define the contractManagerApi slice
 */
export const contractManagerApi = createApi({
  reducerPath: "contractManagerApi",
  baseQuery: apiCallBaseQuery,
  endpoints: (builder) => ({
    getContracts: builder.query<ContractsPromise, GetContractsProps>({
      query({ jwtClient, asAtDate, customerNumber, supplyNodeRef }) {
        return {
          jwtClient: jwtClient,
          path: "/contract_manager/contracts",
          queryParams: {
            as_at:
              (asAtDate && asAtDate.toISOString()) || new Date().toISOString(),
            party_ref: `/customer/customers/${customerNumber}`,
            supply_node_ref: supplyNodeRef,
          },
        };
      },
      transformResponse: (data) => {
        return formattedContractsData(data);
      },
    }),
    getFutureContract: builder.query<ContractsPromise, GetContractsProps>({
      query({ jwtClient, customerNumber, supplyNodeRef }) {
        return {
          jwtClient: jwtClient,
          path: "/contract_manager/contracts",
          queryParams: {
            as_at: endOfTomorrow().toISOString(),
            party_ref: `/customer/customers/${customerNumber}`,
            supply_node_ref: supplyNodeRef,
          },
        };
      },
      transformResponse: (data) => {
        return formattedContractsData(data);
      },
    }),
    getContractForInvoice: builder.query<ContractsPromise, GetContractsProps>({
      query({ jwtClient, asAtDate, customerNumber, supplyNodeRef }) {
        return {
          jwtClient: jwtClient,
          path: "/contract_manager/contracts",
          queryParams: {
            as_at:
              (asAtDate && asAtDate.toISOString()) || new Date().toISOString(),
            party_ref: `/customer/customers/${customerNumber}`,
            supply_node_ref: supplyNodeRef,
          },
        };
      },
      transformResponse: (data) => {
        return formattedContractsData(data);
      },
    }),
    getPlans: builder.query<
      { planId: string; description: string }[],
      {
        customerNumber: string;
        supplyNodeRef: string;
        jwtClient: JwtClient;
      }
    >({
      query({ customerNumber, supplyNodeRef, jwtClient }) {
        return {
          jwtClient: jwtClient,
          path: "/contract_manager/plans",
          queryParams: {
            as_at: new Date().toISOString(),
            brand: "flick",
            existing_customer_plans: "true",
            party_ref: `/customer/customers/${customerNumber}`,
            supply_node_ref: supplyNodeRef,
          },
        };
      },
      transformResponse: (data) => {
        return formattedPlanDescriptions(data);
      },
    }),
    updateCurrentPlan: builder.mutation<
      { planId: string },
      UpdateCurrentPlanProps
    >({
      query({ customerNumber, supplyNodeRef, jwtClient, planId }) {
        return {
          jwtClient: jwtClient,
          path: "/contract_manager/create_contracts",
          queryParams: {
            party_ref: `/customer/customers/${customerNumber}`,
            plan_id: planId,
            start_at: startOfTomorrow().toISOString(),
            supply_node_ref: supplyNodeRef,
          },
          fetchParams: {
            method: "POST",
          },
        };
      },
      transformResponse: (data) => {
        return formattedUpdatedCurrentPlan(data);
      },
    }),
  }),
  keepUnusedDataFor: expireTime,
});

export const {
  useGetContractsQuery,
  useGetContractForInvoiceQuery,
  useGetPlansQuery,
  useUpdateCurrentPlanMutation,
  useGetFutureContractQuery,
} = contractManagerApi;
