import "styles/AccountPage.scss";

import { Button } from "@flick/fep-library";
import { ReactComponent as AccountIcon } from "assets/icon-account.svg";
import { ReactComponent as AddressIcon } from "assets/icon-address.svg";
import { ReactComponent as BillingIcon } from "assets/icon-billing.svg";
import { ReactComponent as ChangePlanIcon } from "assets/icon-change-plan.svg";
import { ReactComponent as ReferralIcon } from "assets/icon-friend-get-friend.svg";
import { ReactComponent as HelpIcon } from "assets/icon-help.svg";
import { AccountPageLink } from "components/AccountPageLink";
import { AccountPageWrapper } from "components/AccountPageWrapper";
import { LoadingSection } from "components/LoadingSection";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  clearNotificationBanner,
  setNotificationBanner,
} from "reducers/notificationBanner";
import { useAppDispatch, useAppSelector } from "reduxUtils/hook";
import {
  NotificationBannerNames,
  NotificationBannerTypes,
} from "utils/constants";
import { jwtClient } from "utils/jwtClient";

import content from "./static/content.json";
const { movingHouseHint } = content;

const pageLinks = [
  {
    icon: (
      <AccountIcon className="nav_customer_tools__item_link_icon account_page__link__icon" />
    ),
    text: "Account details",
    to: "/account/details",
  },
  {
    icon: (
      <BillingIcon className="nav_customer_tools__item_link_icon account_page__link__icon" />
    ),
    text: "Billing",
    to: "/account/billing",
  },
  {
    icon: (
      <AddressIcon className="nav_customer_tools__item_link_icon account_page__link__icon" />
    ),
    text: "Address",
    to: "/account/address",
  },
  {
    icon: (
      <ChangePlanIcon className="nav_customer_tools__item_link_icon account_page__link__icon" />
    ),
    text: "Change plan",
    to: "/account/plan",
  },
  {
    icon: (
      <ReferralIcon className="nav_customer_tools__item_link_icon account_page__link__icon" />
    ),
    text: "Refer a friend",
    to: "/account/friend-get-friend",
  },
  {
    icon: (
      <HelpIcon className="nav_customer_tools__item_link_icon account_page__link__icon" />
    ),
    text: "Help",
    to: "/account/help",
  },
];

export const AccountPage = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    accountNumber,
    nativeRenderMode,
    active: accountActive,
  } = useAppSelector((store) => store.currentAccount);
  const notificationBanner = useAppSelector(
    (store) => store.notificationBanner,
  );

  const { preferredName } = useAppSelector((store) => store.accountDetails);
  const { displayName: currentPlan } = useAppSelector(
    (store) => store.currentProduct,
  );

  const handleLogout = () => {
    jwtClient.clearJwt();
  };

  // display or info banner when selected account is closed
  useEffect(() => {
    if (accountNumber && !accountActive) {
      dispatch(
        setNotificationBanner({
          type: NotificationBannerTypes.notice,
          name: NotificationBannerNames.accountClosed,
          page: pathname,
        }),
      );
      return;
    }

    if (notificationBanner) {
      dispatch(clearNotificationBanner());
    }
  }, [accountNumber, accountActive]);

  if (!preferredName) return <LoadingSection />;

  const activeLinks = accountActive
    ? pageLinks
    : pageLinks.filter((pageLink) => pageLink.text === "Help");

  return (
    <div className="account_page">
      <AccountPageWrapper
        hasCancelButton={false}
        headerLabel={preferredName || "Your Account"}
        headerItems={[
          Boolean(accountNumber) && {
            key: "Account number",
            value: accountNumber,
          },
          Boolean(currentPlan) && { key: "Current plan", value: currentPlan },
        ]}
      >
        {!accountActive && !accountNumber && (
          <section className="spacer--small page_section page_section--single_column">
            <section className="page_section__column">
              <h2 className="h4">Account information currently unavailable</h2>
              <p>
                We weren't able to retrieve any information about your account.
              </p>
              <p>
                If you've signed up recently, you'll see more account
                information here once your property has switched over to Flick.
              </p>
              <p>
                If you've been with Flick, for a while then this can be caused
                by a bad network connection or a problem with our servers.
                Please try again later.
              </p>
            </section>
          </section>
        )}
        <ul className="customer_tools_list spacer spacer--small">
          {activeLinks.map(({ icon, text, to }) => (
            <li key={to} className="customer_tools_list__item">
              <AccountPageLink
                key={to}
                icon={icon}
                linkText={text}
                linkTo={to}
              />
            </li>
          ))}
        </ul>
        {!nativeRenderMode && (
          <Button
            extraClasses="button--expanded spacer--large"
            onClick={handleLogout}
          >
            Logout
          </Button>
        )}
        {accountActive && (
          <section className="spacer">
            <h3 className="h4">Moving house?</h3>
            <p>{movingHouseHint}</p>
            <Button
              extraClasses="button--expanded spacer--large"
              onClick={() => navigate("/account/address/update")}
            >
              Move house
            </Button>
          </section>
        )}
      </AccountPageWrapper>
    </div>
  );
};
