import { add, endOfMonth, endOfWeek, isBefore } from "date-fns";
import { PERIOD_CODES } from "utils/constants";
export const DELAY_FOR_BILLING_PERIOD_DATA = 2;

const calculatePeriodEnd = (nextInvoiceUntil: Date, period: string): Date => {
  const startAt = add(nextInvoiceUntil, { seconds: 1 });
  switch (period) {
    case PERIOD_CODES.P1M:
      return endOfMonth(startAt);
    case PERIOD_CODES.P2W:
      return add(endOfWeek(startAt, { weekStartsOn: 1 }), { weeks: 1 });
    default:
      return endOfWeek(startAt, { weekStartsOn: 1 });
  }
};

const doesNextInvoiceIncludeCurrentConsumption = (
  invoiceUntil: Date,
): boolean => {
  return isBefore(new Date(), invoiceUntil);
};

const isCurrentConsumptionDataUnavailable = (invoiceUntil: Date): boolean => {
  return isBefore(
    new Date(),
    add(invoiceUntil, {
      days: DELAY_FOR_BILLING_PERIOD_DATA,
    }),
  );
};

export const ratingQueryDateRange = (
  billingEntityData: BillingEntity,
): { endAt?: Date; startAt?: Date } => {
  const nextInvoiceDates = billingEntityData.next_invoice_dates;
  const nextInvoiceFrom = new Date(nextInvoiceDates.invoice_from);
  const invoiceUntil = new Date(nextInvoiceDates.invoice_until);

  if (doesNextInvoiceIncludeCurrentConsumption(invoiceUntil)) {
    return {
      startAt: nextInvoiceFrom,
      endAt: invoiceUntil,
    };
  } else if (isCurrentConsumptionDataUnavailable(invoiceUntil)) {
    return {
      startAt: nextInvoiceFrom,
      endAt: invoiceUntil,
    };
  } else {
    return {
      // Hack. add 1 second here to fix a bug when one billing cycle has just ended and another has begun
      startAt: add(invoiceUntil, { seconds: 1 }),
      endAt: calculatePeriodEnd(
        invoiceUntil,
        billingEntityData.nominated_billing_period,
      ),
    };
  }
};
