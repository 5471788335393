import "styles/AccountPage.scss";

import { ReactComponent as ForwardIcon } from "app/assets/images/icon_nav_arrow_forward.svg";
import React from "react";
import { Link } from "react-router-dom";

type AccountPageLinkProps = {
  className?: string;
  icon?: JSX.Element;
  linkText: string;
  linkTo: string;
};

export const AccountPageLink: React.FC<AccountPageLinkProps> = ({
  className,
  icon,
  linkTo,
  linkText,
}) => {
  return icon ? (
    <Link
      className={`customer_tools_section__link account_page__link account_page__link--with-icon ${className}`}
      to={linkTo}
    >
      <span className="icon-label">
        {icon}
        <p data-testid="navigation" className="customer_tools_list__text">
          <strong>{linkText}</strong>
        </p>
      </span>
      <ForwardIcon />
    </Link>
  ) : (
    <Link
      className={`customer_tools_section__link account_page__link ${className}`}
      to={linkTo}
    >
      <p data-testid="navigation" className="customer_tools_list__text">
        <strong>{linkText}</strong>
      </p>
      <ForwardIcon />
    </Link>
  );
};
