import Address from './address';
import { camelCaseKeys } from '../utils/text-transforms';

export const ELIGIBILITY_SUCCESS = 'urn:flick:eligibility:success';
export const ELIGIBILITY_NOT_IN_AREA = 'urn:flick:eligibility:not-in-area';
export const ELIGIBILITY_REJECT = 'urn:flick:eligibility:reject';
export const ELIGIBILITY_MANUAL = 'urn:flick:eligibility:manual';
export const ELIGIBILITY_EXPORT = 'urn:flick:eligibility:export';
export const ELIGIBILITY_UPGRADE = 'urn:flick:eligibility:upgrade';

class SupplyNode {
  ELIGIBLE_STATES = [ELIGIBILITY_SUCCESS, ELIGIBILITY_MANUAL, ELIGIBILITY_EXPORT];

  constructor({
    address,
    networkGeography,
    locationClassification,
    eligibility,
    identifiers,
    networkProvider,
    priceCategoryCode,
  } = {}) {
    this.address = this.setAddress(address);
    this.networkGeography = networkGeography;
    this.locationClassification = locationClassification;
    this.eligibility = this.setEligibility(eligibility);
    this.icpNumber = identifiers && identifiers.registry_id;
    this.networkProvider = networkProvider;
    this.priceCategoryCode = priceCategoryCode;
  }

  setAddress = (data) => {
    const addressData = camelCaseKeys(data);
    return new Address(addressData);
  };

  setEligibility = (data) => {
    if (data) {
      return data.result;
    }
    return null;
  };

  isEligible() {
    return this.ELIGIBLE_STATES.includes(this.eligibility);
  }

  shortEligibilityReason() {
    if (this.eligibility) {
      return this.eligibility.split(':').slice(-1)[0];
    }
    return null;
  }
}

export default SupplyNode;
