import snakeCase from 'lodash/snakeCase';
import { titleCase, isNotBlank } from '../utils/text-transforms';

class Address {
  // Sometimes an address is populated with AddressFinder data, sometimes with Flick supply node data.
  // This map allows us to map between the two. The only difference is alpha (AddressFinder) and unitIdentifer (Flick).
  ADDRESSFINDER_TO_FLICK_PARAMS = new Map([
    ['buildingName', 'buildingName'],
    ['unitIdentifier', 'unitIdentifier'],
    ['alpha', 'unitIdentifier'],
    ['number', 'number'],
    ['street', 'street'],
    ['suburb', 'suburb'],
    ['city', 'city'],
    ['postcode', 'postcode'],
    ['region', 'region'],
  ]);

  constructor(data) {
    this.ADDRESSFINDER_TO_FLICK_PARAMS.forEach((value, key) => {
      if (data[key]) {
        this[value] = titleCase(data[key]);
      }
    });
  }

  toQueryString() {
    const params = new URLSearchParams();

    Array.from(this.ADDRESSFINDER_TO_FLICK_PARAMS.values()).forEach((value) => {
      if (this[value]) {
        params.set(snakeCase(value), this[value]);
      }
    });

    return params.toString();
  }

  numberWithIdentifier() {
    const number = this.number || '';
    const unitIdentifier = this.unitIdentifier || '';
    return [unitIdentifier, number].filter(isNotBlank).join('/');
  }

  area() {
    const city = [this.suburb, this.city].filter(isNotBlank).join(', ');

    return [city, this.postcode].filter(isNotBlank).join(' ');
  }

  shortDescription() {
    return [this.buildingName, `${this.numberWithIdentifier()} ${this.street}`, this.area()]
      .filter(isNotBlank)
      .join(', ');
  }
}

export default Address;
