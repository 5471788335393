import { normaliseProductName } from "utils/productNameUtils";

/**
 * This function uses the current product name and current date to find the current rate card for a customer.
 * returns a RateCard object.
 */

export const findCurrentRateCard = (
  rateCards: RateCard[] | undefined,
  currentProductName: string,
  currentDate: Date,
): RateCard => {
  return rateCards?.length > 0
    ? rateCards.find(
        (rates) =>
          normaliseProductName(rates.attributes.product_name) ===
            currentProductName &&
          rates?.attributes?.start_at <= currentDate.toISOString() &&
          rates?.attributes?.end_at >= currentDate.toISOString(),
      )
    : undefined;
};
