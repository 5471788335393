/**
 *
 * @description Replaces params in a content string with values
 */
export const replaceContentParams = (
  content: string,
  params: object | null = {},
): string => {
  let modifiedContent = content;
  Object.entries(params).forEach(([key, val]) => {
    modifiedContent = modifiedContent.replaceAll(`{{${key}}}`, val);
  });
  return modifiedContent;
};
