import React from 'react';

const IcpNotInArea = () => (
  <div className="address_bar_content address_bar_content_large">
    <h4 className="heading address_bar_title">Sorry, we can&apos;t supply you with power.</h4>
    <p>Unfortunately we&apos;re not currently operating in your network area.</p>
  </div>
);

export default IcpNotInArea;
