import { format } from "date-fns/format";
import React from "react";
import { NZD } from "utils/currency";

const TRANSACTION_DIRECTION = {
  credit: "credit",
  debit: "debit",
};

type BillSmootherTransactionDetailsProps = {
  amount: number;
  completedAt: string;
  direction: string;
};

export const BillSmootherTransactionDetails: React.FC<
  BillSmootherTransactionDetailsProps
> = ({ completedAt, amount, direction }) => {
  const directionIsCredit = direction === TRANSACTION_DIRECTION.credit;
  return (
    <>
      <p className="customer_tools_list__text">
        {format(new Date(completedAt), "d MMMM")}
      </p>
      <p className="customer_tools_list__text">
        <strong
          className={
            directionIsCredit ? "text__colour--green" : "text__colour--crimson"
          }
        >
          {NZD.format(amount)} {directionIsCredit ? "+" : "-"}
        </strong>
      </p>
    </>
  );
};
