/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import sendAnalytics from '../../utils/analytics';

const ManualInformationEntry = ({ enableICPSearch }) => (
  <div className="manual_address_bar_wrapper">
    <div className="text_block">
      <h2 className="h5" aria-hidden>
        Can&apos;t find your address?{' '}
      </h2>
      <p className="h5" aria-hidden>
        Enter{' '}
      </p>
      <button
        className="button button_secondary js-enterAddressManually"
        type="button"
        onClick={sendAnalytics('Manually enter your address', 'clicked')}
      >
        <span className="sr-only"> Enter</span> your address{' '}
        <span className="sr-only">manually</span>
      </button>
      {enableICPSearch && (
        <>
          <p className="h5" aria-hidden>
            {' '}
            or{' '}
          </p>
          <button
            className="button button_secondary js-enterIcpManually"
            type="button"
            onClick={sendAnalytics('Manually enter your ICP', 'clicked')}
          >
            <span className="sr-only"> Enter</span> your ICP number
            <span className="sr-only">manually</span>
          </button>
        </>
      )}
      <p className="h5" aria-hidden>
        {' '}
        manually.
      </p>
    </div>
  </div>
);

ManualInformationEntry.propTypes = {
  enableICPSearch: PropTypes.bool,
};

ManualInformationEntry.defaultProps = {
  enableICPSearch: true,
};

export default ManualInformationEntry;
