/**
 * @description prepare data for BarChart in WholesalePriceForecastSection
 */

export const prepareForecastedPriceData = (
  forecastedPriceData: ForecastedPrice[] | undefined,
): {
  priceData: {
    time: Date;
    price: number;
    barColour: string;
    status: string;
  }[];
  maxYValue: number | [];
} => {
  if (!forecastedPriceData) return { priceData: [], maxYValue: [] };

  const priceData = forecastedPriceData.map((prices) => ({
    time: new Date(prices.start_at),
    price: Number(prices.price.value),
    barColour:
      prices.start_at < new Date().toISOString() ? "yellow" : "greyShade30",
    status: prices.status?.split(":").pop(),
  }));

  const maxYValue = Math.max(...priceData.map((item) => item.price));

  return { priceData, maxYValue };
};
