import "./CurrentPlanPageStyles.scss";

import { Form } from "@flick/fep-library";
import { AccountPageWrapper } from "components/AccountPageWrapper";
import { ComponentItemFallback } from "components/fallback/ComponentItemFallback";
import { LoadingSection } from "components/LoadingSection";
import { PlanInfo } from "components/PlanInfo";
import { SelectInput } from "components/SelectInput";
import { SubmitButton } from "components/SubmitButton";
import { SwitchCheckbox } from "components/SwitchCheckbox/SwitchCheckbox";
import {
  useGetFutureContractQuery,
  useGetPlansQuery,
  useUpdateCurrentPlanMutation,
} from "queries/contractManagerApi";
import { formatAvailablePlans } from "queries/pricing.utils";
import { useGetRateAvailablePlansQuery } from "queries/pricingRateCardApi";
import { expireTime } from "queries/queries.utils";
import React, {
  ChangeEvent,
  FormEvent,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation } from "react-router-dom";
import { setNotificationBanner } from "reducers/notificationBanner";
import { useAppDispatch, useAppSelector } from "reduxUtils/hook";
import {
  NotificationBannerNames,
  NotificationBannerTypes,
} from "utils/constants";
import { jwtClient } from "utils/jwtClient";
import { getCurrentPlan, getOtherPlans } from "utils/plans/plans";
import { sendAnalyticsEvent } from "utils/sendAnalyticsEvent";
import { replaceContentParams } from "utils/stringFormat/contentParams";

import content from "./static/content.json";
const {
  changePlanInfo,
  changePlanHeader,
  changePlanHint,
  notificationBody,
  notificationHeader,
  otherPlansHeader,
  pageHeader,
  submitLabel,
} = content;

export const CurrentPlanPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const [selectedPlanId, setSelectedPlanId] = useState(undefined);
  const { pathname } = useLocation();
  const [changePlanInfoText, setChangePlanInfoText] = useState(undefined);
  const [isGstInclusive, setIsGstInclusive] = useState<boolean>(false);

  const { customerNumber, supplyNodeRef } = useAppSelector(
    (store) => store.currentAccount,
  );
  const {
    displayName: currentPlanName,
    description: currentPlanDescription,
    id: currentPlanId,
    isCurrentProductError,
  } = useAppSelector((store) => store.currentProduct);
  const { rateCards } = useAppSelector((store) => store.rateCards);
  const currentAccount = useAppSelector((store) => store.currentAccount);

  /**
   * Only this page requires other available plans,
   * so we use cached data directly
   */
  const {
    data: availablePlans,
    isError: isAvailablePlansError,
    isFetching: isAvailablePlansLoading,
  } = useGetRateAvailablePlansQuery(
    {
      jwtClient,
      supplyNodeRef: currentAccount.supplyNodeRef,
      usageType: currentAccount.usageType,
      locationClassification: currentAccount.locationClassification,
    },
    {
      skip:
        !currentAccount.supplyNodeRef ||
        !currentAccount.usageType ||
        !currentAccount.locationClassification,
      refetchOnMountOrArgChange: expireTime,
    },
  );

  /**
   * Only this page requires future plan if user has any
   * so we use cached data directly
   * and refetch only when user submit a change plan request to pull latest plan change
   * -- notes --
   * future plan is always as far as tomorow
   * since any switch plan is scheduled to apply at midnight of the day requested
   */
  const { data: futurePlan, refetch: refetchFuturePlan } =
    useGetFutureContractQuery(
      {
        jwtClient,
        customerNumber: currentAccount.customerNumber,
        supplyNodeRef: currentAccount.supplyNodeRef,
      },
      {
        refetchOnMountOrArgChange: expireTime,
      },
    );

  /**
   * Only this page display detail plan description
   * so we use cached data directly
   */
  const { data: planDescriptions } = useGetPlansQuery(
    {
      jwtClient,
      customerNumber: currentAccount.customerNumber,
      supplyNodeRef: currentAccount.supplyNodeRef,
    },
    {
      skip: !currentAccount.accountNumber || !currentAccount.supplyNodeRef,
      refetchOnMountOrArgChange: expireTime,
    },
  );

  const formattedRateCards = useMemo(
    () => formatAvailablePlans(rateCards),
    [rateCards],
  );

  const currentPlan: AvailablePlan = useMemo(
    () => getCurrentPlan(availablePlans, currentPlanId),
    [availablePlans, currentPlanId],
  );

  const otherPlans: AvailablePlan[] = useMemo(
    () => getOtherPlans(availablePlans, currentPlanId),
    [availablePlans, currentPlanId],
  );

  const newPlanName = useMemo(() => {
    return futurePlan?.id
      ? availablePlans?.find((ap) => ap.id === futurePlan.id)?.productName
      : undefined;
  }, [futurePlan, availablePlans]);

  /**
   * if user has a valid future plan
   * show reminder that they are schedule to change plan at midnight
   */
  useEffect(() => {
    if (!newPlanName) {
      setChangePlanInfoText(undefined);
      return;
    }

    if (futurePlan.id !== currentPlanId) {
      const planChangeInfo = replaceContentParams(changePlanInfo || "", {
        planName: newPlanName,
      });
      setChangePlanInfoText(planChangeInfo);
    }
    if (futurePlan.id === currentPlanId) {
      setChangePlanInfoText(undefined);
    }
  }, [currentPlanId, newPlanName]);

  const [updateCurrentPlan, { isLoading }] = useUpdateCurrentPlanMutation();

  const handleUpdateCurrentPlan = async (planId: string) => {
    try {
      await updateCurrentPlan({
        customerNumber: customerNumber,
        planId: planId,
        supplyNodeRef: supplyNodeRef,
        jwtClient,
      }).unwrap();

      refetchFuturePlan();

      dispatch(
        setNotificationBanner({
          type: NotificationBannerTypes.success,
          name: NotificationBannerNames.updatePlan,
          page: pathname,
        }),
      );
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (err) {
      dispatch(
        setNotificationBanner({
          type: NotificationBannerTypes.error,
          name: NotificationBannerNames.updatePlan,
          page: pathname,
        }),
      );
    }
  };

  const selectOptions = [
    { label: `${currentPlanName} (current)`, value: currentPlanId },
    ...otherPlans.map(({ productName, id }) => ({
      label: productName,
      value: id,
    })),
  ];

  if (isCurrentProductError) {
    return <ComponentItemFallback componentTitle={"Current plan details"} />;
  }

  const handlePlanSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedPlanId(e.target.value);
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    const selectedPlanName = availablePlans.find(
      (plan) => plan.id === selectedPlanId,
    )?.productName;
    sendAnalyticsEvent("change_plan", {
      icpNumber: currentAccount.icpNumber,
      previous_plan_id: currentPlanId,
      previous_plan_name: currentPlanName,
      selected_plan_id: selectedPlanId,
      selected_plan_name: selectedPlanName,
    });
    handleUpdateCurrentPlan(selectedPlanId);
  };

  const handleGstInclusionChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    setIsGstInclusive(checked);
  };

  return (
    <AccountPageWrapper headerLabel={pageHeader} hasCancelButton={false}>
      <div className="spacer spacer--medium" />
      <div className="switch_section">
        <SwitchCheckbox
          name="gstInclusion"
          id="gstInclusionSwitch1"
          onChange={handleGstInclusionChange}
          checked={isGstInclusive}
          label="GST Inclusion"
          aria-label="switch GST Inclusion"
        />
        <label className="switch_section__label">
          Prices {isGstInclusive ? "including" : "excluding"} GST
        </label>
      </div>
      <div className="current_plan_page">
        {isAvailablePlansLoading && <LoadingSection />}
        {currentPlanId && !isAvailablePlansLoading && (
          <PlanInfo
            id={currentPlanId}
            name={currentPlanName}
            description={currentPlanDescription}
            rates={{
              subscriptionRates: currentPlan
                ? currentPlan.subscriptionRates
                : formattedRateCards[0]?.subscriptionRates,
              usageRates: currentPlan
                ? currentPlan.usageRates
                : formattedRateCards[0]?.usageRates,
            }}
            isGstInclusive={isGstInclusive}
          />
        )}
        <hr className="content_divider spacer spacer--medium" />
        <section>
          <h2 className="section-title heading h3">{otherPlansHeader}</h2>
          <div className="switch_section">
            <SwitchCheckbox
              name="gstInclusion"
              id="gstInclusionSwitch2"
              onChange={handleGstInclusionChange}
              checked={isGstInclusive}
              label="GST Inclusion"
              aria-label="switch GST Inclusion"
            />
            <label className="switch_section__label">
              Prices {isGstInclusive ? "including" : "excluding"} GST
            </label>
          </div>
          <div className="notification_banner notification_notice-flick icon spacer">
            <p className="heading">{notificationHeader}</p>
            <p className="body_paragraph notification_body">
              {notificationBody}
              <span className="spacer spacer--large" />
              Prices {isGstInclusive ? "include" : "exclude"} GST.
            </p>
          </div>
        </section>
        {isAvailablePlansLoading && <LoadingSection />}
        {isAvailablePlansError && (
          <ComponentItemFallback componentTitle={"Other plans"} />
        )}
        {otherPlans && otherPlans.length === 0 && (
          <p>There are currently no other plans available to you.</p>
        )}
        {otherPlans &&
          otherPlans.length > 0 &&
          otherPlans.map(
            ({ productName, id, subscriptionRates, usageRates }) => {
              return (
                <PlanInfo
                  key={id}
                  name={productName}
                  description={
                    planDescriptions?.find(
                      (planDescription) => planDescription.planId === id,
                    )?.description ?? ""
                  }
                  id={id}
                  rates={{
                    subscriptionRates,
                    usageRates: usageRates,
                  }}
                  isGstInclusive={isGstInclusive}
                />
              );
            },
          )}
        <hr className="content_divider spacer spacer--medium" />
        <div>
          <h2 className="section-title heading h3">{changePlanHeader}</h2>
          {changePlanInfoText && (
            <div className="notification_banner notification_notice icon spacer">
              <p className="heading h6">{changePlanInfoText}</p>
            </div>
          )}
          {isAvailablePlansLoading && <LoadingSection />}
          {!isAvailablePlansLoading && (
            <Form onSubmit={handleSubmit}>
              <SelectInput
                ariaLabel="Change plan"
                name="change-plan"
                hint={changePlanHint}
                defaultOption={selectOptions[0].value}
                options={selectOptions}
                handleOnChange={handlePlanSelect}
                value={selectedPlanId}
              />
              <span className="spacer spacer--small"></span>
              <SubmitButton
                isSubmitDisabled={
                  isLoading ||
                  !selectedPlanId ||
                  selectedPlanId === currentPlanName
                }
                label={submitLabel}
              />
            </Form>
          )}
        </div>
      </div>
    </AccountPageWrapper>
  );
};
