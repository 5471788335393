/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { useA11yDialog } from 'react-a11y-dialog';

const Dialog = ({ title, id, theme, children, dialogRef, size = 'dialog_large' }) => {
  const target = useRef(document.getElementById('modals'));
  const [instance, attr] = useA11yDialog({
    // The required HTML `id` attribute of the dialog element, internally used
    // a11y-dialog to manipulate the dialog.
    id,
    // The required dialog title, mandatory in the document
    // to provide context to assistive technology.
    title,
  });

  const dialog = (
    <div {...attr.container} className="dialog_container">
      <div {...attr.overlay} className="dialog_overlay" tabIndex={-1} />
      <div {...attr.dialog} className={`dialog ${size}`} role="dialog">
        {title && (
          <header className={`dialog_header dialog_header--${theme}`}>
            <h1 id={`${id}-title`} className="dialog_heading">
              {title}
            </h1>
          </header>
        )}
        <div className="dialog_contents">{children}</div>
        <button
          {...attr.closeButton}
          type="button"
          className="dialog_close"
          aria-label="Close this dialog window"
        />
      </div>
    </div>
  );

  useEffect(() => {
    if (dialogRef) {
      // eslint-disable-next-line no-param-reassign
      dialogRef.current = instance;
    }
  }, [instance]);

  useEffect(
    () => () => {
      window.requestAnimationFrame(() => {
        if (target.current.childNodes.length === 0) {
          target.current.remove();
          target.current = null;
        }
      });

      if (instance) {
        instance.destroy();
        // eslint-disable-next-line no-param-reassign
        dialogRef.current = undefined;
      }
    },
    []
  );

  if (!target.current) {
    target.current = document.createElement('div');
    target.current.setAttribute('id', 'modals');
    document.body.appendChild(target.current);
  }

  return ReactDOM.createPortal(dialog, target.current);
};

Dialog.propTypes = {
  title: PropTypes.string,
  theme: PropTypes.string,
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  dialogRef: PropTypes.object.isRequired,
};

Dialog.defaultProps = {
  title: null,
  theme: 'charlie',
};

export default Dialog;
