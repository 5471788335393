/**
 * Validates if the date is either today or in the future
 */

export const closeDateValidation = (value: string): boolean => {
  const today = new Date();
  today.setUTCHours(0, 0, 0, 0);
  const newDate = new Date(value);

  return newDate.getTime() >= today.getTime();
};
