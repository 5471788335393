import { AccountDetailsPageContent } from "pages/AccountDetailsPage/AccountDetailsPageContent";
import { AccountDetailsFormContextProvider } from "pages/AccountDetailsPage/AccountDetailsPageContext";
import React from "react";

export const AccountDetailsPage: React.FC = () => {
  return (
    <AccountDetailsFormContextProvider>
      <AccountDetailsPageContent />
    </AccountDetailsFormContextProvider>
  );
};
