import React from "react";

import { UpdatePasswordPageContent } from "./UpdatePasswordPageContent";
import { UpdatePasswordFormContextProvider } from "./UpdatePasswordPageContext";

export const UpdatePasswordPage: React.FC = () => {
  return (
    <UpdatePasswordFormContextProvider>
      <UpdatePasswordPageContent />
    </UpdatePasswordFormContextProvider>
  );
};
