/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Dropdown from '@flick/front_end_patterns/src/js/forms/form_fields_select';
import { EXCLUSIVE, INCLUSIVE } from '../../utils/constants';
import sendAnalytics from '../../utils/analytics';

const GstInclusion = ({ gstInclusion, setGstInclusion }) => {
  const dropdownJS = new Dropdown();
  const selectRef = useRef(null);

  useEffect(() => {
    dropdownJS.start();
  }, []);

  useEffect(() => {
    dropdownJS.setValue(selectRef.current, gstInclusion);
  }, [gstInclusion]);

  return (
    <div className="form_field">
      <div>
        <select
          ref={selectRef}
          name="gst_inclusion"
          id="gst_inclusion"
          value={gstInclusion}
          onChange={(event) => {
            setGstInclusion(event.target.value);
            sendAnalytics('GST: Toggle', 'Toggle');
          }}
          data-choices
        >
          <option value={INCLUSIVE}>inc. gst</option>
          <option value={EXCLUSIVE}>ex. gst</option>
        </select>
        <label id="gst_inclusion_label" htmlFor="gst_inclusion" className="sr-only">
          GST
        </label>
      </div>
    </div>
  );
};

GstInclusion.propTypes = {
  gstInclusion: PropTypes.string.isRequired,
  setGstInclusion: PropTypes.func.isRequired,
};

export default GstInclusion;
