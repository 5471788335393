/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import "./Dialog.scss";

import React, {
  createRef,
  KeyboardEvent,
  ReactNode,
  RefObject,
  useEffect,
} from "react";

type DialogProps = {
  children: ReactNode;
  dialogIsHidden: boolean;
  handleDialogClose: () => void;
};

export const Dialog: React.FC<DialogProps> = ({
  children,
  handleDialogClose,
  dialogIsHidden,
}) => {
  const modalRef: RefObject<HTMLDivElement> = createRef();

  useEffect(() => {
    function keyListener(e) {
      const listener = keyListenersMap.get(e.keyCode);
      return listener && listener(e);
    }
    document.addEventListener("keydown", keyListener);

    return () => document.removeEventListener("keydown", keyListener);
  });

  // This function creates a focus trap inside the dialog so that if a user is 'tabbing' while the dialog is open,
  // it will cycle only through the dialog elements that are focusable i.e. cannot tab onto any of the background
  // elements when the dialog is open.
  const handleTabKey = (event: KeyboardEvent) => {
    const focusableModalElements = modalRef.current.querySelectorAll(
      'a[href], button, textarea, input[type="text"], input[type="radio"], input[type="checkbox"], select',
    );
    const [firstElement] = focusableModalElements;
    const lastElement =
      focusableModalElements[focusableModalElements.length - 1];
    if (document.activeElement === lastElement) {
      (firstElement as HTMLInputElement).focus();
      event.preventDefault();
    }
  };

  const keyListenersMap = new Map([
    [27, handleDialogClose],
    [9, handleTabKey],
  ]);

  return (
    <div className="dialog_container" aria-hidden={dialogIsHidden}>
      <div
        className="dialog_overlay"
        onClick={handleDialogClose} // Typically click events should not be on static elements, however it is standard for modals to close on overlay click
        tabIndex={-1}
      ></div>
      <div
        aria-labelledby="dialog-title"
        className="dialog dialog_large"
        ref={modalRef}
        role="dialog"
      >
        <div className="dialog_contents">
          <button
            id="close-x"
            type="button"
            aria-label="Close this dialog window"
            className="button button--outline dialog_close"
            onClick={handleDialogClose}
          />
          {children}
        </div>
      </div>
    </div>
  );
};
