import { ComponentItemFallback } from "components/fallback/ComponentItemFallback";
import { LoadingSection } from "components/LoadingSection";
import { addSeconds, differenceInDays } from "date-fns";
import { useGetAggregateRatesQuery } from "queries/ratingApi";
import React, { useMemo } from "react";
import { useAppSelector } from "reduxUtils/hook";
import { jwtClient } from "utils/jwtClient";
import { prepareDailyUsageData } from "utils/prepareDailyUsageData";

import { HomeGenerationChartWrapper } from "./HomeGenerationChartWrapper";

type HomeGenerationSectionProps = {
  hasPreviousData?: boolean;
  previousPeriodStartDate?: Date;
  periodStartDate: Date;
  periodEndAt: Date;
  periodName?: string;
};

export const HomeGenerationSection: React.FC<HomeGenerationSectionProps> = ({
  hasPreviousData,
  previousPeriodStartDate,
  periodStartDate,
  periodEndAt,
  periodName,
}) => {
  const { supplyNodeRef } = useAppSelector((store) => store.currentAccount);

  const startAt = useMemo(() => {
    return hasPreviousData && previousPeriodStartDate
      ? previousPeriodStartDate
      : periodStartDate;
  }, [previousPeriodStartDate, hasPreviousData, periodStartDate]);

  // Billing cycle is 1 millisecond before midnight but difference in days rounds down so we add an extra second to make the final day a whole day.
  const timePeriodEndAt = addSeconds(new Date(periodEndAt), 1);
  const timePeriodDifference = differenceInDays(
    timePeriodEndAt,
    new Date(periodStartDate),
  );
  const {
    data: aggregatesData,
    isFetching,
    isError: isAggregatesDataError,
  } = useGetAggregateRatesQuery(
    {
      supplyNodeRef,
      startAt,
      endAt: periodEndAt,
      jwtClient,
    },
    {
      skip: !supplyNodeRef || !startAt || !periodEndAt,
    },
  );

  const dailyUsageData = useMemo(() => {
    return prepareDailyUsageData(
      aggregatesData,
      periodStartDate,
      timePeriodDifference,
    );
  }, [aggregatesData, periodStartDate, timePeriodDifference]);

  const showDailyUsageSection =
    !isFetching &&
    Boolean(aggregatesData) &&
    Boolean(dailyUsageData) &&
    dailyUsageData.length > 0;

  return (
    <>
      <h2 className="section-title heading h4">Power Bought & Sold</h2>
      {isFetching && <LoadingSection />}
      {showDailyUsageSection && (
        <HomeGenerationChartWrapper
          dailyUsageData={dailyUsageData}
          periodName={periodName}
        />
      )}
      {isAggregatesDataError && (
        <>
          <div className="spacer" />
          <ComponentItemFallback componentTitle="Daily breakdown of power bought & sold" />
        </>
      )}
    </>
  );
};
