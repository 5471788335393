import camelCase from 'lodash/camelCase';
import mapKeys from 'lodash/mapKeys';

export const camelCaseKeys = (object) => mapKeys(object, (_value, key) => camelCase(key));

export const titleCase = (str) =>
  str
    .toLowerCase()
    .replace(/_/g, ' ')
    .replace(/(?:\b)(\w)/g, (_, match) => match.toUpperCase());

export const isNotBlank = (value) => value && value.length > 0;
