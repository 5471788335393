import { BillingDetailsPageContent } from "pages/BillingDetailsPage/BillingDetailsPageContent";
import { BillingDetailsFormContextProvider } from "pages/BillingDetailsPage/BillingDetailsPageContext";
import React from "react";

export const BillingDetailsPage: React.FC = () => {
  return (
    <BillingDetailsFormContextProvider>
      <BillingDetailsPageContent />
    </BillingDetailsFormContextProvider>
  );
};
