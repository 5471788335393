import classnames from "classnames";
import { AnnouncementBanner } from "components/AnnouncementBanner";
import React from "react";

export const DisplayAnnouncementBanner = ({
  enableNativeMode,
  enableDesktopMode,
  nativeRenderMode,
  hiddenBanner = false,
}) => {
  if (!enableNativeMode && !enableDesktopMode) return null;
  return (
    <div
      className={classnames("notification_container", {
        "notification_container--announcement-mobile": nativeRenderMode,
        // do not show the banner when
        "notification_container--hidden":
          hiddenBanner || // if banner is manually hidden
          (!enableDesktopMode && !nativeRenderMode) || // if banner should show in mobile only and is not in native mode
          (!enableNativeMode && nativeRenderMode), // if banner should show in desktop only and is in native mode
      })}
    >
      <AnnouncementBanner />
    </div>
  );
};
