const sumPrices = (lineItems: LineItemData[]): string => {
  if (!lineItems) {
    return null;
  }
  const price = lineItems.reduce(
    (total, item) => total + Number(item.attributes.charge.price),
    0,
  );

  return price.toFixed(2);
};

/**
 * @description filtered out attributes value for given line item group  by category and group name
 */
const lineItemGroupFor = (
  lineItemGroups: LineItemGroup[],
  groupName: string,
): LineItemGroupAttribute[] => {
  return lineItemGroups
    .filter((group) => group.attributes.category == groupName)
    .map((group) => group.attributes);
};

const dailyLineItemValues = (
  lineItemGroupAttribute: LineItemGroupAttribute | null,
): { quantity: string; cost: string } => {
  const lineItems = lineItemGroupAttribute?.items?.data;
  const quantity = lineItems
    ? lineItems[0]?.attributes.eligible_quantity.unit_text
    : null;

  return { quantity, cost: sumPrices(lineItems) };
};

const offPeakLineItemValues = (
  lineItemGroupAttributes?: LineItemGroupAttribute[] | null,
): { quantity: string; cost: string; label: string }[] | null => {
  if (!lineItemGroupAttributes) {
    return null;
  }
  // Build a flattened array of all usage items.
  let lineItems: LineItemData[] = [];
  lineItemGroupAttributes.map((lineItemGroup) => {
    lineItems = lineItems.concat(lineItemGroup.items.data);
  });

  return lineItems.map((item) => {
    return {
      cost: item.attributes.charge.price,
      quantity: item.attributes.eligible_quantity.value.toFixed(3),
      label: normaliseDescription(item.attributes.description),
    };
  });
};

const usageLineItemValues = (
  lineItemGroupAttributes: LineItemGroupAttribute[],
): { quantity: string; cost: string; label: string }[] => {
  const filteredArray = lineItemGroupAttributes.map((lineItemGroup) => {
    const lineItems = lineItemGroup.items.data;
    // Wholesale customers have their usage charges broken down into component parts
    // (network, admin, market etc). These don't sum to total usage but are instead
    // different component charges for the same kWh value. In some instances the total
    // kWh amount is broken down further (e.g offpeak and peak transmission charges) so
    // we take the highest value from the array of component charges and expect this to
    // be the true usage quantity.
    const lineItemUsageQuantities = lineItems.map(
      (item) => item.attributes.eligible_quantity.value,
    );

    const quantity = Math.max(...lineItemUsageQuantities);

    return {
      quantity: quantity.toFixed(3),
      cost: sumPrices(lineItems),
      label: lineItemGroup.group,
    };
  });

  return filteredArray;
};

/**
 *
 * @description Strip out the brackets and contained text from description to make label text easier to read
 */
export const normaliseDescription = (description?: string): string => {
  return description?.replace(/ \([\s\S]*?\)/g, "");
};

const washupLineItemValues = (
  lineItemGroupAttribute?: LineItemGroupAttribute,
): FormattedLineItemValues[] | null => {
  if (!lineItemGroupAttribute) {
    return null;
  }

  const delayedLineItems = lineItemGroupAttribute.items.data.filter(
    (lineItem) => lineItem.attributes.delayed,
  );
  const correctionLineItems = lineItemGroupAttribute.items.data.filter(
    (lineItem) => lineItem.attributes.adjustment,
  );

  if (!delayedLineItems.length && !correctionLineItems.length) {
    return null;
  }

  return [
    {
      id: delayedLineItems[0]?.id,
      label: "Delayed charges (see bill PDF)",
      cost: sumPrices(delayedLineItems),
    },
    {
      id: correctionLineItems[0]?.id,
      label: "Past bill correction (see bill PDF)",
      cost: sumPrices(correctionLineItems),
    },
  ];
};

const otherLineItemValues = (
  lineItemGroupAttribute?: LineItemGroupAttribute | null,
): FormattedLineItemValues[] | null => {
  if (!lineItemGroupAttribute) {
    return null;
  }

  const lineItems = lineItemGroupAttribute.items.data.filter(
    (lineItem) => !lineItem.attributes.washup,
  );

  if (!lineItems.length) {
    return null;
  }

  return lineItems.map((lineItem) => {
    return {
      id: lineItem.id,
      label: lineItem.attributes.description,
      cost: Number(lineItem.attributes.charge.price),
    };
  });
};

/**
 * @description Prepare Bill data for BillBreakdownSection and OtherCostsSection
 * Takes the all the different charges that are associated with a bill and groups them into daily, usage, other, and washup charges.
 * @param {Array<LineItemGroup>} lineItemGroups - The different line items that a bill has
 * @param {string} productName - The product the customer is on e.g. offpeak, flat
 * @returns {{
 * daily: { quantity: string, cost: string };
 * usage: Array<{ quantity: string, cost: string, label: string }> | {quantity: string, cost: string, label: string};
 * other: Array<{ label: string, cost: number }>;
 * washup: Array<{ label: string, cost: string }>;
 * }} the grouped charges with a label describing what the charge is and the sum cost of all the charges that are in this group, and quanitity
 */

type BillBreakDownType = {
  daily: { quantity: string; cost: string };
  usage: { quantity: string; cost: string; label: string }[];
  other: { label: string; cost: string | number }[];
  washup: { label: string; cost: string | number }[];
};

export const prepareBillBreakdown = (
  lineItemGroups: LineItemGroup[],
  productName: string,
): BillBreakDownType => {
  const [dailyLineItemGroup] = lineItemGroupFor(lineItemGroups, "daily");
  const [otherLineItemGroup] = lineItemGroupFor(lineItemGroups, "other");
  const usageLineItemGroups = lineItemGroupFor(lineItemGroups, "usage");

  return {
    daily: dailyLineItemValues(dailyLineItemGroup),
    usage:
      normaliseDescription(productName) === "offpeak"
        ? offPeakLineItemValues(usageLineItemGroups)
        : usageLineItemValues(usageLineItemGroups),
    other: otherLineItemValues(otherLineItemGroup),
    washup: washupLineItemValues(otherLineItemGroup),
  };
};
