/* eslint-disable jsx-a11y/anchor-is-valid */
import "./InlineBanner.scss";

import { Button } from "@flick/fep-library";
import React from "react";
import ReactMarkdown from "react-markdown";

import content from "./static/content.json";

type InlineBannerProps = {
  status: string;
  onHandlePayNowClick?: () => void;
};

export const InlineBanner: React.FC<InlineBannerProps> = ({
  status,
  onHandlePayNowClick,
}) => {
  const bannerClass = status === "failedPayment" ? "error" : status;
  const notificationBody = content[`${status}Text`];

  const components = {
    a: ({ children, href }) => {
      if (href === "#pay-now-button") {
        return (
          <a
            href="#"
            onClick={(event) => {
              event.preventDefault();
              onHandlePayNowClick();
            }}
          >
            {children}
          </a>
        );
      }
      return <a href={href}>{children}</a>;
    },
  };

  return (
    <div
      className={`notification_banner notification_${bannerClass} icon spacer`}
    >
      <ReactMarkdown
        components={components}
        children={notificationBody}
        className="body_paragraph notification_body"
      />
      {status === "notice" && (
        <div className="pay-now-section">
          <Button
            buttonColor="contrast"
            buttonSize="small"
            extraClasses="button--expanded"
            onClick={(event) => {
              event.preventDefault();
              onHandlePayNowClick();
            }}
          >
            Pay now
          </Button>
        </div>
      )}
    </div>
  );
};
