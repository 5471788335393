import React, { useState } from 'react';
import PropTypes from 'prop-types';

import AddressSearch from './addressSearch';
import Results from './components/plan-chooser/results';

const PlanChooser = ({
  addressFinderKey,
  apiBaseUrl,
  defaultPlans,
  externalCopy,
  showDefaultPlans,
}) => {
  const [showPlans, setShowPlans] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [address, setAddress] = useState(null);
  const [supplyNode, setSupplyNode] = useState(null);

  const continueJoiningCallback = (selectedSupplyNode, selectedAddress) => {
    if (selectedSupplyNode) {
      setAddress(selectedSupplyNode.address);
      setSupplyNode(selectedSupplyNode);
      setShowPlans(true);
    } else if (selectedAddress) {
      setAddress(selectedAddress);
      setSupplyNode(null);
      setShowPlans(true);
    } else {
      setAddress(null);
      setSupplyNode(null);
      setShowPlans(false);
    }
  };

  return (
    <>
      <AddressSearch
        addressFinderKey={addressFinderKey}
        addressPlaceholderText={
          externalCopy?.placeholderText || "See what's available at your address..."
        }
        apiBaseUrl={apiBaseUrl}
        continueJoiningCallback={continueJoiningCallback}
        joining={showPlans}
      />
      {(showDefaultPlans || showPlans) && (
        <Results
          apiBaseUrl={apiBaseUrl}
          defaultPlans={defaultPlans}
          supplyNode={supplyNode}
          address={address}
          externalPlansCopy={externalCopy}
        />
      )}
    </>
  );
};

PlanChooser.propTypes = {
  addressFinderKey: PropTypes.string.isRequired,
  apiBaseUrl: PropTypes.string.isRequired,
  defaultPlans: PropTypes.arrayOf(PropTypes.string).isRequired,
  showDefaultPlans: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  externalCopy: PropTypes.object.isRequired,
};

PlanChooser.defaultProps = {
  showDefaultPlans: false,
};

export default PlanChooser;
