import { subDays } from "date-fns/subDays";

export const getAggregateStartDate = (
  previousStartDate: Date,
  currentStartDate: Date,
  hasPreviousData: boolean,
) => {
  if (hasPreviousData && previousStartDate) return subDays(currentStartDate, 7);
  return currentStartDate;
};
