import "./PeriodToggle.scss";

import React, { ChangeEvent } from "react";
import { useAppSelector } from "reduxUtils/hook";
import { AVAILABLE_PERIODS, CUSTOM_PERIOD } from "utils/constants";
import { sendAnalyticsEvent } from "utils/sendAnalyticsEvent";

type PeriodToggleProps = {
  period: string;
  togglePeriod: (period: Period) => void;
};

/**
 * @description A set of buttons to switch the period filtering the data on the usage page.
 */
export const PeriodToggle: React.FC<PeriodToggleProps> = ({
  period,
  togglePeriod,
}) => {
  const { icpNumber } = useAppSelector((store) => store.currentAccount);

  const availablePeriods = { ...AVAILABLE_PERIODS, ...CUSTOM_PERIOD };

  const handleChange = (event: ChangeEvent) => {
    const { value } = event.target as HTMLInputElement;
    const newPeriod = availablePeriods[value];
    sendAnalyticsEvent("usage_tab_select", {
      icpNumber,
      tab_text: newPeriod.periodName,
    });
    togglePeriod(newPeriod);
  };

  return (
    <>
      <p className="small" aria-hidden>
        for the last
      </p>
      <div className="radio_button__container">
        <span className="toggle">
          {Object.values(availablePeriods).map((available_period) => {
            const { periodName, code } =
              availablePeriods[available_period.code];
            return (
              <label htmlFor={periodName} key={periodName}>
                <input
                  type="radio"
                  id={periodName}
                  name="period_group"
                  value={code}
                  checked={period === periodName}
                  onChange={handleChange}
                />
                <span>{periodName}</span>
              </label>
            );
          })}
        </span>
      </div>
    </>
  );
};
