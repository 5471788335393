import "./SelectInput.scss";

import React, { ChangeEvent } from "react";

export interface SelectOption {
  label: string;
  value: string;
}

type SelectInputProps = {
  errorMessage?: string;
  ariaLabel?: string;
  defaultOption?: SelectOption;
  extraClasses?: string;
  hint?: string;
  initialValue?: string;
  label?: string | JSX.Element;
  name: string;
  options: SelectOption[];
  required?: boolean;
  value?: string;
  handleOnChange(event: ChangeEvent<HTMLSelectElement>): void;
};

export const SelectInput: React.FC<SelectInputProps> = ({
  errorMessage,
  ariaLabel = "",
  extraClasses,
  hint,
  initialValue = "Select...",
  defaultOption,
  handleOnChange,
  label,
  name,
  options,
  required,
  value,
}) => {
  return (
    <div className="input input--full-width">
      {label && <label htmlFor={`${name}-select`}>{label}</label>}
      <select
        aria-label={ariaLabel}
        className={`input__unlabeled ${extraClasses ? ` ${extraClasses}` : ""}`}
        id={`${name}-select`}
        name={name}
        onChange={handleOnChange}
        required={required}
        defaultValue={value ? undefined : defaultOption?.value}
        value={value || undefined}
      >
        {!defaultOption && <option className="initial">{initialValue}</option>}

        {options.map((o) => (
          <option key={o.value} value={o.value}>
            {o.label}
          </option>
        ))}
      </select>

      {errorMessage ? (
        <span className="input__helper input__helper--error">
          {errorMessage}
        </span>
      ) : (
        hint && <span className="input__helper">{hint}</span>
      )}
    </div>
  );
};
