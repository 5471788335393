import "../styles/LoadingPage.scss";

import { Loader } from "components/Loader";
import React from "react";

export const LoadingPage: React.FC = () => {
  return (
    <div className="loading-page">
      <Loader />
    </div>
  );
};
