import React, { ChangeEvent } from "react";

import { BasicInput } from "../BasicInput";

type PasswordInputProps = {
  ariaLabel?: string;
  disabled?: boolean;
  errorMessage?: string;
  handleOnBlur?: () => void;
  hint?: string;
  inputSize?: "default" | "large";
  inputWidth?: "default" | "full";
  label: string;
  name: string;
  placeholderText?: string;
  required?: boolean;
  setValue: (value: string) => void;
  value: string;
};

export const PasswordInput: React.FC<PasswordInputProps> = ({
  ariaLabel,
  disabled,
  errorMessage,
  handleOnBlur,
  hint,
  inputSize,
  inputWidth,
  label,
  name,
  placeholderText,
  required = true,
  setValue,
  value,
}) => {
  const handleInputChange = (event: ChangeEvent) => {
    const { value: inputValue } = event.target as HTMLInputElement;
    setValue(inputValue);
  };

  return (
    <BasicInput
      ariaLabel={ariaLabel}
      errorMsg={errorMessage}
      handleInputChange={handleInputChange}
      handleOnBlur={handleOnBlur}
      hint={hint}
      inputSize={inputSize}
      inputWidth={inputWidth}
      label={label}
      name={name}
      placeholderText={placeholderText}
      required={required}
      state={disabled ? "disabled" : errorMessage ? "error" : "default"}
      type="password"
      value={value}
    />
  );
};
