import "./ApplicationJourneyTracker.scss";

import React from "react";

type ApplicationJourneyTrackerItemProps = {
  status: string;
  title: string;
  description: string;
  blocked: boolean;
};

export const ApplicationJourneyTrackerItem: React.FC<
  ApplicationJourneyTrackerItemProps
> = ({ status, title, description, blocked }) => {
  const getStatusClass = (state, blockedStatus) => {
    if (blockedStatus) return "failed";

    switch (state) {
      case "complete":
        return "completed";
      case "pending":
        return "active";
      case "incomplete":
        return "inactive";
      default:
        return "";
    }
  };

  return (
    <li className={getStatusClass(status, blocked)}>
      <span className="progress_item_header text__colour--crimson">
        {title}
      </span>
      <p>{description}</p>
    </li>
  );
};
