import React from "react";

/**
 * @description A collection of detail disclosure widgets that provides users
 * with information about frequently asked questions about Bill Smoother
 */
export const BillSmootherFaq: React.FC = () => {
  return (
    <>
      <details className="accordion accordion--full-width">
        <summary className="summary">
          <p>How do I open a Bill Smoother account?</p>
        </summary>
        <p>
          Go to the Bill Smoother tab in your dashboard, or the account tab in
          your Flick mobile app and click 'Get a Flick Bill Smoother'. After you
          set a minimum payment and a "When to use Bill Smoother" amount, your
          Bill Smoother account will be activated for the next week starting on
          a Monday.
        </p>
      </details>
      <details className="accordion accordion--full-width">
        <summary className="summary">
          <p>How do I change my contribution amount?</p>
        </summary>
        <p>
          You can change your contribution amounts in your dashboard or Flick
          app any time, and the change will take place after the nearest Sunday.
          If you want to pause your Bill Smoother for the week that we're in or
          you want to take your entire bill using your Bill Smoother stash,
          you'll need to give us a call.
        </p>
      </details>
      <details className="accordion accordion--full-width">
        <summary className="summary">
          <p>Can I add a lump sum to Bill Smoother?</p>
        </summary>
        <p>
          The way to do this is to add how much you want to put in to your
          minimum payment, and then change it back. So if your minimum payment
          is $30 and you want to add $100, then make your minimum payment $130
          for one week (before Sunday) and then change it back after the payment
          is taken on Thursday (but before Sunday).
        </p>
      </details>
      <details className="accordion accordion--full-width">
        <summary className="summary">
          <p>What do I do if I want to use my Bill Smoother balance up?</p>
        </summary>
        <p>
          If you want to run your balance down quickly, then set your "When to
          use Bill Smoother" amount to $0 and we'll take the full amount of your
          next bill out of your Volt. Remember though, using our Bill Smoother
          feature gives you a stash to take the edge off the occasional high
          bill, so if you deliberately run it down, you won't have that option
          anymore.
        </p>
      </details>
      <details className="accordion accordion--full-width">
        <summary className="summary">
          <p>Will I ever pay more than my minimum payment?</p>
        </summary>
        <p>
          Possibly, it depends how you set your Bill Smoother up. Remember, you
          have all the control! Here are the things that could happen... If your
          bill is lower than your minimum payment Then the difference between
          your bill and your payment goes into your Bill Smoother. If your bill
          is higher than your minimum payment, but less than your "When to use
          Bill Smoother" amount Then we deduct the total bill from your bank
          account/credit card. If your bill is higher than your "When to use
          Bill Smoother" amount Then the amount up to your "When to use Bill
          Smoother" amount will be deducted from your bank account/credit card,
          and the amount above your "When to use Bill Smoother" amount will come
          out of your Bill Smoother account. If your bill is higher than your
          "When to use Bill Smoother" amount but there isn’t enough in your Bill
          Smoother account to cover it Then the amount up to your "When to use
          Bill Smoother" amount will be deducted from your bank account/credit
          card, your Bill Smoother account will be emptied, and any outstanding
          balance will be deducted from your bank account/credit card.
        </p>
      </details>
      <details className="accordion accordion--full-width">
        <summary className="summary">
          <p>Can I pause my minimum payment?</p>
        </summary>
        <p>
          When your Bill Smoother account is paused, you’ll go back to paying
          the same amount as your bill on Thursday. If your bill is more than
          your "When to use Bill Smoother" amount, the amount above it will come
          from your Bill Smoother account until your credit is used up. When you
          reactivate your Bill Smoother account, your minimum payment will start
          again on the next Thursday cycle. Remember you can always reduce the
          amount of your minimum payment if you find you're paying too much or
          need to reduce your outgoings.
        </p>
      </details>
      <details className="accordion accordion--full-width">
        <summary className="summary">
          <p>Is there a limit to how much I can have in my Bill Smoother?</p>
        </summary>
        <p>
          You can have up to $500 in your Bill Smoother account. If you reach
          this threshold, we'll automatically stop your contributions.
        </p>
      </details>
      <details className="accordion accordion--full-width">
        <summary className="summary">
          <p>Can I get my credit back?</p>
        </summary>
        <p>
          If you need your credit with Flick refunded to you, you can contact us
          on 0800 435 425 or hello@flickelectric.co.nz to request a credit
          refund. We will look at your debt balance, the timing of upcoming
          bills, and how much is in your Bill Smoother stash, to make a plan
          with you. If you are leaving Flick, any possible refund will be
          processed after your final bill is calculated. Check out the Terms &
          Conditions of Bill Smoother for more details.
        </p>
      </details>
      <details className="accordion accordion--full-width">
        <summary className="summary">
          <p>When’s the best time of year to set up my Bill Smoother?</p>
        </summary>
        <p>
          As a tool, Bill Smoother helps to level out the seasonality of power
          consumption, by storing credit during the warmer months which can be
          used to help pay higher bills during the winter time. You can set it
          up at any time, but we’d recommend doing it in the warmer months when
          your bills are lower and it’s an easier time to be able to build up
          credit on your account so that you’re well prepared for winter!
        </p>
        <p>
          With Flick, you cut out the middle man and buy your energy straight
          from the source. You’re buying on the wholesale market, just like the
          big retailers are.
        </p>
      </details>
      <details className="accordion accordion--full-width">
        <summary className="summary">
          <p>
            What’s the difference between the ‘Minimum Payment Amount’ and the
            ‘Trigger Amount’?
          </p>
        </summary>
        <p>
          The ‘Minimum Payment Amount’ is the baseline amount that you choose to
          pay on each bill, even if your power bill is lower. We recommend
          making this about 20% higher than the cost of your average non-winter
          bill, or similar to your average bill spend (across a year). If your
          bill is less than this amount, the difference goes into your Bill
          Smoother account. If it's more, depending on what your ‘Trigger
          Amount’ is set at, we take payment of the full amount and/or use your
          Bill Smoother balance to pay the difference. The ‘Trigger Amount’ is
          the bill amount at that you choose to trigger Bill Smoother to use
          credit from your Bill Smoother account. It’ll contribute towards the
          portion of your bill that’s over the ‘Trigger Amount’.
        </p>
        <p>
          With Flick, you cut out the middle man and buy your energy straight
          from the source. You’re buying on the wholesale market, just like the
          big retailers are.
        </p>
      </details>
      <details className="accordion accordion--full-width">
        <summary className="summary">
          <p>Can I set Bill Smoother at a different cycle to my billing?</p>
        </summary>
        <p>Nope, it needs to match your current billing cycle.</p>
      </details>
      <details className="accordion accordion--full-width">
        <summary className="summary">
          <p>
            If I pause or make changes to my Bill Smoother settings, when will
            the changes take effect?
          </p>
        </summary>
        <p>
          That’s dependent on the day the request was made and the date of your
          next billing cycle. If your next billing cycle is on the following
          Tuesday, you’ll need to change your Bill Smoother settings before
          Sunday in order for the change to take effect on your upcoming bill.
          If changes are made on or after Sunday, they won’t come into effect
          until the following bill.
        </p>
      </details>
      <details className="accordion accordion--full-width">
        <summary className="summary">
          <p>
            Is there a limit to how much credit I can have in my Bill Smoother
            account?
          </p>
        </summary>
        <p>
          You can have up to $500 in your Bill Smoother account. If you reach
          this threshold, we'll automatically stop your contributions. Because
          we’re not a bank, we’re limited by the amount of funds we can store on
          behalf of our customers – that’s to make sure we’re compliant with
          banking regulations (specifically the "Anti-Money Laundering Act and
          Countering Financing of Terrorism Act 2009").
        </p>
      </details>
      <details className="accordion accordion--full-width">
        <summary className="summary">
          <p>Can I use my credit to pay my next bill in full?</p>
        </summary>
        <p>
          Yep – but you’ll need to get in touch with our Customer Experience
          team to request this be done manually.
        </p>
      </details>
      <details className="accordion accordion--full-width">
        <summary className="summary">
          <p>
            I’m moving house – do I need to set up a new Bill Smoother account
            at my new address?
          </p>
        </summary>
        <p>
          Yes, you do! Once you’ve received your new Flick account, set up your
          new Bill Smoother account as soon as possible. That way, once you’ve
          paid your final bill on your old Flick account, the credit on your old
          Bill Smoother account will be transferred to your new Bill Smoother
          account and applied according to your Bill Smoother settings.
        </p>
      </details>
      <details className="accordion accordion--full-width">
        <summary className="summary">
          <p>
            What happens to my Bill Smoother credit if I don’t set up a new Bill
            Smoother account?
          </p>
        </summary>
        <p>
          After your final bill is successfully paid on your old Flick account,
          the credit is manually transferred over to your new account within two
          weeks, where it will be applied in full to your next bill. You can
          still set up a new Bill Smoother account at any stage, but if your
          credit has already been applied to a bill, it’ll mean you need to
          build up your stash of cash again.
        </p>
      </details>
    </>
  );
};
