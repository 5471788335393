import "styles/LoadingIcon.scss";

import React from "react";

export const LoadingIcon: React.FC = () => {
  return (
    <div className="loading-icon">
      <div className="lds-roller" data-testid="loading-indicator">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};
