import { ReactComponent as ForwardIcon } from "app/assets/images/icon_nav_arrow_forward.svg";
import React from "react";
import { Link } from "react-router-dom";
import { NZD } from "utils/currency";

type BillSmootherSummaryProps = {
  billSmootherBalance: number;
};

/**
 * @description used in BillListPage
 */
export const BillSmootherSummary: React.FC<BillSmootherSummaryProps> = ({
  billSmootherBalance,
}) => {
  return (
    <section className="customer_tools_section">
      {!isNaN(billSmootherBalance) && (
        <article className="customer_tools_section__item">
          <h2 className="h6 customer_tools_section__text">
            Bill Smoother account credit
          </h2>
          <span className="h2" data-testid="billSmootherValue">
            {NZD.format(billSmootherBalance)}
          </span>
        </article>
      )}
      <article className="customer_tools_section__link_container">
        <Link
          to="/bills/bill-smoother"
          className="customer_tools_section__link"
        >
          <strong>Your Bill Smoother details</strong>
        </Link>
        <ForwardIcon />
      </article>
    </section>
  );
};
