import React from "react";
import { NZD } from "utils/currency";
import { prepareBillBreakdown } from "utils/prepareBillBreakdown";

import { OtherCostsDetail } from "./OtherCostsDetail";

type OtherCostsSectionProps = {
  lineItemGroups: LineItemGroup[];
  productName: string;
};

export const OtherCostsSection: React.FC<OtherCostsSectionProps> = ({
  lineItemGroups,
  productName,
}) => {
  const billBreakdown = prepareBillBreakdown(lineItemGroups, productName);
  const washupCosts = billBreakdown?.washup?.map((item) => Number(item.cost));

  const totalWashupCosts =
    washupCosts?.reduce((acc, item) => {
      acc += item;
      return acc;
    }, 0) || null;

  const otherCosts = billBreakdown?.other?.map((item) => Number(item.cost));

  const totalOtherCosts =
    otherCosts?.reduce((acc, item) => {
      acc += item;
      return acc;
    }, 0) || null;

  const additionalBillCharges = totalWashupCosts + totalOtherCosts;

  return (
    <section>
      {Boolean(additionalBillCharges) && (
        <>
          <span className="spacer spacer--large"></span>
          <h2 className="heading h4 spacer--small">
            Includes {NZD.format(additionalBillCharges)} of other costs
          </h2>
        </>
      )}
      {Boolean(billBreakdown?.washup?.length) && (
        <OtherCostsDetail costType="washup" lineItem={billBreakdown.washup} />
      )}
      {Boolean(billBreakdown?.other?.length) && (
        <OtherCostsDetail costType="other" lineItem={billBreakdown.other} />
      )}
    </section>
  );
};
