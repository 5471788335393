import "./MovingHousePage.scss";

import { SingleSelectRadioGroup } from "components/SingleSelectRadioGroup";
import React from "react";
import ReactMarkdown from "react-markdown";

import { useMovingHouseFormContext } from "./MovingHouseContext";
import content from "./static/content.json";
const { medicallyDependentLabel, medicallyDependentHint } = content;

const medicallyDependentOptions = [
  { label: "Yes", value: "true" },
  { label: "No", value: "false" },
];

export const MovingHouseMedicallyDependent: React.FC = () => {
  const {
    medicallyDependent: {
      fieldValue: { value },
      handleOnChange: setMedicallyDependent,
    },
  } = useMovingHouseFormContext();

  const handleMedicallyDependentChange = (newValue: string) => {
    setMedicallyDependent(
      medicallyDependentOptions.find((o) => o.value === newValue),
    );
  };

  return (
    <div className="spacer spacer--large">
      <SingleSelectRadioGroup
        handleOnChange={handleMedicallyDependentChange}
        hint={<ReactMarkdown>{medicallyDependentHint}</ReactMarkdown>}
        label={medicallyDependentLabel}
        name="medicallyDependent"
        options={medicallyDependentOptions}
        valueSelect={value.value || ""}
      />
    </div>
  );
};
