import React from 'react';

const IcpUpgrade = () => (
  <div className="address_bar_content address_bar_content_large">
    <h4 className="heading address_bar_title">Sorry, we can&apos;t supply you with power.</h4>
    <p>
      Unfortunately it looks like you don’t have a smart meter at this address, so we can’t connect.
      You’ll need to find a retailer that is able to do manual meter reads.
    </p>
  </div>
);

export default IcpUpgrade;
