/**
 * @description invoicePaymentStatus takes an invoice and determines whether it is overdue or an upcoming payment
 * @param {OutstandingInvoiceSummary | ReceivableInvoice} invoice
 * @returns { verb: string; type: string }
 */

export const invoicePaymentStatus = (
  invoice: OutstandingInvoiceSummary | ReceivableInvoice,
): { verb: string; type: string } => {
  const paymentDatePassed = new Date() > new Date(invoice?.attributes.due_at);
  return paymentDatePassed
    ? { verb: "Overdue from", type: "alert" }
    : { verb: "Paying on", type: "warning" };
};
