import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { customerApi } from "queries/customerApi";
import { ratingApi } from "queries/ratingApi";
import {
  findLocationClassification,
  findUsageType,
} from "utils/findRatingPreferences";
import { jwtClient, rollbar } from "utils/jwtClient";

const currentAccountSlice = createSlice({
  name: "currentAccount",
  initialState: {
    accountIndex: window.sessionStorage.getItem("selectedAccount") ?? 0,
    accountNumber: undefined,
    supplyNodeRef: undefined,
    customerNumber: undefined,
    active: undefined,
    icpNumber: undefined,
    address: undefined,
    nativeRenderMode: false,
    usageType: undefined,
    locationClassification: undefined,
    application_journey_ref: undefined,
    isFetchAccountCompleted: false,
    isFetchAccountError: false,
    isSwitchingAccountComplete: false,
  },
  reducers: {
    setStoreCurrentAccount: (
      state,
      action: PayloadAction<{
        accountIndex: string;
        accountDatas: AccountInfo[];
      }>,
    ) => {
      state.isSwitchingAccountComplete = false;
      state.accountIndex = action.payload.accountIndex;
      window.sessionStorage.setItem(
        "selectedAccount",
        action.payload.accountIndex,
      );

      const selectedAccount =
        action.payload.accountDatas[action.payload.accountIndex]?.attributes;
      Object.assign(state, {
        accountNumber: selectedAccount?.account_number || undefined,
        supplyNodeRef: selectedAccount?.supply_node_ref || undefined,
        customerNumber: selectedAccount?.main_customer || undefined,
        active: selectedAccount?.active || false,
        icpNumber: selectedAccount?.main_icp_number || undefined,
        address: selectedAccount?.address || undefined,
        application_journey_ref:
          selectedAccount?.application_journey_ref || undefined,
        usageType: undefined,
        locationClassification: undefined,
      });
      state.isSwitchingAccountComplete = true;
    },
    setNativeRenderMode: (
      state,
      action: PayloadAction<{ nativeRenderMode: boolean }>,
    ) => {
      if (action.payload.nativeRenderMode !== state.nativeRenderMode) {
        state.nativeRenderMode = action.payload.nativeRenderMode;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        customerApi.endpoints.getAccountInfo.matchRejected,
        (state) => {
          state.isFetchAccountCompleted = true;
          state.isFetchAccountError = true;
          /**
           * Temp log to monitor issue with mobile app loading
           */
          if (state.nativeRenderMode) {
            rollbar.info(
              "Native app: Cannot find customer",
              `isNativeTokenSet: ${jwtClient.isAuthenticated()}`,
              `jwtToken: ${jwtClient.getJwt()}`,
            );
          }
        },
      )
      .addMatcher(
        customerApi.endpoints.getAccountInfo.matchPending,
        (state) => {
          state.isFetchAccountCompleted = false;
        },
      )
      .addMatcher(
        customerApi.endpoints.getAccountInfo.matchFulfilled,
        (state, { payload: accountDatas }) => {
          accountDatas.sort(
            (account, nextAccount) =>
              Number(nextAccount?.attributes.active) -
              Number(account?.attributes.active),
          );
          const selectedAccount = accountDatas[state.accountIndex]?.attributes;
          Object.assign(state, {
            accountNumber: selectedAccount?.account_number || undefined,
            supplyNodeRef: selectedAccount?.supply_node_ref || undefined,
            customerNumber: selectedAccount?.main_customer || undefined,
            active: selectedAccount?.active || false,
            icpNumber: selectedAccount?.main_icp_number || undefined,
            address: selectedAccount?.address || undefined,
            application_journey_ref:
              selectedAccount?.application_journey_ref || undefined,
            isFetchAccountCompleted: true,
            isFetchAccountError: false,
            // reset usageType and locationClassification
            usageType: undefined,
            locationClassification: undefined,
          });
        },
      )
      .addMatcher(
        ratingApi.endpoints.getRatingPreferences.matchFulfilled,
        (state, { payload: ratingPreferences }) => {
          const usageType = findUsageType(ratingPreferences);
          const locationClassification =
            findLocationClassification(ratingPreferences);
          Object.assign(state, {
            usageType: usageType,
            locationClassification: locationClassification,
          });
        },
      );
  },
});

export const { setStoreCurrentAccount, setNativeRenderMode } =
  currentAccountSlice.actions;
export const currentAccountSliceReducer = currentAccountSlice.reducer;
