/**
 * @description Finds the current default payment method and sets it to not default. Returns the array
 */
export const setCurrentDefaultToNonDefault = (
  paymentMethods: AccountDetailPaymentMethod[],
): AccountDetailPaymentMethod[] => {
  const currentDefault = paymentMethods.find((pm) => pm.default);
  currentDefault.default = false;
  return paymentMethods;
};

/**
 * @description find the newly selected default payment method and set default. Return the array
 */
export const setNewPaymentMethodToDefault = (
  defaultPMId: string,
  paymentMethods: AccountDetailPaymentMethod[],
): AccountDetailPaymentMethod[] => {
  const newDefaultPm = paymentMethods.find(
    (pm) => pm.payment_method_id.toString() === defaultPMId.toString(),
  );
  newDefaultPm.default = true;
  return paymentMethods;
};
