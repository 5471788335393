import "./AddressFinder.scss";

import { AddressSearch } from "@flick/plan_chooser";
import { useMovingHouseFormContext } from "pages/MovingHousePage/MovingHouseContext";
import React from "react";

import content from "./static/content.json";
const { addressFinderLabel, addressFinderPlaceholder } = content;

export const MovingHouseAddress: React.FC = () => {
  const {
    address: {
      fieldValue: { error },
      handleOnChange: setAddress,
    },
  } = useMovingHouseFormContext();

  const handleAddressChange = (newAddress: {
    address: CustomerAddress;
    icpNumber?: string;
  }) => {
    if (!newAddress) {
      setAddress(null);
      return;
    }

    setAddress({
      city: newAddress.address.city,
      icpNumber: newAddress.icpNumber,
      number: newAddress.address.number,
      postcode: newAddress.address.postcode,
      street: newAddress.address.street,
      suburb: newAddress.address.suburb,
      unit: newAddress.address.unit,
    });
  };

  return (
    <div className="spacer spacer--large">
      <label className="h5" htmlFor="address-finder">
        {addressFinderLabel}
      </label>
      <div className="address-search__wrapper">
        <AddressSearch
          addressFinderKey={process.env.REACT_APP_ADDRESS_FINDER_KEY}
          addressPlaceholderText={addressFinderPlaceholder}
          apiBaseUrl={process.env.REACT_APP_API_BASE_URL}
          continueJoiningCallback={handleAddressChange}
          joining={false}
          enableICPSearch={true}
        />
      </div>
      {error && (
        <span className="form_field_helper form_field_helper_error">
          {error}
        </span>
      )}
    </div>
  );
};
