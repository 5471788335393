import { PRODUCT_NAMES, RATE_NAMES } from './constants';
import { titleCase } from './text-transforms';

export const summaryLabelDefault = (planName) => {
  if (!planName) return null;

  return [PRODUCT_NAMES.business, PRODUCT_NAMES.freestyle].includes(planName);
};

export const labelName = (meterConfiguration, rate, planName) => {
  if (!meterConfiguration || !rate || !planName) return null;

  const multipleRates = meterConfiguration.rates.length > 1;
  const labelDefault = summaryLabelDefault(planName) ? rate.summaryLabel : RATE_NAMES.default;
  if (!multipleRates) return RATE_NAMES[meterConfiguration.contentCode] ?? RATE_NAMES.default;

  return RATE_NAMES[rate.summaryLabel] ?? titleCase(labelDefault);
};
