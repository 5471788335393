import { format } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import React from "react";

type CurrentBillSpendUnavailableProps = {
  periodName: string;
  periodEnd: string;
  daysRemaining: number;
  progressPercentage: number;
};

export const CurrentBillSpendUnavailable: React.FC<
  CurrentBillSpendUnavailableProps
> = ({ periodName, periodEnd, daysRemaining, progressPercentage }) => {
  return (
    <>
      <p>
        For the {periodName} ending {periodEnd}
      </p>
      <p className="h2">Current bill spend unavailable</p>
      <div className="progress">
        <div
          className="progress__bar"
          style={{ width: `${progressPercentage}%` }}
        >
          {daysRemaining} days to go
        </div>
      </div>
      <small className="latest-valid-date">
        as at {format(toZonedTime(new Date(), "Pacific/Auckland"), "EEE d MMM")}
      </small>
    </>
  );
};
