/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
// this rule appears to be too aggressive https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/issues/718

import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '../common/dialog';
import { CONTENT_SLUG_TO_THEME_MAPPINGS, EXCLUSIVE, METER_NAME } from '../../utils/constants';
import { labelName } from '../../utils/labelNames';

const classForChannelContentCode = (contentCode) => {
  if (contentCode === 'CN' || contentCode === 'EG') {
    return 'plan_prices_pink';
  }
  return 'plan_prices_pink_dark';
};

const PlanDetailsDialog = ({
  dialogRef,
  pricingDetailsContent,
  billEstimate,
  region,
  userType,
  gstInclusion,
  planName,
  title,
}) => {
  const id = `plan-${planName}-dialog`;
  const theme = CONTENT_SLUG_TO_THEME_MAPPINGS[planName];
  const pricingDetailsPostface = [...pricingDetailsContent];
  const pricingDetailsPreface = pricingDetailsPostface.shift();
  const applyGstToRate = (rate) => (gstInclusion === EXCLUSIVE ? rate : rate * billEstimate.gst);
  const formatRates = (rate) => {
    const rateCents = rate.toFixed(2);
    const rateCentsInteger = rateCents.split('.')[0];
    const rateDecimal = rateCents.split('.')[1];

    return { rateCentsInteger, rateDecimal };
  };

  return (
    <Dialog dialogRef={dialogRef} theme={theme} id={id} title={title}>
      {pricingDetailsPreface}
      <h5 className="heading dialog_section_header dialog_section_header_extra_small">
        Prices as of today in {region} for a {userType} usage property, GST {gstInclusion}.
      </h5>
      <div className="plan_prices_outer" data-testid="plan_details_pricing">
        {billEstimate.usageRates.meterConfigurations?.map((meterConfiguration, meterIndex) => (
          <div className="plan_prices_row" key={`${meterIndex}-${meterConfiguration.content_code}`}>
            <div
              className={`plan_prices ${classForChannelContentCode(
                meterConfiguration.content_code
              )}`}
            >
              <div className="plan_prices_list">
                {meterConfiguration.rates.map((rate, rateIndex) => {
                  const price = applyGstToRate(rate.price);
                  const { rateCentsInteger, rateDecimal } = formatRates(price);
                  const rateTimetable = rate.timetable;
                  const controlledMeter = meterConfiguration.name === METER_NAME.CONTROLLED;
                  const embeddedGenerationMeter =
                    meterConfiguration.name === METER_NAME.EMBEDDED_GENERATION;

                  return (
                    <div className="plan_prices_item" key={`${rateIndex}-${price}`}>
                      <small className="plan_prices_item_heading">
                        {labelName(meterConfiguration, rate, planName)}
                        {controlledMeter && ` (${meterConfiguration.periodOfAvailability})`}
                      </small>
                      {embeddedGenerationMeter ? (
                        'Wholesale power rate*/kWh'
                      ) : (
                        <div className="plan_prices_item_value">
                          <span className="plan_prices_item_value_integer">
                            {Math.abs(rateCentsInteger)}
                          </span>
                          {rateDecimal !== '00' && (
                            <span className="plan_prices_item_value_decimal">.{rateDecimal}</span>
                          )}
                          <span className="plan_prices_item_value_unit">c/{rate.unit}</span>
                        </div>
                      )}
                      {rateTimetable?.descriptions && (
                        <div className="plan_prices_item_description">
                          {rateTimetable.descriptions.map((timetableDescription) => (
                            <p key={`timetable-description-${timetableDescription}`}>
                              {timetableDescription}
                            </p>
                          ))}
                        </div>
                      )}
                      {embeddedGenerationMeter && (
                        <div className="plan_prices_item_description">
                          <p>
                            *Wholesale power rates are a variable rate that changes every 30
                            minutes.
                          </p>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        ))}
      </div>

      {billEstimate.subscriptionRates?.rates.map((channelSubscriptionRates, index) => (
        <div
          className="plan_prices_row"
          key={`${index}-${channelSubscriptionRates.share}`}
          data-testid="plan_details_daily"
        >
          {channelSubscriptionRates.rates.map((dailyRate, rateIndex) => {
            const rate = applyGstToRate(dailyRate.rate);
            const parsedRate = parseFloat(rate) * 100;
            const { rateCentsInteger, rateDecimal } = formatRates(parsedRate);

            return (
              <div className="plan_prices plan_prices_pink_light" key={`${rateIndex}-${rate}`}>
                <div className="plan_prices_list">
                  <div className="plan_prices_item">
                    <small className="plan_prices_item_heading">Daily charges</small>
                    <div className="plan_prices_item_value">
                      <span className="plan_prices_item_value_integer">{rateCentsInteger}</span>
                      <span className="plan_prices_item_value_decimal">.{rateDecimal}</span>
                      <span className="plan_prices_item_value_unit">c/day</span>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      ))}
      {pricingDetailsPostface}
    </Dialog>
  );
};

PlanDetailsDialog.propTypes = {
  pricingDetailsContent: PropTypes.array.isRequired,
  dialogRef: PropTypes.object.isRequired,
  billEstimate: PropTypes.object.isRequired,
  region: PropTypes.string.isRequired,
  userType: PropTypes.string.isRequired,
  gstInclusion: PropTypes.string.isRequired,
  planName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default PlanDetailsDialog;
