import "./MovingHousePage.scss";

import { SingleSelectRadioGroup } from "components/SingleSelectRadioGroup";
import { RadioOption } from "components/SingleSelectRadioGroup/SingleSelectRadioGroup";
import React from "react";

import { useMovingHouseFormContext } from "./MovingHouseContext";
import content from "./static/content.json";
const { movingOutLabel } = content;

const movingOutOptions: RadioOption[] = [
  { label: "Yes", value: "yes" },
  { label: "No", value: "no" },
];

export const MovingHouseMovingOut: React.FC = () => {
  const {
    movingOut: {
      fieldValue: { value },
      handleOnChange: setMovingOut,
    },
  } = useMovingHouseFormContext();

  const handleMoveOutChange = (newValue: string) => {
    setMovingOut(movingOutOptions.find((o) => o.value === newValue));
  };

  return (
    <div className="spacer spacer--large">
      <SingleSelectRadioGroup
        handleOnChange={handleMoveOutChange}
        label={movingOutLabel}
        name="movingOut"
        options={movingOutOptions}
        valueSelect={value.value || ""}
      />
    </div>
  );
};
