import { GroupedBarChart } from "components/charts/GroupedBarChart";
import React from "react";
import { BRAND_COLOURS } from "utils/constants";

const generateGroupedBarChartConfig = (
  dailyUsageData: DailyUsage[],
): GroupedBarChartConfig => {
  const dates: Date[] = [];
  const labels: string[][] = [];
  const status: string[] = [];
  const spendData: number[] = [];
  const earnedData: number[] = [];

  dailyUsageData.forEach((item) => {
    dates.push(item.date);
    labels.push([item.weekday, item.ordinal]);
    status.push(item.status);
    spendData.push(item.thisPeriod.spend);
    earnedData.push(item.thisPeriod.earned);
  });

  return {
    labels,
    dates,
    yAxisText: "$",
    chartTitle: "Power bought & sold",
    datasets: [
      {
        label: "Bought",
        data: spendData,
        status,
        barColour: BRAND_COLOURS.yellow,
        hoverBackgroundColour: BRAND_COLOURS.yellowDark,
      },
      {
        label: "Sold",
        data: earnedData,
        status,
        barColour: BRAND_COLOURS.green,
        hoverBackgroundColour: BRAND_COLOURS.greenDark,
      },
    ],
  };
};

type HomeGenerationByDayChartProps = {
  dailyUsageData: DailyUsage[];
  setSelectedDay: (value: number) => void;
};

export const HomeGenerationByDayChart: React.FC<
  HomeGenerationByDayChartProps
> = ({ dailyUsageData, setSelectedDay }) => {
  // Scale the Y axis to fit 110% of the largest day's usage
  const maxYValue = Math.ceil(
    1.1 *
      Math.max(
        ...dailyUsageData.map((d) => d.thisPeriod.spend),
        ...dailyUsageData.map((d) => d.thisPeriod.earned),
      ),
  );

  const groupedBarChartConfig = generateGroupedBarChartConfig(dailyUsageData);

  return (
    <>
      {dailyUsageData.length > 0 && (
        <GroupedBarChart
          maxYValue={maxYValue}
          chartConfig={groupedBarChartConfig}
          onClickMutation={setSelectedDay}
        />
      )}
    </>
  );
};
