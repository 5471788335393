import { format } from "date-fns/format";
import React, { memo } from "react";
import { BILL_GROUP_DATE_FORMAT } from "utils/constants";

import { BillItem } from "./BillItem";

/**
 * @param invoices: an array of invoices
 * @returns a new array with invoices being group by Month & Year
 * example array result: { "July 2024": [...], "May 2024" : [...], "July 2023": []}
 */
const groupInvoicesByMonthAndYear = (invoices: Bill[]) => {
  const groupedInvoices = {};
  invoices.forEach((invoice) => {
    const billingPeriodStart = new Date(
      invoice.attributes.billing_period.period_started_at,
    );
    const monthYear = format(
      new Date(billingPeriodStart),
      BILL_GROUP_DATE_FORMAT,
    );

    if (!groupedInvoices[monthYear]) {
      groupedInvoices[monthYear] = [];
    }

    groupedInvoices[monthYear].push(invoice);
  });

  return groupedInvoices;
};

type BillListProps = {
  bills: Bill[];
};

/**
 * @description A list of bill rows sorted by date.
 */
export const BillList: React.FC<BillListProps> = memo(({ bills }) => {
  const displayInvoices = groupInvoicesByMonthAndYear(bills);

  return (
    <>
      {Object.keys(displayInvoices).map((monthYear) => (
        <React.Fragment key={monthYear}>
          <li className="customer_tools_list__title">{monthYear}</li>
          {displayInvoices[monthYear].map((invoice) => (
            <li className="customer_tools_list__item" key={invoice.id}>
              <BillItem bill={invoice.attributes} componentTitle={"Bills"} />
            </li>
          ))}
        </React.Fragment>
      ))}
    </>
  );
});
