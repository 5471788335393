import { format } from "date-fns";
import React, { ReactNode } from "react";

import { withErrorBoundary } from "./fallback/withErrorBoundary";
import { Item } from "./Item";

type BillItemProps = {
  bill: BillAttributes;
  label?: string | ReactNode;
};

export const BillItem = withErrorBoundary<BillItemProps>(({ bill, label }) => {
  const periodStart = new Date(bill.billing_period.period_started_at);
  const periodEnd = new Date(bill.billing_period.period_ended_at);
  const amount = bill.total_payment_due.price;
  const itemLabel =
    label ??
    `${format(periodStart, "d MMMM")} — ${format(periodEnd, "d MMMM")}`;
  const labelNote = `Invoice Number: ${bill.number}`;

  return (
    <Item
      label={itemLabel}
      labelNote={labelNote}
      value={+amount}
      href={`/bills/${bill.number}`}
    />
  );
});
