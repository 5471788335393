/* eslint-disable no-shadow */

/**
 * @description function that returns the generation price
 * @param spotPriceUsageLineItems - array
 * @returns string | undefined
 */
export const getGenerationPrice = (
  spotPriceUsageLineItems: LineItemData[],
): string | undefined => {
  if (!spotPriceUsageLineItems || spotPriceUsageLineItems.length === 0)
    return undefined;

  return spotPriceUsageLineItems[0].attributes?.reference_quantity?.value
    ?.price;
};

/**
 * @description filters lineItems array by lineItem.type where it equals "line_item_group"
 * @param billData
 * @returns LineItemGroup[] | []
 */
export const getLineItemGroups = (
  billData: Invoice,
): Array<LineItemGroup> | [] => {
  if (!billData || billData.lineItems.length === 0) return [];
  return billData.lineItems.filter(
    (lineItem) => lineItem.type === "line_item_group",
  );
};

/**
 * @description A function that reduces lineItemGroups array
 * @param lineItemGroups - array
 * @returns {{data: LineItemData[]} | {data: []}} An object containing an array of LineItemData objects
 */
export const invoiceUsageLineItems = (
  lineItemGroups: LineItemGroup[],
): { data: LineItemData[] } | { data: [] } => {
  if (!lineItemGroups || lineItemGroups.length === 0) return { data: [] };
  const reducedLineItemGroups = lineItemGroups.reduce(
    (acc, item) => {
      if (item.attributes.category === "usage") {
        acc = item.attributes.items;
      }
      return acc;
    },
    {} as { data: LineItemData[] },
  );

  return reducedLineItemGroups;
};

/**
 * @description filters usageLineItems array by description where it includes "spot price"
 * @param usageLineItems - array
 * @returns LineItemData[] | []
 */
export const spotPriceUsageLineItems = (
  usageLineItems: LineItemData[],
): LineItemData[] | [] => {
  if (!usageLineItems || usageLineItems.length === 0) return [];

  return usageLineItems.filter((item) =>
    item.attributes.description.includes("spot price"),
  );
};
