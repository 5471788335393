import { ComponentItemFallback } from "components/fallback/ComponentItemFallback";
import { LoadingSection } from "components/LoadingSection";
import React from "react";

import { Balance } from "../Balance/Balance";

type AccountBalanceWrapperProps = {
  accountBalance: BillingBalance;
  isAccountBalanceError: boolean;
  isAccountBalanceFetching: boolean;
  isEnrolledInBillSmoother: boolean;
};

export const AccountBalanceWrapper: React.FC<AccountBalanceWrapperProps> = ({
  accountBalance,
  isAccountBalanceError,
  isAccountBalanceFetching,
  isEnrolledInBillSmoother,
}) => {
  if (isAccountBalanceFetching) {
    return <LoadingSection />;
  }

  if (isAccountBalanceError) {
    return <ComponentItemFallback componentTitle="Account balance" />;
  }

  return (
    <>
      {!isEnrolledInBillSmoother &&
        Number(accountBalance?.total_credits?.amount) > 0 && (
          <Balance
            balanceType="Account credit"
            amount={Number(accountBalance?.total_credits?.amount)}
          />
        )}

      <Balance
        balanceType="Amount owing"
        amount={Number(accountBalance?.total_owing?.amount)}
      />
    </>
  );
};
