import { BaseInput } from "@flick/fep-library";
import React, { ChangeEvent } from "react";

type DateInputProps = {
  errorMessage?: string;
  extraClasses?: string;
  handleDateChange?: (value: string) => void;
  handleOnBlur?: () => void;
  hint?: string;
  label: string;
  min?: string | number;
  max?: string | number;
  name: string;
  required?: boolean;
  value?: string;
};

export const DateInput: React.FC<DateInputProps> = ({
  errorMessage = "",
  handleDateChange,
  handleOnBlur,
  hint,
  label,
  max,
  min,
  name,
  required = false,
  value,
}) => {
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    handleDateChange(event.target.value);
  };

  return (
    <BaseInput
      id={name}
      labelText={label}
      max={max}
      min={min}
      inputWidth="full"
      name={name}
      helperText={errorMessage || hint}
      onBlur={handleOnBlur}
      onChange={handleInputChange}
      required={required}
      state={errorMessage ? "error" : "default"}
      type="date"
      value={value}
    />
  );
};
