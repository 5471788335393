export const normaliseProductName = (productName: string): string =>
  productName?.toLowerCase().replace(/[\s\_]/, "");

export const capitaliseProductName = (productName: string): string => {
  return productName
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};
