import TagManager from "react-gtm-module";

/**
 * @description Google Tag Manager sending analytic event
 */
export const sendAnalyticsEvent = (
  eventName: string,
  // The event data can be of various shape
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  eventData: { [key: string]: any },
): void => {
  eventData.event = eventName;
  TagManager.dataLayer({
    dataLayer: eventData,
  });
};

/**
 * @param links - links content passing through GTM banner notifcation tag
 * @returns - an array of new link type to render with notification banner component
 */
export const filteredLinkContent = (
  links?: { link: string; linkText: string }[],
): { title: string; detail: string; contentType: string }[] => {
  return links.map((linkDetail) => {
    return {
      title: linkDetail.linkText,
      detail: linkDetail.link,
      contentType: "link",
    };
  });
};
