import React, { useEffect } from "react";
import { useLocation } from "react-router";
import { clearNotificationBanner } from "reducers/notificationBanner";
import { useAppDispatch, useAppSelector } from "reduxUtils/hook";
import { replaceContentParams } from "utils/stringFormat/contentParams";

import content from "./static/content.json";

export const NotificationBanner: React.FC = () => {
  const notificationBanner = useAppSelector(
    (store) => store.notificationBanner,
  );
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();

  /*
   * if notification banner has `page` property, and that value is not in the current URL, clear the banner.
   * A banner with no page property should persist across URLs.
   * strip query params before comparison
   */
  useEffect(() => {
    if (
      notificationBanner?.page &&
      pathname.split("?")[0] !== notificationBanner.page.split("?")[0] &&
      !pathname.includes(notificationBanner.page)
    ) {
      dispatch(clearNotificationBanner());
    }
  }, [pathname]);

  if (!notificationBanner) return null;

  const { type, name, params, additionalContent } = notificationBanner;

  const message = replaceContentParams(
    content[`${type}${name}Message`] || "",
    params,
  );
  const subText = replaceContentParams(
    content[`${type}${name}SubText`] || "",
    params,
  );

  return (
    type && (
      <div className={`notification_banner notification_${type} icon`}>
        <p className="heading">{message}</p>
        <p>{subText}</p>
        {additionalContent && (
          <ul>
            {additionalContent.map(
              ({ detail = undefined, title = undefined }) => (
                <li key={title ?? detail}>
                  <p className="errorList">
                    {title && <span className="h4">{title}</span>}
                    {detail}
                  </p>
                </li>
              ),
            )}
          </ul>
        )}
      </div>
    )
  );
};
