import "./BillSmootherDefault.scss";

import { ReactComponent as ForwardIcon } from "app/assets/images/icon_nav_arrow_forward.svg";
import React from "react";
import { Link } from "react-router-dom";
/**
 * @description The default view for customerswho are not signed up to Bill Smoother.
 * Used in BillListPage
 */
export const BillSmootherDefault: React.FC = () => (
  <section className="customer_tools_section">
    <article className="customer_tools_section__item customer_tools_section__item--borders">
      <h2 className="h5">Want more predictable bills?</h2>
      <small>
        Make ‘em super smooth all year round, or stash cash for those super high
        bills with Bill Smoother
      </small>
    </article>
    <article className="customer_tools_section__link_container bill_smoother_container">
      <Link
        to="/bills/bill-smoother/more-information"
        className="customer_tools_section__link bill_smoother_container__link"
      >
        Take a look at Bill Smoother
        <ForwardIcon />
      </Link>
    </article>
  </section>
);
