type Token = {
  access_token: string;
  expires_in: number;
  id_token: string;
  refresh_token: string;
  token_type: string;
};

export const getOauthTokens = async (params: string): Promise<Token> => {
  const response = await fetch(
    `${process.env.REACT_APP_API_BASE_URL}/identity/oauth/token${params}`,
    { method: "POST" },
  );
  return await response.json();
};
