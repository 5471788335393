import { LoadingSection } from "components/LoadingSection";
import { WholesalePriceSection } from "components/WholesalePriceSection/WholesalePriceSection";
import { useGetInvoiceQuery } from "queries/billingApi";
import React from "react";
import { jwtClient } from "utils/jwtClient";
import {
  getGenerationPrice,
  getLineItemGroups,
  invoiceUsageLineItems,
  spotPriceUsageLineItems,
} from "utils/lineItems/lineItems";

type WholesalePriceSectionWrapperProps = {
  bill: Bill;
  lineItemGroups: Array<LineItemGroup>;
};

/**
 * @description Component WholesalePriceSectionWrapper used in BillPage
 */
export const WholesalePriceSectionWrapper: React.FC<
  WholesalePriceSectionWrapperProps
> = ({ bill, lineItemGroups }) => {
  const {
    data: previousPeriodInvoiceData,
    isFetching: isPreviousPeriodInvoiceFetching,
  } = useGetInvoiceQuery(
    { invoiceNumber: bill.previous_invoice_id, jwtClient },
    { skip: !bill.previous_invoice_id },
  );

  if (isPreviousPeriodInvoiceFetching) return <LoadingSection />;

  const { data: currentInvoiceUsageLineItems } =
    invoiceUsageLineItems(lineItemGroups);

  const currentSpotPriceUsageLineItems = spotPriceUsageLineItems(
    currentInvoiceUsageLineItems,
  );

  const currentGenerationPrice = getGenerationPrice(
    currentSpotPriceUsageLineItems,
  );

  const previousPeriodLineItemGroups = getLineItemGroups(
    previousPeriodInvoiceData,
  );

  const { data: previousPeriodInvoiceUsageLineItems } = invoiceUsageLineItems(
    previousPeriodLineItemGroups,
  );

  const previousSpotPriceUsageLineItems = spotPriceUsageLineItems(
    previousPeriodInvoiceUsageLineItems,
  );

  const previousGenerationPrice = getGenerationPrice(
    previousSpotPriceUsageLineItems,
  );

  return (
    <WholesalePriceSection
      currentGenerationPrice={currentGenerationPrice}
      previousGenerationPrice={previousGenerationPrice}
      duration={bill.billing_period.duration}
    />
  );
};
