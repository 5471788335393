import { format } from "date-fns";

/**
 * Gets two years ago date
 * @returns object consisting of 3 formats (Date, date string, and error message string)
 */
export const getTwoYearsAgo = (): {
  twoYearsAgoDate: Date;
  twoYearsAgoString: string;
  twoYearsAgoErrMsg: string;
} => {
  const twoYearsAgo = new Date();
  twoYearsAgo.setFullYear(new Date().getFullYear() - 2);
  twoYearsAgo.setHours(0, 0, 0, 0);

  return {
    twoYearsAgoDate: twoYearsAgo,
    twoYearsAgoString: format(twoYearsAgo, "yyyy-MM-dd"),
    twoYearsAgoErrMsg: format(twoYearsAgo, "dd/MM/yyyy"),
  };
};

/**
 * Gets one week ago date in string format
 */
export const getOneWeekAgoDate = (): { oneWeekAgoString: string } => {
  const oneWeekAgo = new Date();
  oneWeekAgo.setDate(new Date().getDate() - 7);
  oneWeekAgo.setHours(0, 0, 0, 0);

  return {
    oneWeekAgoString: format(oneWeekAgo, "yyyy-MM-dd"),
  };
};

/**
 * Gets yesterdays date
 * @returns object consisting of 3 formats -(Date, date string, and error message string)
 */
export const getYesterdayDate = (): {
  yesterdayDate: Date;
  yesterdayDateString: string;
  yesterdayDateErrMsg: string;
} => {
  const yesterdayDate = new Date();
  yesterdayDate.setDate(new Date().getDate() - 1);
  yesterdayDate.setHours(0, 0, 0, 0);

  return {
    yesterdayDate,
    yesterdayDateString: format(yesterdayDate, "yyyy-MM-dd"),
    yesterdayDateErrMsg: format(yesterdayDate, "dd/MM/yyyy"),
  };
};

/**
 * Gets maximum end date which is yesterday's date or 31 days from the given date
 * @param date
 * @returns object consisting of 3 formats (Date, date string, and error message string)
 */
export const getMaxEndDate = (
  date: string,
): { maxEndDate: Date; maxEndDateErrMsg: string; maxEndDateString: string } => {
  const fromDate = new Date(date);
  const maxDate = new Date(fromDate);
  maxDate.setDate(fromDate.getDate() + 31);
  maxDate.setHours(0, 0, 0, 0);

  const { yesterdayDate, yesterdayDateString, yesterdayDateErrMsg } =
    getYesterdayDate();

  if (maxDate > yesterdayDate) {
    return {
      maxEndDate: yesterdayDate,
      maxEndDateErrMsg: yesterdayDateErrMsg,
      maxEndDateString: yesterdayDateString,
    };
  }
  return {
    maxEndDate: maxDate,
    maxEndDateErrMsg: format(maxDate, "dd/MM/yyyy"),
    maxEndDateString: format(maxDate, "yyyy-MM-dd"),
  };
};

// get the minimum date possible - earliest of 2 years ago and billing start date
export const getMinDate = (billingStartDate?: string) => {
  const { twoYearsAgoDate, twoYearsAgoString, twoYearsAgoErrMsg } =
    getTwoYearsAgo();

  if (!billingStartDate || billingStartDate < twoYearsAgoString)
    return {
      minDate: twoYearsAgoDate,
      minDateString: twoYearsAgoString,
      mindDateErrorMsg: twoYearsAgoErrMsg,
    };

  const minDate = new Date(billingStartDate);
  minDate.setHours(0, 0, 0, 0);
  return {
    minDate,
    minDateString: billingStartDate,
    mindDateErrorMsg: format(minDate, "dd/MM/yyyy"),
  };
};

export const getDateErrorMessage = ({
  billingStartDate,
  startDate,
  endDate,
  type,
}: {
  billingStartDate?: string;
  endDate: string;
  startDate: string;
  type: "start" | "end";
}): string => {
  const { minDateString, mindDateErrorMsg } = getMinDate(billingStartDate);
  const { yesterdayDateString, yesterdayDateErrMsg } = getYesterdayDate();
  const { maxEndDateErrMsg, maxEndDateString } = getMaxEndDate(startDate);

  if (type === "start") {
    if (startDate < minDateString) {
      return `Start date cannot be earlier than ${mindDateErrorMsg}`;
    }
    if (startDate > yesterdayDateString) {
      return `Start date cannot be later than ${yesterdayDateErrMsg}`;
    }
    if (startDate >= endDate) {
      return `Start date needs to be before the end date`;
    }
  }

  if (type === "end") {
    if (endDate < minDateString) {
      return `End date cannot be earlier than ${mindDateErrorMsg}`;
    }
    if (endDate <= startDate) {
      return "End date needs to be after the start date";
    }
    if (endDate > maxEndDateString) {
      return `End date cannot be later than ${maxEndDateErrMsg}`;
    }
  }

  return null;
};
