/**
 * @description RollBar config for RollBar https://www.npmjs.com/package/rollbar
 */
export const rollbarConfig = {
  accessToken: process.env.REACT_APP_ROLLBAR_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: process.env.REACT_APP_ROLLBAR_ENV || process.env.NODE_ENV,
    client: {
      javascript: {
        code_version: "1.0.0",
        source_map_enabled: true,
      },
    },
  },
  ignoredMessages: ["Script error."],
  checkIgnore: (isUncaught, args, payload) => {
    const errorUrl = payload.custom.response.url;
    if (errorUrl.includes("payment_smoother/customer_configuration")) {
      return true;
    }
  },
};
