// See: https://docs.google.com/spreadsheets/d/1uHlULjnvwPwTO-TeVkFQ_iVzUam81vxHW7IuH0eK7Ds/edit#gid=0

const ValidationMessagePrefix = "data-msg-";

const ValidationMessages = {
  badInput: {
    message: "",
    dataAttribute: "bad-input",
  },
  customError: {
    message: "",
    dataAttribute: "custom-error",
  },
  patternMismatch: {
    message: "Are you sure that’s meant to go there?",
    dataAttribute: "pattern-mismatch",
  },
  rangeOverflow: {
    message: "Well, that escalated quickly.",
    dataAttribute: "range-overflow",
  },
  rangeUnderflow: {
    message: "Let’s turn the dial up eh?",
    dataAttribute: "range-underflow",
  },
  stepMismatch: {
    message: "Are you sure that’s meant to go there?",
    dataAttribute: "step-mismatch",
  },
  tooLong: {
    message: "Whoa whoa whoa… let’s dial it back a little.",
    dataAttribute: "too-long",
  },
  tooShort: {
    message: "I think that’s a bit less than we were expecting.",
    dataAttribute: "too-short",
  },
  typeMismatch: {
    message: "Are you sure that’s meant to go there?",
    dataAttribute: "type-mismatch",
  },
  valueMissing: {
    message: "Leaving this field blank is like leaving t-sauce off hot chips.",
    dataAttribute: "value-missing",
  },
};

export { ValidationMessagePrefix, ValidationMessages };
