import "./SingleSelectRadioInput.scss";

import React, { ChangeEventHandler } from "react";

type SingleSelectRadioInputProps = {
  children?: JSX.Element;
  extraClasses?: string;
  hint?: string;
  isChecked: boolean;
  label: string;
  name: string;
  required?: boolean;
  value: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
};

export const SingleSelectRadioInput: React.FC<SingleSelectRadioInputProps> = ({
  children,
  extraClasses,
  hint,
  isChecked,
  label,
  name,
  onChange,
  required,
  value,
}) => (
  <li>
    <input
      checked={isChecked}
      className={extraClasses ? extraClasses : ""}
      id={`${name}_radio`}
      name={name}
      data-testid={`${name}_radio`}
      onChange={onChange}
      required={required}
      type="radio"
      value={value}
    />
    <label htmlFor={`${name}_radio`}>
      <span>{label}</span>
    </label>
    {hint && <span className="form_field_helper">{hint}</span>}
    {children && isChecked ? children : null}
  </li>
);
