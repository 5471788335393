import { expireTime } from "queries/queries.utils";
import { useGetWholesalePricesQuery } from "queries/ratingApi";
import React from "react";
import { jwtClient } from "utils/jwtClient";

import { LoadingSection } from "../LoadingSection";
import { DailyCostPerKwhChart } from "./DailyCostPerKwhChart";
import { prepareDailyCostPerKwhData } from "./DailyCostPerKwhSection.utils";
import content from "./static/content.json";

const { wholesalePriceErrorText } = content;

type DailyCostPerKwhSectionProps = {
  endAt: Date;
  startAt: Date;
  supplyNodeRef: string;
};

export const DailyCostPerKwhSection: React.FC<DailyCostPerKwhSectionProps> = ({
  endAt,
  startAt,
  supplyNodeRef,
}) => {
  const {
    data: wholesalePrices,
    isFetching: isWholesalePriceFetching,
    isError: isWholesalePriceError,
  } = useGetWholesalePricesQuery(
    {
      supplyNodeRef,
      startAt,
      endAt,
      jwtClient,
    },

    {
      skip: !supplyNodeRef,
      refetchOnMountOrArgChange: expireTime,
    },
  );

  if (isWholesalePriceFetching) return <LoadingSection />;

  if (isWholesalePriceError)
    return (
      <div className="notification_banner notification_notice icon">
        <p>{wholesalePriceErrorText}</p>
      </div>
    );
  const { maxYValue, dailyKwhValues } =
    prepareDailyCostPerKwhData(wholesalePrices);

  return (
    <DailyCostPerKwhChart
      selectedDayMaxYValue={maxYValue}
      dailyKwhValues={dailyKwhValues}
    />
  );
};
