import React from "react";
import { NZD } from "utils/currency";

type BalanceProps = {
  balanceType: string;
  amount: number;
};

export const Balance: React.FC<BalanceProps> = ({ balanceType, amount }) => {
  const colour = balanceType.includes("credit") ? "green" : "yellow";

  return (
    <>
      <section className="customer_tools_section__item_container customer_tools_section__item--borderless account_balance_section">
        <p className="h5">{balanceType}</p>

        <p
          data-testid="balanceAmount"
          className={`text_highlight text_highlight--${colour}`}
        >
          <strong>{!isNaN(amount) ? `${NZD.format(amount)}` : "$0.00"}</strong>
        </p>
      </section>
    </>
  );
};
