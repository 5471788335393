import { Card, CardBody, CardHeader } from "@flick/fep-library";
import { ApplicationProcess } from "components/ApplicationProcess/ApplicationProcess";
import { GetTheFlickApp } from "components/GetTheFlickApp/GetTheFlickApp";
import { LoadingSection } from "components/LoadingSection";
import { useGetApplicationJourneyQuery } from "queries/provisioningApi";
import { expireTime } from "queries/queries.utils";
import React, { useMemo } from "react";
import { useAppSelector } from "reduxUtils/hook";
import { jwtClient } from "utils/jwtClient";
import { sendAnalyticsEvent } from "utils/sendAnalyticsEvent";

import { ApplicationJourneyTrackerItem } from "./ApplicationJourneyTrackerItem";
import content from "./static/content.json";
const {
  trackerDescription,
  trackerHeading,
  notificationBannerHeading,
  notificationBannerPOne,
  joinFormLink,
  joinFormLinkText,
  notificationBannerPTwo,
} = content;

export const ApplicationJourneyTracker: React.FC = ({}) => {
  const currentAccount = useAppSelector((store) => store.currentAccount);

  const { data: applicationJourney, isFetching: isApplicationJourneyFetching } =
    useGetApplicationJourneyQuery(
      {
        jwtClient,
        applicationJourneyRef: currentAccount.application_journey_ref,
      },
      {
        skip: !Boolean(currentAccount.application_journey_ref),
        refetchOnMountOrArgChange: expireTime,
      },
    );

  useMemo(() => {
    if (!applicationJourney?.attributes?.state_journey?.length) return;
    sendAnalyticsEvent("application_journey_tracker", {
      icp: currentAccount?.icpNumber,
    });
  }, [applicationJourney]);

  if (isApplicationJourneyFetching) return <LoadingSection />;

  if (applicationJourney?.attributes?.state_journey?.length) {
    return (
      <>
        <p>{trackerDescription}</p>
        <div className="page_section">
          <section className="page_section__column page_section__column--1">
            <Card cardBorderStyle="shadow" cardPaddingType="compact">
              <CardHeader headingColor="black">
                <h2 className="h4">{trackerHeading}</h2>
              </CardHeader>
              <CardBody>
                <ol className="list_progress">
                  {applicationJourney.attributes.state_journey.map(
                    (state, index) => {
                      return (
                        <ApplicationJourneyTrackerItem
                          key={`application-journey-item-${index}`}
                          status={state.status}
                          title={state.title}
                          description={state.description}
                          blocked={state.blocked}
                        />
                      );
                    },
                  )}
                </ol>
              </CardBody>
            </Card>
            <div className="spacer--large" />
          </section>
          <section className="page_section__column page_section__column--1">
            <ApplicationProcess />
            <GetTheFlickApp />
          </section>
        </div>
      </>
    );
  }

  if (
    !isApplicationJourneyFetching &&
    !applicationJourney?.attributes?.state_journey
  ) {
    return (
      <div
        data-testid="applicationJourneyNotificationBanner"
        className="notification_banner notification_notice icon"
      >
        <p className="heading">{notificationBannerHeading}</p>
        <br />
        <p>
          {notificationBannerPOne}
          <a href={joinFormLink}>{joinFormLinkText}</a>
        </p>
        <br />
        <p>{notificationBannerPTwo}</p>
      </div>
    );
  }
};
