import { createApi } from "@reduxjs/toolkit/query/react";
import { JwtClient } from "utils/jwtClient";

import { expireTime } from "./queries.utils";
import { formatWholesalePriceData } from "./rating.utils";

interface ApiCallParams {
  path: string;
  queryParams?: Record<string, string | number>;
}

interface BaseQueryParams extends ApiCallParams {
  jwtClient: JwtClient;
}

/**
 * Define a base query function that calls jwtClient.apiCall
 */
const apiCallBaseQuery = async ({ jwtClient, ...params }: BaseQueryParams) => {
  const response = await jwtClient.apiCall(params);
  const result = await response.json();
  return { data: result };
};

export type AggregatesQueryParams = {
  endAt: Date | number;
  frequency?: string;
  jwtClient: JwtClient;
  startAt: Date | number;
  supplyNodeRef: string;
  source?: string;
};

/**
 * Define the ratingApi slice
 */
export const ratingApi = createApi({
  reducerPath: "ratingApi",
  baseQuery: apiCallBaseQuery,
  endpoints: (builder) => ({
    getAggregateRates: builder.query<Aggregates, AggregatesQueryParams>({
      query: ({ jwtClient, supplyNodeRef, startAt, endAt, frequency }) => {
        const queryParams = {
          supply_node: supplyNodeRef,
          start_at: new Date(startAt).toISOString(),
          end_at: new Date(endAt).toISOString(),
          aggregation_frequency: frequency || "day",
          timezone: "Pacific/Auckland",
        };
        return {
          jwtClient: jwtClient,
          path: "/rating/aggregates",
          queryParams,
        };
      },
    }),
    getAggregateHourlyRates: builder.query<Aggregates, AggregatesQueryParams>({
      query: ({ jwtClient, supplyNodeRef, startAt, endAt }) => {
        const queryParams = {
          supply_node: supplyNodeRef,
          start_at: new Date(startAt).toISOString(),
          end_at: new Date(endAt).toISOString(),
          aggregation_frequency: "hour",
          timezone: "Pacific/Auckland",
          expand: "individual_periods",
        };
        return {
          jwtClient: jwtClient,
          path: "/rating/aggregates",
          queryParams,
        };
      },
    }),
    getForecastedPrices: builder.query<
      ForecastedPrice[],
      AggregatesQueryParams
    >({
      query: ({ jwtClient, supplyNodeRef, startAt, endAt }) => {
        const queryParams = {
          supply_node: supplyNodeRef,
          start_at: new Date(startAt).toISOString(),
          end_at: new Date(endAt).toISOString(),
        };

        return {
          jwtClient: jwtClient,
          path: "/rating/forecast_prices",
          queryParams,
        };
      },
      transformResponse: (data) => {
        return data.prices ?? [];
      },
    }),
    getRatingPreferences: builder.query<
      RatingPreferencesTypes[],
      {
        jwtClient: JwtClient;
        supplyNodeRef: string;
      }
    >({
      query: ({ supplyNodeRef, jwtClient }) => {
        const queryParams = {
          supply_node: supplyNodeRef,
          active_at: new Date().toISOString(),
        };
        return {
          jwtClient,
          path: "/rating/supply_node_preferences",
          queryParams,
        };
      },
      transformResponse: (data) => {
        return data.data ?? [];
      },
    }),
    getWholesalePrices: builder.query<
      WholesalePrice[],
      {
        jwtClient: JwtClient;
        supplyNodeRef: string;
        startAt: Date;
        endAt: Date;
      }
    >({
      query: ({ supplyNodeRef, jwtClient, startAt, endAt }) => {
        const queryParams = {
          supply_node: supplyNodeRef,
          start_at: new Date(startAt).toISOString(),
          end_at: new Date(endAt).toISOString(),
        };
        return {
          jwtClient,
          path: "/rating/prices",
          queryParams,
        };
      },
      transformResponse: (data) => {
        return data.prices ? formatWholesalePriceData(data) : [];
      },
    }),
  }),
  keepUnusedDataFor: expireTime,
});

export const {
  useGetAggregateRatesQuery,
  useGetAggregateHourlyRatesQuery,
  useGetForecastedPricesQuery,
  useGetRatingPreferencesQuery,
  useGetWholesalePricesQuery,
  useLazyGetAggregateRatesQuery,
} = ratingApi;
