import { BaseInput } from "@flick/fep-library";
import React, { ChangeEvent } from "react";

import { AvailableInputModes, AvailableInputTypes } from "./BasicInput.types";

type BasicInputProps = {
  ariaLabel?: string;
  dataTestId?: string;
  errorMsg?: string;
  handleInputChange(event: ChangeEvent<HTMLInputElement>): void;
  handleOnBlur?(event: ChangeEvent<HTMLInputElement>): void;
  hint?: string;
  inputMode?: AvailableInputModes;
  inputSize?: "default" | "large";
  inputWidth?: "default" | "full";
  label: string;
  name: string;
  placeholderText?: string;
  required?: boolean;
  state?: "default" | "disabled" | "error";
  type?: AvailableInputTypes;
  value?: string;
};

export const BasicInput: React.FC<BasicInputProps> = ({
  ariaLabel,
  dataTestId,
  errorMsg,
  handleInputChange,
  handleOnBlur = () => ({}),
  hint,
  inputMode = "text",
  inputSize,
  inputWidth,
  label,
  name,
  placeholderText,
  required,
  type = "text",
  value = "",
  state,
}) => {
  return (
    <div>
      <BaseInput
        data-testid={dataTestId}
        aria-label={ariaLabel}
        helperText={state === "error" ? errorMsg : hint}
        id={name}
        inputMode={inputMode}
        inputSize={inputSize}
        inputWidth={inputWidth}
        labelText={label}
        name={name}
        onBlur={handleOnBlur}
        placeholderText={placeholderText}
        onChange={handleInputChange}
        required={required}
        state={state}
        type={type}
        value={value}
      />
    </div>
  );
};
