import {
  differenceInMinutes,
  formatDuration,
  intervalToDuration,
} from "date-fns";

/**
 * calculate the total time in a shift, the remaining time left of the current shift, the remaining time until the next shift as a percentage
 */

type RemainingShiftTime = {
  timeRemainingInWords: string;
  timeRemainingPercentage: number;
};

export const calculateRemainingShiftTime = (
  currentShiftDetails: CurrentShiftDetails | undefined,
  currentDate: Date,
): RemainingShiftTime | undefined => {
  if (!currentShiftDetails || Object.keys(currentShiftDetails).length === 0) {
    return undefined;
  }

  const totalTimeInMinutes = differenceInMinutes(
    new Date(currentShiftDetails.endDate),
    new Date(currentShiftDetails.startDate),
  );

  const timeLeftInMinutes = differenceInMinutes(
    new Date(currentShiftDetails?.endDate),
    currentDate,
  );

  const remainingTimeShiftDuration = intervalToDuration({
    start: currentDate,
    end: new Date(currentShiftDetails.endDate),
  });

  const remainingHours = () => {
    if (!remainingTimeShiftDuration || !remainingTimeShiftDuration.hours)
      return undefined;
    if (!remainingTimeShiftDuration.days) {
      return remainingTimeShiftDuration.hours;
    }
    return (
      remainingTimeShiftDuration?.hours + remainingTimeShiftDuration?.days * 24
    );
  };

  const timeRemainingInWords = formatDuration({
    hours: remainingHours(),
    minutes: remainingTimeShiftDuration?.minutes,
  });

  // percentage of time (minutes) left until the next shift starts
  const timeRemainingPercentage =
    (timeLeftInMinutes / totalTimeInMinutes) * 100;

  const result = {
    timeRemainingInWords,
    timeRemainingPercentage,
  };

  return result;
};
