import { createApi } from "@reduxjs/toolkit/query/react";
import { endOfDay } from "date-fns/endOfDay";
import { startOfDay } from "date-fns/startOfDay";
import { JwtClient } from "utils/jwtClient";

import { expireTime } from "./queries.utils";

interface ApiCallParams {
  path: string;
  queryParams?: Record<string, string | number>;
}

interface BaseQueryParams extends ApiCallParams {
  jwtClient: JwtClient;
}

/**
 * Define a base query function that calls jwtClient.apiCall
 */
const apiCallBaseQuery = async ({ jwtClient, ...params }: BaseQueryParams) => {
  const response = await jwtClient.apiCall(params);
  const result = await response.text();
  return { data: result };
};

export type GetUsageDataCSVParams = {
  startAt: string;
  endAt: string;
  icpNumber: string;
  jwtClient: JwtClient;
};

/**
 * Define the telemetryApi slice
 */
export const telemetryApi = createApi({
  reducerPath: "telemetryApi",
  baseQuery: apiCallBaseQuery,
  endpoints: (builder) => ({
    getUsageDataCSV: builder.query<string, GetUsageDataCSVParams>({
      query({ jwtClient, startAt, endAt, icpNumber }) {
        const queryParams = {
          starting_at: startOfDay(new Date(startAt)).toString(),
          ending_at: endOfDay(new Date(endAt)).toString(),
          icp_number: icpNumber,
        };
        return {
          jwtClient: jwtClient,
          path: "/telemetry/validated_measurements.csv",
          queryParams,
        };
      },
    }),
  }),
  keepUnusedDataFor: expireTime,
});

export const { useGetUsageDataCSVQuery, useLazyGetUsageDataCSVQuery } =
  telemetryApi;
