import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";

const initialState: {
  type: string;
  name: string;
  modifier?: string;
  params?: {
    closedAccountDate?: string;
    unconfirmedEmail?: string;
    bodyCopy?: string;
    heading?: string;
  };
  additionalContent?: {
    title?: string;
    detail?: string;
    contentType?: string;
  }[];
  dismissable?: boolean;
  page?: string;
} | null = null;

const notificationBannerSlice = createSlice({
  name: "notificationBanner",
  initialState,
  reducers: {
    setNotificationBanner: (
      state,
      action: PayloadAction<{
        type: string;
        name: string;
        modifier?: string;
        params?: {
          closedAccountDate?: string;
          unconfirmedEmail?: string;
          bodyCopy?: string;
          heading?: string;
        };
        page?: string;
        additionalContent?: {
          title?: string;
          detail?: string;
          contentType?: string;
        }[];
        dismissable?: boolean;
      }>,
    ) => {
      const {
        type,
        name,
        params = undefined,
        modifier = undefined,
        page = undefined,
        additionalContent = undefined,
        dismissable = undefined,
      } = action.payload;
      state = {
        type,
        name,
        modifier,
        params,
        page,
        additionalContent,
        dismissable,
      };
      return state;
    },

    clearNotificationBanner: (state) => {
      state = null;
      return state;
    },
  },
});

export const { setNotificationBanner, clearNotificationBanner } =
  notificationBannerSlice.actions;
export const notificationBannerReducer = notificationBannerSlice.reducer;
