import React from 'react';

const IcpReject = () => (
  <div className="address_bar_content address_bar_content_large">
    <h4 className="heading address_bar_title">Sorry, we can&apos;t supply you with power.</h4>
    <p>Unfortunately we currently don&apos;t support the meter setup at this address.</p>
  </div>
);

export default IcpReject;
