import { createApi } from "@reduxjs/toolkit/query/react";
import { JwtClient } from "utils/jwtClient";

import { expireTime } from "./queries.utils";

interface ApiCallParams {
  path: string;
  queryParams?: Record<string, string | number>;
}

interface BaseQueryParams extends ApiCallParams {
  jwtClient: JwtClient;
}

/**
 * Define a base query function that calls jwtClient.apiCall
 */
const apiCallBaseQuery = async ({ jwtClient, ...params }: BaseQueryParams) => {
  const response = await jwtClient.apiCall(params);
  const result = await response.json();

  return { data: result.data };
};

export type GetApplicationJourneyProps = {
  applicationJourneyRef: string;
  jwtClient: JwtClient;
};

/**
 * Define the provisioningApi slice
 */
export const provisioningApi = createApi({
  reducerPath: "provisioningApi",
  baseQuery: apiCallBaseQuery,
  endpoints: (builder) => ({
    getApplicationJourney: builder.query<
      ApplicationJourneyJSON,
      GetApplicationJourneyProps
    >({
      query({ jwtClient, applicationJourneyRef }) {
        return {
          jwtClient: jwtClient,
          path: applicationJourneyRef,
        };
      },
    }),
  }),
  keepUnusedDataFor: expireTime,
});

export const { useGetApplicationJourneyQuery } = provisioningApi;
