import "styles/IndividualDayDetail.scss";

import { SecondaryButton } from "@flick/fep-library";
import { ReactComponent as RightArrowSvg } from "assets/icon_arrow_right.svg";
import { ReactComponent as BoltIconSvg } from "assets/icon_bolt.svg";
import { ReactComponent as LeafIconSvg } from "assets/icon_leaf.svg";
import { ReactComponent as PriceTagIconSvg } from "assets/icon_price_tag.svg";
import { IndividualDayChart } from "components/IndividualDayChart";
import { LoadingIcon } from "components/LoadingIcon";
import { LoadingSection } from "components/LoadingSection";
import { isWeekend } from "date-fns";
import { endOfDay } from "date-fns/endOfDay";
import { format } from "date-fns/format";
import { startOfDay } from "date-fns/startOfDay";
import { useGetAggregateHourlyRatesQuery } from "queries/ratingApi";
import React, { useEffect, useMemo, useState } from "react";
import { useAppSelector } from "reduxUtils/hook";
import { PRODUCT_NAMES } from "utils/constants";
import { NZD } from "utils/currency";
import { getAggregateStartDate } from "utils/getAggregateStartDate";
import { jwtClient } from "utils/jwtClient";
import { calculateOffpeakUsageChangePercentage } from "utils/offpeakCalculations/calculateOffpeakUsageChangePercentage";
import { findCurrentRateCard } from "utils/rateCardHelpers/findCurrentRateCard";
import { findCurrentTimeTable } from "utils/rateCardHelpers/findCurrentTimetable";
import { calculateUsageChangePercentage } from "utils/usageCalculations/calculateUsageChangePercentage";
import { timeOfUseSummary } from "utils/usageCalculations/timeOfUseSummary";

import { DailyCostPerKwhSection } from "../DailyCostPerKwhSection";
import { renderDeltaMessage } from "../DeltaMessage";
import { prepareIndividualDayUsageData } from "./IndividualDayDetail.utils";

const DailyUsageStatus = {
  final: "final",
  pending: "pending",
  missing: "missing",
};

type IndividualDayDetailProps = {
  hasPreviousData: boolean;
  maxDayDataIndex: number;
  periodName: string;
  previousPeriodStartDate: Date;
  productName: string;
  selectedDayIndex: number;
  setSelectedDay: (value: number) => void;
  usageData: DailyUsage;
};

export type UsagePercentageComparison = {
  selectedDayUsagePercentage: string;
  previousPeriodDayUsagePercentage: string;
};

/**
 * @description Displays individual day detail component used in Daily Usage Section
 */
export const IndividualDayDetail: React.FC<IndividualDayDetailProps> = ({
  previousPeriodStartDate,
  hasPreviousData,
  productName,
  usageData,
  selectedDayIndex,
  maxDayDataIndex,
  setSelectedDay,
  periodName,
}) => {
  const { supplyNodeRef } = useAppSelector((store) => store.currentAccount);
  const { rateCards, isRateCardLoading } = useAppSelector(
    (store) => store.rateCards,
  );

  const [displayLoadingIcon, setDisplayLoadingIcon] = useState<boolean>(false);

  const day = usageData?.date;

  const productIsOffpeak = productName === PRODUCT_NAMES.offPeak;
  const productIsWholesale = productName === PRODUCT_NAMES.wholesale;

  const previousDate = getAggregateStartDate(
    previousPeriodStartDate,
    day,
    hasPreviousData,
  );

  //----- @TODO move all caculations onto the store, keep the component clean

  const currentRateCard = findCurrentRateCard(
    rateCards,
    productName,
    new Date(),
  );

  const currentTimetable = findCurrentTimeTable(currentRateCard, day);

  //----- @TODO move all caculations onto the store, keep the component clean

  const {
    data: selectedDayAggregateData,
    isFetching: isFetchingSelectedDayAggregateData,
    isError: isSelectedDayAggregateError,
  } = useGetAggregateHourlyRatesQuery(
    {
      supplyNodeRef,
      startAt: startOfDay(day),
      endAt: endOfDay(day),
      jwtClient,
      source: "Individual Day - Hourly Selected Day",
    },
    { skip: !supplyNodeRef || !usageData || !usageData.date },
  );

  const {
    data: previousPeriodDayAggregateData,
    isFetching: isFetchingPreviousPeriodAggregateData,
  } = useGetAggregateHourlyRatesQuery(
    {
      supplyNodeRef,
      startAt: startOfDay(previousDate),
      endAt: endOfDay(previousDate),
      jwtClient,
      source: "Individual Day - Hourly Previous Day",
    },

    { skip: !selectedDayAggregateData || !supplyNodeRef },
  );

  useEffect(() => {
    if (
      !isFetchingSelectedDayAggregateData &&
      !isFetchingPreviousPeriodAggregateData
    ) {
      setDisplayLoadingIcon(false);
      return;
    }
    setDisplayLoadingIcon(true);
  }, [
    isFetchingSelectedDayAggregateData,
    isFetchingPreviousPeriodAggregateData,
  ]);

  const {
    usageComponentValues: selectedDayUsageData,
    maxYValue: selectedDayMaxYValue,
  } = prepareIndividualDayUsageData(
    selectedDayAggregateData,
    productName,
    currentTimetable,
  );

  const usagePercentageComparison: UsagePercentageComparison = useMemo(() => {
    if (selectedDayUsageData) {
      const { usageComponentValues: previousPeriodDayUsageData } =
        prepareIndividualDayUsageData(
          previousPeriodDayAggregateData,
          productName,
          currentTimetable,
        );

      return {
        selectedDayUsagePercentage: timeOfUseSummary(selectedDayUsageData),
        previousPeriodDayUsagePercentage: timeOfUseSummary(
          previousPeriodDayUsageData,
        ),
      };
    }
  }, [
    selectedDayAggregateData,
    previousPeriodDayAggregateData,
    selectedDayUsageData,
  ]);

  const usageChangePercentage = calculateUsageChangePercentage(usageData);

  const comparisonLabel = `this time the previous ${periodName}`;

  const offPeakUsageChangePercentage = calculateOffpeakUsageChangePercentage(
    usagePercentageComparison,
  );

  const notFirstDayData = selectedDayIndex > 0;
  const notLastDayData = selectedDayIndex < maxDayDataIndex;

  const totalCost = usageData?.thisPeriod?.spend
    ? NZD.format(usageData.thisPeriod.spend)
    : "";

  const hasUsageData = usageData.status !== DailyUsageStatus.missing;

  if (isRateCardLoading) return <LoadingSection />;

  return (
    <div className="individualday_container background--grey">
      <span className="spacer spacer--small"></span>
      <div className="individual-day-detail__controls">
        {notFirstDayData && (
          <SecondaryButton
            className="button-secondary individual-day-detail__button small"
            onClick={() => {
              setSelectedDay(selectedDayIndex - 1);
            }}
          >
            <span aria-label="Previous day">{"< Prev"}</span>
          </SecondaryButton>
        )}
        <h3 className="h4 individual-day-detail__title">
          {format(usageData.date, "EEEE d MMMM")}
        </h3>
        {notLastDayData && (
          <SecondaryButton
            className="button-secondary individual-day-detail__button small"
            onClick={() => {
              setSelectedDay(selectedDayIndex + 1);
            }}
          >
            <span aria-label="Next day">{"Next >"}</span>
          </SecondaryButton>
        )}
      </div>
      <section className="bill_usage_section">
        {Boolean(usageData.thisPeriod.consumption) && (
          <div className="usage_details_section">
            <div className="header_section">
              <BoltIconSvg data-testid="icon" />
              <p className="section-sub-title">
                Used {usageData.thisPeriod.consumption.toFixed(3)} kWh of power
                ({totalCost})
              </p>

              {Boolean(usageData.lastPeriod.consumption) &&
                renderDeltaMessage({
                  delta: usageChangePercentage,
                  periodLabel: comparisonLabel,
                })}
            </div>
          </div>
        )}
        {productIsOffpeak && Boolean(usageData.thisPeriod.consumption) && (
          <div className="usage_details_section">
            <div className="header_section">
              <LeafIconSvg />
              {displayLoadingIcon ? (
                <LoadingIcon />
              ) : (
                <p className="section-sub-title">
                  {usagePercentageComparison.selectedDayUsagePercentage}%
                  off-peak
                </p>
              )}

              {isWeekend(usageData.date) && (
                <>
                  <RightArrowSvg className="arrow icon_colour--secondary" />
                  <p className="text text__secondary">
                    Weekends are entirely off-peak!
                  </p>
                </>
              )}

              {Boolean(usageData.lastPeriod.consumption) &&
                renderDeltaMessage({
                  delta: offPeakUsageChangePercentage,
                  periodLabel: comparisonLabel,
                })}
            </div>
          </div>
        )}
      </section>
      <hr className="content_divider" />
      {hasUsageData && (
        <IndividualDayChart
          displayLoadingIcon={displayLoadingIcon}
          isSelectedDayAggregateError={isSelectedDayAggregateError}
          productIsOffpeak={productIsOffpeak}
          selectedDayMaxYValue={selectedDayMaxYValue}
          selectedDayUsageData={selectedDayUsageData}
        />
      )}
      {productIsWholesale && hasUsageData && (
        <>
          <hr className="content_divider spacer--large" />
          <div className="header_section">
            <PriceTagIconSvg data-testid="icon" />
            <strong>Price per kWh</strong>
          </div>
          <DailyCostPerKwhSection
            endAt={endOfDay(day)}
            startAt={startOfDay(day)}
            supplyNodeRef={supplyNodeRef}
          />
        </>
      )}
    </div>
  );
};
