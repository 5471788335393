export const RENDER_MODE = {
  native: "native",
};

export const BILL_GROUP_DATE_FORMAT = "MMMM yyyy";

export const BILL_QUERY_LIMIT = 20;

export const BILL_PAYMENT_STATUSES = {
  pastDue: "past_due",
  due: "due",
  complete: "complete",
  notDetermined: "not_determined",
};

export const INVOICED_PAYMENT_STATUSES = {
  pastDue: "payment_past_due",
  due: "payment_due",
  complete: "payment_complete",
  notDetermined: "not_determined",
};

export const AGGREGATE_DATA_STATUSES = {
  final: "final",
  pending: "pending",
  missing: "missing",
};

export const PAYMENT_ACTION_SUCCESS = "succeeded";
export const PAYMENT_ACTION_PENDING = "pending";
export const PAYMENT_ACTION_FAILED = "failed";

export const PRODUCT_NAMES = {
  offPeak: "offpeak",
  flat: "flat",
  business: "business",
  wholesale: "freestyle",
};

// freestyle is returned from our apis but needs to be output to customers as wholesale
// Off peak requires different formatting when displayed to customers (see "Product Naming" in the README)
export const PRODUCT_DISPLAY_NAMES = {
  offpeak: "off peak",
  flat: PRODUCT_NAMES.flat,
  business: PRODUCT_NAMES.business,
  freestyle: "wholesale",
  // include older product names for historic closed accounts and older bills
  earnie: "earnie",
  fixie: "fixie",
};

export const TIME_OF_DAY = {
  offPeak: "offpeak",
  peak: "peak",
};

export const ROUTE_INFO = {
  bills: { background: "green", name: "bills" },
  usage: { background: "pink", name: "usage" },
  account: { background: "red", name: "account" },
  default: { background: "yellow", name: "home" },
};

export const PLAN_COLOURS = {
  offpeak: "yellow",
  flat: "pink",
  wholesale: "green",
  business: "yellow",
};

export const BRAND_COLOURS = {
  crimson: "crimson",
  green: "green",
  greenDark: "greenDark",
  pink: "pink",
  pinkDark: "pinkDark",
  yellow: "yellow",
  yellowDark: "yellowDark",
  greyShade10: "greyShade10",
  greyShade30: "greyShade30",
  greyShade50: "greyShade50",
  greyShade70: "greyShade70",
};

export const PERIOD_CODES = {
  P7D: "P7D",
  P2W: "P2W",
  P1M: "P1M",
  CUSTOM: "CUSTOM",
};

export const BILLING_OFFSET_WEEKS = {
  thisWeek: "0",
  nextWeek: "1",
};

export const AVAILABLE_PERIODS = {
  P7D: { code: PERIOD_CODES.P7D, duration: 7, periodName: "week" },
  P2W: { code: PERIOD_CODES.P2W, duration: 14, periodName: "fortnight" },
  P1M: { code: PERIOD_CODES.P1M, duration: 30, periodName: "month" },
};

export const CUSTOM_PERIOD = {
  CUSTOM: { code: PERIOD_CODES.CUSTOM, duration: 7, periodName: "custom" },
};

export const UNIT_CODE_TYPES = {
  cost: "cost",
  usage: "usage",
};

export const BILL_SMOOTHER_STATE = {
  paused: "paused",
  activated: "activated",
  updated: "updated",
  deactivated: "deactivated",
};

// due to delays in getting data from network companies we often don't have the
// most current customer data
export const DAY_DELAY_FOR_USAGE_DATA = 4;

export const MEDIUM_SCREEN_WIDTH = 768;

/* Notification Banners */

export const NotificationBannerNames = {
  accountClosed: "AccountClosed",
  accountDetailsEmail: "AccountDetailsEmail",
  accountClosure: "AccountClosure",
  accountDetails: "AccountDetails",
  addDirectDebitPayment: "AddDirectDebitPayment",
  addCardPayment: "AddCardPayment",
  addCardPaymentSuccess: "AddCardPaymentSuccess", // has notice styling but is a success message (requires extra steps for user to use)
  billInvoice: "BillInvoice",
  movingHouse: "MovingHouse",
  updateBillingPreferences: "UpdateBillingPreferences",
  updatePassword: "UpdatePassword",
  updatePlan: "UpdatePlan",
};

export const NotificationBannerTypes = {
  error: "error",
  notice: "notice",
  success: "success",
};

export const BANK_ACCOUNT_MAX_LENGTH = 16;

export const RATE_CODES = {
  offpeak: "Off-peak",
  peak: "Peak",
  day: "Daily charge",
  anytime: "Anytime",
  shoulder: "Shoulder",
  night: "Night",
  summer: "Summer",
  winter: "Winter",
  standard: "Usage",
  controlled: "Controlled",
};

export const UNIT_CODES = {
  kwh: "c/kWh",
  day: "c/day",
};

export const RATE_CHANNEL_CODES = {
  un: "Uncontrolled",
  cn: "Controlled",
  in: "Inclusive",
  d: "Day / Night",
  "n|d": "Day / Night",
  night: "Night",
  eg: "Export",
  we: "Weekend",
};

export const LOGOUT_ACTION = "LOGOUT";

// APIs can return 401s for a few different reasons but when this value is returned as a value
// for the error key it should mean that the token has expired or been revoked.
export const API_TOKEN_INVALID_ERROR =
  "urn:flick:authentication:error:token_verification_failed";

export const FRIEND_GET_FRIEND_CREDIT_AMOUNT: 50 | 100 = 50;
