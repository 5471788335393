import { paymentStatus } from "utils/billPaymentStatus";
import { BILL_PAYMENT_STATUSES } from "utils/constants";
import { formattedPaymentDate } from "utils/formattedBillPaymentDate";
import { friendlyPaymentMethod } from "utils/friendlyPaymentMethod";

/**
 * @returns A string containing the total paid or to be paid and the payment date
 */
export const labelForTotal = (bill: Bill): string => {
  const paymentDate = formattedPaymentDate(bill.payment_due_date);

  const paymentVerb =
    friendlyPaymentMethod(bill.payment_method) === "direct debit"
      ? "debit"
      : "charge";

  if (paymentStatus(bill) === BILL_PAYMENT_STATUSES.complete) {
    return `Total paid ${paymentDate}`;
  }

  if (paymentStatus(bill) === BILL_PAYMENT_STATUSES.pastDue) {
    return "Total owing";
  }

  return `Total we'll ${paymentVerb} ${paymentDate}`;
};
