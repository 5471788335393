import classnames from "classnames";
import React from "react";
import { useAppSelector } from "reduxUtils/hook";

type PageProps = {
  children: JSX.Element | JSX.Element[];
  isAuthenticated: boolean;
};

export const Page: React.FC<PageProps> = ({ children, isAuthenticated }) => {
  const announcementBanner: AnnouncementBanner = useAppSelector(
    (store) => store.announcementBanner,
  );

  const notificationBanner = useAppSelector(
    (store) => store.notificationBanner,
  );

  const { nativeRenderMode } = useAppSelector((store) => store.currentAccount);

  const isDesktopModeBannerEnabled =
    !nativeRenderMode && announcementBanner?.enableDesktopMode; // is desktop mode, banner for desktop enabled
  const isNativeModeBannerEnabled =
    nativeRenderMode && announcementBanner?.enableNativeMode; // is native mode, banner for native enabled

  const hasAnnouncementBanner =
    announcementBanner &&
    !announcementBanner.hideBanner &&
    (isDesktopModeBannerEnabled || isNativeModeBannerEnabled);

  const hasNotificationBanner = notificationBanner !== null;

  return (
    <div
      className={classnames("page", {
        "page--authenticated": isAuthenticated,
        "page--unauthenticated": !isAuthenticated,
        "page--has_notification_banner":
          hasAnnouncementBanner || hasNotificationBanner,
      })}
    >
      {children}
    </div>
  );
};
