import { isEmptyValue } from "utils/forms/forms";

/** 
@description Util function that takes a field value, a custom validation function, and optional required and invalid error messages.
Checks for required OR validity, or both, depending on the params supplied
Validates the field and returns an error message if invalid or empty string if valid 
*/

export const validationFunctionHelper = ({
  value,
  validationFunction,
  requiredError,
  invalidError,
}: {
  value: string;
  validationFunction: (val: string) => boolean;
  requiredError?: string;
  invalidError?: string;
}) => {
  const isSpecified = !requiredError || !isEmptyValue(value);
  const isValid = isSpecified && validationFunction(value);

  let error: string;
  if (invalidError) error = isValid ? "" : invalidError;
  if (requiredError) error = isSpecified ? error : requiredError;
  return error || "";
};
