import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { billingApi } from "queries/billingApi";

const billingEntityDataSlice = createSlice({
  name: "billingEnityData",
  initialState: {
    billingEntityData: undefined,
  },
  reducers: {
    setUpdateDefaultPaymentMethod: (
      state,
      action: PayloadAction<{
        default_payment_method?: AccountDetailPaymentMethod;
      }>,
    ) => {
      const { payload } = action;
      Object.entries(payload).forEach(([key, param]) => {
        if (param) {
          state.billingEntityData[key] = param;
        }
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        billingApi.endpoints.getBillingEntity.matchPending,
        (state) => {
          state.billingEntityData = undefined;
        },
      )
      .addMatcher(
        billingApi.endpoints.getBillingEntity.matchFulfilled,
        (state, { payload }) => {
          state.billingEntityData = payload;
        },
      );
  },
});

export const { setUpdateDefaultPaymentMethod } = billingEntityDataSlice.actions;
export const billingEntityDataSliceReducer = billingEntityDataSlice.reducer;
