import "styles/PercentageChart.scss";

import { ReactComponent as MarkerSvg } from "assets/marker.svg";
import React from "react";

type PercentageChartProps = {
  markerLabel: string;
  markerPercentage: string | number;
  percentage: string | number;
};

export const PercentageChart: React.FC<PercentageChartProps> = ({
  percentage,
  markerPercentage,
  markerLabel,
}) => {
  return (
    <div className="chart_percentage">
      <div className="chart_percentage_background background--grey">
        <div
          className="chart_percentage_bar background--green"
          style={{ width: `${percentage}%` }}
        />
      </div>

      {markerPercentage === undefined ? null : (
        <div className="marker_percentage_wrapper">
          <div
            className="marker_percentage"
            style={{ left: `${markerPercentage}%` }}
            data-label={markerLabel}
          >
            <MarkerSvg />
          </div>
        </div>
      )}
    </div>
  );
};
