/* eslint-disable react/style-prop-object */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';

import BillEstimateDialog from './billEstimateDialog';
import SupplyNode from '../../models/supply-node';
import Address from '../../models/address';
import BillEstimateService from '../../services/bill-estimate-service';
import { EXCLUSIVE, INCLUSIVE, LOW, PRODUCT_NAMES, STANDARD } from '../../utils/constants';
import BillEstimateOptions from './billEstimateOptions';
import PriceNotFound from './priceNotFound';
import Plan from './plan';
import sendAnalytics from '../../utils/analytics';

const getCarouselEffectScopedTo = (carouselScope) => () => {
  if (carouselScope.current) {
    import(/* webpackMode: "eager" */ '@flick/front_end_patterns/src/js/dna/carousel').then(
      (CarouselJS) => {
        // eslint-disable-next-line new-cap
        new CarouselJS.default().setup(carouselScope.current);
      }
    );
  }
};

const mapEstimatesToPlans = (estimates) => {
  const availablePlans =
    estimates &&
    estimates
      .map((estimate) => ({
        contentSlug: PRODUCT_NAMES[estimate.product],
        estimate,
      }))
      .sort((a, b) => (a.slug > b.slug ? 1 : -1));
  return availablePlans;
};

const Results = ({ apiBaseUrl, defaultPlans, supplyNode, address, externalPlansCopy }) => {
  const [availablePlans, setAvailablePlans] = useState(null);
  const [userType, setUserType] = useState(null);
  const [gstInclusion, setGstInclusion] = useState(EXCLUSIVE);
  const initialBillDetailState = {
    usage: null,
    cost: null,
    endDate: null,
    startDate: null,
    daysInBillingPeriod: null,
  };
  const [personalBillDetails, setPersonalBillDetails] = useState(initialBillDetailState);
  const [showControls, setShowControls] = useState(false);
  const [pricingError, setPricingError] = useState(false);
  const [billEstimateLoading, setBillEstimateLoading] = useState(false);
  const carouselRef = useRef(null);
  useEffect(getCarouselEffectScopedTo(carouselRef), []);
  useEffect(getCarouselEffectScopedTo(carouselRef), [availablePlans]);

  const continueJoiningWithoutPricesCallback = () => {
    setPricingError(false);
    setShowControls(false);
    setBillEstimateLoading(false);
    setAvailablePlans(defaultPlans.map((plan) => ({ contentSlug: plan })));
  };

  const updatePersonalBillDetails = (billDetailsData) => {
    if (!billDetailsData) return setPersonalBillDetails(initialBillDetailState);
    return setPersonalBillDetails(billDetailsData);
  };

  // TODO: this should be refactored
  const generateBillingEstimate = useCallback((billDetailsData = null) => {
    setBillEstimateLoading(true);
    const billEstimateService = new BillEstimateService(apiBaseUrl);
    if (billDetailsData !== personalBillDetails) updatePersonalBillDetails(billDetailsData);
    billEstimateService
      .fetch(
        supplyNode.icpNumber,
        userType,
        supplyNode.locationClassification,
        billDetailsData,
        supplyNode.networkProvider
      )
      .then((data) => {
        // If the selected userType differs to what's returned by the estimate, update userType
        if (userType !== data[0].usageType) setUserType(data[0].usageType);
        setAvailablePlans(mapEstimatesToPlans(data));
        const hasBusinessPlan = availablePlans?.some(
          (plan) => plan.contentSlug === PRODUCT_NAMES.business
        );
        setGstInclusion(hasBusinessPlan ? EXCLUSIVE : INCLUSIVE);
        /**
         * In BillEstimateDialog when a user submits data to be compared with Flick plan rates a new bill estimate is created
         * we set GST inclusive for the Business plan so the user can compare a GST inclusive bill with a GST inclusive Flick estimate
         * when it is no longer a billing estimate comparison we show Business GST exclusive rates again
         */
        if (billDetailsData?.daysInBillingPeriod) {
          setGstInclusion(INCLUSIVE);
        }
        getCarouselEffectScopedTo(carouselRef);
        setShowControls(true);
      })
      .catch(() => {
        // Try the standard user type in case low was missing
        if (userType === LOW) {
          setUserType(STANDARD);
          setShowControls(false);
        } else {
          setPricingError(true);
        }
      })
      .finally(() => {
        setBillEstimateLoading(false);
      });
  });

  useEffect(() => {
    if (supplyNode && Object.values(personalBillDetails).includes(null)) {
      generateBillingEstimate();
    } else if (!supplyNode || !userType) {
      setPersonalBillDetails(initialBillDetailState);
      continueJoiningWithoutPricesCallback();
    }
  }, [supplyNode, userType]);

  const hasExistingEstimate = !Object.values(personalBillDetails).includes(null);

  const businessPlanPresent = useMemo(() => {
    const businessPlanExist = availablePlans?.some(
      (plan) => plan.contentSlug === PRODUCT_NAMES.business
    );
    return businessPlanExist;
  }, [availablePlans]);

  const clearBillEstimate = (isBusinessPlanPresent) => {
    sendAnalytics('clear bill details', 'clicked');
    setGstInclusion(isBusinessPlanPresent ? EXCLUSIVE : INCLUSIVE);
    generateBillingEstimate();
  };

  return (
    <div className="plan_card_component" ref={carouselRef}>
      {pricingError && (
        <PriceNotFound continueJoiningWithoutPrices={continueJoiningWithoutPricesCallback} />
      )}
      {!pricingError && (
        <>
          {address && (
            <div className="plan_card_component_header">
              <h3 className="h3">Tū meke! Flick’s available at your place. Let’s pick a plan…</h3>
            </div>
          )}
          {billEstimateLoading && <div className="plan_card_component_loading" />}
          {!billEstimateLoading && showControls && (
            <section className="plan_card_component_filters">
              <div className="plan_card_component_filters_tools">
                <BillEstimateOptions
                  userType={userType}
                  setUserType={setUserType}
                  gstInclusion={gstInclusion}
                  setGstInclusion={setGstInclusion}
                  billDetails={personalBillDetails}
                  businessPlan={businessPlanPresent}
                />
                <BillEstimateDialog
                  billDetails={personalBillDetails}
                  businessPlan={businessPlanPresent}
                  updateBillDetails={(data) => {
                    generateBillingEstimate(data);
                  }}
                />
                {hasExistingEstimate && (
                  <div className="plan_card_component_filters_actions">
                    <button
                      className="button button_secondary"
                      onClick={clearBillEstimate}
                      type="button"
                    >
                      Clear estimate
                    </button>
                  </div>
                )}
              </div>
            </section>
          )}
          {!billEstimateLoading && (
            <div className="plan_card_list carousel" data-carousel-plans>
              <div className="carousel_track">
                <div className="carousel_list">
                  {availablePlans?.map((plan) => {
                    const externalPlanCopy = externalPlansCopy?.plans.find(
                      (copy) => copy.slug === plan.contentSlug
                    );
                    return (
                      <Plan
                        plan={plan}
                        copy={externalPlanCopy}
                        region={supplyNode?.address?.region}
                        icp={supplyNode?.icpNumber}
                        userType={userType}
                        gstInclusion={gstInclusion}
                        address={address}
                        key={`plan-${plan.contentSlug}-result`}
                        billDetails={personalBillDetails}
                        billEstimateLoading={billEstimateLoading}
                        updateBillDetails={(data) => generateBillingEstimate(data)}
                        setGstInclusion={setGstInclusion}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          )}
          {availablePlans && (
            <section className="plan_card_component_footer">
              <p>
                <strong>Still not sure?</strong>
              </p>
              <p>
                <a
                  className="link"
                  href="https://www.flickelectric.co.nz/bill-comparison/"
                  target="_blank"
                  rel="noreferrer"
                  onClick={() =>
                    sendAnalytics('Send Bill link Under Plans: Send us a bill link', 'Clicked')
                  }
                >
                  Send us a bill
                </a>{' '}
                and we can get an accurate estimate for you.
              </p>
              <br />
              <h3 className="h3">
                <span className="text_highlight text_highlight--alpha">
                  <span>Flickin’ Best Plan Promise</span>
                </span>
              </h3>
              <p>
                Join the power company that proactively ensures you’re always on the
                <br /> best plan for you.
                <br />
                <br />
                Every 90 days we’ll assess the times of day you’re using your power,
                <br /> and if another plan might suit you better - we’ll let you know.
                <br />
                <br />
              </p>
              <div className="plan_card_link">
                <a
                  className="link"
                  href="https://www.flickelectric.co.nz/tools-and-features/flickin-best-plan-promise/"
                  target="_blank"
                  rel="noreferrer"
                >
                  How <span aria-hidden>it</span>
                  <span className="sr-only">the Best Plan Promise</span> works
                </a>
              </div>
            </section>
          )}
        </>
      )}
    </div>
  );
};

Results.propTypes = {
  apiBaseUrl: PropTypes.string.isRequired,
  defaultPlans: PropTypes.arrayOf(PropTypes.string).isRequired,
  supplyNode: PropTypes.instanceOf(SupplyNode),
  address: PropTypes.instanceOf(Address),
  // eslint-disable-next-line react/forbid-prop-types
  externalPlansCopy: PropTypes.object.isRequired,
};

Results.defaultProps = {
  supplyNode: null,
  address: null,
};

export default Results;
