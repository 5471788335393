export const visitLink = ({
  url,
  extraCallBack,
}: {
  url: string;
  extraCallBack?: () => void;
}) => {
  const link: HTMLAnchorElement = document.createElement("a");
  link.style.display = "none";
  link.target = "_blank";
  link.href = url;
  link.click();
  if (extraCallBack) extraCallBack();
  link.remove();
};

export const navigateLink = (url: string) => {
  window.location.href = url;
};
