import {
  AGGREGATE_DATA_STATUSES,
  BRAND_COLOURS,
  PRODUCT_NAMES,
  TIME_OF_DAY,
} from "utils/constants";
import { findCurrentShift } from "utils/offpeakCalculations/findCurrentShift";
/**
 * @description used to find a rate code string based on where the
 * current time sits in a rate card time table.
 */
export const rateCodeForCurrentShift = (
  currentTimetable: Shift[],
  time: string,
): string => {
  if (currentTimetable?.length === 1) return currentTimetable[0].rateCode;

  const localTime = new Date(time);
  const currentShiftDetails = findCurrentShift(currentTimetable, localTime);

  return currentShiftDetails?.currentShift?.rateCode;
};

export const determineBarColour = (
  currentProductName: string,
  rateCode: string,
  status: string,
): string => {
  const productIsOffpeak = currentProductName === PRODUCT_NAMES.offPeak;

  if (
    productIsOffpeak &&
    rateCode === TIME_OF_DAY.peak &&
    status === AGGREGATE_DATA_STATUSES.final
  ) {
    return BRAND_COLOURS.crimson;
  }

  if (
    (productIsOffpeak &&
      rateCode === TIME_OF_DAY.peak &&
      status === AGGREGATE_DATA_STATUSES.pending) ||
    (!productIsOffpeak && status === AGGREGATE_DATA_STATUSES.pending)
  ) {
    return BRAND_COLOURS.pink;
  }

  if (
    (productIsOffpeak &&
      rateCode === TIME_OF_DAY.offPeak &&
      status === AGGREGATE_DATA_STATUSES.final) ||
    (!productIsOffpeak && status === AGGREGATE_DATA_STATUSES.final)
  ) {
    return BRAND_COLOURS.green;
  }

  if (
    productIsOffpeak &&
    rateCode === TIME_OF_DAY.offPeak &&
    status === AGGREGATE_DATA_STATUSES.pending
  ) {
    return BRAND_COLOURS.greyShade30;
  }

  return "";
};

/**
 * @description prepare data for Individual Day Chart component
 */
export const prepareIndividualDayUsageData = (
  aggregatesData: Aggregates,
  currentProductName: string,
  currentTimetable?: Shift[],
): { usageComponentValues: ChartUsage[]; maxYValue: number | [] } => {
  if (
    !aggregatesData ||
    Array.isArray(!aggregatesData.aggregates) ||
    aggregatesData.aggregates.length === 0
  )
    return { usageComponentValues: [], maxYValue: [] };

  const usageComponentValues = aggregatesData.aggregates.map(
    (aggregateItem) => {
      const rateCode = rateCodeForCurrentShift(
        currentTimetable,
        aggregateItem.start_at,
      )?.toLowerCase();

      const barColour = determineBarColour(
        currentProductName,
        rateCode,
        aggregateItem.status,
      );

      return {
        barColour,
        time: Date.parse(aggregateItem.start_at),
        value: Number(aggregateItem.usage.import_value),
        usageType: rateCode || "default",
        status: aggregateItem.status,
      };
    },
  );

  const maxYValue = Math.max(
    ...usageComponentValues.map((item) => Number(item.value)),
  );

  return {
    usageComponentValues,
    maxYValue,
  };
};
