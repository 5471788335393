import { PRODUCT_DISPLAY_NAMES, RATE_CHANNEL_CODES } from "utils/constants";
import { ADD_NZ_GST } from "utils/currency";
import {
  capitaliseProductName,
  normaliseProductName,
} from "utils/productNameUtils";

// Get a comma separated string of the unique channel codes to display with each rate
const getChannelCodes = (rate: SubscriptionUsageRate) => {
  const channelCodes = [
    ...new Set(
      rate.channels.map(
        (channel) => RATE_CHANNEL_CODES[channel.content_code?.toLowerCase()],
      ),
    ),
  ];
  return channelCodes.join(", ");
};

const getRateInfo = (
  rateAttributes: SubscriptionUsageRate[],
): AvailablePlanRate[] => {
  const result = rateAttributes.map((rate: SubscriptionUsageRate) => {
    return {
      rates: rate.rates.map((r: Rate) => {
        return {
          ...(rate.channels.length && { channelCodes: getChannelCodes(rate) }),
          rateCode:
            rate.unit_code === "day" ? "day" : r.rate_code.toLowerCase(), // subscription rates and usage rates have unit code 'Anytime' which displays as 'Daily charges. Usage rates can also have 'unit code 'Anytime'
          total: parseFloat((Number(r.total) * 100).toFixed(3)).toString(),
          unitCode: rate.unit_code.toLowerCase(),
        };
      }),
    };
  });
  return result;
};

export const formatAvailablePlans = (
  rateCards: RateCardResponse[],
): AvailablePlan[] => {
  if (!rateCards || !Array.isArray(rateCards) || rateCards.length === 0)
    return [];
  const plans = rateCards.map(({ attributes }) => {
    const normalisedName = attributes.product_name
      ? normaliseProductName(attributes.product_name)
      : undefined;
    const productName = normalisedName
      ? capitaliseProductName(PRODUCT_DISPLAY_NAMES[normalisedName])
      : undefined;

    return {
      id: attributes.plan_id,
      productName: productName,
      subscriptionRates: getRateInfo(attributes.subscription_rates),
      usageRates: getRateInfo(attributes.usage_rates),
    };
  });
  return plans;
};

export const addGst = (amount: string) => {
  return parseFloat((Number(amount) * ADD_NZ_GST).toFixed(3)).toString();
};
