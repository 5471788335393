import { MovingHousePageContent } from "pages/MovingHousePage/MovingHousePageContent";
import React from "react";

import { MovingHouseFormContextProvider } from "./MovingHouseContext";

export const MovingHousePage: React.FC = () => {
  return (
    <MovingHouseFormContextProvider>
      <MovingHousePageContent />
    </MovingHouseFormContextProvider>
  );
};
