import { BillSmootherTransactionDetails } from "components/BillSmoother/BillSmootherTransactionDetails";
import { format } from "date-fns/format";
import React from "react";
import { BILL_GROUP_DATE_FORMAT } from "utils/constants";

const BILL_SMOOTHER_CATEGORY = "payment_smoothing";

/**
 * @description fuction that takes a transcations array and filters to return only transactions where the category is "payment_smoothing"
 * @param transactions
 * @returns array
 */
const getFilteredTransactions = (transactions: BillSmootherTransaction[]) => {
  if (transactions.length === 0) return [];
  return transactions.filter(
    (transaction) => transaction.attributes.category == BILL_SMOOTHER_CATEGORY,
  );
};

type BillSmootherTransactionsProps = {
  startDate: Date;
  transactions: BillSmootherTransaction[];
};

/**
 * @description used in BillSmootherPage
 */
export const BillSmootherTransactions: React.FC<
  BillSmootherTransactionsProps
> = ({ startDate, transactions }) => {
  let currentMonth = format(new Date(startDate), BILL_GROUP_DATE_FORMAT);
  const filteredTransactions = getFilteredTransactions(transactions);

  if (filteredTransactions.length === 0) {
    return <p>No Bill Smoother transactions yet</p>;
  }

  return (
    <nav>
      <ul className="customer_tools_list">
        {filteredTransactions.map((transaction) => {
          const periodStart = new Date(
            transaction.attributes.timestamps.completed_at,
          );
          const transactionMonth = format(periodStart, BILL_GROUP_DATE_FORMAT);
          const showMonth = transactionMonth !== currentMonth;
          currentMonth = transactionMonth;
          return (
            <React.Fragment key={transaction.id}>
              {showMonth && (
                <li className="customer_tools_list__title">
                  <h3 className="h6">
                    <span className="sr-only">
                      Bill Smoother transactions for{" "}
                    </span>
                    {currentMonth}
                  </h3>
                </li>
              )}
              <li className="customer_tools_list__item customer_tools_section__item_container">
                <BillSmootherTransactionDetails
                  completedAt={transaction.attributes.timestamps.completed_at}
                  direction={transaction.attributes.direction}
                  amount={parseFloat(transaction.attributes.amount)}
                />
              </li>
            </React.Fragment>
          );
        })}
      </ul>
    </nav>
  );
};
