import { SingleSelectRadioInput } from "components/SingleSelectRadioGroup/SingleSelectRadioInput";
import React, { ChangeEvent } from "react";

export interface RadioOption {
  children?: JSX.Element;
  hint?: string;
  label?: string;
  name?: string;
  value?: string;
}

type SingleSelectRadioGroupProps = {
  extraClasses?: string;
  handleOnChange?: (value: string) => void;
  hint?: string | JSX.Element;
  label?: string | JSX.Element;
  name: string;
  options: RadioOption[];
  required?: boolean;
  valueSelect: string;
};

export const SingleSelectRadioGroup: React.FC<SingleSelectRadioGroupProps> = ({
  extraClasses,
  handleOnChange,
  hint,
  label,
  name,
  options,
  required,
  valueSelect,
}) => {
  const handleRadioChange = (event: ChangeEvent) => {
    const { value } = event.target as HTMLInputElement;
    handleOnChange(value);
  };

  return (
    <>
      {label && (
        <label className="h5" htmlFor={`${name}-radio`}>
          {label}
        </label>
      )}
      {hint && <span className="form_field_helper">{hint}</span>}
      <ul className="form_field_collection">
        {options.map(
          ({
            children,
            hint: optionHint,
            label: labelHint,
            name: nameHint,
            value,
          }) => (
            <SingleSelectRadioInput
              children={children}
              extraClasses={extraClasses}
              isChecked={value === valueSelect}
              hint={optionHint}
              key={value}
              label={labelHint}
              name={nameHint || `${name}-${value}`}
              onChange={handleRadioChange}
              required={required}
              value={value}
            />
          ),
        )}
      </ul>
    </>
  );
};
