export default class MeterConfiguration {
  constructor({ channels, rates }) {
    this.contentCode = channels?.content_code;
    this.periodOfAvailability = channels?.period_of_availability;
    this.rates = rates;
    this.name = channels?.content_name;
  }

  sortByPrice() {
    return this.rates.sort((prev, current) => prev.price - current.price);
  }

  sortRatesBySummaryLabel() {
    const rateSummaryLabelOrder = [
      'offpeak',
      'shoulder',
      'peak',
      'uncontrolled',
      'inclusive',
      'day / night',
      'day',
      'night',
      'anytime',
      'controlled',
    ];

    this.rates = this.rates.sort((previous, current) => {
      const previousLabelIndex = rateSummaryLabelOrder.indexOf(previous.summaryLabel);
      const currentLabelIndex = rateSummaryLabelOrder.indexOf(current.summaryLabel);
      if (currentLabelIndex === -1) return -1;
      if (previousLabelIndex === -1) return 1;
      return previousLabelIndex - currentLabelIndex;
    });
  }

  minRate() {
    return this.sortByPrice()[0];
  }

  maxRate() {
    return this.sortByPrice()[this.sortByPrice().length - 1];
  }

  displayNonMaxRateShortTimetables() {
    const nonMaxRates = this.rates.filter((rate) => rate === this.minRate());
    const timetables = nonMaxRates.map((rate) => rate.displayShortTimetable());
    return timetables.join('<br /><br/>');
  }
}
