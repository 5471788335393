import "./CurrentBillPeriodSection.scss";

import { ReactComponent as ForwardIcon } from "app/assets/images/icon_nav_arrow_forward.svg";
import { CurrentBillSpendUnavailable } from "components/CurrentBillSpendUnavailable";
import { DeltaMessage } from "components/DeltaMessage";
import {
  add,
  differenceInDays,
  format,
  isBefore,
  isSameDay,
  sub,
} from "date-fns";
import { toZonedTime } from "date-fns-tz";
import { expireTime } from "queries/queries.utils";
import { useGetAggregateRatesQuery } from "queries/ratingApi";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useAppSelector } from "reduxUtils/hook";
import {
  getAggregatesTotalValue,
  getValidAggregateTotals,
} from "utils/aggregateCalculations/findValidAggregateTotals";
import { AVAILABLE_PERIODS, PERIOD_CODES } from "utils/constants";
import { NZD } from "utils/currency";
import { jwtClient } from "utils/jwtClient";

import { withErrorBoundary } from "../fallback/withErrorBoundary";
import { LoadingSection } from "../LoadingSection";
import { ratingQueryDateRange } from "./CurrentBillPeriod.utils";

const LastPeriodComparison = ({
  aggregatesData,
  thisPeriodValue,
  periodName,
}) => {
  const validAggregateTotals = getValidAggregateTotals(aggregatesData);
  const aggregatesTotalValue = getAggregatesTotalValue(validAggregateTotals);
  const delta = thisPeriodValue - aggregatesTotalValue;
  return (
    <div className="header_section">
      <DeltaMessage
        delta={delta}
        formatter={(v) => NZD.format(v)}
        periodLabel={`this time last ${periodName}`}
      />
    </div>
  );
};

/**
 * wrap this with memo because all this caculations are expensive and need to memorise
 */
const CurrentBillPeriodHeading = memo<{
  aggregatesData: Aggregates;
  ratingQueryDate: {
    endAt?: Date;
    startAt?: Date;
  };
  billingEntityData: BillingEntity;
  supplyNodeRef: string;
  duration: number;
  periodName: string;
  onLatestValidDateUpdate: (latestValidDate: Date) => void;
}>(
  ({
    aggregatesData,
    ratingQueryDate,
    billingEntityData,
    supplyNodeRef,
    duration,
    periodName,
    onLatestValidDateUpdate,
  }) => {
    const validAggregateTotals = getValidAggregateTotals(aggregatesData);
    const aggregatesTotalValue = getAggregatesTotalValue(validAggregateTotals);

    // covert Dates to numbers by getTime() https://stackoverflow.com/a/70739006
    const allValidDates = validAggregateTotals.map((validTotal) =>
      new Date(validTotal.end_at).getTime(),
    );

    const latestValidDate =
      allValidDates.length > 0
        ? new Date(Math.max(...allValidDates))
        : new Date(aggregatesData.aggregates[0].end_at);

    const daysPassed = Math.max(
      1,
      aggregatesData.aggregates.findIndex((aggregate) =>
        isSameDay(new Date(aggregate.end_at), latestValidDate),
      ) + 1,
    );

    const previousPeriodStart = sub(new Date(ratingQueryDate.startAt), {
      days: duration,
    });

    const periodPeriodEnd = add(previousPeriodStart, { days: daysPassed });

    useEffect(() => {
      if (onLatestValidDateUpdate) {
        onLatestValidDateUpdate(latestValidDate);
      }
    }, [latestValidDate, onLatestValidDateUpdate]);

    const { data: lastPeriodAggregateData } = useGetAggregateRatesQuery(
      {
        supplyNodeRef,
        startAt: previousPeriodStart,
        endAt: periodPeriodEnd,
        jwtClient,
        source: "Last Period Bill Comparision Section",
      },
      {
        skip:
          !previousPeriodStart ||
          !periodPeriodEnd ||
          !supplyNodeRef ||
          !billingEntityData ||
          // query start date must not before user's start date else it will invalid request and expire token
          isBefore(previousPeriodStart, new Date(billingEntityData?.start_at)),
        refetchOnMountOrArgChange: expireTime,
      },
    );

    return (
      <>
        <p className="h1">
          {aggregatesTotalValue && NZD.format(aggregatesTotalValue)}
        </p>
        {aggregatesTotalValue && lastPeriodAggregateData && periodName && (
          <LastPeriodComparison
            aggregatesData={lastPeriodAggregateData}
            thisPeriodValue={aggregatesTotalValue}
            periodName={periodName}
          />
        )}
      </>
    );
  },
);

/**
 * @description CurrentBillPeriodSection component
 * used in HomePage to display current bill
 * conds to display is supplyNodeRef and billingEntityData
 */
export const CurrentBillPeriodSection = withErrorBoundary(() => {
  const { supplyNodeRef } = useAppSelector((store) => store.currentAccount);
  const { billingEntityData } = useAppSelector(
    (store) => store.billingEntityData,
  );

  const [latestValidDate, setLatestValidDate] = useState(null);

  const handleLatestValidDateUpdate = useCallback((date) => {
    setLatestValidDate(date);
  }, []);

  const ratingQueryDate = useMemo(
    () =>
      billingEntityData
        ? ratingQueryDateRange(billingEntityData)
        : { endAt: undefined, startAt: undefined },
    [billingEntityData],
  );

  const { periodName, duration } = useMemo(
    () =>
      billingEntityData
        ? AVAILABLE_PERIODS[billingEntityData.nominated_billing_period]
        : { periodName: undefined, duration: undefined },
    [billingEntityData],
  );

  const formattedPeriodEndDate = useMemo(
    () =>
      ratingQueryDate.endAt
        ? format(
            toZonedTime(ratingQueryDate.endAt, "Pacific/Auckland"),
            "EEE d MMM",
          )
        : "",
    [ratingQueryDate.endAt],
  );

  // if todays date is before the end of the current invoice period then return the number of days until it ends, otherwise the next invoice period isnt ready to start yet so return 0
  const daysRemaining = useMemo(() => {
    return isBefore(new Date(), ratingQueryDate.endAt)
      ? differenceInDays(ratingQueryDate.endAt, new Date())
      : 0;
  }, [ratingQueryDate.endAt]);

  const progressPercentage = useMemo(
    () => (duration ? ((duration - daysRemaining) / duration) * 100 : 0),
    [duration, daysRemaining],
  );

  const {
    data: aggregatesData,
    isFetching: isAggregatesStatusFetching,
    isError: isAggregateError,
    error: aggregateError,
  } = useGetAggregateRatesQuery(
    {
      supplyNodeRef,
      startAt: ratingQueryDate.startAt,
      endAt: ratingQueryDate.endAt,
      jwtClient,
      source: "Current Bill Section",
    },
    {
      skip:
        !billingEntityData ||
        !supplyNodeRef ||
        !ratingQueryDate.startAt ||
        !ratingQueryDate.endAt ||
        // query start date must not before user's start date else it will invalid request and expire token
        isBefore(ratingQueryDate.startAt, new Date(billingEntityData.start_at)),
      refetchOnMountOrArgChange: expireTime,
    },
  );

  if (!aggregatesData || isAggregatesStatusFetching) {
    return <LoadingSection />;
  }

  if (isAggregateError) throw aggregateError;

  if (!aggregatesData.aggregates || aggregatesData.aggregates.length === 0) {
    return (
      <CurrentBillSpendUnavailable
        periodName={periodName}
        periodEnd={formattedPeriodEndDate}
        daysRemaining={daysRemaining}
        progressPercentage={progressPercentage}
      />
    );
  }

  return (
    <>
      <p>
        For the {periodName} ending {formattedPeriodEndDate}
      </p>
      {aggregatesData && aggregatesData.aggregates.length > 0 && (
        <CurrentBillPeriodHeading
          aggregatesData={aggregatesData}
          billingEntityData={billingEntityData}
          ratingQueryDate={ratingQueryDate}
          onLatestValidDateUpdate={handleLatestValidDateUpdate}
          supplyNodeRef={supplyNodeRef}
          duration={duration}
          periodName={periodName}
        />
      )}
      {progressPercentage && (
        <div className="progress">
          <div
            className="progress__bar"
            style={{ width: `${progressPercentage}%` }}
          >
            {" "}
            {daysRemaining} days to go
          </div>
        </div>
      )}
      {latestValidDate && (
        <small className="latest-valid-date text__colour--disabled">
          as at{" "}
          {format(
            toZonedTime(latestValidDate, "Pacific/Auckland"),
            "EEE d MMM",
          )}
        </small>
      )}
      {billingEntityData && periodName && (
        <section className="customer_tools_section">
          <article className="customer_tools_section__link_container bill_period_usage">
            <Link
              to={`/usage?periodDuration=${billingEntityData.nominated_billing_period}`}
              className="customer_tools_section__link bill_period_usage__link bill_period_usage__link--with-icon"
              state={{ previousPath: window.location.pathname }}
            >
              <strong>Your usage this {periodName}</strong>
              <ForwardIcon />
            </Link>
          </article>
        </section>
      )}
    </>
  );
});
