import { Button, SecondaryButton } from "@flick/fep-library";
import { Dialog } from "components/Dialog";
import React from "react";
import { BILL_SMOOTHER_STATE } from "utils/constants";

type PauseBillSmootherDialogProps = {
  billSmootherData: BillSmootherConfig;
  handleClick: (
    event:
      | React.MouseEvent<HTMLButtonElement>
      | React.FormEvent<HTMLFormElement>,
    data: {
      active?: boolean;
      enrolled?: boolean;
      createData?: BillSmootherConfig;
    },
    newBannerText: string,
  ) => void;
  handleDialogClose: () => void;
  hidePauseBillSmootherDialog: boolean;
};

/**
 * @description used in BillSmootherSettingPage
 */
export const PauseBillSmootherDialog: React.FC<
  PauseBillSmootherDialogProps
> = ({
  hidePauseBillSmootherDialog,
  handleDialogClose,
  handleClick,
  billSmootherData,
}) => (
  <Dialog
    handleDialogClose={handleDialogClose}
    dialogIsHidden={hidePauseBillSmootherDialog}
  >
    <h1 id="dialog-title" className="dialog_heading">
      Pause payments into Bill Smoother?
    </h1>
    <p>Want to pause your Bill Smoother contributions?</p>
    <p>
      Pressing pause will delete your Bill Smoother 'minimum payment amount',
      so:
    </p>
    <ul>
      <li>
        Your bills won't be smoothed and you'll pay the actual cost of your
        bill.
      </li>
      <li>You won't be topping up your Bill Smoother credit.</li>
      <li>
        We'll continue to use your current Bill Smoother credit for power bills
        over your trigger amount until it's used up.
      </li>
    </ul>
    <p>
      Pausing your Bill Smoother might not take effect immediately - if your
      next billing cycle is the following Tuesday, you'll need to pause Bill
      Smoother <strong>before Sunday</strong> for it to take effect on your
      upcoming bill.
    </p>
    <p>
      You can start Bill Smoother up again at any time by entering in a 'minimum
      payment amount'.
    </p>
    <div className="dialog_contents__buttons spacer--medium">
      <Button
        buttonSize="large"
        buttonColor="outline"
        type="submit"
        onClick={(event) =>
          handleClick(
            event,
            { active: !billSmootherData.active },
            !billSmootherData.active
              ? BILL_SMOOTHER_STATE.activated
              : BILL_SMOOTHER_STATE.paused,
          )
        }
      >
        Pause payments
      </Button>
      <div className="spacer--small" />
      <SecondaryButton id="close-cancel" onClick={handleDialogClose}>
        Cancel
      </SecondaryButton>
    </div>
  </Dialog>
);
