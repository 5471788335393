import "styles/BillPage.scss";

import { Card, CardBody, CardHeader } from "@flick/fep-library";
import { AggregatedUsageSection } from "components/AggregatedUsageSection";
import { BillBreakdownSection } from "components/BillBreakdownSection";
import { BillOffpeakUsageSection } from "components/BillOffpeakUsageSection";
import { BillPDFSection } from "components/BillPDFSection";
import { BillUsageSection } from "components/BillUsageSection/BillUsageSection";
import { CurrentPlanPanel } from "components/CurrentPlanPanel";
import { InlineBanner } from "components/InlineBanner";
import { OtherCostsSection } from "components/OtherCostsSection";
import { WholesalePriceSectionWrapper } from "components/WholesalePriceSection/WholesalePriceSectionWrapper";
import { format } from "date-fns/format";
import { ErrorPage } from "pages/ErrorPage";
import { LoadingPage } from "pages/LoadingPage";
import {
  billingApi,
  useGetInvoiceQuery,
  useLazyPayOverdueBillQuery,
} from "queries/billingApi";
import { useGetContractForInvoiceQuery } from "queries/contractManagerApi";
import { expireTime } from "queries/queries.utils";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { setNotificationBanner } from "reducers/notificationBanner";
import { useAppDispatch, useAppSelector } from "reduxUtils/hook";
import {
  AVAILABLE_PERIODS,
  INVOICED_PAYMENT_STATUSES,
  NotificationBannerNames,
  NotificationBannerTypes,
  PRODUCT_DISPLAY_NAMES,
  PRODUCT_NAMES,
} from "utils/constants";
import { NZD } from "utils/currency";
import { friendlyPaymentMethod } from "utils/friendlyPaymentMethod";
import { visitLink } from "utils/htmlLinks/visitLink";
import { jwtClient } from "utils/jwtClient";
import { getLineItemGroups } from "utils/lineItems/lineItems";
import { normaliseProductName } from "utils/productNameUtils";

const friendlyPaymentStatusDetails = (
  bill: Bill,
): { verb: string; type: string } => {
  const paymentDatePassed = new Date() > new Date(bill.payment_due_date);
  const statusDetails = { verb: "Due", type: "tag--warning" };

  if (bill.payment_status === INVOICED_PAYMENT_STATUSES.complete) {
    statusDetails.verb = "Paid";
    statusDetails.type = "tag--success";
  } else if (
    paymentDatePassed &&
    bill.payment_status === INVOICED_PAYMENT_STATUSES.pastDue
  ) {
    statusDetails.verb = "Overdue";
    statusDetails.type = "tag--error";
  }
  return statusDetails;
};

/**
 * Render a banner allowing 'pay now' of overdue bill
 * or informing user of the results of 'pay now' attempt
 */
const renderInlineBanner = ({
  handlePayNow,
  isOverdue,
  windcaveNavigatedBack,
  windcaveSuccess,
}): JSX.Element => {
  if (windcaveSuccess === "true") {
    return <InlineBanner status="success" />;
  }

  if (windcaveNavigatedBack || windcaveSuccess === "false") {
    return (
      <InlineBanner
        status={"failedPayment"}
        onHandlePayNowClick={handlePayNow}
      />
    );
  }

  if (isOverdue) {
    return <InlineBanner status="notice" onHandlePayNowClick={handlePayNow} />;
  }

  return null;
};

export const BillPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { invoiceNumber } = useParams();
  const [searchParams] = useSearchParams();
  const [windcaveNavigatedBack, setWindcaveNavigatedBack] = useState(false);
  const { customerNumber, nativeRenderMode, supplyNodeRef, active } =
    useAppSelector((store) => store.currentAccount);
  const windcaveSuccess = searchParams.get("success") ?? undefined;
  const [triggerOverdueBillQuery] = useLazyPayOverdueBillQuery();

  useEffect(() => {
    if (window.sessionStorage.getItem("windcaveVisited")) {
      if (!windcaveSuccess) {
        setWindcaveNavigatedBack(true);
      }
      window.sessionStorage.removeItem("windcaveVisited");
    }
  }, [window.sessionStorage.getItem("windcaveVisited")]);

  /**
   * This checks that if invoiceNumber is passed "null"  - a string,
   * (so it is not filtered out by the filteredQueryParams function)
   * then it will instead navigate back to bills and show an error due to not having an invoice number
   */
  if (invoiceNumber === "null") {
    navigate("/bills");
    dispatch(
      setNotificationBanner({
        type: NotificationBannerTypes.error,
        name: NotificationBannerNames.billInvoice,
        page: "/bills",
      }),
    );
  }

  const {
    data: billData,
    isError: isBillError,
    isLoading: isBillLoading,
    error: billError,
    refetch: refetchBillData,
  } = useGetInvoiceQuery(
    { invoiceNumber, jwtClient },
    {
      skip: !invoiceNumber,
      refetchOnMountOrArgChange: expireTime,
    },
  );

  useEffect(() => {
    if (!windcaveSuccess) return;
    /**
     * if bill is paid successfully,
     * in mobile we need to force refetch bill status due to page is not remount
     * we bust the caching of outstanding invoices and refetch it
     * this is for outstanding invoices display of homepage
     * we do not to do this in webapp because Windcave redirect trigger whole app remount
     * by using window.location
     */
    if (nativeRenderMode) {
      refetchBillData();
      dispatch(billingApi.util.invalidateTags(["OutstandingInvoices"]));
    }
  }, [windcaveSuccess]);

  const bill = billData?.bill;
  const invoiceStart = new Date(bill?.billing_period.period_started_at);
  const invoiceEnd = new Date(bill?.billing_period.period_ended_at);

  const {
    data: contractData,
    isLoading: isContractLoading,
    isError: isContractError,
    error: contractError,
  } = useGetContractForInvoiceQuery(
    {
      jwtClient,
      asAtDate: invoiceEnd,
      customerNumber,
      supplyNodeRef,
    },
    {
      skip: !billData || !supplyNodeRef,
      refetchOnMountOrArgChange: expireTime,
    },
  );

  if (isBillLoading || isContractLoading) return <LoadingPage />;

  /**
   * In RTK Query, the error returned from query or mutation hooks is typed as unknown.
   * This is because the error being returned can be in various formats depending on the API or the network request
   * Hence we checking the error type here before passing through
   */
  if (
    (isContractError || isBillError || !billData) &&
    contractError instanceof Error &&
    billError instanceof Error
  ) {
    return (
      <ErrorPage
        error={
          isBillError
            ? billError
            : !billData
              ? new Error(`Cannot find invoice #${invoiceNumber}`)
              : contractError
        }
      />
    );
  }

  const lineItemGroups = getLineItemGroups(billData);

  const normalisedProductName = normaliseProductName(contractData?.name);
  const paymentDueDate = new Date(bill.payment_due_date);

  const invoiceSpansNewYear =
    invoiceStart.getFullYear() !== invoiceEnd.getFullYear();
  const invoiceStartFormatted = format(
    invoiceStart,
    invoiceSpansNewYear ? "EEEE d MMMM yyyy" : "EEEE d MMMM",
  );

  const productIsOffpeak = normalisedProductName === PRODUCT_NAMES.offPeak;
  const productIsWholesale = normalisedProductName === PRODUCT_NAMES.wholesale;
  const hasPreviousInvoice = Boolean(bill.previous_invoice_id);
  const paymentStatusDetails = friendlyPaymentStatusDetails(bill);
  const { periodName } = AVAILABLE_PERIODS[bill.billing_period.duration];

  const handlePayNow = async () => {
    const response = await triggerOverdueBillQuery({
      invoiceID: billData.id,
      jwtClient,
      invoiceNumber,
    });

    const windCaveUrl = response.data.dps_uri;

    /**
     * in mobile, we create a hidden tempt link to open the url outside of the app
     * we also passing the set windcave visited item into local storage
     */
    if (windCaveUrl && nativeRenderMode) {
      visitLink({
        url: windCaveUrl,
        extraCallBack: () => {
          window.sessionStorage.setItem("windcaveVisited", windCaveUrl);
        },
      });
      return;
    }

    /**
     * in desktop we replace the windcave URL directly to the current session
     * and making sure windcaveVisited is only set when the page is about to unload
     * this prevents the flash of failed payment
     * which triggers setWindcaveNavigatedBack that is used in renderInlineBanner
     */
    if (windCaveUrl) {
      const setWindcaveVisited = () => {
        window.sessionStorage.setItem("windcaveVisited", windCaveUrl);
        // cleanup
        window.removeEventListener("beforeunload", setWindcaveVisited);
      };
      window.addEventListener("beforeunload", setWindcaveVisited);
      window.location.href = windCaveUrl;
    }
  };

  const isOverdue = paymentStatusDetails.verb === "Overdue";

  return (
    <div className="bill_period_page">
      <section className="page_section page_section--top">
        <section className="page_section__column page_section__column--1">
          <Card cardBorderStyle="shadow" cardPaddingType="compact">
            <CardHeader
              headingColor="black"
              headingRightContent={
                <p
                  data-testid="billSmootherTag"
                  className={`tag ${paymentStatusDetails.type}`}
                >
                  {paymentStatusDetails.verb}
                </p>
              }
            >
              <h2 className="h4">
                <p className="h4">Invoice #{bill.number} </p>
              </h2>
            </CardHeader>
            <CardBody>
              {renderInlineBanner({
                handlePayNow,
                isOverdue,
                windcaveNavigatedBack,
                windcaveSuccess,
              })}
              <p>
                {invoiceStartFormatted} –{" "}
                {format(invoiceEnd, "EEEE d MMMM yyyy")}
              </p>

              <h1 className="bill_total_due">
                {NZD.format(parseFloat(bill.total_payment_due.price))}
              </h1>

              <p className="text__secondary">
                {paymentStatusDetails.verb}{" "}
                {format(paymentDueDate, "EEEE d MMMM")}{" "}
                {friendlyPaymentMethod(bill.payment_method)}
              </p>
            </CardBody>
          </Card>
          <OtherCostsSection
            lineItemGroups={lineItemGroups}
            productName={normalisedProductName}
          />
          {normalisedProductName && (
            <>
              <span className="spacer spacer--large"></span>
              <CurrentPlanPanel
                name={PRODUCT_DISPLAY_NAMES[normalisedProductName]}
                description={contractData?.description}
              />
            </>
          )}
        </section>
        <section className="page_section__column page_section__column--2">
          <div className="bill_usage_section">
            <hr className="content_divider spacer--large" />
            <section className="page_section__column page_section__column--1">
              {bill && <BillUsageSection bill={bill} />}
            </section>
            <hr className="content_divider spacer--large" />
            <section className="page_section__column page_section__column--1">
              {productIsOffpeak && bill && (
                <BillOffpeakUsageSection bill={bill} />
              )}

              {productIsWholesale && bill && (
                <WholesalePriceSectionWrapper
                  bill={bill}
                  lineItemGroups={lineItemGroups}
                />
              )}
            </section>
          </div>
        </section>
      </section>
      <hr className="content_divider spacer--large" />
      <section className="page_section page_section--bottom page_section--reverse">
        <section className="page_section__column page_section__column--2">
          {active && supplyNodeRef && (
            <AggregatedUsageSection
              previousPeriodStartDate={
                new Date(bill.comparison_data.previous.started_at)
              }
              periodName={periodName}
              hasPreviousData={hasPreviousInvoice}
              productName={normalisedProductName}
              periodStartDate={invoiceStart}
              periodEndAt={invoiceEnd}
            />
          )}
        </section>
        <section className="page_section__column page_section__column--1">
          <BillBreakdownSection
            lineItemGroups={lineItemGroups}
            bill={bill}
            productName={normalisedProductName}
          />

          {billData.id && (
            <BillPDFSection
              invoiceUUID={billData.id}
              invoiceNumber={bill.number}
            />
          )}
        </section>
      </section>
    </div>
  );
};
