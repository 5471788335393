/**
 * @description calculate total usage change percentage between selected day and same day of the previous period
 * @param {DailyUsage} usageData
 * @returns {number | undefined} the calculated percentage change or undefined if usageData param or thisPeriod/lastPeriod consumption values are invalid
 */

export const calculateUsageChangePercentage = (
  usageData: DailyUsage,
): number | undefined => {
  if (
    !usageData ||
    !usageData.thisPeriod.consumption ||
    !usageData.lastPeriod.consumption
  )
    return undefined;
  return (
    100 *
    (usageData.thisPeriod.consumption / usageData.lastPeriod.consumption - 1)
  );
};
