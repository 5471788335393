/* eslint-disable class-methods-use-this */
import BillEstimate from '../models/bill-estimate';
import { LOW, STANDARD, HIGH_USAGE_THRESHOLD_NETWORKS } from '../utils/constants';

class BillEstimateService {
  constructor(apiBaseUrl) {
    this.apiBaseUrl = apiBaseUrl;
  }

  startAt() {
    // Get today date
    const today = new Date();

    /**
     * Add tomorrow day to the date start from mid night
     * example:
     * if today is  March 31 2023, midnight of today is April 01 2023 => +1 day,
     * nextday of today midnight is April 02 2023 => +1 day
     */
    const startDate = new Date(today);
    startDate.setDate(today.getDate() + 2);

    // Set the time to midnight
    startDate.setHours(0, 0, 0, 0);
    return startDate.toISOString();
  }

  endAt(days) {
    const today = new Date();

    /**
     * Add tomorrow day to the date started from mid night
     * example:
     * if today is  March 31 2023, midnight of today is April 01 2023 => +1 day,
     * nextday of today midnight is April 02 2023 => +1 day
     */
    const endDate = new Date(today);
    endDate.setDate(today.getDate() + 2);

    // Set end date
    endDate.setDate(endDate.getDate() + days - 1);

    endDate.setHours(23, 59, 59, 999);
    return endDate.toISOString();
  }

  billEstimatesEndpoint(icp, userType, locationClassification, billDetails) {
    let url = `${this.apiBaseUrl}/public_website/bill_estimate/${icp}?brand=flick`;
    if (locationClassification === 'Residential' && userType) {
      url += `&usage_type=${userType}&location_classification=residential`;
    }
    if (locationClassification !== 'Residential') {
      url += '&usage_type=standard&location_classification=commercial';
    }
    if (billDetails !== null) {
      url += `&consumption_over_period=${
        billDetails.usage
      }&start_at=${this.startAt()}&end_at=${this.endAt(billDetails.daysInBillingPeriod)}`;
    } else {
      url += `&start_at=${this.startAt()}&end_at=${this.endAt(30)}`;
    }
    return url;
  }

  calculateUserType(networkProvider, billDetails) {
    const { usage, daysInBillingPeriod } = billDetails;
    const annualUsage = (usage / daysInBillingPeriod) * 365;
    const hasSpecialUsageThreshold = HIGH_USAGE_THRESHOLD_NETWORKS.includes(networkProvider);

    if (annualUsage >= 9000 && hasSpecialUsageThreshold) return STANDARD;
    if (annualUsage >= 8000 && !hasSpecialUsageThreshold) return STANDARD;

    return LOW;
  }

  fetch(icp, userType, locationClassification, billDetails, networkProvider) {
    const estimatedUserType =
      billDetails !== null ? this.calculateUserType(networkProvider, billDetails) : userType;

    return fetch(
      this.billEstimatesEndpoint(icp, estimatedUserType, locationClassification, billDetails),
      {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw Error('There was a problem retrieving the bill estimates');
        }
        return response.json();
      })
      .then((billEstimatesResponse) =>
        billEstimatesResponse.data.map(
          (billEstimateResponse) => new BillEstimate(billEstimateResponse)
        )
      )
      .catch((Error) => {
        throw Error('There was a problem retrieving the bill estimates');
      });
  }
}

export default BillEstimateService;
