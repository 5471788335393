import Rates from './rates';

class BillEstimate {
  constructor(data) {
    if (data === undefined) {
      return;
    }

    const billEstimateData = data;
    this.consumptionPerDay = parseFloat(billEstimateData.attributes.consumption_per_day);
    this.currencyCode = billEstimateData.attributes.currency_code;
    this.days = parseFloat(billEstimateData.attributes.days);
    this.generationPriceContract = billEstimateData.attributes.generation_price_contract;
    this.product = billEstimateData.attributes.product_name.split(' ').join('_').toLowerCase();
    this.locationClassification = billEstimateData.attributes.location_classification;
    this.subscriptionChargesEstimate = parseFloat(
      billEstimateData.attributes.subscription_charges_estimate
    );
    this.totalEstimate = parseFloat(billEstimateData.attributes.total_estimate);
    this.usageChargesEstimate = parseFloat(billEstimateData.attributes.usage_charges_estimate);
    this.usageType = billEstimateData.attributes.usage_type;
    this.gst = 1.15;
    this.usageRates = new Rates({
      rates: billEstimateData.attributes.usage_rates,
      timetables: billEstimateData.attributes.timetables,
      type: 'usage',
    }).orderedMeterConfigurations();
    this.subscriptionRates = {
      rates: billEstimateData.attributes.subscription_rates,
      timetables: billEstimateData.attributes.timetables,
    };
  }
}

export default BillEstimate;
