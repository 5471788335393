import "./CloseAccountDialog.scss";

import { Button, SecondaryButton } from "@flick/fep-library";
import React from "react";

import content from "./static/content.json";
const { buttonCancel, buttonConfirm, description, heading } = content;

type CloseAccountDialogProps = {
  closeDialog: () => void;
  confirmCloseAccount: () => void;
};

export const CloseAccountDialog: React.FC<CloseAccountDialogProps> = ({
  closeDialog,
  confirmCloseAccount,
}) => {
  return (
    <div
      role="dialog"
      className="dialog dialog_large"
      aria-labelledby="dialog-title"
    >
      <div className="close_account_dialog__content spacer spacer--large">
        <header>
          <h3 id="dialog-title" className="dialog_heading">
            {heading}
          </h3>
        </header>
        <p>{description}</p>
        <div className="dialog_contents__buttons">
          <Button
            buttonSize="large"
            extraClasses="spacer"
            onClick={confirmCloseAccount}
            type="button"
          >
            {buttonConfirm}
          </Button>
          <SecondaryButton
            className="button-secondary spacer--small"
            onClick={closeDialog}
          >
            {buttonCancel}
          </SecondaryButton>
        </div>
      </div>
    </div>
  );
};
