import { createApi } from "@reduxjs/toolkit/query/react";
import { JwtClient } from "utils/jwtClient";

import { formattedUserDetails } from "./identity.utils";
import { expireTime } from "./queries.utils";

interface ApiCallParams {
  path: string;
  queryParams?: Record<string, string | number>;
}

interface BaseQueryParams extends ApiCallParams {
  jwtClient: JwtClient;
}

export type UpdatePasswordParams = {
  jwtClient: JwtClient;
  userId: string;
  params: {
    confirmPassword: string;
    currentPassword: string;
    newPassword: string;
  };
};

export type UpdateUserDetailsParams = {
  email?: string;
  jwtClient: JwtClient;
  preferredName?: string;
  userId: string;
};

/**
 * Define a base query function that calls jwtClient.apiCall
 */
const apiCallBaseQuery = async ({ jwtClient, ...params }: BaseQueryParams) => {
  const response = await jwtClient.apiCall(params);
  const result = await response.json();
  return result;
};

/**
 * Define the identityApi slice
 */
export const identityApi = createApi({
  reducerPath: "identityApi",
  baseQuery: apiCallBaseQuery,
  endpoints: (builder) => ({
    getUserDetails: builder.query<
      UserDetails,
      { userId: string; jwtClient: JwtClient }
    >({
      query: ({ jwtClient, userId }) => {
        return {
          jwtClient: jwtClient,
          path: "/identity/user_details",
          queryParams: {
            id: userId,
          },
        };
      },
      transformResponse: (res) => {
        return formattedUserDetails({ data: res });
      },
    }),
    updatePassword: builder.mutation<void, UpdatePasswordParams>({
      query: ({
        jwtClient,
        userId,
        params: { currentPassword, newPassword, confirmPassword },
      }) => {
        return {
          jwtClient,
          path: `/identity/passwords`,
          queryParams: {
            id: userId,
            current_password: currentPassword,
            password: newPassword,
            password_confirmation: confirmPassword,
          },
          fetchParams: {
            method: "PUT",
          },
        };
      },
    }),
    updateUserDetails: builder.mutation<
      UpdateUserResponse,
      UpdateUserDetailsParams
    >({
      query: ({ email, jwtClient, preferredName, userId }) => {
        return {
          jwtClient,
          path: `/identity/user_details`,
          queryParams: {
            ...(email && { email }),
            id: userId,
            ...(preferredName && { preferred_name: preferredName }),
          },
          fetchParams: {
            method: "PUT",
          },
        };
      },
    }),
  }),
  keepUnusedDataFor: expireTime,
});

export const {
  useGetUserDetailsQuery,
  useUpdatePasswordMutation,
  useUpdateUserDetailsMutation,
} = identityApi;
