/**
 * This method capitalizes the first letter of a given string
 */
export function capitalize(string: string): string {
  return string[0].toUpperCase() + string.substring(1);
}

/**
 *
 * @description This function returns replaces all non alpha-numeric and space characters with an empty string
 */
export const alphaNumericAndSpaceRegex = (value: string): string => {
  return value.replace(/[^a-zA-Z0-9 ]/g, "");
};
