import "./NewPaymentMethodPage.scss";

import { AccountPageWrapper } from "components/AccountPageWrapper";
import { SingleSelectRadioGroup } from "components/SingleSelectRadioGroup";
import { CreditCardPaymentForm } from "pages/NewPaymentMethodPage/CreditCardPaymentForm";
import { DirectDebitPaymentForm } from "pages/NewPaymentMethodPage/DirectDebitPaymentForm";
import { useNewPaymentMethodFormContext } from "pages/NewPaymentMethodPage/NewPaymentMethodPageContext";
import React from "react";

import { MultiplePersonDirectDebit } from "./MultiplePersonDirectDebit";
import content from "./static/content.json";
const {
  cardMethod,
  directDebitMethod,
  multiplePeopleMethod,
  pageHeader,
  paymentMethodsLabel,
} = content;

const paymentMethodOptions = [
  {
    label: directDebitMethod,
    value: "directDebit",
    children: <DirectDebitPaymentForm />,
  },
  {
    label: multiplePeopleMethod,
    value: "multipleDebit",
    children: <MultiplePersonDirectDebit />,
  },
];

export const NewPaymentMethodPageContent: React.FC = () => {
  if (paymentMethodOptions.length === 2) {
    paymentMethodOptions.push({
      label: cardMethod,
      value: "cardDebit",
      children: <CreditCardPaymentForm />,
    });
  }

  const {
    paymentMethod: {
      fieldValue: { value: paymentMethodValue },
      handleOnChange: handleOnChangePaymentMethod,
    },
  } = useNewPaymentMethodFormContext();

  const handleOnChangeMethod = (value: string) => {
    handleOnChangePaymentMethod(value);
  };

  return (
    <div className="new-payment-method-page">
      <AccountPageWrapper hasCancelButton headerLabel={pageHeader}>
        <div className="spacer spacer--large">
          <SingleSelectRadioGroup
            handleOnChange={handleOnChangeMethod}
            label={paymentMethodsLabel}
            name="payment-method"
            options={paymentMethodOptions}
            valueSelect={paymentMethodValue}
          />
        </div>
      </AccountPageWrapper>
    </div>
  );
};
