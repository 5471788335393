import React from 'react';
import PropTypes from 'prop-types';

const IcpNotFound = ({ continueJoiningWithoutIcp, enableICPSearch }) => (
  <div className="address_bar_content address_bar_content_large">
    <h4 className="heading address_bar_title">
      Hmmm, we can’t identify the meter at that address.
    </h4>
    {enableICPSearch && (
      <p>
        If you’ve got a power bill or your meter nearby, you can enter your{' '}
        <a href="#icp" className="link address_bar_link js-enterIcpManually">
          ICP number
        </a>{' '}
        and get back underway.
      </p>
    )}
    <p>
      You can still{' '}
      <a href="#continue" className="link address_bar_link" onClick={continueJoiningWithoutIcp}>
        continue joining,
      </a>{' '}
      though there’s a chance we won’t be able to supply your address. We’ll give you a call after
      you submit your application to gather more information.
    </p>
  </div>
);

IcpNotFound.propTypes = {
  continueJoiningWithoutIcp: PropTypes.func.isRequired,
  enableICPSearch: PropTypes.bool,
};

IcpNotFound.defaultProps = {
  enableICPSearch: true,
};

export default IcpNotFound;
