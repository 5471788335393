import { IndividualDayDetail } from "components/IndividualDayDetail";
import { UsageByDayChart } from "components/UsageByDayChart";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { withErrorBoundary } from "../fallback/withErrorBoundary";

type DailyUsageSectionProps = {
  dailyUsageData: DailyUsage[];
  hasPreviousData: boolean;
  isCustomDatePeriod?: boolean;
  periodName: string;
  previousPeriodStartDate: Date;
  productName?: string;
  sectionSubHeading?: string;
};

/**
 * @description used in AggregatedUsageSection and HomePage
 * daily usage data cannot be empty array
 */
export const DailyUsageSection: React.FC<DailyUsageSectionProps> = ({
  dailyUsageData,
  hasPreviousData,
  isCustomDatePeriod,
  periodName,
  previousPeriodStartDate,
  productName,
  sectionSubHeading,
}) => {
  const [selectedDay, setSelectedDay] = useState(0);
  const maxDayDataIndex = dailyUsageData.length - 1;

  const selectedDayData = useMemo(() => {
    if (selectedDay < dailyUsageData.length) {
      return dailyUsageData[selectedDay];
    }
    return null;
  }, [selectedDay, dailyUsageData]);

  const handleSelectedDay = useCallback((value: number) => {
    if (value < 0) return;
    setSelectedDay(value);
  }, []);

  useEffect(() => {
    if (selectedDay > maxDayDataIndex) {
      setSelectedDay(0);
    }
  }, [selectedDay, maxDayDataIndex]);

  return (
    <>
      {sectionSubHeading && <p>{sectionSubHeading}</p>}
      <UsageByDayChart
        dailyUsageData={dailyUsageData}
        setSelectedDay={handleSelectedDay}
        periodName={periodName}
        isCustomDatePeriod={isCustomDatePeriod}
      />
      <IndividualDayDetail
        previousPeriodStartDate={previousPeriodStartDate}
        hasPreviousData={hasPreviousData}
        productName={productName ? productName : ""}
        usageData={selectedDayData}
        selectedDayIndex={selectedDay}
        maxDayDataIndex={maxDayDataIndex}
        setSelectedDay={setSelectedDay}
        periodName={periodName}
      />
    </>
  );
};

export const DailyUsageSectionWrapped = withErrorBoundary(DailyUsageSection);
