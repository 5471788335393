/* eslint-disable jsx-a11y/anchor-has-content */
import { BaseInput, Button, Form } from "@flick/fep-library";
import { format } from "date-fns";
import InputLabel from "front-end-patterns-temp/js/forms/form_fields_label";
import FormValidator from "front-end-patterns-temp/js/forms/form_validator";
import { useLazyGetUsageDataCSVQuery } from "queries/telemetryApi";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useAppSelector } from "reduxUtils/hook";
import { jwtClient } from "utils/jwtClient";
import { sendAnalyticsEvent } from "utils/sendAnalyticsEvent";

type CsvDownloaderProps = {
  billingEntityStartAt: Date | string;
  billingEntityEndAt: Date | string;
  currentPeriodEndDate: Date | string;
  viewport: "desktop" | "mobile";
};

/**
 * @description CSV download component
 */
export const CsvDownloader: React.FC<CsvDownloaderProps> = ({
  viewport,
  billingEntityStartAt,
  billingEntityEndAt,
  currentPeriodEndDate,
}) => {
  const { icpNumber } = useAppSelector((store) => store.currentAccount);

  useEffect(() => {
    new FormValidator().start();
    new InputLabel().start();
  }, []);

  const dataAvailableEndAt = billingEntityEndAt
    ? new Date(billingEntityEndAt)
    : new Date(currentPeriodEndDate);

  const billingEntityStartDate = billingEntityStartAt
    ? new Date(billingEntityStartAt)
    : new Date();

  const [csvDates, setCsvDates] = useState({
    startAt: null,
    endAt: null,
  });

  useEffect(() => {
    setCsvDates({
      ...csvDates,
      endAt: billingEntityEndAt
        ? format(new Date(billingEntityEndAt), "yyyy-MM-dd")
        : null,
    });
  }, [billingEntityEndAt]);

  const [trigger, { isFetching: fetchingCsvData }] =
    useLazyGetUsageDataCSVQuery();

  const handleClick = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!event.currentTarget.checkValidity()) return;

    sendAnalyticsEvent("download_usage_csv", {
      icpNumber,
      csv_start_date: csvDates.startAt,
      csv_end_date: csvDates.endAt,
    });
    const response = await trigger({
      jwtClient,
      icpNumber,
      startAt: csvDates.startAt,
      endAt: csvDates.endAt,
    });
    const blob = new Blob([response.data], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    // To perform a download in JS we need to create a download link to interact with.
    const link = document.createElement("a");
    link.style.display = "none";
    link.setAttribute("href", url);
    link.setAttribute("download", `${csvDates.startAt}_${csvDates.endAt}`);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);
  };

  const handleChange = (event: ChangeEvent) => {
    const { id, value } = event.target as HTMLInputElement;
    // clearing a date in the datepicker sets the value to an empty string which causes an error when rendering, we need to be manually reset it here
    const checkedValue = Boolean(value) ? value : null;

    setCsvDates({
      ...csvDates,
      [id]: checkedValue,
    });
  };

  const endDateOverflowMessage = `End date cannot be later than ${format(
    dataAvailableEndAt,
    "dd-MM-yyyy",
  )}`;

  const endDateUnderflowMessage = `End date needs to be after the start date.`;

  const startDateUndeflowMessage = `Start date cannot be earlier than ${format(
    billingEntityStartDate,
    "dd-MM-yyyy",
  )}`;

  const startDateOverflowMessage = `Start date cannot be later than ${format(
    dataAvailableEndAt,
    "yyyy-MM-dd",
  )}`;

  return (
    <section
      className={`page_section__column csv_download--${viewport} spacer--large`}
    >
      <h2 className="section-title heading h4">Your Usage Data</h2>
      <p className="section-sub-title">
        Download your half-hour usage information
      </p>
      <Form data-validate data-synthetic-submit onSubmit={handleClick}>
        {/* including class `form-row` here so that the validation in flick_form.js works - todo: remove when the temp front-end-patterns js files are gone */}
        <div className="form_row usage_page__start-and-end-inputs spacer--small">
          <div>
            <BaseInput
              data-msg-range-overflow={startDateOverflowMessage}
              data-msg-range-underflow={startDateUndeflowMessage}
              data-msg-value-missing="Please enter a start date."
              inputWidth="full"
              labelText="Start date"
              max={format(dataAvailableEndAt, "yyyy-MM-dd")}
              min={format(billingEntityStartDate, "yyyy-MM-dd")}
              name="startAt"
              onChange={handleChange}
              required
              type="date"
            />
          </div>
          <div className="spacer--medium">
            <BaseInput
              data-msg-range-overflow={endDateOverflowMessage}
              data-msg-range-underflow={endDateUnderflowMessage}
              data-msg-value-missing="Please enter an end date."
              defaultValue={csvDates.endAt}
              inputWidth="full"
              labelText="End date"
              max={format(dataAvailableEndAt, "yyyy-MM-dd")}
              min={format(new Date(csvDates.startAt), "yyyy-MM-dd")}
              name="endAt"
              onChange={handleChange}
              required
              type="date"
            />
          </div>
        </div>
        <Button
          type="submit"
          extraClasses="button--expanded"
          disabled={fetchingCsvData}
        >
          Download
        </Button>
        <p className="small spacer">
          We have data available for you between{" "}
          {format(billingEntityStartDate, "dd/MM/yyyy")} and{" "}
          {format(dataAvailableEndAt, "dd/MM/yyyy")}.
        </p>
      </Form>
    </section>
  );
};
