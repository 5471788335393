/* eslint-disable jsx-a11y/anchor-is-valid */
// (it has to be an <a> tag for the FEP styling to work)
import { Button } from "@flick/fep-library";
import { useLazyGetInvoiceResourceUrlsQuery } from "queries/billingApi";
import React from "react";
import { useAppSelector } from "reduxUtils/hook";
import { downloadLink } from "utils/htmlLinks/downloadLink";
import { jwtClient } from "utils/jwtClient";
import { sendAnalyticsEvent } from "utils/sendAnalyticsEvent";

type BillPDFSectionProps = {
  invoiceNumber: number;
  invoiceUUID: string;
};

export const BillPDFSection: React.FC<BillPDFSectionProps> = ({
  invoiceUUID,
  invoiceNumber,
}) => {
  const { icpNumber } = useAppSelector((store) => store.currentAccount);

  const [trigger, { isFetching: fetchingBillURL }] =
    useLazyGetInvoiceResourceUrlsQuery();

  const downloadPDF = async (event: React.MouseEvent) => {
    event.preventDefault();
    sendAnalyticsEvent("download_bill", {
      icpNumber,
      invoice_number: invoiceNumber,
    });
    const response = await trigger({
      jwtClient,
      invoiceUUID,
    });
    downloadLink({
      url: response.data.resource_urls.s3_object_url,
      id: `Flick-Electric-invoice-${invoiceUUID}`,
    });
  };

  return (
    <Button
      disabled={fetchingBillURL}
      extraClasses="button--expanded"
      onClick={downloadPDF}
    >
      Download Bill PDF
    </Button>
  );
};
