import { Form, FormRow } from "@flick/fep-library";
import { AccountPageWrapper } from "components/AccountPageWrapper";
import { LoadingSection } from "components/LoadingSection";
import { errorReasonDetails } from "components/NotificationBanner/utils/errorReasons";
import { PasswordInput } from "components/PasswordInput";
import { SubmitButton } from "components/SubmitButton";
import { useUpdatePasswordMutation } from "queries/identityApi";
import React, { FormEvent } from "react";
import { useLocation } from "react-router-dom";
import { setNotificationBanner } from "reducers/notificationBanner";
import { useAppDispatch, useAppSelector } from "reduxUtils/hook";
import {
  NotificationBannerNames,
  NotificationBannerTypes,
} from "utils/constants";
import { jwtClient } from "utils/jwtClient";

import content from "./static/content.json";
import { useUpdatePasswordFormContext } from "./UpdatePasswordPageContext";

const {
  confirmNewHint,
  confirmNewLabel,
  currentPasswordHint,
  currentPasswordLabel,
  infoHeader,
  infoParagraph,
  newPasswordLabel,
  submitLabel,
} = content;

export const UpdatePasswordPageContent: React.FC = () => {
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();

  const { accountNumber } = useAppSelector((store) => store.currentAccount);
  const { preferredName, userSub } = useAppSelector(
    (store) => store.accountDetails,
  );

  const [updatePassword, { isLoading }] = useUpdatePasswordMutation();

  const handleUpdatePassword = async ({
    currentUserSub,
    currentPassword,
    newPassword,
    confirmPassword,
  }) => {
    try {
      await updatePassword({
        jwtClient,
        userId: currentUserSub,
        params: {
          currentPassword,
          newPassword,
          confirmPassword,
        },
      }).unwrap();
      dispatch(
        setNotificationBanner({
          type: NotificationBannerTypes.success,
          name: NotificationBannerNames.updatePassword,
        }),
      );
      window.sessionStorage.removeItem("accountOverrideNumber");
      jwtClient.clearJwt();
    } catch (err) {
      dispatch(
        setNotificationBanner({
          type: NotificationBannerTypes.error,
          name: NotificationBannerNames.updatePassword,
          page: pathname,
          additionalContent: errorReasonDetails(err),
        }),
      );
    }
  };

  const {
    isFormValid,
    currentPassword: {
      fieldValue: { value: currentPasswordValue, error: currentPasswordError },
      handleOnBlur: handleBlurCurrentPassword,
      handleOnChange: setCurrentPassword,
    },
    newPassword: {
      fieldValue: { value: newPasswordValue, error: newPasswordError },
      handleOnBlur: handleBlurNewPassword,
      handleOnChange: setNewPassword,
    },
    confirmPassword: {
      fieldValue: { value: confirmPasswordValue, error: confirmPasswordError },
      handleOnBlur: handleBlurConfirmPassword,
      handleOnChange: setConfirmPassword,
    },
  } = useUpdatePasswordFormContext();

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    handleUpdatePassword({
      currentUserSub: userSub,
      currentPassword: currentPasswordValue,
      newPassword: newPasswordValue,
      confirmPassword: confirmPasswordValue,
    });
  };

  if (!preferredName) return <LoadingSection />;

  return (
    <AccountPageWrapper
      headerLabel="Update Password"
      headerItems={[
        { key: preferredName, value: null },
        { key: "Account number", value: accountNumber },
      ]}
    >
      <div className="notification_banner notification_notice icon">
        <p className="heading">{infoHeader}</p>
        <p>{infoParagraph}</p>
      </div>
      <Form onSubmit={handleSubmit}>
        <FormRow>
          <div className="spacer--large">
            <PasswordInput
              errorMessage={currentPasswordError}
              handleOnBlur={handleBlurCurrentPassword}
              hint={currentPasswordHint}
              inputWidth="full"
              label={currentPasswordLabel}
              name="current-password"
              setValue={setCurrentPassword}
              value={currentPasswordValue}
            />
          </div>
        </FormRow>
        <FormRow>
          <div className="spacer--large">
            <PasswordInput
              errorMessage={newPasswordError}
              handleOnBlur={handleBlurNewPassword}
              inputWidth="full"
              label={newPasswordLabel}
              name="new-password"
              setValue={setNewPassword}
              value={newPasswordValue}
            />
          </div>
        </FormRow>
        <FormRow>
          <div className="spacer--large">
            <PasswordInput
              errorMessage={confirmPasswordError}
              handleOnBlur={handleBlurConfirmPassword}
              hint={confirmNewHint}
              inputWidth="full"
              label={confirmNewLabel}
              name="confirm-password"
              setValue={setConfirmPassword}
              value={confirmPasswordValue}
            />
          </div>
        </FormRow>
        <SubmitButton
          isSubmitDisabled={!isFormValid || isLoading}
          label={submitLabel}
        />
      </Form>
    </AccountPageWrapper>
  );
};
