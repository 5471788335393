import "../styles/UsagePage.scss";

import { AggregatedUsageSection } from "components/AggregatedUsageSection";
import { CustomPeriodSelector } from "components/CustomPeriodSelector";
import {
  BoughtSection,
  HomeGenerationSection,
  SoldSection,
} from "components/HomeGenerationCharts";
import { LoadingSection } from "components/LoadingSection";
import { PeriodToggle } from "components/PeriodToggle";
import { SpendSection } from "components/SpendSection";
import { CsvDownloader } from "components/Usage/CsvDownloader";
import { NoAvailableData } from "components/Usage/NoAvailableData";
import { UsageClosedAccountDefault } from "components/Usage/UsageClosedAccountDefault";
import { UsageSection } from "components/UsageSection";
import { endOfDay, format, isBefore, startOfDay, subYears } from "date-fns";
import { expireTime } from "queries/queries.utils";
import { useGetAggregateRatesQuery } from "queries/ratingApi";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useAppSelector } from "reduxUtils/hook";
import {
  calculateCurrentPeriodDates,
  calculatePreviousPeriodDates,
  dateUsageDataAvailableFrom,
  previousDataAvailable,
  yearAgoDataAvailable,
} from "utils/aggregateCalculations/calculatePeriodDates";
import {
  AVAILABLE_PERIODS,
  PERIOD_CODES,
  PRODUCT_DISPLAY_NAMES,
} from "utils/constants";
import { jwtClient } from "utils/jwtClient";

export const UsagePage = (): JSX.Element => {
  const { supplyNodeRef, nativeRenderMode, active, accountNumber } =
    useAppSelector((store) => store.currentAccount);
  const currentProduct = useAppSelector((store) => store.currentProduct);
  const { billingEntityData } = useAppSelector(
    (store) => store.billingEntityData,
  );
  const [searchParams] = useSearchParams();
  const periodDuration = searchParams.get("periodDuration");

  const [selectedPeriod, setSelectedPeriod] = useState(
    AVAILABLE_PERIODS[periodDuration || "P7D"],
  );
  const [currentPeriod, setCurrentPeriod] = useState(
    calculateCurrentPeriodDates(selectedPeriod.duration),
  );
  const [previousPeriod, setPreviousPeriod] = useState(
    calculatePreviousPeriodDates(
      currentPeriod.startDate,
      selectedPeriod.duration,
    ),
  );
  const [yearAgoPeriod, setYearAgoPeriod] = useState({
    startDate: subYears(currentPeriod.startDate, 1),
    endDate: subYears(currentPeriod.endDate, 1),
  });

  const setCustomPeriodDates = ({ startDate, endDate }) => {
    setCurrentPeriod({
      startDate: startOfDay(startDate),
      endDate: endOfDay(endDate),
    });
    setPreviousPeriod({ startDate: null, endDate: null });
    setYearAgoPeriod({ startDate: null, endDate: null });
  };

  useEffect(() => {
    const newCurrentPeriod = calculateCurrentPeriodDates(
      selectedPeriod.duration,
    );
    setCurrentPeriod(newCurrentPeriod);
    setPreviousPeriod(
      calculatePreviousPeriodDates(
        newCurrentPeriod.startDate,
        selectedPeriod.duration,
      ),
    );
    setYearAgoPeriod({
      startDate: subYears(newCurrentPeriod.startDate, 1),
      endDate: subYears(newCurrentPeriod.endDate, 1),
    });
  }, [selectedPeriod]);

  const {
    data: currentPeriodAggregatesData,
    isFetching: isCurrentPeriodAggregatesFetching,
    isSuccess: isCurrentPeriodAggregatesSuccess,
  } = useGetAggregateRatesQuery(
    {
      supplyNodeRef,
      startAt: currentPeriod.startDate,
      endAt: currentPeriod.endDate,
      jwtClient,
      source: "Usage Page - Current Period",
    },
    {
      skip:
        !supplyNodeRef ||
        !billingEntityData ||
        !currentPeriod.startDate ||
        !currentPeriod.endDate ||
        isBefore(currentPeriod.startDate, new Date(billingEntityData.start_at)),
      refetchOnMountOrArgChange: expireTime,
    },
  );

  const {
    data: previousPeriodAggregatesData,
    isSuccess: isPreviousPeriodAggregatesSuccess,
  } = useGetAggregateRatesQuery(
    {
      supplyNodeRef,
      startAt: previousPeriod.startDate,
      endAt: previousPeriod.endDate,
      jwtClient,
      source: "Usage Page - Previous Period",
    },
    {
      skip:
        !supplyNodeRef ||
        !billingEntityData ||
        !previousPeriod.startDate ||
        !previousPeriod.endDate ||
        isBefore(
          previousPeriod.startDate,
          new Date(billingEntityData.start_at),
        ),
      refetchOnMountOrArgChange: expireTime,
    },
  );

  const {
    data: yearAgoPeriodAggregatesData,
    isSuccess: isYearAgoPeriodAggregatesSuccess,
  } = useGetAggregateRatesQuery(
    {
      supplyNodeRef,
      startAt: yearAgoPeriod.startDate,
      endAt: yearAgoPeriod.endDate,
      jwtClient,
      source: "Usage Page - Year Ago",
    },
    {
      skip:
        !supplyNodeRef ||
        !billingEntityData ||
        !yearAgoPeriod.endDate ||
        !yearAgoDataAvailable(
          new Date(yearAgoPeriod.endDate),
          new Date(billingEntityData.start_at),
        ) ||
        isBefore(yearAgoPeriod.startDate, new Date(billingEntityData.start_at)),
      refetchOnMountOrArgChange: expireTime,
    },
  );

  if (accountNumber && !active && nativeRenderMode) {
    return <UsageClosedAccountDefault />;
  }

  /**
   * @description We only display the breakdown of import (bought) and export (sold) costs if the customer has exported power. Currently the only way to check if they are a home generation customer is to check if the total_export_quantity is greater than 0.
   */
  const displayHomeGenerationCharts =
    currentPeriodAggregatesData &&
    Number(currentPeriodAggregatesData.total_export_quantity) > 0;

  if (
    active &&
    billingEntityData &&
    isBefore(currentPeriod.startDate, new Date(billingEntityData.start_at))
  ) {
    const usageDataStartDate = dateUsageDataAvailableFrom(
      billingEntityData.start_at,
    );
    return <NoAvailableData startDate={usageDataStartDate} />;
  }

  if (
    active &&
    isCurrentPeriodAggregatesSuccess &&
    !currentPeriodAggregatesData &&
    isPreviousPeriodAggregatesSuccess &&
    !previousPeriodAggregatesData
  ) {
    const usageDataStartDate = dateUsageDataAvailableFrom(
      billingEntityData.start_at,
    );
    return <NoAvailableData startDate={usageDataStartDate} />;
  }
  const isCustomPeriod = selectedPeriod.code === PERIOD_CODES.CUSTOM;

  return (
    <div className="usage_page">
      <section className="page_section page_section--top">
        <section className="page_section__column page_section__column--1">
          {!accountNumber && !active && <NoAvailableData />}
          {accountNumber && !active && <UsageClosedAccountDefault />}
          {active && (
            <>
              <h2 className="section-title heading h4">
                Your Usage
                <span className="sr-only">
                  {" "}
                  for the last {selectedPeriod.periodName}
                </span>
              </h2>
              <PeriodToggle
                period={selectedPeriod.periodName}
                togglePeriod={setSelectedPeriod}
              />
              {isCustomPeriod ? (
                <CustomPeriodSelector
                  billingStartDate={
                    billingEntityData?.start_at &&
                    format(new Date(billingEntityData.start_at), "yyyy-MM-dd")
                  }
                  handleCustomDatesChange={setCustomPeriodDates}
                />
              ) : (
                <p>
                  {format(currentPeriod.startDate, "EEEE d MMMM")} -{" "}
                  {format(currentPeriod.endDate, "EEEE d MMMM")}
                </p>
              )}
            </>
          )}
          {active && !billingEntityData?.end_at && (
            <>
              <div className="spacer--large" />
              <UsageSection
                completedBill={false}
                isCustomDatePeriod={isCustomPeriod}
                thisPeriodAggregatesData={currentPeriodAggregatesData}
                previousPeriodAggregatesData={previousPeriodAggregatesData}
                yearAgoAggregatesData={yearAgoPeriodAggregatesData}
                unitCode={"kWh"}
                periodName={selectedPeriod.periodName}
                isThisPeriodAggregatesSuccess={isCurrentPeriodAggregatesSuccess}
                isThisPeriodAggregatesFetching={
                  isCurrentPeriodAggregatesFetching
                }
                isPreviousPeriodAggregatesSuccess={
                  isPreviousPeriodAggregatesSuccess
                }
                isYearAgoAggregatesSuccess={isYearAgoPeriodAggregatesSuccess}
              />
              <div className="spacer--large" />
              <SpendSection
                isCustomDatePeriod={isCustomPeriod}
                thisPeriodAggregatesData={currentPeriodAggregatesData}
                previousPeriodAggregatesData={previousPeriodAggregatesData}
                yearAgoAggregatesData={yearAgoPeriodAggregatesData}
                periodName={selectedPeriod.periodName}
                isThisPeriodAggregatesSuccess={isCurrentPeriodAggregatesSuccess}
                isThisPeriodAggregatesFetching={
                  isCurrentPeriodAggregatesFetching
                }
                isPreviousPeriodAggregatesSuccess={
                  isPreviousPeriodAggregatesSuccess
                }
                isYearAgoAggregatesSuccess={isYearAgoPeriodAggregatesSuccess}
              />
              {displayHomeGenerationCharts && (
                <>
                  <div className="spacer--large" />
                  <BoughtSection
                    isCustomDatePeriod={isCustomPeriod}
                    periodName={selectedPeriod.periodName}
                    thisPeriodAggregatesData={currentPeriodAggregatesData}
                    previousPeriodAggregatesData={previousPeriodAggregatesData}
                    yearAgoAggregatesData={yearAgoPeriodAggregatesData}
                    isThisPeriodAggregatesSuccess={
                      isCurrentPeriodAggregatesSuccess
                    }
                    isPreviousPeriodAggregatesSuccess={
                      isPreviousPeriodAggregatesSuccess
                    }
                    isYearAgoAggregatesSuccess={
                      isYearAgoPeriodAggregatesSuccess
                    }
                  />
                  <div className="spacer--large" />
                  <SoldSection
                    isCustomDatePeriod={isCustomPeriod}
                    periodName={selectedPeriod.periodName}
                    thisPeriodAggregatesData={currentPeriodAggregatesData}
                    previousPeriodAggregatesData={previousPeriodAggregatesData}
                    yearAgoAggregatesData={yearAgoPeriodAggregatesData}
                    isThisPeriodAggregatesSuccess={
                      isCurrentPeriodAggregatesSuccess
                    }
                    isPreviousPeriodAggregatesSuccess={
                      isPreviousPeriodAggregatesSuccess
                    }
                    isYearAgoAggregatesSuccess={
                      isYearAgoPeriodAggregatesSuccess
                    }
                  />
                </>
              )}
            </>
          )}
          {!nativeRenderMode && (
            <>
              <div className="spacer--large" />
              <CsvDownloader
                viewport="desktop"
                billingEntityStartAt={billingEntityData?.start_at}
                billingEntityEndAt={billingEntityData?.end_at}
                currentPeriodEndDate={currentPeriod.endDate}
              />
            </>
          )}
          <div className="spacer--large" />
        </section>
        <section className="page_section__column page_section__column--2">
          {active && isCurrentPeriodAggregatesFetching && <LoadingSection />}
          {active &&
            !billingEntityData?.end_at &&
            !isCurrentPeriodAggregatesFetching &&
            (isCustomPeriod ? (
              <AggregatedUsageSection
                periodName={selectedPeriod.periodName}
                productName={currentProduct?.normalisedName}
                periodStartDate={currentPeriod.startDate}
                periodEndAt={currentPeriod.endDate}
                isCustomDatePeriod={true}
              />
            ) : (
              <AggregatedUsageSection
                previousPeriodStartDate={previousPeriod.startDate}
                periodName={selectedPeriod.periodName}
                hasPreviousData={previousDataAvailable(
                  selectedPeriod.duration,
                  billingEntityData
                    ? new Date(billingEntityData.start_at)
                    : undefined,
                )}
                productName={currentProduct?.normalisedName}
                periodStartDate={currentPeriod.startDate}
                periodEndAt={currentPeriod.endDate}
              />
            ))}
          {displayHomeGenerationCharts && (
            <>
              <div className="spacer--large" />
              <HomeGenerationSection
                hasPreviousData={previousDataAvailable(
                  selectedPeriod.duration,
                  billingEntityData
                    ? new Date(billingEntityData.start_at)
                    : undefined,
                )}
                previousPeriodStartDate={previousPeriod.startDate}
                periodStartDate={currentPeriod.startDate}
                periodEndAt={currentPeriod.endDate}
                periodName={selectedPeriod.periodName}
              />
            </>
          )}
        </section>
      </section>
      {billingEntityData?.start_at && !nativeRenderMode && (
        <CsvDownloader
          viewport="mobile"
          billingEntityStartAt={billingEntityData?.start_at}
          billingEntityEndAt={billingEntityData?.end_at}
          currentPeriodEndDate={currentPeriod.endDate}
        />
      )}
    </div>
  );
};
