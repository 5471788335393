import React, { ChangeEvent } from "react";

type SwitchCheckboxProps = {
  id: string;
  name: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
  label: string;
};

export const SwitchCheckbox: React.FC<SwitchCheckboxProps> = ({
  id,
  name,
  onChange,
  checked,
  label,
}) => {
  return (
    <span className="switch">
      <input
        type="checkbox"
        name={name}
        id={id}
        onChange={onChange}
        checked={checked}
      />
      <label htmlFor={id}>{label}</label>
    </span>
  );
};
