/**
 *
 * This function takes an array of RatingPreferencesTypes, and finds the object where the preference type is "usage_type".
 * returns the usage type value
 */
export const findUsageType = (
  preferences: Array<RatingPreferencesTypes> | undefined,
): UsageType | undefined => {
  if (!Boolean(preferences?.length)) return undefined;

  const usageTypePreference = preferences.find(
    (preference) => preference?.attributes?.preference_type === "usage_type",
  );
  const usageType = ["low", "standard"].includes(
    usageTypePreference?.attributes?.value,
  )
    ? (usageTypePreference?.attributes?.value as UsageType)
    : undefined;
  return usageType;
};

/**
 *
 * This function takes an array of RatingPreferencesTypes, and finds the object where the preference type is "location_classification".
 * returns the location classification value
 */
export const findLocationClassification = (
  preferences: Array<RatingPreferencesTypes> | undefined,
): LocationClassification | undefined => {
  if (!Boolean(preferences?.length)) return undefined;

  const locationClassificationPreference = preferences.find(
    (preference) =>
      preference?.attributes?.preference_type === "location_classification",
  );
  const locationClassification = ["residential", "commercial"].includes(
    locationClassificationPreference?.attributes?.value,
  )
    ? (locationClassificationPreference?.attributes
        ?.value as LocationClassification)
    : undefined;
  return locationClassification;
};
