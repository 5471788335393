import { Button, SecondaryButton } from "@flick/fep-library";
import { Dialog } from "components/Dialog";
import React from "react";
import { BILL_SMOOTHER_STATE } from "utils/constants";

type StopBillSmootherDialogProps = {
  handleClick: (
    event:
      | React.MouseEvent<HTMLButtonElement>
      | React.FormEvent<HTMLFormElement>,
    data: {
      active?: boolean;
      enrolled?: boolean;
      createData?: BillSmootherConfig;
    },
    newBannerText: string,
  ) => void;
  handleDialogClose: () => void;
  hideStopBillSmootherDialog: boolean;
};

/**
 * @description Used in BillSmootherSettingsPage
 */
export const StopBillSmootherDialog: React.FC<StopBillSmootherDialogProps> = ({
  hideStopBillSmootherDialog,
  handleDialogClose,
  handleClick,
}) => (
  <Dialog
    handleDialogClose={handleDialogClose}
    dialogIsHidden={hideStopBillSmootherDialog}
  >
    <h1 id="dialog-title" className="dialog_heading">
      Are you sure you want to stop using Bill Smoother?
    </h1>
    <p>Want to stop your Bill Smoother?</p>
    <p>Pressing stop means:</p>
    <ul>
      <li>
        Your bills won't be smoothed and you'll pay the actual cost of your
        bill.
      </li>
      <li>
        You won't be topping up your Bill Soother credit for high future power
        bills.
      </li>
      <li>
        We'll use your current Bill Smoother credit to pay for future bills,
        until it's all used up.
      </li>
    </ul>
    <p>
      Stopping your Bill Smoother might not take effect immediately - if your
      next billing cycle is on the following Tuesday, you'll need to change your
      Bill Smoother settings <strong>before Sunday</strong> for it to take
      effect on your upcoming bill.
    </p>
    <p>You can set up Bill Smoother again at any time.</p>
    <div className="dialog_contents__buttons spacer--medium">
      <Button
        buttonColor="danger"
        extraClasses="button--expanded"
        onClick={(event) =>
          handleClick(
            event,
            { active: false, enrolled: false },
            BILL_SMOOTHER_STATE.deactivated,
          )
        }
      >
        Yes, stop using Bill Smoother
      </Button>

      <div className="spacer--small" />
      <SecondaryButton onClick={handleDialogClose}>
        No, don't stop using Bill Smoother
      </SecondaryButton>
    </div>
  </Dialog>
);
