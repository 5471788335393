import { useGetInvoiceQuery } from "queries/billingApi";
import React from "react";
import { INVOICED_PAYMENT_STATUSES } from "utils/constants";
import { jwtClient } from "utils/jwtClient";

import { ComponentItemFallback } from "../fallback/ComponentItemFallback";
import { LatestReceivableInvoice } from "../LatestReceivableInvoice";
import { LoadingSection } from "../LoadingSection";
import { OutstandingBillListItem } from "./OutstandingBillListItem";

/**
 * @description filterOutstandingInvoices filters out the latestReceivableInvoice if it is present in the outstandingInvoices array
 * @param {ReceivableInvoice} latestReceivableInvoice
 * @param {OutstandingInvoiceSummary[]} outstandingInvoices
 * @returns {OutstandingInvoiceSummary[]} a filtered array that does not contain the lastestReceivableInvoice
 */
const filterOutstandingInvoices = (
  latestReceivableInvoice: ReceivableInvoice,
  outstandingInvoices: OutstandingInvoiceSummary[],
): OutstandingInvoiceSummary[] => {
  if (!outstandingInvoices || outstandingInvoices.length === 0) {
    return;
  }

  const latestInvoiceNumber =
    latestReceivableInvoice?.attributes.invoice_number;

  return outstandingInvoices
    .filter(
      (outstandingInvoice) =>
        outstandingInvoice.attributes.number !== latestInvoiceNumber,
    )
    .reverse();
};

type OutstandingBillsListProps = {
  outstandingInvoices: OutstandingInvoiceSummary[];
  isOutstandingInvoicesError: boolean;
  isOutstandingInvoicesFetching: boolean;
  latestReceivableInvoice: ReceivableInvoice;
  isLatestReceivableInvoiceError: boolean;
  isLatestReceivableInvoiceFetching: boolean;
};

export const OutstandingBillsList: React.FC<OutstandingBillsListProps> = ({
  outstandingInvoices,
  isOutstandingInvoicesError,
  isOutstandingInvoicesFetching,
  latestReceivableInvoice,
  isLatestReceivableInvoiceError,
  isLatestReceivableInvoiceFetching,
}) => {
  const {
    data: billData,
    isError: isBillError,
    isFetching: isBillFetching,
  } = useGetInvoiceQuery(
    {
      invoiceNumber: latestReceivableInvoice?.attributes?.invoice_number,
      jwtClient,
    },
    {
      skip:
        !latestReceivableInvoice ||
        !latestReceivableInvoice.attributes ||
        !latestReceivableInvoice.attributes.invoice_number,
    },
  );

  const bill = billData?.bill;

  const filteredOutstandingInvoices = filterOutstandingInvoices(
    latestReceivableInvoice,
    outstandingInvoices,
  );

  const noOutstandingInvoices =
    !outstandingInvoices || outstandingInvoices.length === 0;

  const successfullyFetchedNoOutstandingInvoices =
    noOutstandingInvoices &&
    !isOutstandingInvoicesError &&
    !isOutstandingInvoicesFetching;

  const successfullyFetchedNoLatestReceivableInvoice =
    !latestReceivableInvoice &&
    !isLatestReceivableInvoiceError &&
    !isLatestReceivableInvoiceFetching;

  if (
    successfullyFetchedNoLatestReceivableInvoice &&
    successfullyFetchedNoOutstandingInvoices
  ) {
    return;
  }

  const paidLatestReceivableInvoiceBill =
    bill?.payment_status &&
    bill?.payment_status === INVOICED_PAYMENT_STATUSES.complete;

  if (
    noOutstandingInvoices &&
    latestReceivableInvoice &&
    paidLatestReceivableInvoiceBill
  ) {
    return;
  }

  if (
    isLatestReceivableInvoiceFetching ||
    isOutstandingInvoicesFetching ||
    isBillFetching
  ) {
    return <LoadingSection />;
  }

  const displayUnpaidLatestReceivableInvoice =
    latestReceivableInvoice && bill && !paidLatestReceivableInvoiceBill;

  const latestReceivableInvoiceOrBillError =
    isLatestReceivableInvoiceError || isBillError;

  return (
    <nav>
      <ul className="customer_tools_list">
        <li className="customer_tools_list__title">Outstanding bills</li>
        {latestReceivableInvoiceOrBillError && (
          <ComponentItemFallback componentTitle={"Latest receivable invoice"} />
        )}
        {displayUnpaidLatestReceivableInvoice && (
          <LatestReceivableInvoice
            invoice={latestReceivableInvoice}
            bill={bill}
          />
        )}

        {isOutstandingInvoicesError && (
          <ComponentItemFallback componentTitle={"Outstanding invoices"} />
        )}

        {filteredOutstandingInvoices?.map((outstandingInvoice, i) => (
          <OutstandingBillListItem invoice={outstandingInvoice} key={i} />
        ))}
      </ul>
    </nav>
  );
};
