import { Tooltip } from "@flick/fep-library";
import { ReactComponent as DollarSignIconSvg } from "assets/icon_dollar_sign.svg";
import React, { useMemo } from "react";
import { calculatePercentageChangeDelta } from "utils/calculatePercentageChangeDelta";
import { UNIT_CODE_TYPES } from "utils/constants";
import { prepareComparisonChartData } from "utils/progressComparisonChart/prepareComparisonChartData";

import { renderDeltaMessage } from "../DeltaMessage";
import { LoadingIcon } from "../LoadingIcon";
import { ProgressComparisonChart } from "../ProgressComparisonChart";
import { getPeriodTotalSpend, periodTotalSpend } from "./SpendSection.utils";

type SpendSectionProps = {
  isCustomDatePeriod?: boolean;
  isPreviousPeriodAggregatesSuccess?: boolean;
  isThisPeriodAggregatesFetching: boolean;
  isThisPeriodAggregatesSuccess: boolean;
  isYearAgoAggregatesSuccess?: boolean;
  periodName: string;
  previousPeriodAggregatesData?: Aggregates;
  thisPeriodAggregatesData: Aggregates;
  yearAgoAggregatesData?: Aggregates;
};

/**
 * @description Used in the Usage Page
 */
export const SpendSection: React.FC<SpendSectionProps> = ({
  isCustomDatePeriod = false,
  isPreviousPeriodAggregatesSuccess,
  isThisPeriodAggregatesFetching,
  isThisPeriodAggregatesSuccess,
  isYearAgoAggregatesSuccess,
  periodName,
  previousPeriodAggregatesData,
  thisPeriodAggregatesData,
  yearAgoAggregatesData,
}) => {
  const previousPeriodPercentageChange = useMemo(() => {
    if (isCustomDatePeriod) return undefined;

    return calculatePercentageChangeDelta(
      getPeriodTotalSpend(thisPeriodAggregatesData),
      getPeriodTotalSpend(previousPeriodAggregatesData),
    );
  }, [
    thisPeriodAggregatesData,
    previousPeriodAggregatesData,
    isCustomDatePeriod,
  ]);

  const yearAgoPeriodPercentageChange = useMemo(() => {
    if (isCustomDatePeriod) return undefined;

    return calculatePercentageChangeDelta(
      getPeriodTotalSpend(thisPeriodAggregatesData),
      getPeriodTotalSpend(yearAgoAggregatesData),
    );
  }, [thisPeriodAggregatesData, yearAgoAggregatesData, isCustomDatePeriod]);

  const displayPreviousPeriodDeltaMessage =
    !isCustomDatePeriod &&
    isPreviousPeriodAggregatesSuccess &&
    previousPeriodAggregatesData &&
    isThisPeriodAggregatesSuccess;

  const displayYearAgoPeriodDeltaMessage =
    !isCustomDatePeriod &&
    isYearAgoAggregatesSuccess &&
    yearAgoAggregatesData &&
    isThisPeriodAggregatesSuccess;

  return (
    <section>
      <section className="header_section">
        <DollarSignIconSvg data-testid="icon" />
        <h2 className="heading h4 usage_details_section__tooltip">
          {isThisPeriodAggregatesFetching ? (
            <LoadingIcon />
          ) : (
            <>
              {periodTotalSpend({
                thisPeriodAggregatesData,
                periodName,
                isCustomDatePeriod,
              })}
              <Tooltip
                name="spend-section-tooltip"
                contentAlignment="bottom-left"
              >
                <p></p>
                <p className="small font_weight--normal max-width--200">
                  The 'Spent' amount is the sum total of the power 'Bought'
                  minus the power 'Sold'.
                </p>
              </Tooltip>
            </>
          )}
        </h2>
        {displayPreviousPeriodDeltaMessage &&
          renderDeltaMessage({
            delta: previousPeriodPercentageChange,
            periodLabel: `last ${periodName}`,
          })}
        {displayYearAgoPeriodDeltaMessage &&
          renderDeltaMessage({
            delta: yearAgoPeriodPercentageChange,
            periodLabel: "last year",
          })}
      </section>
      {!isCustomDatePeriod && (
        <ProgressComparisonChart
          data={prepareComparisonChartData({
            thisPeriodData: getPeriodTotalSpend(thisPeriodAggregatesData),
            previousPeriodData: getPeriodTotalSpend(
              previousPeriodAggregatesData,
            ),
            yearAgoData: getPeriodTotalSpend(yearAgoAggregatesData),
            isThisPeriodAggregatesSuccess,
            isPreviousPeriodAggregatesSuccess,
            isYearAgoAggregatesSuccess,
            periodName,
            unitType: UNIT_CODE_TYPES.cost,
          })}
        />
      )}
    </section>
  );
};
