import { UsagePercentageComparison } from "components/IndividualDayDetail/IndividualDayDetail";

/**
 * @description calculate off-peak usage change percentage between the selected day and same day of the previous period
 * @param {UsagePercentageComparison } usagePercentageComparison
 * @returns {number | undefined} the calculated percentage change or undefined if the string coerced to a number is not a number.
 */

export const calculateOffpeakUsageChangePercentage = (
  usagePercentageComparison: UsagePercentageComparison,
): number | undefined => {
  if (
    isNaN(Number(usagePercentageComparison?.selectedDayUsagePercentage)) ||
    isNaN(Number(usagePercentageComparison?.previousPeriodDayUsagePercentage))
  )
    return undefined;

  return (
    100 *
    (+usagePercentageComparison.selectedDayUsagePercentage /
      +usagePercentageComparison.previousPeriodDayUsagePercentage -
      1)
  );
};
