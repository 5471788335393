import "./CustomPeriodSelector.scss";

import { Button, Form, SecondaryButton } from "@flick/fep-library";
import React, { useState } from "react";
import {
  dateWithinMinDateAndTodayValidation,
  endDateValidation,
  startDateValidation,
} from "utils/validations/customPeriodDateValidation";

import { CustomPeriodDate } from "./CustomPeriodDate";
import {
  getDateErrorMessage,
  getOneWeekAgoDate,
  getYesterdayDate,
} from "./CustomPeriodSelector.utils";
import content from "./static/content.json";

const { customDateLabel, endDateLabel, startDateLabel } = content;
type CustomPeriod = {
  startDate: Date;
  endDate: Date;
};

type CustomPeriodSelectorProps = {
  billingStartDate?: string;
  handleCustomDatesChange: (dates: CustomPeriod) => void;
};

export const CustomPeriodSelector: React.FC<CustomPeriodSelectorProps> = ({
  billingStartDate,
  handleCustomDatesChange,
}) => {
  const { yesterdayDateString } = getYesterdayDate();
  const { oneWeekAgoString } = getOneWeekAgoDate();
  const [startDate, setStartDate] = useState<string>(oneWeekAgoString);
  const [endDate, setEndDate] = useState<string>(yesterdayDateString);

  const [startDateError, setStartDateError] = useState<string | null>(null);
  const [endDateError, setEndDateError] = useState<string | null>(null);

  const handleSetDates = (event: React.FormEvent) => {
    event.preventDefault();

    const isStartDateValid = startDateValidation({ startDate, endDate });
    const isEndDateValid = endDateValidation({ endDate, startDate });

    if (!isStartDateValid) {
      setStartDateError(
        getDateErrorMessage({ startDate, endDate, type: "start" }),
      );
    }
    if (!isEndDateValid) {
      setEndDateError(getDateErrorMessage({ startDate, endDate, type: "end" }));
    }

    if (isStartDateValid && isEndDateValid) {
      handleCustomDatesChange({
        startDate: new Date(startDate),
        endDate: new Date(endDate),
      });
    }
  };

  const handleResetCustomDates = () => {
    setStartDate(oneWeekAgoString);
    setEndDate(yesterdayDateString);
    setStartDateError(null);
    setEndDateError(null);
  };

  const handleStartDateChange = (date: string) => {
    const withinValidDateRange = dateWithinMinDateAndTodayValidation({
      value: date,
      billingStartDate,
    });
    const error = getDateErrorMessage({
      billingStartDate,
      startDate: date,
      endDate,
      type: "start",
    });

    setStartDateError(!withinValidDateRange || startDateError ? error : null);

    // if end date is in error, revalidate on start date change
    if (endDateError) {
      const isEndDateValid = endDateValidation({ endDate, startDate: date });
      const endDateWithinValidRange = dateWithinMinDateAndTodayValidation({
        value: endDate,
        billingStartDate,
      });
      if (isEndDateValid && endDateWithinValidRange) setEndDateError(null);
    }

    setStartDate(date);
  };

  const handleEndDateChange = (date: string) => {
    const withinValidDateRange = dateWithinMinDateAndTodayValidation({
      value: date,
      billingStartDate,
    });
    const error = getDateErrorMessage({
      billingStartDate,
      startDate,
      endDate: date,
      type: "end",
    });

    setEndDateError(!withinValidDateRange || endDateError ? error : null);

    // if start date is in error, revalidate on end date change
    if (startDateError) {
      const isStartDateValid = startDateValidation({
        startDate,
        endDate: date,
      });
      const startDateWithinValidRange = dateWithinMinDateAndTodayValidation({
        value: startDate,
        billingStartDate,
      });
      if (isStartDateValid && startDateWithinValidRange) {
        setStartDateError(null);
      }
    }

    setEndDate(date);
  };

  return (
    <section>
      <p>{customDateLabel}</p>
      <Form onSubmit={handleSetDates}>
        <div className="custom_period_selector__start-and-end-inputs spacer--small">
          <CustomPeriodDate
            billingStartDate={billingStartDate}
            date={startDate}
            error={startDateError}
            handleDateChanged={handleStartDateChange}
            label={startDateLabel}
            name="customPeriodStart"
          />
          <CustomPeriodDate
            billingStartDate={billingStartDate}
            date={endDate}
            error={endDateError}
            handleDateChanged={handleEndDateChange}
            label={endDateLabel}
            name="customPeriodEnd"
          />
        </div>
        <div className="custom_period_selector__buttons">
          <Button
            disabled={Boolean(startDateError) || Boolean(endDateError)}
            buttonSize="small"
            type="submit"
          >
            View
          </Button>
          <SecondaryButton onClick={handleResetCustomDates}>
            Reset
          </SecondaryButton>
        </div>
      </Form>
    </section>
  );
};
