import { createSlice } from "@reduxjs/toolkit";
import { contractManagerApi } from "queries/contractManagerApi";
import { PRODUCT_DISPLAY_NAMES } from "utils/constants";
import {
  capitaliseProductName,
  normaliseProductName,
} from "utils/productNameUtils";

import { setStoreCurrentAccount } from "./currentAccount";

const currentProductSlice = createSlice({
  name: "currentProduct",
  initialState: {
    contractName: undefined,
    description: undefined,
    displayName: undefined,
    id: undefined,
    endsAt: undefined,
    isCurrentProductError: false,
    normalisedName: undefined,
    isCurrentProductChanged: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setStoreCurrentAccount, (state) => {
        Object.assign(state, {
          contractName: undefined,
          description: undefined,
          displayName: undefined,
          endsAt: undefined,
          id: undefined,
          normalisedName: undefined,
        });
        state.isCurrentProductChanged = false;
      })
      .addMatcher(
        contractManagerApi.endpoints.getContracts.matchPending,
        (state) => {
          state.isCurrentProductChanged = false;
          Object.assign(state, {
            contractName: undefined,
            description: undefined,
            displayName: undefined,
            endsAt: undefined,
            id: undefined,
            normalisedName: undefined,
          });
        },
      )
      .addMatcher(
        contractManagerApi.endpoints.getContracts.matchFulfilled,
        (state, { payload: contractData = undefined }) => {
          const normalisedName = contractData?.name
            ? normaliseProductName(contractData.name)
            : undefined;

          const displayName = normalisedName
            ? capitaliseProductName(PRODUCT_DISPLAY_NAMES[normalisedName])
            : undefined;
          Object.assign(state, {
            contractName: contractData?.contractName || false,
            description: contractData?.description || undefined,
            displayName: displayName,
            endsAt: contractData?.endsAt,
            id: contractData?.id,
            normalisedName: normalisedName,
          });
          state.isCurrentProductChanged = true;
        },
      )
      .addMatcher(
        contractManagerApi.endpoints.getContracts.matchRejected,
        (state, { payload: error }) => {
          state.isCurrentProductError = Boolean(error);
          state.isCurrentProductChanged = false;
        },
      );
  },
});

export const currentProductSliceReducer = currentProductSlice.reducer;
