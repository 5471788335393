/* eslint-disable react/forbid-prop-types */
// This component has two adrs 0002 and 003

import React from 'react';
import PropTypes from 'prop-types';
import { RATE_CODES, UNIT_CODES, METER_NAME } from '../../utils/constants';
import { labelName } from '../../utils/labelNames';
import { calculateGstAdjustedAmount } from '../../utils/calculateGstAdjustedAmount';

export const PlanRates = ({ planId, subscriptionRates, usageRates, gstInclusion }) => (
  <dl className="plan_card_rates_text">
    {Boolean(subscriptionRates) &&
      subscriptionRates.map((rate) => (
        <>
          <dt key={`${planId}-${rate}`}>{RATE_CODES[rate.unit_code]}:</dt>
          <dd>
            {calculateGstAdjustedAmount(rate.rates[0].rate * 100, gstInclusion).toFixed(2)}
            {UNIT_CODES[rate.unit_code]}
          </dd>
        </>
      ))}
    {Boolean(usageRates) &&
      usageRates.meterConfigurations?.length > 0 &&
      usageRates.meterConfigurations.map((meterConfiguration) => {
        const controlledMeter = meterConfiguration.name === METER_NAME.CONTROLLED;
        const embeddedGenerationMeter = meterConfiguration.name === METER_NAME.EMBEDDED_GENERATION;
        return meterConfiguration.rates.map((rate) => (
          <>
            <dt key={`${planId}`} className="sanitizedAnytimeRates">
              {labelName(meterConfiguration, rate, planId)}
              {controlledMeter && ` (${meterConfiguration.periodOfAvailability})`}:
            </dt>
            <dd>
              {embeddedGenerationMeter
                ? 'Wholesale power rate*/kWh'
                : Math.abs(calculateGstAdjustedAmount(rate?.price, gstInclusion)).toFixed(2) +
                  UNIT_CODES[rate?.unit]}
            </dd>
          </>
        ));
      })}
  </dl>
);

PlanRates.propTypes = {
  planId: PropTypes.string.isRequired,
  subscriptionRates: PropTypes.arrayOf(PropTypes.object).isRequired,
  usageRates: PropTypes.object.isRequired,
  gstInclusion: PropTypes.string.isRequired,
};
