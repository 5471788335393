/* eslint-disable @typescript-eslint/no-explicit-any */
import { createApi } from "@reduxjs/toolkit/query/react";
import { JwtClient } from "utils/jwtClient";

import {
  formatPhoneNumbers,
  formattedAccountDetails,
  formattedDirectDebitPayment,
} from "./customer.utils";
import { BaseQueryParams, expireTime } from "./queries.utils";

export type MovingHouseParams = {
  jwtClient: JwtClient;
  accountNumber: string;
  address: CustomerAddress;
  icpNumber: string;
  moveInDate: string;
  moveOutDate?: string;
  medicalDependency: boolean;
  termsAccepted: boolean;
  usageType: string;
};

export type AddDirectDebitPaymentParams = {
  jwtClient: JwtClient;
  accountIdentifier: string;
  accountName: string;
  agreedToTerms: boolean;
  bankAccountNumber: string;
  code?: string;
  confirmedAuthority: boolean;
  particulars?: string;
  reference?: string;
  isDefaultPaymentMethod?: boolean; // this is not implemented in the UI. Adding it to the query in case of future use. Defaults to true in API
};

export type UpdatePhoneNumbersParams = {
  jwtClient: JwtClient;
  accountIdentifier: string;
  params: {
    contactId: number;
    mobileNumberParams?: { id: number; number: string; type: string }[];
    homeNumberParams?: { id: number; number: string; type: string }[];
  };
};

export type UpdateBillingPreferencesParams = {
  jwtClient: JwtClient;
  params: {
    accountIdentifier: string;
    billingEmail?: string;
    billingPeriod?: string;
    billingPeriodOffsetWeeks?: string;
    selectedPaymentMethod?: AccountDetailPaymentMethod;
  };
};

/**
 * a newly added payment method has pament_type in snake_case, but the existing ones
 * fetched on load are PascalCase and need to use this map
 * */
const defaultPaymentTypes = {
  CreditCard: "credit_card",
  DirectDebit: "direct_debit",
};

// Define a base query function that calls jwtClient.apiCall
const apiCallBaseQuery = async ({ jwtClient, ...params }: BaseQueryParams) => {
  const response = await jwtClient.apiCall(params);
  const result = await response.json();

  // for accountDetails formatting we need the includes details
  if (params.path.includes("/customer/accounts/")) {
    return { data: result };
  }

  return { data: result.data };
};

// Define the customerApi slice
export const customerApi = createApi({
  reducerPath: "customerApi",
  baseQuery: apiCallBaseQuery,
  tagTypes: ["AccountDetails"],
  endpoints: (builder) => ({
    getAccountInfo: builder.query<
      AccountInfo[],
      { jwtClient: JwtClient; accountOverrideNumber?: string }
    >({
      query({ jwtClient, accountOverrideNumber }) {
        return {
          jwtClient: jwtClient,
          path: "/customer/user_accounts_info",
          queryParams: {
            account_number: accountOverrideNumber,
          },
        };
      },
    }),
    getAccountDetails: builder.query<
      AccountDetails,
      { jwtClient: JwtClient; accountNumber: string }
    >({
      query({ jwtClient, accountNumber }) {
        return {
          jwtClient: jwtClient,
          path: `/customer/accounts/${accountNumber}`,
        };
      },
      transformResponse: (data) => {
        return formattedAccountDetails(data);
      },
      providesTags: ["AccountDetails"],
    }),
    closeAccount: builder.mutation<
      AccountInfo,
      { jwtClient: JwtClient; accountNumber: string; endDate: string }
    >({
      query({ accountNumber, endDate, jwtClient }) {
        return {
          jwtClient: jwtClient,
          path: `/customer/accounts/account_closure/`,
          queryParams: {
            account_identifier: accountNumber,
            end_date: endDate,
          },
          fetchParams: {
            method: "PUT",
          },
        };
      },
    }),
    movingHouse: builder.mutation<AccountInfo, MovingHouseParams>({
      query({
        accountNumber,
        address,
        icpNumber,
        moveInDate,
        moveOutDate,
        medicalDependency,
        termsAccepted,
        usageType,
        jwtClient,
      }) {
        // Submit the icp number. If there is no icp number, submit the address
        const hasAddress = address.city && address.number && address.street;
        const submitAddress = !Boolean(icpNumber) && hasAddress;
        const queryParams = {
          account_identifier: accountNumber,
          "address[city]": submitAddress ? address.city : undefined,
          "address[number]": submitAddress ? address.number : undefined,
          "address[postcode]": submitAddress ? address.postcode : undefined,
          "address[street]": submitAddress ? address.street : undefined,
          "address[suburb]": submitAddress ? address.suburb : undefined,
          "address[unit]": submitAddress ? address.unit : undefined,
          icp_number: icpNumber,
          move_in_date: moveInDate,
          move_out_date: moveOutDate,
          medical_dependency: medicalDependency,
          terms_accepted: termsAccepted,
          usage_type: usageType,
        };

        return {
          jwtClient: jwtClient,
          path: `/customer/moving_house`,
          queryParams: Object.entries(queryParams).reduce((acc, [key, val]) => {
            // Remove the undefined values. Can't use `if (val)` as some values are boolean and can be false
            if (val !== undefined) {
              return { ...acc, [key]: val };
            }
            return acc;
          }, {}),
          fetchParams: {
            method: "POST",
          },
        };
      },
    }),
    addDirectDebitPayment: builder.mutation<
      AccountDetailPaymentMethod,
      AddDirectDebitPaymentParams
    >({
      query({
        accountIdentifier,
        accountName,
        agreedToTerms,
        bankAccountNumber,
        code,
        confirmedAuthority,
        jwtClient,
        particulars,
        reference,
        isDefaultPaymentMethod = false,
      }) {
        const JSONParams = {
          account_name: accountName,
          agreed_to_terms: agreedToTerms,
          account_number: bankAccountNumber,
          code,
          confirmed_bank_authority: confirmedAuthority,
          particulars,
          reference,
          default_payment_method: isDefaultPaymentMethod,
        };

        return {
          jwtClient: jwtClient,
          path: `/customer/direct_debit/${accountIdentifier}`,
          params: {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          },
          fetchParams: {
            body: JSON.stringify(JSONParams),
            method: "POST",
          },
        };
      },
      transformResponse: (data) => {
        return formattedDirectDebitPayment(data);
      },
    }),
    addCreditCardPayment: builder.mutation<
      AddCardPaymentResponse,
      {
        jwtClient: JwtClient;
        redirectUrl: string;
        accountIdentifier: string;
      }
    >({
      query({ jwtClient, redirectUrl, accountIdentifier }) {
        const JSONParams = {
          account_identifier: accountIdentifier,
          redirect_url: redirectUrl,
        };

        return {
          jwtClient: jwtClient,
          path: "/customer/authenticated_credit_cards/authorisations/",
          params: {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          },
          fetchParams: {
            body: JSON.stringify(JSONParams),
            method: "POST",
          },
        };
      },
    }),
    getWindcaveAuthResult: builder.query<
      AddCardAuthorisationResponse,
      {
        windcaveTransactionId: string;
        jwtClient: JwtClient;
      }
    >({
      query({ jwtClient, windcaveTransactionId }) {
        return {
          jwtClient: jwtClient,
          path: `/customer/authenticated_credit_cards/authorisations/${windcaveTransactionId}`,
        };
      },
    }),
    updatePhoneNumbers: builder.mutation<any, UpdatePhoneNumbersParams>({
      query({
        jwtClient,
        accountIdentifier,
        params: { contactId, mobileNumberParams, homeNumberParams },
      }) {
        const JSONParams = {
          phone_number_attributes: [
            ...formatPhoneNumbers(mobileNumberParams),
            ...formatPhoneNumbers(homeNumberParams),
          ],
        };

        return {
          jwtClient: jwtClient,
          path: `/customer/contacts/${accountIdentifier}`,
          params: {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          },
          queryParams: { contact_id: contactId },
          fetchParams: {
            body: JSON.stringify(JSONParams),
            method: "PUT",
          },
        };
      },
    }),
    updateBillingPreferences: builder.mutation<
      FormattedAccountBillingPreference,
      UpdateBillingPreferencesParams
    >({
      query({
        params: {
          accountIdentifier,
          billingEmail,
          billingPeriod,
          billingPeriodOffsetWeeks,
          selectedPaymentMethod,
        },
        jwtClient,
      }) {
        const params = {
          account_identifier: accountIdentifier,
          ...(billingEmail && { billing_email: billingEmail }),
          ...(billingPeriod && {
            billing_period: billingPeriod,
          }),
          ...(billingPeriodOffsetWeeks && {
            billing_period_offset_weeks: billingPeriodOffsetWeeks,
          }),
          ...(selectedPaymentMethod && {
            default_payment_method_id: selectedPaymentMethod.payment_method_id,
            default_payment_method_type:
              defaultPaymentTypes[selectedPaymentMethod.payment_type] ??
              selectedPaymentMethod.payment_type,
          }),
        };
        return {
          jwtClient: jwtClient,
          path: "/customer/billing_preferences",
          queryParams: params,
          fetchParams: {
            method: "PUT",
          },
        };
      },
    }),
  }),
  keepUnusedDataFor: expireTime,
});

export const {
  useGetAccountInfoQuery,
  useGetAccountDetailsQuery,
  useGetWindcaveAuthResultQuery,
  useCloseAccountMutation,
  useMovingHouseMutation,
  useAddDirectDebitPaymentMutation,
  useAddCreditCardPaymentMutation,
  useUpdatePhoneNumbersMutation,
  useUpdateBillingPreferencesMutation,
} = customerApi;
