import "./AddressDetailsPage.scss";

import { AccountPageLink } from "components/AccountPageLink";
import { AccountPageWrapper } from "components/AccountPageWrapper";
import { LoadingSection } from "components/LoadingSection";
import React from "react";
import ReactMarkdown from "react-markdown";
import { useAppSelector } from "reduxUtils/hook";

import content from "./static/content.json";
const {
  heading,
  icp,
  movingHouseContent,
  movingHouseHeader,
  movingHouseLink,
  medicalDependencyStatusContent,
  medicalDependencyStatusHeader,
  nonMedicalDependencyStatusContent,
} = content;

export const AddressDetailsPage: React.FC = () => {
  const { active } = useAppSelector((store) => store.currentAccount);
  const { usageType, accountNumber, icpNumber } = useAppSelector(
    (store) => store.currentAccount,
  );
  const { currentICPAddress, preferredName, vulnerabilityState } =
    useAppSelector((store) => store.accountDetails);

  if (!preferredName) return <LoadingSection />;

  return (
    <div className="address-details-page">
      <AccountPageWrapper
        headerLabel={heading}
        headerItems={[
          { key: preferredName, value: null },
          { key: "Account number", value: accountNumber },
          { key: icp, value: icpNumber },
        ]}
      >
        {currentICPAddress && (
          <p className="column-content">
            {currentICPAddress.split(",").map((line) => (
              <span key={line}>
                <strong>{line}</strong>
              </span>
            ))}
          </p>
        )}
        {vulnerabilityState && (
          <p className="column-content">
            <span>
              <strong>{medicalDependencyStatusHeader}</strong>
            </span>
            <ReactMarkdown
              components={{
                p: "span",
              }}
            >
              {[
                "medically_dependent_verified",
                "medically_dependent_notified",
              ].includes(vulnerabilityState)
                ? medicalDependencyStatusContent
                : nonMedicalDependencyStatusContent}
            </ReactMarkdown>
          </p>
        )}
        <p className={`column-content ${!active && "moving-house-inactive"}`}>
          <strong>
            <span>{movingHouseHeader}</span>
          </strong>
          <span>{movingHouseContent}</span>
        </p>
        {usageType && (
          <div className="customer_tools_list__item spacer--medium">
            {!active ? (
              <AccountPageLink
                className="moving-house-inactive"
                linkText={movingHouseLink}
                linkTo="/account/address/update"
              />
            ) : (
              <AccountPageLink
                linkText={movingHouseLink}
                linkTo="/account/address/update"
              />
            )}
          </div>
        )}
      </AccountPageWrapper>
    </div>
  );
};
