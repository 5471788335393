import MeterConfiguration from './meter-configuration';
import Rate from './rate';

const meterConfigurationOrder = ['UN', 'IN', 'D|N', 'N|D', 'CN', 'EG'];

export default class Rates {
  constructor(data) {
    this.meterConfigurations = data.rates
      .map((meterRates) => {
        const rates = meterRates.rates.map(
          (rate) =>
            new Rate({
              ...rate,
              ...{ unit_code: meterRates.unit_code, timetables: data.timetables, type: data.type },
            })
        );

        return meterRates.channels.map((channel) => {
          const meterConfiguration = new MeterConfiguration({
            channels: channel,
            rates,
          });

          meterConfiguration.sortRatesBySummaryLabel();

          return meterConfiguration;
        });
      })
      .flat();
  }

  /**
   * @description This method creates an ordered list of meter configurations (ordering based on type of meter, see adr 0002).
   * It also de-duplicates configurations so we don't display multiple configurations that are the same.
   * An exception to this rule are controlled meters where rates might be the same but a meter's period of availability might differ.
   * Refer to adr 0003 for examples.
   *
   * @returns a object containing an array that follows the hierarchy laid out in adr 0002 and the deduplication rules laid out in adr 0003
   *  */

  orderedMeterConfigurations() {
    /** @description de-duplicate meters */
    const uniqueMeterConfigurations = [...new Set(this.meterConfigurations)];

    /** @description find those meters that have offpeak and/or peak rates */
    const offpeakPeakMeterConfigurations = uniqueMeterConfigurations.filter((configuration) => {
      const offpeakPeakRates = configuration.rates.every((rate) =>
        ['offpeak', 'peak'].includes(rate.summaryLabel)
      );

      if (offpeakPeakRates) return configuration;
      return false;
    });

    /** @description de-duplicate offpeak/peak meters */
    const uniqueOffpeakPeakMeterConfigurations = offpeakPeakMeterConfigurations.reduce(
      (uniqueConfigs, meterconfig) => {
        if (!uniqueConfigs.some((uniqueConfig) => uniqueConfig.rates === meterconfig.rates)) {
          uniqueConfigs.push(meterconfig);
        }
        return uniqueConfigs;
      },
      []
    );

    /** @description find all meters that are not offpeak/peak (they have an `anytime` ratecode) */
    const anytimeMeterConfigurations = uniqueMeterConfigurations.filter((configuration) => {
      const offpeakPeakRates = configuration.rates.filter(
        (rate) => !['offpeak', 'peak'].includes(rate.summaryLabel)
      );

      if (offpeakPeakRates.length) return configuration;
      return false;
    });

    /** @description de-duplicate the anytime meters, with the exclusion of `controlled` meters, see adr 0003 for examples why controlled is handled differently */
    const uniqueAnytimeMeterConfigurations = anytimeMeterConfigurations.reduce(
      (uniqueConfigs, meterconfig) => {
        if (
          !uniqueConfigs.some(
            (uniqueConfig) =>
              uniqueConfig.rates === meterconfig.rates && meterconfig.contentCode !== 'CN'
          )
        ) {
          uniqueConfigs.push(meterconfig);
        }
        return uniqueConfigs;
      },
      []
    );

    /** @description sort anytime meters based on the hierarchy constant at the top of this file */
    const sortedUniqueAnytimeConfigurations = uniqueAnytimeMeterConfigurations.sort(
      (previous, current) =>
        meterConfigurationOrder.indexOf(previous.contentCode) -
        meterConfigurationOrder.indexOf(current.contentCode)
    );

    return {
      meterConfigurations: uniqueOffpeakPeakMeterConfigurations.concat(
        sortedUniqueAnytimeConfigurations
      ),
    };
  }
}
