import "./errorReasonListStyles.scss";

type ErrorDetails = { title: string; detail?: string };
/**
 * Display in bullet points the error reasons only.
 * For errors returned in format: Error: { title: string, detail?: string }[] or Error: { errors: { title: string, detail?: string }[] }
 *
 * if the error is valid JSON - returns an array with only the details populated
 */
export const errorReasonDetails = (error: Error) => {
  try {
    const errorResponse = JSON.parse(error.message);
    const errorArray = errorResponse?.errors || errorResponse;

    const errorDetails = errorArray.filter((err: ErrorDetails) => !!err.detail);
    return errorDetails.map((e: ErrorDetails) => {
      return { detail: e.detail };
    });
  } catch (e) {
    // for exception we need to log it into console so we can help customer debug in case it happens
    console.log(e);
    return;
  }
};
