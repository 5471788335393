import "./AccountHeaderSectionStyles.scss";

import { ReactComponent as AccountIcon } from "app/assets/images/account-black.svg";
import React from "react";

type AccountHeaderSectionProps = {
  items?: { key: string; value?: string | number }[];
  label: string;
  hasHeaderIcon?: boolean;
};

export const AccountHeaderSection: React.FC<AccountHeaderSectionProps> = ({
  label,
  items,
  hasHeaderIcon = false,
}) => {
  return (
    <div>
      <div className="account_header__label">
        {hasHeaderIcon && (
          <AccountIcon className="account_header__label_icon" />
        )}
        <h2 className="account_header__label_text heading">{label}</h2>
      </div>
      {items && (
        <div className="account_header__customer_info">
          {items.map(({ key, value }) => (
            <p key={`${key}-${value}`}>
              <span className="account_header--capitalize h5">{key}</span>
              {value && <span>{`: ${value}`}</span>}
            </p>
          ))}
          <span className="spacer spacer--large"></span>
        </div>
      )}
    </div>
  );
};
