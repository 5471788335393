import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SupplyNode, {
  ELIGIBILITY_NOT_IN_AREA,
  ELIGIBILITY_REJECT,
  ELIGIBILITY_UPGRADE,
  ELIGIBILITY_EXPORT,
} from '../../models/supply-node';

import IcpNotFound from './icpNotFound';
import IcpNotInArea from './icpNotInArea';
import IcpReject from './icpReject';
import IcpUpgrade from './icpUpgrade';
import SelectIcp from './selectIcp';
import AddressNotFound from './addressNotFound';
import IcpErrorModal from './icpErrorModal';

const AddressBar = ({
  addressFinderKey,
  addressPlaceholderText,
  loading,
  joining,
  supplyNodes,
  selectedSupplyNode,
  address,
  addressSelected,
  icpSelected,
  icpError,
  clearAddressSearch,
  manualAddressLine,
  continueJoiningWithoutIcp,
  enableICPSearch,
}) => {
  let widget = null;
  const inputRef = React.createRef();
  const containerRef = React.createRef();
  const [showClearButton, setShowClearButton] = useState(false);
  const [addressFinderLoaded, setAddressFinderLoaded] = useState(false);

  const emptyResultContent = `<p><strong>Can't see your place?</strong> Try entering your <a href="#" class="link address_bar_link js-enterAddressManually">address manually</a> ${
    enableICPSearch
      ? 'or enter your <a href="#" class="link address_bar_link js-enterIcpManually">ICP number</a>'
      : ''
  }</p>`;

  const loadWidget = () => {
    if (window?.AddressFinder) {
      widget = new window.AddressFinder.Widget(inputRef.current, addressFinderKey, 'NZ', {
        manual_style: true,
        list_class: 'af_list eligibility-address-finder__list',
        item_class: 'af_item address_bar_list_item',
        empty_class: 'af_item address_bar_content',
        address_params: {
          post_box: 0,
        },
        show_locations: false,
        byline: false,
        container: containerRef.current,
        empty_content: emptyResultContent,
      });

      if (widget?.on) {
        widget.on('result:select', addressSelected);
      }
    }
  };

  const destroyAddressFinder = () => {
    if (widget) {
      widget.destroy();
      widget = null;
    }
  };

  const downloadAddressFinder = () => {
    if (document) {
      const script = document.createElement('script');
      script.src = 'https://api.addressfinder.io/assets/v3/widget.js';
      script.async = true;
      script.onload = () => {
        setAddressFinderLoaded(true);
      };
      document.body.appendChild(script);
    }
  };

  const clearInput = () => {
    setShowClearButton(false);
    if (inputRef.current) {
      inputRef.current.value = '';
    }
    clearAddressSearch();
  };

  const inputChange = (e) => {
    if (e.target.value.length > 0) {
      if (address) {
        clearAddressSearch();
      }
      setShowClearButton(true);
    } else {
      setShowClearButton(false);
      clearAddressSearch();
    }
  };

  useEffect(() => {
    downloadAddressFinder();

    // This is a callback used when you want code to run before the component is destroyed.
    // https://reactjs.org/docs/hooks-effect.html#example-using-hooks-1
    return () => {
      destroyAddressFinder();
    };
  }, []);

  useEffect(() => {
    if (addressFinderLoaded) {
      loadWidget();
    }
  }, [addressFinderLoaded]);

  useEffect(() => {
    if (selectedSupplyNode) {
      inputRef.current.value = selectedSupplyNode.address.shortDescription();
    }
  }, [selectedSupplyNode]);

  useEffect(() => {
    if (manualAddressLine) {
      inputRef.current.value = manualAddressLine;
      loadWidget();
    }
  }, [manualAddressLine]);

  const addressBarClasses = () => {
    let classes = 'address_bar_input_wrapper';

    if (loading) {
      classes += ' address_bar_input_wrapper_loading';
    }

    if (selectedSupplyNode) {
      if (selectedSupplyNode.isEligible()) {
        classes += ' address_bar_input_wrapper_success';
      } else {
        classes += ' address_bar_input_wrapper_error';
      }
    }

    return classes;
  };
  return (
    <div className="address_bar" ref={containerRef}>
      <div className={addressBarClasses()}>
        <input
          className="address_bar_input"
          type="text"
          placeholder={addressPlaceholderText}
          spellCheck="false"
          ref={inputRef}
          id="address-finder"
          name="address"
          onChange={inputChange}
        />
        {showClearButton && (
          <button
            type="button"
            data-clear-input
            className="address_bar_clear_input"
            onClick={clearInput}
          >
            <span className="sr-only">Clear</span>
          </button>
        )}
      </div>
      {!joining && (
        <>
          {supplyNodes && supplyNodes.length === 0 && (
            <IcpNotFound
              continueJoiningWithoutIcp={continueJoiningWithoutIcp}
              enableICPSearch={enableICPSearch}
            />
          )}
          {selectedSupplyNode && selectedSupplyNode.eligibility === ELIGIBILITY_NOT_IN_AREA && (
            <IcpNotInArea />
          )}
          {selectedSupplyNode && selectedSupplyNode.eligibility === ELIGIBILITY_REJECT && (
            <IcpReject />
          )}
          {selectedSupplyNode && selectedSupplyNode.eligibility === ELIGIBILITY_EXPORT && (
            <IcpReject />
          )}
          {selectedSupplyNode && selectedSupplyNode.eligibility === ELIGIBILITY_UPGRADE && (
            <IcpUpgrade />
          )}
          {!selectedSupplyNode && supplyNodes && supplyNodes.length > 1 && (
            <SelectIcp supplyNodes={supplyNodes} icpSelected={icpSelected} />
          )}
          {!selectedSupplyNode && supplyNodes?.length > 0 && (
            <AddressNotFound continueJoiningWithoutIcp={continueJoiningWithoutIcp} />
          )}
          {icpError && <IcpErrorModal />}
        </>
      )}
    </div>
  );
};

AddressBar.propTypes = {
  addressFinderKey: PropTypes.string.isRequired,
  selectedSupplyNode: PropTypes.instanceOf(SupplyNode),
  supplyNodes: PropTypes.arrayOf(PropTypes.instanceOf(SupplyNode)),
  loading: PropTypes.bool.isRequired,
  joining: PropTypes.bool.isRequired,
  address: PropTypes.string,
  addressSelected: PropTypes.func.isRequired,
  icpSelected: PropTypes.func.isRequired,
  clearAddressSearch: PropTypes.func.isRequired,
  manualAddressLine: PropTypes.string,
  continueJoiningWithoutIcp: PropTypes.func.isRequired,
  addressPlaceholderText: PropTypes.string.isRequired,
  icpError: PropTypes.bool.isRequired,
  enableICPSearch: PropTypes.bool,
};

AddressBar.defaultProps = {
  selectedSupplyNode: null,
  supplyNodes: null,
  manualAddressLine: null,
  address: null,
  enableICPSearch: true,
};

export default AddressBar;
