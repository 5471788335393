import React from 'react';
import PropTypes from 'prop-types';

const AddressNotFound = ({ continueJoiningWithoutIcp }) => (
  <div className="address_bar_content address_bar_content_large">
    <h4 className="heading address_bar_title">None of these?</h4>
    <p>
      You can still{' '}
      <a href="#continue" className="link address_bar_link" onClick={continueJoiningWithoutIcp}>
        continue joining,
      </a>{' '}
      though there’s a chance we won’t be able to supply your address. We’ll give you a call after
      you submit your application to gather more information.
    </p>
  </div>
);

AddressNotFound.propTypes = {
  continueJoiningWithoutIcp: PropTypes.func.isRequired,
};

export default AddressNotFound;
