import { AccountPageLink } from "components/AccountPageLink";
import { SelectInput } from "components/SelectInput";
import React, { ChangeEvent, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { setNotificationBanner } from "reducers/notificationBanner";
import { useAppDispatch, useAppSelector } from "reduxUtils/hook";
import {
  NotificationBannerNames,
  NotificationBannerTypes,
} from "utils/constants";
import { hasInputValueChanged } from "utils/forms/forms";

import { useBillingDetailsFormContext } from "./BillingDetailsPageContext";
import content from "./static/content.json";
const {
  paymentMethodAddLink,
  paymentMethodHint,
  paymentMethodLabel,
  paymentMethodError,
} = content;

type BillingDetailsPagePaymentMethodProps = {
  setValueHasChanged: (value: boolean) => void;
};

export const BillingDetailsPagePaymentMethod: React.FC<
  BillingDetailsPagePaymentMethodProps
> = ({ setValueHasChanged }) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const {
    paymentMethod: {
      fieldValue: { value, error },
      handleOnChange,
      handleError,
    },
  } = useBillingDetailsFormContext();
  const { paymentMethods } = useAppSelector((store) => store.accountDetails);

  const { defaultOption, options } = useMemo(() => {
    if (paymentMethods?.length === 0) {
      handleError(paymentMethodError);
    }
    return {
      options:
        paymentMethods?.map((pm) => ({
          label: pm.payment_type_details,
          value: pm.payment_method_id,
        })) || [],
      defaultOption: paymentMethods?.find((pm) => pm.default) || undefined,
    };
  }, [paymentMethods]);

  useEffect(() => {
    if (!value) {
      handleError(paymentMethodError);
    }
  }, [value]);

  useEffect(() => {
    if (defaultOption?.payment_method_id) {
      handleOnChange(defaultOption.payment_method_id);
    }
  }, [defaultOption?.payment_method_id]);

  useEffect(() => {
    if (value) {
      setValueHasChanged(
        hasInputValueChanged(defaultOption?.payment_method_id, value),
      );
    }
  }, [value]);

  const handleOnChangePayment = (event: ChangeEvent<HTMLSelectElement>) => {
    handleOnChange(event.target.value);
  };

  // Show the sucess banner when hitting this page after a successful new payment method POST
  useEffect(() => {
    if (location?.state?.showSuccessBanner) {
      dispatch(
        setNotificationBanner({
          type: NotificationBannerTypes.notice,
          name: NotificationBannerNames.addDirectDebitPayment,
          page: location.pathname,
        }),
      );
    }
  }, [location]);

  return (
    <>
      <div className="form_row spacer spacer--medium">
        <SelectInput
          errorMessage={error ? error : ""}
          defaultOption={defaultOption}
          handleOnChange={handleOnChangePayment}
          hint={
            value === defaultOption?.payment_method_id ? "" : paymentMethodHint
          }
          label={paymentMethodLabel}
          name="billing-payment-method"
          options={options}
          initialValue=""
          value={value}
          extraClasses={
            error ? "spacer spacer--small field_error" : "spacer spacer--small"
          }
        />
      </div>
      <div className="customer_tools_list">
        <div className="customer_tools_list__item">
          <AccountPageLink
            linkText={paymentMethodAddLink}
            linkTo={"/account/billing/new-payment-method"}
          />
        </div>
      </div>
    </>
  );
};
