import { ReactComponent as PriceTagIconSvg } from "assets/icon_price_tag.svg";
import React, { useMemo } from "react";
import { calculatePercentageChangeDelta } from "utils/calculatePercentageChangeDelta";
import { BRAND_COLOURS, UNIT_CODE_TYPES } from "utils/constants";
import { prepareComparisonChartData } from "utils/progressComparisonChart/prepareComparisonChartData";

import { renderDeltaMessage } from "../DeltaMessage";
import { ProgressComparisonChart } from "../ProgressComparisonChart";
import {
  getPeriodTotalExportRevenue,
  periodTotalExportRevenue,
} from "./SoldSection.utils";

type SoldSectionProps = {
  isCustomDatePeriod?: boolean;
  isPreviousPeriodAggregatesSuccess: boolean;
  isThisPeriodAggregatesSuccess: boolean;
  isYearAgoAggregatesSuccess: boolean;
  periodName: string;
  previousPeriodAggregatesData: Aggregates;
  thisPeriodAggregatesData: Aggregates;
  yearAgoAggregatesData: Aggregates;
};

/**
 * @description Sold Section displays on the Usage Page for home generation/solar customers.
 */
export const SoldSection: React.FC<SoldSectionProps> = ({
  isCustomDatePeriod = false,
  isPreviousPeriodAggregatesSuccess,
  isThisPeriodAggregatesSuccess,
  isYearAgoAggregatesSuccess,
  periodName,
  previousPeriodAggregatesData,
  thisPeriodAggregatesData,
  yearAgoAggregatesData,
}) => {
  const previousPeriodPercentageChange = useMemo(() => {
    if (isCustomDatePeriod) return undefined;

    return calculatePercentageChangeDelta(
      getPeriodTotalExportRevenue(thisPeriodAggregatesData),
      getPeriodTotalExportRevenue(previousPeriodAggregatesData),
    );
  }, [
    thisPeriodAggregatesData,
    previousPeriodAggregatesData,
    isCustomDatePeriod,
  ]);

  const yearAgoPercentageChange = useMemo(() => {
    if (isCustomDatePeriod) return undefined;

    return calculatePercentageChangeDelta(
      getPeriodTotalExportRevenue(thisPeriodAggregatesData),
      getPeriodTotalExportRevenue(yearAgoAggregatesData),
    );
  }, [thisPeriodAggregatesData, yearAgoAggregatesData, isCustomDatePeriod]);

  const displayPreviousPeriodDeltaMessage =
    !isCustomDatePeriod &&
    isPreviousPeriodAggregatesSuccess &&
    previousPeriodAggregatesData &&
    isThisPeriodAggregatesSuccess;

  const displayYearAgoPeriodDeltaMessage =
    !isCustomDatePeriod &&
    isYearAgoAggregatesSuccess &&
    yearAgoAggregatesData &&
    isThisPeriodAggregatesSuccess;

  return (
    <section>
      <section className="header_section">
        <PriceTagIconSvg data-testid="icon" />
        <h2 className="heading h4">
          {periodTotalExportRevenue({
            thisPeriodAggregatesData,
            periodName,
            isCustomDatePeriod,
          })}
        </h2>
        {displayPreviousPeriodDeltaMessage &&
          renderDeltaMessage({
            delta: previousPeriodPercentageChange,
            periodLabel: `last ${periodName}`,
          })}
        {displayYearAgoPeriodDeltaMessage &&
          renderDeltaMessage({
            delta: yearAgoPercentageChange,
            periodLabel: "last year",
          })}
      </section>
      {!isCustomDatePeriod && (
        <ProgressComparisonChart
          data={prepareComparisonChartData({
            isPreviousPeriodAggregatesSuccess,
            isThisPeriodAggregatesSuccess,
            isYearAgoAggregatesSuccess,
            periodName,
            thisPeriodData: getPeriodTotalExportRevenue(
              thisPeriodAggregatesData,
            ),
            previousPeriodData: getPeriodTotalExportRevenue(
              previousPeriodAggregatesData,
            ),
            yearAgoData: getPeriodTotalExportRevenue(yearAgoAggregatesData),
            barColour: BRAND_COLOURS.green,
            unitType: UNIT_CODE_TYPES.cost,
          })}
        />
      )}
    </section>
  );
};
