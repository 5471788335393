import { ComponentItemFallback } from "components/fallback/ComponentItemFallback";
import { addGst } from "queries/pricing.utils";
import React from "react";
import { RATE_CODES, UNIT_CODES } from "utils/constants";

type PlanRatesProps = {
  planId: string;
  rates: AvailablePlanRate[] | SubscriptionUsageRate[];
  isGstInclusive: boolean;
};

export const PlanRates: React.FC<PlanRatesProps> = ({
  planId,
  rates,
  isGstInclusive,
}) => {
  if (!rates || !Array.isArray(rates) || rates.length === 0)
    return <ComponentItemFallback componentTitle="Plan rates" />;
  return (
    <>
      {rates &&
        rates.map((rate) => (
          <div key={`${planId}-${rate}`}>
            {rate.rates.map(({ channelCodes, rateCode, total, unitCode }) => (
              <p key={rateCode}>
                <span className="h5">{RATE_CODES[rateCode]}</span>
                {channelCodes && (
                  <span className="h5">{` (${channelCodes})`}</span>
                )}
                {channelCodes === "Export" ? (
                  <>
                    <span>: Wholesale power rate*/kwh</span>
                    <br />
                    <span>
                      *Wholesale power rates are a variable rate that changes
                      every 30 mins
                    </span>
                  </>
                ) : (
                  <span>{`: ${
                    isGstInclusive
                      ? Number(addGst(total)).toFixed(3)
                      : Number(total).toFixed(3)
                  } ${UNIT_CODES[unitCode]}`}</span>
                )}
              </p>
            ))}
          </div>
        ))}
    </>
  );
};
