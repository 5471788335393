/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';

const IcpChoice = ({ position, supplyNode, onSelect }) => {
  const selectAddress = () => {
    onSelect(supplyNode);
  };
  return (
    <li
      className="af_item address_bar_list_item"
      onClick={selectAddress}
      onKeyDown={selectAddress}
      role="option"
      aria-posinset={position + 1}
      aria-selected={false}
      id={`suggestion_${position + 1}`}
    >
      {supplyNode.address.shortDescription()}
    </li>
  );
};

IcpChoice.propTypes = {
  position: PropTypes.number.isRequired,
  supplyNode: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
};

const SelectIcp = ({ supplyNodes, icpSelected }) => (
  <ul className="af_list eligibility-address-finder__list" role="listbox">
    {supplyNodes.map((supplyNode, index) => (
      <IcpChoice
        key={supplyNode.icpNumber}
        supplyNode={supplyNode}
        onSelect={icpSelected}
        position={index}
      />
    ))}
  </ul>
);

SelectIcp.propTypes = {
  supplyNodes: PropTypes.arrayOf(PropTypes.object).isRequired,
  icpSelected: PropTypes.func.isRequired,
};

export default SelectIcp;
