export const formattedContractsData = (contractsData: ContractsDataJSON) => {
  const { data, included: contractInformation } = contractsData || {};
  const endsAt = data[0]?.attributes?.period?.end_at;

  const currentProduct = Array.isArray(contractInformation)
    ? contractInformation.find((element) => element.type === "product")
        ?.attributes
    : undefined;

  const currentContract = Array.isArray(contractInformation)
    ? contractInformation.find((element) => element.type === "plan")
    : undefined;

  const currentContractsAttributes = currentContract?.attributes;

  return {
    contractName: currentContractsAttributes?.name,
    description: currentProduct?.description,
    ...(endsAt && { endsAt }),
    id: currentContract?.id,
    name: currentProduct?.name,
  };
};

export const formattedPlanDescriptions = (plansData: PlansDataJSON) => {
  const { data, included: productInformation } = plansData || {};

  const planDescriptions = data.map((plan: Plan) => {
    const productId = plan.relationships?.product?.data.id;
    if (productId) {
      return {
        planId: plan.id,
        description: productInformation.find(
          (product: Contract) => product.id === productId,
        )?.attributes?.description,
      };
    }
  });

  return planDescriptions;
};

export const formattedUpdatedCurrentPlan = (updatedCurrentPlanData) => {
  const { included } = updatedCurrentPlanData || [];
  const contract = Array.isArray(included)
    ? included.find((element) => element.type === "plan")
    : undefined;

  return { planId: contract?.id };
};
