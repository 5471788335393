import "./MovingHousePage.scss";

import { SingleSelectRadioGroup } from "components/SingleSelectRadioGroup";
import React, { useMemo } from "react";
import ReactMarkdown from "react-markdown";
import { useAppSelector } from "reduxUtils/hook";
import { replaceContentParams } from "utils/stringFormat/contentParams";
import { capitalize } from "utils/text";

import { useMovingHouseFormContext } from "./MovingHouseContext";
import content from "./static/content.json";

const {
  changePlanHintLowUser,
  changePlanHintStandardUser,
  changePlanLabel,
  userPlanChange,
  userPlanKeep,
} = content;

export const MovingHousePlan: React.FC = () => {
  const { usageType } = useAppSelector((store) => store.currentAccount);
  const {
    userPlan: {
      fieldValue: { value },
      handleOnChange: setUserPlan,
    },
  } = useMovingHouseFormContext();

  const isStandard = usageType === "standard";

  const userPlanOptions = useMemo(() => {
    if (!usageType) return [];
    return (
      usageType && [
        {
          label: replaceContentParams(userPlanKeep || "", {
            usageType: capitalize(usageType),
          }),
          value: usageType,
        },
        {
          label: replaceContentParams(userPlanChange || "", {
            change: isStandard ? "decrease" : "increase",
            usageType: isStandard ? "Low" : "Standard",
          }),
          value: isStandard ? "low" : "standard",
        },
      ]
    );
  }, [usageType]);

  const handlePlanChange = (newValue: string) => {
    setUserPlan(userPlanOptions.find((o) => o.value === newValue));
  };

  const changePlanHintValue = isStandard
    ? changePlanHintStandardUser
    : changePlanHintLowUser;

  return (
    <div className="spacer spacer--large">
      {userPlanOptions.length > 0 && (
        <SingleSelectRadioGroup
          handleOnChange={handlePlanChange}
          hint={<ReactMarkdown>{changePlanHintValue}</ReactMarkdown>}
          label={changePlanLabel}
          name="userPlan"
          options={userPlanOptions}
          valueSelect={value.value || ""}
          extraClasses="spacer spacer--small"
        />
      )}
    </div>
  );
};
