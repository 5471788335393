import "styles/Loader.scss";

import React from "react";

export const Loader: React.FC = () => {
  return (
    <div className="lds-roller" data-testid="loading-indicator">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};
