import "styles/CurrentPlan.scss";

import React from "react";

type CurrentPlanPanelProps = {
  name: string;
  description: string;
  panelColour?: string;
};

export const CurrentPlanPanel: React.FC<CurrentPlanPanelProps> = ({
  name,
  description,
  panelColour,
}) => {
  return (
    <div className={`panel background--${panelColour || "green"} current-plan`}>
      <p className="h4 current-plan__headline">
        Your plan at the end of this period
      </p>
      <p className="h1 current-plan__name">{name}</p>
      <p className="current-plan__tagline">{description}</p>
    </div>
  );
};
