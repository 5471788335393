import SupplyNode from '../models/supply-node';
import { camelCaseKeys } from '../utils/text-transforms';

class SupplyNodeSearchService {
  constructor(apiBaseUrl) {
    this.apiBaseUrl = apiBaseUrl;
  }

  supplyNodesEndpoint(address) {
    return `${this.apiBaseUrl}/public_website/supply_nodes?${address.toQueryString()}`;
  }

  fetch(address) {
    return fetch(this.supplyNodesEndpoint(address), {
      method: 'GET',
    })
      .then((response) => {
        if (!response.ok) {
          throw Error('There was a problem retrieving the supply nodes');
        }
        return response.json();
      })
      .then((data) =>
        data.supply_nodes.map((supplyNode) => {
          const supplyNodeData = camelCaseKeys(supplyNode);
          return new SupplyNode(supplyNodeData);
        })
      )
      .catch((e) => {
        throw e;
      });
  }
}

export default SupplyNodeSearchService;
