export const formattedAccountDetails = (
  accountData: AccountDetailJSON,
): AccountDetails => {
  const { data, included = [] } = accountData || {};

  const paymentMethods = included
    .filter(({ type }) => type === "payment_method")
    .map((pm) => pm.attributes);

  const primary_contact = included.find(
    ({ attributes }) => attributes.primary_contact,
  );

  const primaryContact = {
    id: primary_contact?.id,
    phoneNumbers:
      primary_contact?.attributes.phone_numbers.map(
        ({ id, number_type, number }) => {
          return { id, numberType: number_type, number };
        },
      ) || [],
  };

  const currentICPAddress = included.find(({ type }) => type === "icp_consumer")
    ?.attributes?.address_description;

  return {
    paymentMethods,
    vulnerabilityState: data?.attributes?.vulnerability_state || "none",
    userSub: data?.attributes?.user_sub,
    primaryContact,
    currentICPAddress,
    referralLink: data?.attributes?.referral_link,
  };
};

export const formattedDirectDebitPayment = (
  directDebitPayment: AddDirectDebitPaymentResponse,
): AccountDetailPaymentMethod => {
  const {
    id,
    type,
    attributes: {
      obfuscated_account_number,
      default_payment_method,
      account_number,
    },
  } = directDebitPayment;

  // need the dashes back to update the billingEntity payment method
  const hyphenatedAccountNumber = account_number.replace(
    /(\d{2})(\d{4})(\d{7})(\d{3})/,
    "$1-$2-$3-$4",
  );

  return {
    payment_number_reference: hyphenatedAccountNumber,
    default: default_payment_method,
    payment_method_id: id,
    payment_type_details: obfuscated_account_number,
    payment_type: type,
  };
};

// Convert some values from number to string
// Fetch billing entity and update billing entity return different param names, so format to account for this
export const formattedUpdatedBillingPreferences = (
  billingPreferences: AccountBillingPreference,
): FormattedAccountBillingPreference => {
  return {
    ...billingPreferences,
    attributes: {
      ...billingPreferences.attributes,
      billing_contact_email: billingPreferences.attributes.billing_email,
      nominated_billing_period: billingPreferences.attributes.billing_period,
      nominated_billing_period_offset_weeks:
        billingPreferences.attributes.billing_period_offset_weeks,
      default_payment_method_id:
        billingPreferences.attributes.default_payment_method_id,
    },
  };
};

export const formatPhoneNumbers = (numbers) => {
  return (
    numbers?.map(({ id, number, type }) => ({
      dirty_number: number,
      id,
      number_type: type,
    })) || []
  );
};
