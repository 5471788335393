import "styles/BillSmootherInformationPage.scss";

import { Button } from "@flick/fep-library";
import { BillSmootherFaq } from "components/BillSmoother/BillSmootherFaq";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "reduxUtils/hook";

const BILL_SMOOTHER_AVERAGE_BILL = "average";
const BILL_SMOOTHER_HIGH_BILL = "high";

export const BillSmootherInformationPage: React.FC = () => {
  const navigate = useNavigate();

  const [billSmootherUsage, setBillSmootherUsage] = useState(null);
  const { supplyNodeRef } = useAppSelector((store) => store.currentAccount);
  const { billingEntityData } = useAppSelector(
    (store) => store.billingEntityData,
  );
  const canUseBillSmoother = billingEntityData?.id && supplyNodeRef;

  return (
    <>
      <div className="page_section page_section--single_column page_section--top">
        <section className="page_section__column page_section__column--1">
          <h2 className="page__heading">
            Smooth your payments with Bill Smoother
          </h2>
          <p>
            Want to pay exactly the same amount at each and every bill, or stash
            cash aside for those high winter bills?
          </p>
          {canUseBillSmoother && (
            <Button
              extraClasses="button--expanded spacer"
              onClick={() => navigate("/bills/bill-smoother/settings")}
            >
              Setup Bill Smoother
            </Button>
          )}
        </section>
      </div>
      <hr className="content_divider" />
      <div className="page_section">
        <section className="page_section__column page_section__column--1">
          <h3 className="h4">How it works</h3>
          <ol className="list_progress">
            <li className="completed">
              <h4 className="h5">Summertime set-up</h4>
              <p>
                You can set up Bill Smoother at any time, but doing it in the
                warmer months when your power bills are cheaper means you'll
                have time to build up credit before those high winter bills come
                knocking.
              </p>
            </li>
            <li className="completed">
              <h4 className="h5">Crunch the numbers</h4>
              <p>
                To find the minimum amount you'll need to contribute to your
                power bills to keep your payments smooth, work out your average
                bill spend.
              </p>
              <details className="accordion accordion--small">
                <summary className="summary">
                  <h5>How do I work out my average bill spend?</h5>
                </summary>
                <ul>
                  <li>
                    Take your previous non-winter bills and add on an extra 20%
                    (multiply it by 1.2).
                  </li>
                  <li>
                    Or head to the 'Analyse' tab of your web dashboard and,
                    under the monthly view, add together each month's spend for
                    the last year. Then divide this by your billing cycle – 52
                    for weekly, 26 for fortnightly, and 12 for monthly.
                  </li>
                  <li>
                    Alternatively, give us a call and we'll help you work it
                    out.
                  </li>
                </ul>
              </details>
              <span className="spacer"></span>
            </li>
            <li className="completed">
              <h4 className="h5">Set up your Bill Smoother account</h4>
              <p>
                Using your average bill spend as a guide, choose how much you
                want to contribute at each bill, and the power bill amount
                that'll trigger Bill Smoother to use your credit to help pay
                your bill.
              </p>
              <details className="accordion accordion--small">
                <summary className="summary">
                  <h5>
                    How you set up Bill Smoother depends on what you want Bill
                    Smoother to do.
                  </h5>
                </summary>
                <ul>
                  <li>
                    Want super smooth bills, day-in, day-out? Make the two
                    amounts the same.
                  </li>
                  <li>
                    Happy for your payment amounts to change a little, and just
                    want to knock the top off those high bills? Make the second
                    amount higher.
                  </li>
                </ul>
                <span className="spacer"></span>
                <h5>How do you want to use Bill Smoother?</h5>
                <span className="spacer spacer--small"></span>
                <ul className="form_field_collection">
                  <li>
                    <input
                      type="radio"
                      name="bill_smoother_high_bills"
                      value={BILL_SMOOTHER_AVERAGE_BILL}
                      id="bill_smoother_average_bill"
                      onClick={() =>
                        setBillSmootherUsage(BILL_SMOOTHER_AVERAGE_BILL)
                      }
                    />
                    <label htmlFor="bill_smoother_average_bill">
                      <span>
                        I want smooth bills all year round
                        <span className="spacer spacer--small" />
                        <span className="small">
                          For those who run a tight budget or simply want to pay
                          exactly the same amount with each bill.
                        </span>
                      </span>
                    </label>
                    {billSmootherUsage === BILL_SMOOTHER_AVERAGE_BILL && (
                      <div className="sub-section-container spacer background--grey">
                        <h6>How to set up Bill Smoother:</h6>
                        <ul className="spacer">
                          <li>
                            Make your minimum payment amount slightly higher
                            than your average bill spend.
                          </li>
                          <li>Make your trigger amount the same.</li>
                        </ul>
                        <h6>How it works:</h6>
                        <span className="spacer"></span>
                        <ul>
                          <li>
                            You'll pay the minimum payment amount/trigger amount
                            at each bill.
                          </li>
                          <li>
                            For any bills under that amount, the difference will
                            go into your Bill Smoother credit.
                          </li>
                          <li>
                            For any bills over that amount, that portion of the
                            bill will be covered by your Bill Smoother credit.
                          </li>
                        </ul>
                        <span className="spacer"></span>
                      </div>
                    )}
                  </li>
                  <li>
                    <input
                      type="radio"
                      name="bill_smoother_high_bills"
                      value={BILL_SMOOTHER_HIGH_BILL}
                      id="bill_smoother_high_bill"
                      onClick={() =>
                        setBillSmootherUsage(BILL_SMOOTHER_HIGH_BILL)
                      }
                    />
                    <label htmlFor="bill_smoother_high_bill">
                      <span>
                        I want to save my credit for super high bills
                        <span className="spacer spacer--small" />
                        <span className="small">
                          For those who're happy with a little variance in their
                          bill payments and want to stash credit for very high
                          bills.
                        </span>
                      </span>
                    </label>
                    {billSmootherUsage === BILL_SMOOTHER_HIGH_BILL && (
                      <div className="sub-section-container spacer background--grey">
                        <h6>How to set up Bill Smoother:</h6>
                        <ul className="spacer">
                          <li>
                            Make your minimum payment amount slightly higher
                            than your average bill spend.
                          </li>
                          <li>
                            Make your trigger amount higher, at the point where
                            you want your credit to be used.
                          </li>
                        </ul>
                        <h6>How it works:</h6>
                        <span className="spacer"></span>
                        <ul>
                          <li>
                            Your payments will vary between your minimum payment
                            amount and trigger amount.
                          </li>
                          <li>
                            For any bills under the minimum payment amount, the
                            difference will go into your Bill Smoother credit.
                          </li>
                          <li>
                            For any bills over the trigger amount, that portion
                            of the bill will be covered by your Bill Smoother
                            credit.
                          </li>
                        </ul>
                      </div>
                    )}
                  </li>
                </ul>
              </details>
              <span className="spacer"></span>
            </li>
            <li className="completed">
              <h4 className="h5">Get your bill</h4>
              <p>
                We’ll send you your bill as normal on a Tuesday and take a
                payment on Thursday, in line with your billing frequency
                (weekly, fortnightly or monthly).
              </p>
            </li>
            <li className="completed">
              <h4 className="h5">Watch Bill Smoother do its thing</h4>
              <p>
                For power bills that are less than the amount you've chosen to
                contribute at each bill, the difference will go into your Bill
                Smoother account as credit.
              </p>
              <p>
                For power bills that are more than the amount you've chosen as a
                trigger to use your Bill Smoother credit, we'll dip into your
                credit to pay the difference. Seriously smooth!
              </p>
            </li>
          </ol>
          <span className="spacer spacer--large"></span>
          {canUseBillSmoother && (
            <Button
              extraClasses="button--expanded"
              onClick={() => navigate("/bills/bill-smoother/settings")}
            >
              Setup Bill Smoother
            </Button>
          )}
        </section>
        <hr className="content_divider" />
        <section className="page_section__column page_section__column--1">
          <h4 className="h5">Example Bill Smoother setups</h4>
          <p>
            <strong className="text__colour--crimson">
              Minimum Payment Amount:
            </strong>{" "}
            the baseline amount that you choose to pay on each bill, even if
            your power bill is lower.
          </p>
          <p>
            <strong className="text__colour--green">Trigger Amount:</strong> the
            bill amount that you choose to trigger Bill Smoother to use credit
            from your Bill Smoother account. It contributes towards the portion
            of your bill that’s over the ‘Trigger Amount’.
          </p>
          <ul>
            <li>
              Your{" "}
              <strong className="text__colour--crimson">minimum payment</strong>{" "}
              amount is <strong className="text__colour--crimson">$40</strong>.
            </li>
            <li>
              Your{" "}
              <strong className="text__colour--green">trigger amount</strong> to
              use your Bill Smoother credit is also{" "}
              <strong className="text__colour--green">$40</strong>.
            </li>
            <li>
              Your power bill this week is <strong>$30</strong>.
            </li>
            <li>
              We take payment of <strong>$30</strong> to pay your bill, and{" "}
              <strong className="text__colour--crimson">$10</strong> goes into
              your Bill Smoother credit.
            </li>
          </ul>
          <ul>
            <li>
              Your{" "}
              <strong className="text__colour--crimson">minimum payment</strong>{" "}
              amount is <strong className="text__colour--crimson">$70</strong>.
            </li>
            <li>
              Your{" "}
              <strong className="text__colour--green">trigger amount</strong> to
              use your Bill Smoother credit is also{" "}
              <strong className="text__colour--green">$70</strong>.
            </li>
            <li>
              Your power bill this fortnight is <strong>$80</strong>.
            </li>
            <li>
              We take payment of <strong>$70</strong>, and use{" "}
              <strong className="text__colour--green">$10</strong> of your Bill
              Smoother credit to pay your power bill.
            </li>
          </ul>
          <ul>
            <li>
              Your{" "}
              <strong className="text__colour--crimson">minimum payment</strong>{" "}
              amount is <strong className="text__colour--crimson">$300</strong>.
            </li>
            <li>
              Your{" "}
              <strong className="text__colour--green">trigger amount</strong> to
              use your Bill Smoother credit is{" "}
              <strong className="text__colour--green">$350</strong>.
            </li>
            <li>
              Your power bill this month is <strong>$330</strong>.
            </li>
            <li>
              We take full payment of <strong>$330</strong> to pay your power
              bill.
            </li>
          </ul>
          <ul>
            <li>
              Your{" "}
              <strong className="text__colour--crimson">minimum payment</strong>{" "}
              amount is <strong className="text__colour--crimson">$30</strong>.
            </li>
            <li>
              Your{" "}
              <strong className="text__colour--green">trigger amount</strong> to
              use your Bill Smoother credit is{" "}
              <strong className="text__colour--green">$60</strong>.
            </li>
            <li>
              Your power bill this week is <strong>$70</strong>.
            </li>
            <li>
              We take payment of <strong>$60</strong>, and use{" "}
              <strong className="text__colour--green">$10</strong> of your Bill
              Smoother credit to pay your power bill.
            </li>
          </ul>
          <hr className="content_divider spacer spacer--large" />
          <h2 className="page__heading">FAQs</h2>
          <BillSmootherFaq />
        </section>
      </div>
    </>
  );
};
