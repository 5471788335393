import { NZD } from "utils/currency";

/**
 * @description Calculates the total import cost for a period (inclusive of GST)
 * @param periodAggregatesData
 * @returns The total import cost for a period as a string
 */
export const getPeriodTotalImportCost = (
  periodAggregatesData: Aggregates,
): string | null => {
  if (!periodAggregatesData) return null;
  return String(
    parseFloat(String(periodAggregatesData.total_import_cost)) +
      parseFloat(periodAggregatesData.total_import_tax),
  );
};

/**
 * @returns A string containing the period name and the total import cost value for thisPeriod
 */
export const periodTotalImportCost = ({
  isCustomDatePeriod = false,
  periodName,
  thisPeriodAggregatesData,
}: {
  isCustomDatePeriod?: boolean;
  periodName: string;
  thisPeriodAggregatesData: Aggregates;
}): string => {
  if (!Boolean(thisPeriodAggregatesData)) {
    return isCustomDatePeriod
      ? `The cost of power bought for this period is not available`
      : `The cost of power bought for this ${periodName} is not available`;
  }

  return isCustomDatePeriod
    ? `Bought ${NZD.format(
        parseFloat(getPeriodTotalImportCost(thisPeriodAggregatesData)),
      )} of power`
    : `Bought ${NZD.format(
        parseFloat(getPeriodTotalImportCost(thisPeriodAggregatesData)),
      )} of power so far this ${periodName}`;
};
