import { Card, CardBody, CardHeader } from "@flick/fep-library";
import { ReactComponent as ForwardIcon } from "app/assets/images/icon_nav_arrow_forward.svg";
import { AccountBalanceWrapper } from "components/AccountBalance/AccountBalanceWrapper";
import { ApplicationJourneyTracker } from "components/ApplicationJourneyTracker/ApplicationJourneyTracker";
import { BillSmootherBalance } from "components/BillSmoother/BillSmootherBalance";
import { CurrentBillPeriodSection } from "components/CurrentBillPeriodSection";
import { OffpeakDetailsSection } from "components/OffpeakDetailsSection";
import { OutstandingBillsList } from "components/OutstandingBills";
import { RecentBills } from "components/RecentBills";
import { WholesalePriceForecastSection } from "components/WholesalePriceForecastSection";
import {
  useGetBillingBalanceQuery,
  useGetLatestReceivableInvoiceQuery,
  useGetOutstandingInvoicesQuery,
} from "queries/billingApi";
import { expireTime } from "queries/queries.utils";
import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { useAppSelector } from "reduxUtils/hook";
import { AVAILABLE_PERIODS, PRODUCT_NAMES } from "utils/constants";
import { jwtClient } from "utils/jwtClient";

import { DailyUsageSectionWrapper } from "./DailyUsageSectionWrapper";
import content from "./static/content.json";
const { applicationJourneyWelcome } = content;

interface BillingPeriod {
  code: string;
  duration: number;
  periodName: string;
}

export const getBillingPeriod = (
  billingEntityData?: BillingEntity,
): BillingPeriod => {
  if (!billingEntityData) return undefined;
  return AVAILABLE_PERIODS[
    billingEntityData.nominated_billing_period
  ] as BillingPeriod;
};

export const productIsOffpeak = (currentProduct?: CurrentProduct): boolean =>
  currentProduct && currentProduct.normalisedName
    ? currentProduct.normalisedName === PRODUCT_NAMES.offPeak
    : false;

export const productIsWholesale = (currentProduct?: CurrentProduct): boolean =>
  currentProduct && currentProduct.normalisedName
    ? currentProduct.normalisedName === PRODUCT_NAMES.wholesale
    : false;

/**
 * @description renders the Application Journey Tracker when it's called from the HomePage
 */
const ApplicationJourneyWrapper = (): JSX.Element => {
  return (
    <>
      <h1 className="heading h3">{applicationJourneyWelcome}</h1>
      <ApplicationJourneyTracker />
    </>
  );
};

/**
 * @description HomePage
 */
export const HomePage = (): JSX.Element => {
  const {
    active,
    supplyNodeRef,
    accountNumber,
    customerNumber,
    isFetchAccountError,
  } = useAppSelector((store) => store.currentAccount);
  const { billingEntityData } = useAppSelector(
    (store) => store.billingEntityData,
  );
  const currentProduct = useAppSelector((store) => store.currentProduct);
  const { billSmootherConfig: billSmootherState } = useAppSelector(
    (store) => store.billSmootherConfig,
  );

  const billingPeriod: BillingPeriod = useMemo(
    () => getBillingPeriod(billingEntityData),
    [billingEntityData],
  );

  const {
    data: accountBalance,
    isFetching: isAccountBalanceFetching,
    isError: isAccountBalanceError,
  } = useGetBillingBalanceQuery(
    { customerNumber, jwtClient },
    {
      skip: !customerNumber,
      refetchOnMountOrArgChange: expireTime,
    },
  );

  const {
    data: outstandingInvoices,
    isError: isOutstandingInvoicesError,
    isFetching: isOutstandingInvoicesFetching,
  } = useGetOutstandingInvoicesQuery(
    { customerNumber, jwtClient },
    {
      skip: !customerNumber,
      refetchOnMountOrArgChange: expireTime,
    },
  );

  const {
    data: latestReceivableInvoice,
    isError: isLatestReceivableInvoiceError,
    isFetching: isLatestReceivableInvoiceFetching,
  } = useGetLatestReceivableInvoiceQuery(
    { customerNumber, jwtClient },
    {
      skip: !customerNumber,
      refetchOnMountOrArgChange: expireTime,
    },
  );

  /**
   * handle when useGetAccountInfoQuery returns 200 but no accountNumber existing
   * there is a seperate fallback screen within ApplicationJourney to handle if no associated application found
   */
  if (!isFetchAccountError && !accountNumber) {
    return <ApplicationJourneyWrapper />;
  }

  /**
   * show a fallback screen when useGetAccountInfoQuery falls over
   */
  if (isFetchAccountError) {
    return (
      <div
        data-testid="applicationJourneyNotificationBanner"
        className="notification_banner notification_notice icon"
      >
        <p className="heading">Sorry we can’t find your account information.</p>
        <p>Please contact us if the issue persists.</p>
      </div>
    );
  }

  return (
    <div className="page_section">
      <section className="page_section__column page_section__column--1">
        {productIsOffpeak(currentProduct) && active && (
          <>
            <Card cardBorderStyle="shadow" cardPaddingType="compact">
              <CardHeader headingColor="black">
                <h2 className="h4">Off Peak details</h2>
              </CardHeader>
              <CardBody>
                <OffpeakDetailsSection />
              </CardBody>
            </Card>
            <div className="spacer--large" />
          </>
        )}
        {productIsWholesale(currentProduct) && active && (
          <>
            <Card cardBorderStyle="shadow" cardPaddingType="compact">
              <CardHeader headingColor="black">
                <h2 className="h4">Wholesale details</h2>
              </CardHeader>
              <CardBody>
                <WholesalePriceForecastSection />
              </CardBody>
            </Card>
            <div className="spacer--large" />
          </>
        )}
        {Boolean(customerNumber) && (
          <>
            <Card cardBorderStyle="shadow" cardPaddingType="compact">
              <CardHeader headingColor="black">
                <h2 className="h4">Your account balance</h2>
              </CardHeader>
              <CardBody>
                <>
                  <AccountBalanceWrapper
                    accountBalance={accountBalance}
                    isAccountBalanceError={isAccountBalanceError}
                    isAccountBalanceFetching={isAccountBalanceFetching}
                    isEnrolledInBillSmoother={billSmootherState?.enrolled}
                  />
                  <OutstandingBillsList
                    outstandingInvoices={outstandingInvoices}
                    isOutstandingInvoicesError={isOutstandingInvoicesError}
                    isOutstandingInvoicesFetching={
                      isOutstandingInvoicesFetching
                    }
                    latestReceivableInvoice={latestReceivableInvoice}
                    isLatestReceivableInvoiceError={
                      isLatestReceivableInvoiceError
                    }
                    isLatestReceivableInvoiceFetching={
                      isLatestReceivableInvoiceFetching
                    }
                  />
                </>
              </CardBody>
            </Card>
            <div className="spacer--large" />
          </>
        )}
        {billSmootherState?.enrolled && Boolean(customerNumber) && (
          <>
            <Card cardBorderStyle="shadow" cardPaddingType="compact">
              <CardHeader headingColor="black">
                <h2 className="h4">Your Bill Smoother balance</h2>
              </CardHeader>
              <CardBody>
                <>
                  <BillSmootherBalance
                    accountBalance={accountBalance}
                    isAccountBalanceError={isAccountBalanceError}
                    isAccountBalanceFetching={isAccountBalanceFetching}
                  />
                  <article className="customer_tools_section__link_container">
                    <Link
                      to="/bills/bill-smoother"
                      className="customer_tools_section__link"
                      state={{ previousPath: window.location.pathname }}
                    >
                      View Bill Smoother details
                    </Link>
                    <ForwardIcon />
                  </article>
                </>
              </CardBody>
            </Card>
            <div className="spacer--large" />
          </>
        )}
        <Card cardBorderStyle="shadow" cardPaddingType="compact">
          <CardHeader headingColor="black">
            <h2 className="h4">Your bill</h2>
          </CardHeader>
          <CardBody>
            <>
              {billingEntityData && active && supplyNodeRef && (
                <CurrentBillPeriodSection
                  componentTitle={"Current bill period"}
                />
              )}
              <RecentBills />
            </>
          </CardBody>
        </Card>
        <div className="spacer--large" />
      </section>
      <section className="page_section__column page_section__column--2">
        <Card cardBorderStyle="shadow" cardPaddingType="compact">
          <CardHeader headingColor="black">
            <h2 className="h4">Your usage</h2>
          </CardHeader>
          <CardBody>
            <>
              {billingPeriod && active && currentProduct && supplyNodeRef && (
                <DailyUsageSectionWrapper
                  billingPeriod={billingPeriod}
                  billingEntityData={billingEntityData}
                  supplyNodeRef={supplyNodeRef}
                  currentProductName={currentProduct.normalisedName}
                  componentTitle="Daily usage"
                />
              )}
            </>
          </CardBody>
        </Card>
      </section>
    </div>
  );
};
