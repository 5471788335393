import { Button } from "@flick/fep-library";
import React from "react";

type SubmitButtonProps = {
  label: string;
  isSubmitDisabled?: boolean;
};

export const SubmitButton: React.FC<SubmitButtonProps> = ({
  label,
  isSubmitDisabled = false,
}) => {
  return (
    <Button
      disabled={isSubmitDisabled}
      extraClasses="button--expanded spacer"
      type="submit"
    >
      {label}
    </Button>
  );
};
