import { ReactComponent as FlickLogo } from "app/assets/images/flick_logo_2021.svg";
import { ReactComponent as HeaderMenuIcon } from "app/assets/images/three_dot_menu.svg";
import classnames from "classnames";
import { Navigation } from "components/Navigation";
import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useLocation } from "react-router-dom";
// @ts-ignore
import colours from "styles/_colours.scss";
import { pathnameBrand } from "utils/pathnameBrand";

type HeaderProps = {
  handleLogout: () => void;
};

export const Header: React.FC<HeaderProps> = ({ handleLogout }) => {
  const { pathname } = useLocation();
  const { background, name } = pathnameBrand(pathname);

  const dropdownRef = useRef<HTMLElement>(null);
  const checkBoxLabelRef = useRef<HTMLLabelElement>(null);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  /* Logic for closing logout dropdown on clicking anywhere */
  useEffect(() => {
    if (isDropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [isDropdownOpen]);

  const handleClickOutside = (event: Event) => {
    if (
      !dropdownRef.current?.contains(event.target as Node) &&
      event.target !== checkBoxLabelRef.current // clicked target is not the checkbox label, and the logout dropdown or it's children)
    ) {
      setIsDropdownOpen(false);
    }
  };

  const handleCheckboxChange = () => {
    setIsDropdownOpen((previous) => !previous);
  };

  const handleLogoutClick = () => {
    setIsDropdownOpen(false);
    handleLogout();
  };

  return (
    <>
      <Helmet>
        <meta name="theme-color" content={colours[background]} />
      </Helmet>
      <header
        className={classnames(
          `background--pattern-bolt customer_tools_header background--${background}`,
          {
            "text__colour--inverse": background === "red",
          },
        )}
      >
        <span className="customer_tools_header__logo-container">
          <FlickLogo className="customer_tools_header__logo" />
        </span>
        <h1 className="customer_tools_header__title">{name}</h1>
        <div className="dropdown customer_tools_header__dropdown">
          <input
            type="checkbox"
            id="dropdown_example"
            checked={isDropdownOpen}
            onChange={handleCheckboxChange}
            className="checkbox_menu_toggle"
          />
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label
            className="dropdown_trigger customer_tools_header__dropdown_trigger"
            htmlFor="dropdown_example"
            ref={checkBoxLabelRef}
          >
            <HeaderMenuIcon />
          </label>
          {isDropdownOpen && (
            <nav
              className="dropdown_menu customer_tools_header__dropdown_menu"
              aria-label="Secondary"
              ref={dropdownRef}
            >
              <ul>
                <li>
                  <Link
                    to="/"
                    className="link customer_tools_header__link"
                    onClick={handleLogoutClick}
                  >
                    Logout
                  </Link>
                </li>
              </ul>
            </nav>
          )}
        </div>
      </header>
      <Navigation handleLogout={handleLogout} />
    </>
  );
};
