import { Button } from "@flick/fep-library";
import { useBackNavigation } from "customHooks/useBackNavigation";
import React from "react";

type CancelButtonProps = {
  label?: string;
  backPathname?: string;
};

export const CancelButton: React.FC<CancelButtonProps> = ({
  label = "Cancel",
}) => {
  const { handleBackClicked } = useBackNavigation();

  return (
    <Button
      buttonColor="outline"
      extraClasses="button--expanded"
      onClick={handleBackClicked}
    >
      {label}
    </Button>
  );
};
