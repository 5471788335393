import { ReactComponent as LinkIcon } from "assets/icon-link.svg";
import React, { useState } from "react";
import { Link } from "react-router-dom";

import content from "./static/content.json";
const { copy, copied, failedToCopy } = content;

interface ClipboardLinkProps {
  url: string;
  readableLinkText?: string;
}

export const ClipboardLink: React.FC<ClipboardLinkProps> = ({
  url,
  readableLinkText,
}) => {
  const [copyText, setCopyText] = useState<string>(copy);
  const [failedToCopyError, setFailedToCopyError] = useState<string>("");

  const copyToClipboard = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopyText(copied);
      })
      .catch((err) => {
        console.error("Failed to copy:", err);
        setFailedToCopyError(failedToCopy);
      });
  };

  return (
    <>
      <article className="customer_tools_section__link_container customer_tools_section__item--borders">
        <Link
          className={`customer_tools_section__link account_page__link--with-icon`}
          to={url}
          role="button"
          onClick={(e) => {
            e.preventDefault();
            copyToClipboard(url);
          }}
          aria-label="Copy my referral url link"
        >
          <LinkIcon className="account_page__link__icon" />
          <p className="customer_tools_list__text">
            <strong>{readableLinkText ? readableLinkText : url}</strong>
          </p>
        </Link>
        <p className="customer_tools_list__text">
          <strong>
            <u>{copyText}</u>
          </strong>
        </p>
      </article>
      {failedToCopyError && (
        <>
          <div className="spacer" />
          <p className="text__colour--crimson">{failedToCopyError}</p>
        </>
      )}
    </>
  );
};
