export const formatWholesalePriceData = ({ prices }): WholesalePrice[] => {
  const priceData = prices.map(
    ({ end_at, price: { per, unit_code, value }, start_at }) => {
      return {
        endAt: end_at,
        startAt: start_at,
        price: {
          per,
          unitCode: unit_code,
          value,
        },
      };
    },
  );

  return priceData;
};
