import { GST } from './constants';

/**
 * Uses the GST const which evaluates to 1.15
 * @param billDetailsCost - the total amount a prospect has entered based on their comparison bill, this amount is by default GST inclusive
 * @param billEstimateTotalEstimate - the total amount on a Flick plan based on the dates and kWh values entered by the prospect, this amount is by default GST exclusive
 * @returns {string|null} - returns a GST exclusive amount (or null)
 */
export const calculateGstExclusiveSavings = (billDetailsCost, billEstimateTotalEstimate) => {
  if (!billDetailsCost || !billEstimateTotalEstimate) return null;

  const billDetailsCostExclGst = Number(billDetailsCost) / GST;

  return (billDetailsCostExclGst - billEstimateTotalEstimate).toFixed(2);
};
