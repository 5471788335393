import SupplyNode from '../models/supply-node';
import { camelCaseKeys } from '../utils/text-transforms';

class SupplyNodeDetailsService {
  constructor(apiBaseUrl) {
    this.apiBaseUrl = apiBaseUrl;
  }

  supplyNodeDetailsEndpoint(icpNumber) {
    return `${this.apiBaseUrl}/public_website/supply_nodes/${icpNumber}`;
  }

  fetch(icpNumber) {
    return fetch(this.supplyNodeDetailsEndpoint(icpNumber), {
      method: 'GET',
    })
      .then((response) => {
        if (!response.ok) {
          throw Error('There was a problem retrieving the supply node details');
        }
        return response.json();
      })
      .then((data) => {
        const supplyNodeData = camelCaseKeys(data);
        return new SupplyNode(supplyNodeData);
      })
      .catch((e) => {
        throw e;
      });
  }
}

export default SupplyNodeDetailsService;
