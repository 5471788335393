import React, { ReactNode } from "react";

type TextFallbackChartProps = {
  chartConfig: BarChartConfig;
};

export const TextFallbackChart: React.FC<TextFallbackChartProps> = ({
  chartConfig,
}) => {
  const formatLabel = (label: string[]): string => {
    return Array.isArray(label) ? label.join(" ") : label;
  };

  const formatData = (
    datasets: BarChartConfigDataSet[],
    unit: string,
    labelIndex: number,
  ): string | ReactNode => {
    if (datasets.length <= 1) {
      return `${datasets[0].data[labelIndex]} ${unit}`;
    } else {
      return (
        <dl role="list">
          {datasets.map((dataset) => (
            <React.Fragment key={`${dataset.label}-${labelIndex}`}>
              <dt>{dataset.label}</dt>
              <dd>{`${dataset.data[labelIndex]} ${unit}`}</dd>
            </React.Fragment>
          ))}
        </dl>
      );
    }
  };

  return (
    <dl title={`${chartConfig.chartTitle} details`}>
      {chartConfig.labels.map((label, labelIndex) => (
        <React.Fragment key={`${formatLabel(label)}-${labelIndex}`}>
          <dt>{formatLabel(label)}</dt>
          <dd>
            {formatData(
              chartConfig.datasets,
              chartConfig.yAxisText,
              labelIndex,
            )}
          </dd>
        </React.Fragment>
      ))}
    </dl>
  );
};
