export const isEmptyValue = (value: string | { [key: string]: string }) =>
  !value || value.length === 0;

/**
 * Returns if an input is valid or not.
 * The input is either required and valid or not required.
 * It also has to have an empty error message.
 */
type Input = {
  error: string;
  isRequired: boolean;
  isValid: boolean;
  value: string | object | boolean;
};
export const isValidInput = (input: Input): boolean => {
  return (
    ((input.isRequired && input.isValid) || !input.isRequired) &&
    isEmptyValue(input.error)
  );
};

export const hasInputValueChanged = (
  currentValue: string,
  nextValue: string,
) => {
  return currentValue !== nextValue;
};
