/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
// this rule appears to be too aggressive https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/issues/718

import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import InputLabel from '@flick/front_end_patterns/src/js/forms/form_fields_label';
import Dialog from '../common/dialog';
import sendAnalytics from '../../utils/analytics';

const AddressFormDialog = ({ onSelect, dialogRef }) => {
  useEffect(() => {
    new InputLabel().start();
  }, []);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const onSubmit = (data) => {
    dialogRef.current.hide();
    onSelect('', data, true);
    sendAnalytics('Enter address manually', 'Submitted');
  };

  return (
    <Dialog id="address-dialog" dialogRef={dialogRef}>
      <h2>What&apos;s your address?</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form_group">
          <div className="form_row form_row_inline">
            <div className="form_field">
              <div>
                <input
                  type="text"
                  name="unitIdentifier"
                  id="unitIdentifier"
                  {...register('unitIdentifier', { required: false })}
                />
                <label htmlFor="unitIdentifier">
                  Unit<span className="label_optional"> (Optional)</span>
                </label>
              </div>
            </div>
            <div className={errors.number ? 'form_field form_field_error' : 'form_field'}>
              <div>
                <input
                  type="text"
                  name="number"
                  id="number"
                  aria-required
                  {...register('number', { required: true })}
                />
                <label htmlFor="number">
                  Number<span className="label_required"> (Required)</span>
                </label>
              </div>
              {errors.number?.type === 'required' && (
                <span className="form_field_helper form_field_helper_error">
                  Something is missing...
                </span>
              )}
            </div>
          </div>
          <div className={errors.street ? 'form_row form_row_error' : 'form_row'}>
            <div className={errors.street ? 'form_field form_field_error' : 'form_field'}>
              <div>
                <input
                  type="text"
                  name="street"
                  id="street"
                  aria-required
                  {...register('street', { required: true })}
                />
                <label htmlFor="street">
                  Street<span className="label_required"> (Required)</span>
                </label>
              </div>
              {errors.street?.type === 'required' && (
                <span className="form_field_helper form_field_helper_error">
                  Something is missing...
                </span>
              )}
            </div>
          </div>
          <div className="form_row">
            <div className="form_field">
              <div>
                <input
                  type="text"
                  name="suburb"
                  id="suburb"
                  {...register('suburb', { required: false })}
                />
                <label htmlFor="suburb">
                  Suburb<span className="label_optional"> (Optional)</span>
                </label>
              </div>
            </div>
          </div>
          <div className={errors.city ? 'form_row form_row_error' : 'form_row'}>
            <div className={errors.city ? 'form_field form_field_error' : 'form_field'}>
              <div>
                <input
                  type="text"
                  name="city"
                  id="city"
                  aria-required
                  {...register('city', { required: true })}
                />
                <label htmlFor="city">
                  Town/city<span className="label_required"> (Required)</span>
                </label>
              </div>
              {errors.city?.type === 'required' && (
                <span className="form_field_helper form_field_helper_error">
                  Something is missing...
                </span>
              )}
            </div>
          </div>
          <div className="form_row">
            <div className="form_field">
              <div>
                <input
                  type="text"
                  name="postcode"
                  id="postcode"
                  {...register('postcode', { required: false })}
                />
                <label htmlFor="postcode">
                  Postcode<span className="label_optional"> (Optional)</span>
                </label>
              </div>
            </div>
          </div>
          <div className="form_row form_actions" data-bias="center">
            <button className="button button_primary" type="submit">
              Check address
            </button>
          </div>
        </div>
      </form>
    </Dialog>
  );
};

AddressFormDialog.propTypes = {
  onSelect: PropTypes.func.isRequired,
  dialogRef: PropTypes.object.isRequired,
};

export default AddressFormDialog;
