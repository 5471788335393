import React from "react";

type AccountSelectMenuProps = {
  callback: (value: string) => void;
  data: AccountInfo[];
  defaultValue: string | number;
};

export const AccountSelectMenu: React.FC<AccountSelectMenuProps> = ({
  defaultValue,
  data,
  callback,
}) => {
  return (
    <div className="customer_tools_select background--yellow nav_customer_tools_subnav__input">
      <select
        id="account"
        name="account"
        defaultValue={defaultValue}
        onChange={(event) => callback(event.target.value)}
        className="customer_tools_select__field customer_tools_select__field--account input_unlabeled"
      >
        {data.map((account, index) => (
          <option value={index} key={`account-${index}`}>
            {!account.attributes.active && "CLOSED - "}
            {account.attributes.address} - Account #
            {account.attributes.account_number}
          </option>
        ))}
      </select>
      <label className="sr-only" htmlFor="account">
        Current account
      </label>
    </div>
  );
};
