import { DailyUsageSection } from "components/DailyUsageSection";
import { withErrorBoundary } from "components/fallback/withErrorBoundary";
import { LoadingSection } from "components/LoadingSection";
import { NoAvailableDataHomePage } from "components/Usage/NoAvailableData";
import { addSeconds, differenceInDays, isBefore } from "date-fns";
import { expireTime } from "queries/queries.utils";
import { useGetAggregateRatesQuery } from "queries/ratingApi";
import React, { useMemo } from "react";
import {
  calculateCurrentPeriodDates,
  calculatePreviousPeriodDates,
  previousDataAvailable,
} from "utils/aggregateCalculations/calculatePeriodDates";
import { jwtClient } from "utils/jwtClient";
import { prepareDailyUsageData } from "utils/prepareDailyUsageData";

interface BillingPeriod {
  code: string;
  duration: number;
  periodName: string;
}

interface PrerenderDetails {
  currentPeriodEndDate: Date;
  currentPeriodStartDate: Date;
  previousPeriodStartDate: Date;
  hasPreviousData: boolean;
  startAt: Date;
}

interface DailyUsageSectionWrapperProps {
  billingPeriod: BillingPeriod;
  billingEntityData: BillingEntity;
  supplyNodeRef: string;
  currentProductName: string;
}

/**
 * @description preparation values for queryFetch and render DailyUsageSection
 */
const getPrerenderDetails = (
  billingPeriod: BillingPeriod,
  billingEntityData: BillingEntity,
): PrerenderDetails => {
  const { endDate: currentPeriodEndDate, startDate: currentPeriodStartDate } =
    calculateCurrentPeriodDates(+billingPeriod.duration);

  const { startDate: previousPeriodStartDate } = calculatePreviousPeriodDates(
    currentPeriodStartDate,
    +billingPeriod.duration,
  );
  const hasPreviousData = previousDataAvailable(
    +billingPeriod.duration,
    new Date(billingEntityData.start_at),
  );

  const startAt =
    hasPreviousData && previousPeriodStartDate
      ? previousPeriodStartDate
      : currentPeriodStartDate;

  return {
    currentPeriodEndDate,
    currentPeriodStartDate,
    previousPeriodStartDate,
    hasPreviousData,
    startAt,
  };
};

/**
 * @description calculate daily usage, default is empty array
 */
const getDailyUsageData = (
  aggregatesData: Aggregates,
  prepDetails: PrerenderDetails,
): DailyUsage[] => {
  if (!aggregatesData || !prepDetails) return [];
  const timePeriodDifference = differenceInDays(
    addSeconds(new Date(prepDetails.currentPeriodEndDate), 1),
    new Date(prepDetails.currentPeriodStartDate),
  );

  return prepareDailyUsageData(
    aggregatesData,
    prepDetails.currentPeriodStartDate,
    timePeriodDifference,
  );
};
/**
 * @description renders the DailyUsageSection when it's called from the HomePage
 */
export const DailyUsageSectionWrapper =
  withErrorBoundary<DailyUsageSectionWrapperProps>(
    ({
      billingPeriod,
      billingEntityData,
      supplyNodeRef,
      currentProductName,
    }: DailyUsageSectionWrapperProps) => {
      const prepDailyUsageDetails = useMemo(
        () => getPrerenderDetails(billingPeriod, billingEntityData),
        [billingPeriod, billingEntityData],
      );

      const {
        data: aggregatesData,
        isFetching: isAggregateFetching,
        isError: isAggregateError,
        error: aggregateError,
      } = useGetAggregateRatesQuery(
        {
          supplyNodeRef,
          startAt: prepDailyUsageDetails.startAt,
          endAt: prepDailyUsageDetails.currentPeriodEndDate,
          jwtClient,
          source: "Daily Usage Section",
        },
        {
          skip:
            !billingEntityData ||
            !supplyNodeRef ||
            // query start date must not before user's start date else it will invalid request and expire token
            isBefore(
              prepDailyUsageDetails.startAt,
              new Date(billingEntityData.start_at),
            ),
          refetchOnMountOrArgChange: expireTime,
        },
      );

      if (isAggregateFetching) return <LoadingSection />;

      if (isAggregateError) {
        // for ErrorBoundary in withErrorBoundary to catch and display fallback
        throw aggregateError;
      }

      const dailyUsageData = getDailyUsageData(
        aggregatesData,
        prepDailyUsageDetails,
      );

      // if dailyUsageData is empty array
      // or  daily usage start date is before user's start date
      // then fallbacks
      if (
        dailyUsageData.length === 0 ||
        isBefore(
          prepDailyUsageDetails.startAt,
          new Date(billingEntityData.start_at),
        )
      ) {
        return <NoAvailableDataHomePage />;
      }

      return (
        <DailyUsageSection
          sectionSubHeading={`For the last ${billingPeriod.periodName}`}
          previousPeriodStartDate={
            prepDailyUsageDetails.previousPeriodStartDate
          }
          hasPreviousData={prepDailyUsageDetails.hasPreviousData}
          productName={currentProductName}
          dailyUsageData={dailyUsageData}
          periodName={billingPeriod.periodName}
        />
      );
    },
  );
