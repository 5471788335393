import React from "react";

const COST_TYPE = {
  other: "other",
};

type OtherCostsDetailProps = {
  costType: string;
  lineItem: Array<FormattedLineItemValues>;
};

/**
 * @description Displays the other costs details in a set of labels and costs
 */
export const OtherCostsDetail: React.FC<OtherCostsDetailProps> = ({
  costType,
  lineItem,
}) => (
  <>
    {lineItem.map(
      (item) =>
        Boolean(Number(item.cost)) && (
          <React.Fragment key={item.id}>
            {item.label.toLowerCase().includes("delayed") && (
              <p className="text__secondary">
                Delayed charges for usage data that was missing in a previous
                bill
              </p>
            )}
            {item.label.toLowerCase().includes("correction") && (
              <p className="text__secondary">
                Correcting a mistake for charges in a previous bill
              </p>
            )}
            {costType === COST_TYPE.other && (
              <p className="text__secondary">{item.label}</p>
            )}
          </React.Fragment>
        ),
    )}
  </>
);
