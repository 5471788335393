import { ReactComponent as LeafIconSvg } from "assets/icon_leaf.svg";
import { PercentageChart } from "components/PercentageChart";
import React from "react";
import { AVAILABLE_PERIODS } from "utils/constants";

import { renderDeltaMessage } from "./DeltaMessage";

type OffpeakUsageSectionProps = {
  duration: string;
  offpeakRatio: string | number;
  previousPeriodOffpeakRatio: string | number;
};

export const OffpeakUsageSection: React.FC<OffpeakUsageSectionProps> = ({
  offpeakRatio,
  previousPeriodOffpeakRatio,
  duration,
}) => {
  const offpeakPercentage = +offpeakRatio * 100;

  const previousPeriodOffpeakPercentage = previousPeriodOffpeakRatio
    ? 100 * +previousPeriodOffpeakRatio
    : 0;
  const previousPeriodDelta =
    offpeakPercentage - previousPeriodOffpeakPercentage;

  const { periodName } = AVAILABLE_PERIODS[duration];

  return (
    <>
      {offpeakRatio && (
        <>
          <section className="usage_details_section">
            <div className="header_section">
              <LeafIconSvg data-testid="icon" />
              <h2 className="h4">
                {offpeakPercentage.toFixed(0)}% at off-peak times
              </h2>
              {previousPeriodOffpeakRatio &&
                renderDeltaMessage({
                  delta: previousPeriodDelta,
                  periodLabel: `last ${periodName}`,
                })}
            </div>
            {previousPeriodOffpeakRatio && (
              <PercentageChart
                percentage={offpeakPercentage}
                markerPercentage={previousPeriodOffpeakPercentage}
                markerLabel={`Last ${periodName}`}
              />
            )}
          </section>
        </>
      )}
    </>
  );
};
