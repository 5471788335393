import React from 'react';
import PropTypes from 'prop-types';

export const LinkButton = ({ extraClasses, href, handleOnClick, linkCopy }) => (
  <a className={`button ${extraClasses}`} type="button" href={href} onClick={handleOnClick}>
    {linkCopy}
  </a>
);

LinkButton.propTypes = {
  href: PropTypes.string.isRequired,
  handleOnClick: PropTypes.func.isRequired,
  linkCopy: PropTypes.string.isRequired,
  extraClasses: PropTypes.string,
};

LinkButton.defaultProps = {
  extraClasses: '',
};
