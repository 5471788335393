import { BarChartWrapper } from "components/charts/BarChartWrapper";
import {
  addHours,
  format,
  formatRelative,
  subHours,
  subMinutes,
} from "date-fns";
import { expireTime } from "queries/queries.utils";
import { useGetForecastedPricesQuery } from "queries/ratingApi";
import React from "react";
import { useAppSelector } from "reduxUtils/hook";
import { forecastPriceLegend } from "utils/charts";
import { jwtClient } from "utils/jwtClient";
import { prepareForecastedPriceData } from "utils/prepareForecastedPriceData";

import { withErrorBoundary } from "./fallback/withErrorBoundary";
import { LoadingSection } from "./LoadingSection";

/**
 *  @description WholesalePriceForecastSection component
 *  display Wholesale details in HomePage
 */
export const WholesalePriceForecastSection: React.FC = withErrorBoundary(() => {
  const { supplyNodeRef } = useAppSelector((store) => store.currentAccount);

  /*
   * setSeconds method is required to set the seconds for a specified date to local time.
   * This prevents the api being called whenever the seconds changes.
   * startOfPeriod date-time is 12 hours prior to the current local date-time.
   * endOfPeriod date-time is 2.5 hours ahead of the current local date-time,
   * due to this being the longest period of time that we have data for and know we can reliably render.
   */
  const startOfPeriod = subHours(new Date(), 12).setSeconds(0, 0);
  const endOfPeriod = addHours(new Date(), 2).setMinutes(30, 0, 0);

  const {
    data: todaysForecastPrices,
    isLoading: istodaysForecastPricesLoading,
  } = useGetForecastedPricesQuery(
    {
      supplyNodeRef,
      startAt: startOfPeriod,
      endAt: endOfPeriod,
      jwtClient,
    },
    {
      skip: !supplyNodeRef || !startOfPeriod || !endOfPeriod,
      refetchOnMountOrArgChange: expireTime,
    },
  );

  const upcomingForecastPeriodStart = subMinutes(new Date(), 30);
  const upcomingForecastPeriodEnd = addHours(upcomingForecastPeriodStart, 3);

  const { priceData: todaysForecastData, maxYValue: todaysPricesMaxYValue } =
    prepareForecastedPriceData(todaysForecastPrices);

  const upcomingForecastPrices = todaysForecastPrices?.filter(
    (prices) =>
      prices.start_at >= upcomingForecastPeriodStart.toISOString() &&
      prices.start_at <= upcomingForecastPeriodEnd.toISOString(),
  );

  const {
    priceData: upcomingForecastData,
    maxYValue: upcomingPricesMaxYValue,
  } = prepareForecastedPriceData(upcomingForecastPrices);

  if (istodaysForecastPricesLoading) return <LoadingSection />;

  const todaysPricesBarChartConfig = {
    labels: todaysForecastData?.map((data) => format(data.time, "h:mmaa")),
    yAxisText: "c/kWh",
    legendOptions: {
      display: true,
      labels: forecastPriceLegend,
    },
    datasets: [
      {
        barColours: todaysForecastData?.map((data) => data.barColour),
        data: todaysForecastData?.map((data) =>
          !data.price || data.status === "missing" ? 0 : data.price,
        ),
        status: todaysForecastData?.map((data) => data.status),
      },
    ],
    showZoom: true,
  };

  const upcomingForecastDataPriceLabels = upcomingForecastData?.map(
    (data) => `${Math.round(data.price)} c`,
  );

  const upcomingForecastDataTimeLabels = upcomingForecastData?.map((data) =>
    data?.time ? format(data.time, "h:mmaa") : "",
  );

  const upcomingPeriodsBarChartConfig = {
    labels: upcomingForecastDataPriceLabels,
    legendOptions: {
      display: false,
    },
    datasets: [
      {
        barColours: upcomingForecastData?.map((data) => data.barColour),
        data: upcomingForecastData?.map((data) => data.price || 0),
        status: upcomingForecastData?.map((data) => data.status),
      },
    ],
    scales: {
      x: {
        grid: {
          drawTicks: false,
        },
        ticks: {
          font: {
            weight: "bold",
            size: 15,
          },
        },
      },
      x2: {
        grid: {
          drawOnChartArea: false,
          drawTicks: false,
          drawBorder: false,
        },
        ticks: {
          display: true,
          font: {
            weight: [400],
            size: 11,
          },
          padding: 0,
          callback: (value) => upcomingForecastDataTimeLabels[value],
        },
      },
      y: {
        display: false,
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
        title: {
          align: "center",
        },
      },
    },
  };

  return (
    <>
      {!todaysForecastPrices && (
        <>
          <div
            data-testid="forecastPricesNotificationBanner"
            className="notification_banner notification_notice icon"
          >
            <p className="heading">Forecast prices are currently unavailable</p>
          </div>
        </>
      )}
      <h2 className="h5">Price forecast</h2>
      <p className="small">In cents per kWh</p>
      <span className="spacer"></span>
      <BarChartWrapper
        maxYValue={upcomingPricesMaxYValue}
        chartConfig={upcomingPeriodsBarChartConfig}
      />

      <h2 className="h5">Wholesale prices</h2>
      <p className="small">
        From {formatRelative(startOfPeriod, new Date())} to{" "}
        {formatRelative(endOfPeriod, new Date())}
      </p>
      <BarChartWrapper
        maxYValue={todaysPricesMaxYValue}
        chartConfig={todaysPricesBarChartConfig}
      />
    </>
  );
});
