import React from 'react';
import PropTypes from 'prop-types';

const PriceNotFound = ({ continueJoiningWithoutPrices }) => (
  <div className="address_bar_wrapper">
    <div className="address_bar">
      <div className="address_bar_content address_bar_content_large">
        <h4 className="heading address_bar_title">
          Sorry, we&apos;re having trouble finding prices for your property.
        </h4>
        <p>
          You can still{' '}
          <a
            href="#continue"
            className="link address_bar_link"
            onClick={continueJoiningWithoutPrices}
          >
            continue joining
          </a>{' '}
          - we&apos;ll give you a call after you submit your application to gather more information
          on your property. There may be a small chance we&apos;ll not be able to supply your
          property.
        </p>
      </div>
    </div>
  </div>
);

PriceNotFound.propTypes = {
  continueJoiningWithoutPrices: PropTypes.func.isRequired,
};

export default PriceNotFound;
