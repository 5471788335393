import { PlanRates } from "components/PlanRates/PlanRates";
import React from "react";
import { PLAN_COLOURS, PRODUCT_DISPLAY_NAMES } from "utils/constants";
import { normaliseProductName } from "utils/productNameUtils";

type PlanInfoProps = {
  channelCodes?: string;
  name: string;
  description: string;
  id: string;
  rates: {
    subscriptionRates: AvailablePlanRate[] | SubscriptionUsageRate[];
    usageRates: AvailablePlanRate[] | SubscriptionUsageRate[];
  };
  isGstInclusive: boolean;
};

export const PlanInfo = ({
  name,
  description,
  id,
  rates,
  isGstInclusive,
}: PlanInfoProps) => {
  return (
    <section className="spacer spacer--medium">
      <div
        className={`panel background--${
          PLAN_COLOURS[normaliseProductName(name.toLowerCase())] || "grey"
        }`}
      >
        <h3 className="heading plan-name">{name}</h3>
        <p>{description}</p>
      </div>
      <div className="current_plan_page__plan_info panel background--linen">
        {name.toLowerCase() === PRODUCT_DISPLAY_NAMES.freestyle && (
          <div className="notification_banner notification_notice icon">
            <p>
              Please note that because Wholesale's kWh rates are variable, the
              Usage Rates shown here are based on the 6-month rolling average
              spot price for NZ. Depending on how your household uses power and
              where you live, your Usage Rates may differ from these.
            </p>
          </div>
        )}
        <p className="h4 rate-code">Usage rates</p>
        <PlanRates
          rates={rates.usageRates}
          planId={id}
          isGstInclusive={isGstInclusive}
        />
        <p className="h4 rate-code">Subscription rates</p>
        <PlanRates
          rates={rates.subscriptionRates}
          planId={id}
          isGstInclusive={isGstInclusive}
        />
      </div>
    </section>
  );
};
