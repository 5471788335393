import { CloseAccountPageContent } from "pages/CloseAccountPage/CloseAccountPageContent";
import { CloseAccountFormContextProvider } from "pages/CloseAccountPage/CloseAccountPageContext";
import React from "react";

export const CloseAccountPage: React.FC = () => {
  return (
    <CloseAccountFormContextProvider>
      <CloseAccountPageContent />
    </CloseAccountFormContextProvider>
  );
};
