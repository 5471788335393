/* eslint-disable max-params */
import { AVAILABLE_PERIODS, BRAND_COLOURS } from "utils/constants";

/**
 * @description Takes the periodName and determines what the label for the period should be.
 * Months are hard-coded to 30 days, so 'previous' values in this graph do not represent the same equivalent day 1 month prior.
 */
export const determineLabelPeriod = (periodName: string): string => {
  return periodName === AVAILABLE_PERIODS.P1M.periodName
    ? "30 days"
    : periodName;
};

/**
 * @description returns an array of consumption data for this period. When the status is "final" or "missing" it pushes the actual data value to the array. When the status is "pending" it pushes 0 to the array.
 */
export const getThisPeriodFinalAndMissingData = (
  dailyUsageData: DailyUsage[],
): number[] => {
  const finalAndMissingDataForThisPeriod = [];
  dailyUsageData.forEach((data) => {
    if (data.status === "final" || data.status === "missing") {
      finalAndMissingDataForThisPeriod.push(data.thisPeriod.consumption);
    }
    if (data.status === "pending") {
      finalAndMissingDataForThisPeriod.push(0);
    }
  });

  return finalAndMissingDataForThisPeriod;
};

/**
 * @description returns an array of consumption data for the last period. When the status is "final" or "missing" it pushes the actual data value to the array. When the status is "pending" it pushes 0 to the array.
 */
export const getLastPeriodFinalAndMissingData = (
  dailyUsageData: DailyUsage[],
): number[] => {
  const finalAndMissingDataForLastPeriod = [];
  dailyUsageData.forEach((data) => {
    if (data.previousStatus === "final" || data.previousStatus === "missing") {
      finalAndMissingDataForLastPeriod.push(data.lastPeriod.consumption);
    }
    if (data.previousStatus === "pending") {
      finalAndMissingDataForLastPeriod.push(0);
    }
  });

  return finalAndMissingDataForLastPeriod;
};

/**
 * @description returns an array of consumption data for this period. When the status is "final" or "missing" it pushes 0 to the array. When the status is "pending" it pushes the actual data value to the array.
 */
export const getThisPeriodPendingData = (
  dailyUsageData: DailyUsage[],
): number[] => {
  const pendingDataForThisPeriod = [];
  dailyUsageData.forEach((data) => {
    if (data.status === "final" || data.status === "missing") {
      pendingDataForThisPeriod.push(0);
    }
    if (data.status === "pending") {
      pendingDataForThisPeriod.push(data.thisPeriod.consumption);
    }
  });

  return pendingDataForThisPeriod;
};

/**
 * @description returns an array of consumption data for the last period. When the status is "final" or "missing" it pushes 0 to the array. When the status is "pending" it pushes the actual data value to the array.
 */
export const getLastPeriodPendingData = (
  dailyUsageData: DailyUsage[],
): number[] => {
  const pendingDataForLastPeriod = [];
  dailyUsageData.forEach((data) => {
    if (data.previousStatus === "final" || data.previousStatus === "missing") {
      pendingDataForLastPeriod.push(0);
    }
    if (data.previousStatus === "pending") {
      pendingDataForLastPeriod.push(data.lastPeriod.consumption);
    }
  });

  return pendingDataForLastPeriod;
};

/**
 * @description Takes the dailyUsageData, labelPeriod, current period final and missing data and current period pending data. Returns groupedBarChartConfig object.
 */
export const generateCutomPeriodDailyUsageGroupedBarChartConfig = (
  dailyUsageData: DailyUsage[],
  labelPeriod: string,
  currentPeriodFinalAndMissingData: number[],
  currentPeriodPendingData: number[],
): GroupedBarChartConfig => ({
  labels: dailyUsageData.map((data) => [data.weekday, data.ordinal]),
  dates: dailyUsageData.map((data) => data.date),
  yAxisText: "kWh",
  chartTitle: "Daily usage",
  datasets: [
    {
      label: "Actual",
      data: currentPeriodFinalAndMissingData,
      status: dailyUsageData.map((data) => data.status),
      barColour: BRAND_COLOURS.yellow,
      hoverBackgroundColour: BRAND_COLOURS.yellowDark,
      stack: "Actual",
    },
    {
      label: "Estimate",
      data: currentPeriodPendingData,
      status: dailyUsageData.map((data) => data.status),
      barColour: BRAND_COLOURS.pink,
      hoverBackgroundColour: BRAND_COLOURS.pinkDark,
      stack: "Estimate",
    },
  ],
  showZoom: true,
});

/**
 * @description Takes the dailyUsageData, labelPeriod, current and previous period final and missing data and current and previous period pending data. Returns groupedBarChartConfig object.
 */
export const generateDailyUsageGroupedBarChartConfig = (
  dailyUsageData: DailyUsage[],
  labelPeriod: string,
  currentPeriodFinalAndMissingData: number[],
  currentPeriodPendingData: number[],
  previousPeriodFinalAndMissingData: number[],
  previousPeriodPendingData: number[],
): GroupedBarChartConfig => ({
  labels: dailyUsageData.map((data) => [data.weekday, data.ordinal]),
  dates: dailyUsageData.map((data) => data.date),
  yAxisText: "kWh",
  chartTitle: "Daily usage",
  datasets: [
    {
      label: `Previous ${labelPeriod}`,
      data: previousPeriodFinalAndMissingData,
      status: dailyUsageData.map((data) => data.previousStatus),
      barColour: BRAND_COLOURS.greyShade30,
      hoverBackgroundColour: BRAND_COLOURS.greyShade50,
      stack: `Previous ${labelPeriod}`,
    },
    {
      label: `Current ${labelPeriod}`,
      data: currentPeriodFinalAndMissingData,
      status: dailyUsageData.map((data) => data.status),
      barColour: BRAND_COLOURS.yellow,
      hoverBackgroundColour: BRAND_COLOURS.yellowDark,
      stack: `Current ${labelPeriod}`,
    },
    {
      label: "Previous (estimate)",
      data: previousPeriodPendingData,
      status: dailyUsageData.map((data) => data.previousStatus),
      barColour: BRAND_COLOURS.greyShade10,
      hoverBackgroundColour: BRAND_COLOURS.greyShade50,
      stack: `Previous ${labelPeriod}`,
    },
    {
      label: "Current (estimate)",
      data: currentPeriodPendingData,
      status: dailyUsageData.map((data) => data.status),
      barColour: BRAND_COLOURS.pink,
      hoverBackgroundColour: BRAND_COLOURS.pinkDark,
      stack: `Current ${labelPeriod}`,
    },
  ],
  showZoom: true,
});
