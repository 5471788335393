import "./GetTheFlickApp.scss";

import appStoreBadge from "assets/image_app_store_badge.png";
import googlePlayBadge from "assets/image_google_play_badge.png";
import imageUsageIphone from "assets/image_usage_iphone.png";
import React from "react";
import { useAppSelector } from "reduxUtils/hook";

import content from "./static/content.json";
const { cta, appStoreAlt, playStoreAlt, imageAlt } = content;

export const GetTheFlickApp: React.FC = ({}) => {
  const { nativeRenderMode } = useAppSelector((store) => store.currentAccount);

  if (!nativeRenderMode) {
    return (
      <>
        <div className="app_cta background--yellow">
          <div className="app_cta__title">
            <h2 className="heading h4">{cta}</h2>
          </div>
          <div className="app_cta__app_store_badge">
            <a href="https://apps.apple.com/nz/app/flick-electric-co/id1081680985">
              <img src={appStoreBadge} alt={appStoreAlt} />
            </a>
          </div>
          <div className="app_cta__google_play_badge">
            <a href="https://play.google.com/store/apps/details?id=nz.co.flickelectric.androidapp&hl=en&gl=US">
              <img src={googlePlayBadge} alt={playStoreAlt} />
            </a>
          </div>
          <div className="app_cta__flick_app">
            <img src={imageUsageIphone} alt={imageAlt} />
          </div>
        </div>
      </>
    );
  }
};
