import "../../styles/Unauthenticated.scss";

import { Button } from "@flick/fep-library";
import { ReactComponent as FlickLogo } from "app/assets/images/flick_logo_2021.svg";
import { NotificationBanner } from "components/NotificationBanner";
import React from "react";
import { Helmet } from "react-helmet-async";
import { Navigate } from "react-router";
import { useAppSelector } from "reduxUtils/hook";

type SplashPageProps = {
  login: () => void;
  isAuthenticated: boolean;
};

export const SplashPage: React.FC<SplashPageProps> = ({
  login,
  isAuthenticated,
}) => {
  const notificationBanner = useAppSelector(
    (store) => store.notificationBanner,
  );

  if (isAuthenticated) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <Helmet>
        <body className={"background--black background--spotted"} />
      </Helmet>
      <section className="unauthenticated__container unauthenticated__container--inverse">
        <FlickLogo className="unauthenticated__logo unauthenticated__logo--yellow" />
        {notificationBanner && (
          <div className="unauthenticated__notification_container">
            <NotificationBanner />
          </div>
        )}
        <h2 className="unauthenticated__tagline h3">You've got the power!</h2>
        <Button
          buttonSize="large"
          buttonColor="contrast"
          buttonVariant="cta"
          extraClasses="button--expanded spacer--large"
          onClick={login}
        >
          Log in
        </Button>
      </section>
    </>
  );
};
