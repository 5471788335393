/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import UserType from './userType';
import GstInclusion from './gstInclusion';

const BillEstimateOptions = ({
  userType,
  setUserType,
  gstInclusion,
  setGstInclusion,
  billDetails,
  businessPlan,
}) => {
  const hasExistingEstimate = !Object.values(billDetails).includes(null);

  return (
    <form className="filters" action="#" method="get">
      {hasExistingEstimate && (
        <>
          <legend className="plan_card_component_filters_legend">
            Estimate based on: <strong>{billDetails.usage}kWh</strong> over{' '}
            <strong>{Math.round(billDetails.daysInBillingPeriod)}</strong> days
          </legend>
          <GstInclusion gstInclusion={gstInclusion} setGstInclusion={setGstInclusion} />
        </>
      )}

      {!businessPlan && !hasExistingEstimate && (
        <>
          <legend className="plan_card_component_filters_legend">Rates based on:</legend>
          <UserType userType={userType} setUserType={setUserType} />
          <GstInclusion gstInclusion={gstInclusion} setGstInclusion={setGstInclusion} />
        </>
      )}
    </form>
  );
};

BillEstimateOptions.propTypes = {
  userType: PropTypes.string.isRequired,
  setUserType: PropTypes.func.isRequired,
  gstInclusion: PropTypes.string.isRequired,
  setGstInclusion: PropTypes.func.isRequired,
  billDetails: PropTypes.object.isRequired,
  businessPlan: PropTypes.bool.isRequired,
};

export default BillEstimateOptions;
