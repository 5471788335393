import { GroupedBarChart } from "components/charts/GroupedBarChart";
import React, { memo } from "react";

import {
  determineLabelPeriod,
  generateCutomPeriodDailyUsageGroupedBarChartConfig,
  generateDailyUsageGroupedBarChartConfig,
  getLastPeriodFinalAndMissingData,
  getLastPeriodPendingData,
  getThisPeriodFinalAndMissingData,
  getThisPeriodPendingData,
} from "./UsageByDayChart.utils";

type UsageByDayChartProps = {
  dailyUsageData: DailyUsage[];
  isCustomDatePeriod?: boolean;
  periodName: string;
  setSelectedDay: (value: number) => void;
};

/**
 * @description Used in DailyUsageSection
 */
export const UsageByDayChart: React.FC<UsageByDayChartProps> = memo(
  ({ dailyUsageData, isCustomDatePeriod, periodName, setSelectedDay }) => {
    // Scale the Y axis to fit 110% of the largest day's usage
    const maxYValue = Math.ceil(
      1.1 *
        Math.max(
          ...dailyUsageData.map((d) => d.thisPeriod.consumption),
          ...dailyUsageData.map((d) => d.lastPeriod.consumption),
        ),
    );

    const labelPeriod = determineLabelPeriod(periodName);

    const currentPeriodFinalAndMissingData =
      getThisPeriodFinalAndMissingData(dailyUsageData);

    const currentPeriodPendingData = getThisPeriodPendingData(dailyUsageData);

    const previousPeriodFinalAndMissingData =
      getLastPeriodFinalAndMissingData(dailyUsageData);

    const previousPeriodPendingData = getLastPeriodPendingData(dailyUsageData);

    const groupedBarChartConfig = isCustomDatePeriod
      ? generateCutomPeriodDailyUsageGroupedBarChartConfig(
          dailyUsageData,
          labelPeriod,
          currentPeriodFinalAndMissingData,
          currentPeriodPendingData,
        )
      : generateDailyUsageGroupedBarChartConfig(
          dailyUsageData,
          labelPeriod,
          currentPeriodFinalAndMissingData,
          currentPeriodPendingData,
          previousPeriodFinalAndMissingData,
          previousPeriodPendingData,
        );

    return (
      <GroupedBarChart
        maxYValue={maxYValue}
        chartConfig={groupedBarChartConfig}
        onClickMutation={setSelectedDay}
      />
    );
  },
);
