import { RadioOption } from "components/SingleSelectRadioGroup/SingleSelectRadioGroup";
import React, { useContext, useState } from "react";
import { isEmptyValue, isValidInput } from "utils/forms/forms";
import {
  moveInDateValidation,
  moveOutDateValidation,
} from "utils/validations/moveDateValidation";
import { validationFunctionHelper } from "utils/validations/validationFunctionHelper";

import content from "./static/content.json";
const {
  addressFinderError,
  dateInvalidError,
  moveInDateRequiredError,
  moveOutDateRequiredError,
  termsAndConditionsError,
} = content;

type MovingHouseContext = {
  address: FormField;
  isFormValid: boolean;
  isMovingOut: boolean;
  medicallyDependent: FormField;
  moveDate: FormField;
  moveOutDate: FormField;
  movingOut: FormField;
  termsAndConditions: FormField;
  userPlan: FormField;
};

const MovingHouseFormContext = React.createContext<MovingHouseContext>(
  {} as MovingHouseContext,
);

const validationMoveInDate = (value: string) => {
  return validationFunctionHelper({
    value,
    validationFunction: moveInDateValidation,
    requiredError: moveInDateRequiredError,
    invalidError: dateInvalidError,
  });
};

const validationMoveOutDate = (value: string) => {
  return validationFunctionHelper({
    value,
    validationFunction: moveOutDateValidation,
    requiredError: moveOutDateRequiredError,
    invalidError: dateInvalidError,
  });
};

export const useMovingHouseFormContext = () =>
  useContext(MovingHouseFormContext);

const initialFormFieldState = {
  value: "",
  isRequired: true,
  isValid: false,
  error: "",
};

export const MovingHouseFormContextProvider = ({ children }) => {
  const [address, setAddress] = useState({
    ...initialFormFieldState,
    value: {},
  });
  const [moveDate, setMoveDate] = useState(initialFormFieldState);
  const [medicallyDependent, setMedicallyDependent] = useState({
    ...initialFormFieldState,
    value: {},
  });
  const [movingOut, setMovingOut] = useState({
    ...initialFormFieldState,
    value: {},
  });
  const [isMovingOut, setIsMovingOut] = useState(false);
  const [moveOutDate, setMoveOutDate] = useState(initialFormFieldState);
  const [userPlan, setUserPlan] = useState({
    ...initialFormFieldState,
    value: {},
  });
  const [termsAndConditions, setTermsAndConditions] = useState({
    ...initialFormFieldState,
    value: false,
  });

  const setValueAddress = (value: CustomerAddress | null) => {
    const isValid = !isEmptyValue(value);
    setAddress((prev) => ({
      ...prev,
      isValid,
      error: isValid ? "" : addressFinderError,
      value,
    }));
  };

  const setValueMoveDate = (value: string) => {
    const error = validationMoveInDate(value);
    setMoveDate((prev) => ({
      ...prev,
      isValid: !error,
      error,
      value,
    }));
  };

  const setValueMedicallyDependent = (value: RadioOption) => {
    setMedicallyDependent((prev) => ({
      ...prev,
      isValid: true,
      error: "",
      value,
    }));
  };

  const setValueMovingOut = (value: RadioOption) => {
    setIsMovingOut(value?.value === "yes");
    setMovingOut((prev) => ({
      ...prev,
      isValid: true,
      error: "",
      value,
    }));
  };

  const setValueMoveOutDate = (value: string) => {
    const error = validationMoveOutDate(value);
    setMoveOutDate((prev) => ({
      ...prev,
      isValid: !error,
      error,
      value,
    }));
  };

  const setValueUserPlan = (value: boolean) => {
    setUserPlan((prev) => ({
      ...prev,
      isValid: true,
      error: "",
      value,
    }));
  };

  const setValueTermsAndConditions = () => {
    setTermsAndConditions((prev) => {
      const value = !prev.value;
      return {
        ...prev,
        isValid: value,
        error: value ? "" : termsAndConditionsError,
        value,
      };
    });
  };

  const allInputsValid = [
    isValidInput(address),
    isValidInput(moveDate),
    isValidInput(movingOut),
    isValidInput(medicallyDependent),
    isValidInput(userPlan),
    isValidInput(termsAndConditions),
    !isMovingOut || isValidInput(moveOutDate),
  ].every(Boolean);

  return (
    <MovingHouseFormContext.Provider
      value={{
        address: {
          fieldValue: address,
          handleOnChange: setValueAddress,
        },
        moveDate: {
          fieldValue: moveDate,
          handleOnChange: setValueMoveDate,
        },
        movingOut: {
          fieldValue: movingOut,
          handleOnChange: setValueMovingOut,
        },
        moveOutDate: {
          fieldValue: moveOutDate,
          handleOnChange: setValueMoveOutDate,
        },
        medicallyDependent: {
          fieldValue: medicallyDependent,
          handleOnChange: setValueMedicallyDependent,
        },
        termsAndConditions: {
          fieldValue: termsAndConditions,
          handleOnChange: setValueTermsAndConditions,
        },
        userPlan: {
          fieldValue: userPlan,
          handleOnChange: setValueUserPlan,
        },
        isFormValid: allInputsValid,
        isMovingOut,
      }}
    >
      {children}
    </MovingHouseFormContext.Provider>
  );
};
