import "./ApplicationProcess.scss";

import React from "react";
import { useAppSelector } from "reduxUtils/hook";

import content from "./static/content.json";
const { flickAppCTASegue, applicationProcessTitle, applicationProcessMessage } =
  content;

export const ApplicationProcess: React.FC = ({}) => {
  const { nativeRenderMode } = useAppSelector((store) => store.currentAccount);

  function FlickAppCTASegue() {
    if (!nativeRenderMode) return <p>{flickAppCTASegue}</p>;
  }

  return (
    <div className="application_process_container">
      <h2 className="heading h4">{applicationProcessTitle}</h2>
      <p>{applicationProcessMessage}</p>

      <FlickAppCTASegue />
    </div>
  );
};
