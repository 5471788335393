import { DailyUsageSectionWrapped } from "components/DailyUsageSection";
import { NoAvailableData } from "components/Usage/NoAvailableData";
import { addSeconds, differenceInDays, format } from "date-fns";
import { expireTime } from "queries/queries.utils";
import { useGetAggregateRatesQuery } from "queries/ratingApi";
import React, { useMemo } from "react";
import { useAppSelector } from "reduxUtils/hook";
import { jwtClient } from "utils/jwtClient";
import { prepareDailyUsageData } from "utils/prepareDailyUsageData";
import { prepareUsageTotal } from "utils/usageCalculations/prepareUsageTotal";

import { LoadingSection } from "./LoadingSection";

type AggregatedUsageSectionProps = {
  isCustomDatePeriod?: boolean;
  hasPreviousData?: boolean;
  periodEndAt: Date;
  periodName?: string;
  periodStartDate: Date;
  previousPeriodStartDate?: Date;
  productName?: string;
};

/**
 * Display in individual Bill Page and Usage page
 */
export const AggregatedUsageSection: React.FC<AggregatedUsageSectionProps> = ({
  isCustomDatePeriod = false,
  previousPeriodStartDate,
  periodName,
  hasPreviousData,
  productName,
  periodStartDate,
  periodEndAt,
}) => {
  const { supplyNodeRef, active } = useAppSelector(
    (store) => store.currentAccount,
  );

  const startAt = useMemo(
    () =>
      hasPreviousData && previousPeriodStartDate
        ? previousPeriodStartDate
        : periodStartDate,
    [hasPreviousData, previousPeriodStartDate],
  );

  const timePeriodEndAt = useMemo(
    () => addSeconds(new Date(periodEndAt), 1),
    [periodEndAt],
  );

  const { data: aggregatesData, isFetching } = useGetAggregateRatesQuery(
    {
      supplyNodeRef,
      startAt: startAt,
      endAt: periodEndAt,
      jwtClient,
      source: "Aggregated Usage Section",
    },
    {
      skip: !supplyNodeRef || !startAt || !periodEndAt,
      refetchOnMountOrArgChange: expireTime,
    },
  );

  // Billing cycle is 1 millisecond before midnight but difference in days rounds down so we add an extra second to make the final day a whole day.
  const timePeriodDifference = useMemo(
    () => differenceInDays(timePeriodEndAt, new Date(periodStartDate)),
    [periodStartDate],
  );

  const dailyUsageData = useMemo(
    () =>
      aggregatesData
        ? prepareDailyUsageData(
            aggregatesData,
            periodStartDate,
            timePeriodDifference,
          )
        : [],
    [aggregatesData, periodStartDate, timePeriodDifference],
  );
  const thisPeriodUsage = useMemo(
    () => prepareUsageTotal(aggregatesData?.aggregates),
    [aggregatesData],
  );

  const showDailyUsageSection = useMemo(
    () =>
      !isFetching &&
      parseFloat(thisPeriodUsage) > 0 &&
      Boolean(aggregatesData) &&
      dailyUsageData.length > 0,
    [isFetching, thisPeriodUsage, aggregatesData, dailyUsageData],
  );

  const dataNotAvailable = useMemo(
    () =>
      (active &&
        !isFetching &&
        !aggregatesData &&
        parseFloat(thisPeriodUsage) >= 0) ||
      (active &&
        !isFetching &&
        Boolean(aggregatesData) &&
        parseFloat(thisPeriodUsage) === 0),
    [active, isFetching, aggregatesData, thisPeriodUsage],
  );

  return (
    <>
      <h2 className="section-title heading h4">Daily Usage Details</h2>
      {isCustomDatePeriod ? (
        <p className="section-sub-title">
          {format(periodStartDate, "EEEE d MMMM")} -{" "}
          {format(periodEndAt, "EEEE d MMMM")}
        </p>
      ) : (
        <p className="section-sub-title">For the last {periodName}</p>
      )}
      {showDailyUsageSection ? (
        <DailyUsageSectionWrapped
          componentTitle="Daily Usage"
          dailyUsageData={dailyUsageData}
          hasPreviousData={hasPreviousData}
          isCustomDatePeriod={isCustomDatePeriod}
          periodName={periodName}
          previousPeriodStartDate={previousPeriodStartDate}
          productName={productName}
        />
      ) : dataNotAvailable ? (
        <NoAvailableData />
      ) : (
        <LoadingSection />
      )}
    </>
  );
};
