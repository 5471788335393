// @ts-ignore
import colours from "styles/_colours.scss";
import { barBackgroundColours } from "utils/charts";

export const prepareDataForBarChart = (
  chartConfig: BarChartConfig,
): BarChartConfig => {
  const backgroundColours = barBackgroundColours(
    chartConfig.datasets[0].barColours,
  );

  const barChartConfigData = {
    labels: chartConfig.labels,
    datasets: chartConfig.datasets.map((dataset) => ({
      label: dataset.label,
      noDataText: dataset.noDataText,
      data: dataset.data,
      backgroundColor: backgroundColours || colours.yellow,
      stack: dataset.label,
      status: dataset.status,
      barPercentage: 0.9,
      categoryPercentage: 0.8,
      datalabels: {
        offset: 1,
        align: "end",
      },
    })),
  };
  return barChartConfigData;
};
