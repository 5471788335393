/**
 * @description Return the total amount of usage by given aggregate data
 */
export const prepareUsageTotal = (aggregates: Aggregate[] = []): string => {
  if (aggregates.length === 0) return "0.000";
  const formattedImportValue = (value?: string | number) => {
    if (!value) return "0";
    return value.toString();
  };

  const usageTotal = aggregates.reduce(
    (total, aggregate) =>
      total + parseFloat(formattedImportValue(aggregate.usage?.import_value)),
    0,
  );

  return usageTotal.toFixed(3);
};
