import React from "react";

import { HomeGenerationByDayChart } from "./HomeGenerationByDayChart";
import { HomeGenerationIndividualDayDetails } from "./HomeGenerationIndividualDayDetails";

type HomeGenerationChartProps = {
  dailyUsageData: DailyUsage[];
  periodName?: string;
};
export const HomeGenerationChartWrapper: React.FC<HomeGenerationChartProps> = ({
  dailyUsageData,
  periodName,
}) => {
  const [selectedDay, setSelectedDay] = React.useState(0);
  const validDayData = () => {
    if (selectedDay < dailyUsageData?.length)
      return dailyUsageData[selectedDay];
    setSelectedDay(0);
  };

  const selectedDayData = validDayData();

  const handleSelectedDay = (value: number) => {
    if (value < 0) return;
    setSelectedDay(value);
  };

  const maxDayDataIndex = dailyUsageData?.length - 1;

  return (
    <section>
      <HomeGenerationByDayChart
        dailyUsageData={dailyUsageData}
        setSelectedDay={handleSelectedDay}
      />
      <HomeGenerationIndividualDayDetails
        selectedDayData={selectedDayData}
        periodName={periodName}
        maxDayDataIndex={maxDayDataIndex}
        selectedDayIndex={selectedDay}
        setSelectedDay={setSelectedDay}
      />
    </section>
  );
};
