/* eslint-disable camelcase */
export default class Rate {
  constructor({ rate_code, unit_code, rate, timetables, type }) {
    this.summaryLabel = unit_code === 'days' ? 'daily' : rate_code.toLowerCase();
    this.price = rate * 100;
    this.gst = 1.15;
    this.timetable = timetables.find((timetable) => timetable.rate_code === rate_code);
    this.unit = unit_code;
    this.type = type;
  }

  displayShortTimetable() {
    if (this.timetable === undefined) return '';

    const shortenTimetableDescriptions = (descriptions) =>
      descriptions.map((description) => {
        if (description === 'Weekends: all day') return 'all weekend long';
        return description
          .replace(/:00/g, '')
          .replace(/\s&/g, ',')
          .replace(/Weekdays:/g, 'Weekdays');
      });

    const shiftWeekendToEndOfTimetable = (descriptions) => {
      const weekendDescriptionIndex = descriptions.findIndex(
        (description) => description === 'all weekend long'
      );

      if (weekendDescriptionIndex === -1) return descriptions;

      return descriptions.concat(descriptions.splice(weekendDescriptionIndex, 1));
    };

    const concatenateTimetableDescriptions = (descriptions) => {
      const beginning = /^/;
      const lastComma = /,(?=[^,]*$)/;
      const rateDescription = (rate_code) => {
        if (rate_code === 'Offpeak') return 'Off-peak hours<br />';

        return `${rate_code} hours<br />`;
      };

      return descriptions
        .join(', ')
        .replace(beginning, `${rateDescription(this.timetable.rate_code)}`)
        .replace(lastComma, ', and ');
    };

    return concatenateTimetableDescriptions(
      shiftWeekendToEndOfTimetable(shortenTimetableDescriptions(this.timetable.descriptions))
    );
  }
}
