import { NewPaymentMethodPageContent } from "pages/NewPaymentMethodPage/NewPaymentMethodPageContent";
import { NewPaymentMethodFormContextProvider } from "pages/NewPaymentMethodPage/NewPaymentMethodPageContext";
import React from "react";

export const NewPaymentMethodPage: React.FC = () => {
  return (
    <NewPaymentMethodFormContextProvider>
      <NewPaymentMethodPageContent />
    </NewPaymentMethodFormContextProvider>
  );
};
