import { SecondaryButton } from "@flick/fep-library";
import { ReactComponent as CartIconSvg } from "assets/icon_cart.svg";
import { ReactComponent as PriceTagIconSvg } from "assets/icon_price_tag.svg";
import { renderDeltaMessage } from "components/DeltaMessage";
import { format } from "date-fns/format";
import React, { useMemo } from "react";
import { NZD } from "utils/currency";

import {
  calculateExportRevenueChangePercentage,
  calculateImportCostChangePercentage,
} from "./HomeGeneration.utils";

type HomeGenerationIndividualDayDetailsProps = {
  selectedDayData: DailyUsage;
  periodName: string;
  maxDayDataIndex: number;
  selectedDayIndex: number;
  setSelectedDay: (value: number) => void;
};

export const HomeGenerationIndividualDayDetails: React.FC<
  HomeGenerationIndividualDayDetailsProps
> = ({
  selectedDayData,
  periodName,
  maxDayDataIndex,
  selectedDayIndex,
  setSelectedDay,
}) => {
  const comparisonLabel = `this time the previous ${periodName}`;

  const boughtChangePercentage = useMemo(() => {
    return calculateImportCostChangePercentage(selectedDayData);
  }, [selectedDayData]);

  const soldChangePercentage = useMemo(() => {
    return calculateExportRevenueChangePercentage(selectedDayData);
  }, [selectedDayData]);

  const notFirstDayData = selectedDayIndex > 0;
  const notLastDayData = selectedDayIndex < maxDayDataIndex;

  return (
    <section className="individualday_container background--grey">
      <span className="spacer spacer--small" />
      <div className="individual-day-detail__controls">
        {notFirstDayData && (
          <SecondaryButton
            data-testid="previous-button"
            className="button-secondary individual-day-detail__button small"
            onClick={() => {
              setSelectedDay(selectedDayIndex - 1);
            }}
          >
            <span aria-label="Previous bill">{"< Prev"}</span>
          </SecondaryButton>
        )}
        <h3 className="h4 individual-day-detail__title">
          {format(selectedDayData.date, "EEEE d MMMM")}
        </h3>
        {notLastDayData && (
          <SecondaryButton
            data-testid="next-button"
            className="button-secondary individual-day-detail__button small"
            onClick={() => {
              setSelectedDay(selectedDayIndex + 1);
            }}
          >
            <span aria-label="Next bill">{"Next >"}</span>
          </SecondaryButton>
        )}
      </div>
      {selectedDayData.status !== "missing" && (
        <section className="bill_usage_section">
          <section className="usage_details_section">
            <section className="header_section">
              <CartIconSvg data-testid="icon" />
              <p className="section-sub-title">
                Bought {NZD.format(selectedDayData.thisPeriod.spend)} (
                {selectedDayData.thisPeriod.consumption.toFixed(3)} kWh)
              </p>
              {Boolean(selectedDayData.lastPeriod.spend) &&
                renderDeltaMessage({
                  delta: boughtChangePercentage,
                  periodLabel: comparisonLabel,
                })}
            </section>
          </section>
          <section className="usage_details_section">
            <section className="header_section">
              <PriceTagIconSvg data-testid="icon" />
              <p className="section-sub-title">
                Sold {NZD.format(selectedDayData.thisPeriod.earned)} (
                {selectedDayData.thisPeriod.generation.toFixed(3)} kWh)
              </p>
              {Boolean(selectedDayData.lastPeriod.earned) &&
                renderDeltaMessage({
                  delta: soldChangePercentage,
                  periodLabel: comparisonLabel,
                })}
            </section>
          </section>
        </section>
      )}
    </section>
  );
};
