import {
  addDays,
  endOfDay,
  format,
  isAfter,
  startOfDay,
  subDays,
} from "date-fns";

import { DAY_DELAY_FOR_USAGE_DATA } from "../constants";

type StartDateEndDate = { endDate: Date | null; startDate?: Date | null };

/**
 * Calculates the current consumption period based on the duration of a customer's billing period
 * param - periodDuration, either 7, 14 or 30 (days => weekly, fortnightly or monthly)
 */
export const calculateCurrentPeriodDates = (
  periodDuration: number,
): StartDateEndDate => {
  if (!periodDuration) {
    return {
      endDate: null,
      startDate: null,
    };
  }

  const currentPeriodEndDate = endOfDay(subDays(new Date(), 1));
  const currentPeriodStartDate = startOfDay(
    subDays(currentPeriodEndDate, periodDuration - 1),
  );
  return {
    endDate: currentPeriodEndDate,
    startDate: currentPeriodStartDate,
  };
};

/**
 * Calculates the previous consumption period based on the current consumption period's start date and duration of a customer's billing period
 * param - currentPeriodStartDate, calculated in calculateCurrentPeriodDates() above
 * param - billingPeriodDuration, either 7, 14 or 30 (days => weekly, fortnightly or monthly)
 */
export const calculatePreviousPeriodDates = (
  currentPeriodStartDate?: Date,
  billingPeriodDuration?: number,
): StartDateEndDate => {
  if (!currentPeriodStartDate || !billingPeriodDuration) {
    return {
      endDate: null,
      startDate: null,
    };
  }
  const previousPeriodEndDate = endOfDay(subDays(currentPeriodStartDate, 1));
  const previousPeriodStartDate = startOfDay(
    subDays(previousPeriodEndDate, billingPeriodDuration - 1),
  );
  return {
    endDate: previousPeriodEndDate,
    startDate: previousPeriodStartDate,
  };
};

/**
 * This function checks if we have sufficient aggregate data since a customer's account has been
 * activated. Requesting data prior to their start date would cause the api call to fail as no such data
 * exists for them. Uses date-fns isAfter = is the first date after the second one?
 * param - [billingPeriodDuration] - either 7, 14 or 30 (days => weekly, fortnightly or monthly)
 * param - billingEntityDataStartDate -  date a customer's account becomes active upon signing up
 */
export const previousDataAvailable = (
  billingPeriodDuration: number,
  billingEntityDataStartDate: Date,
): boolean => {
  if (!billingPeriodDuration || !billingEntityDataStartDate) return false;

  return isAfter(
    subDays(
      calculateCurrentPeriodDates(billingPeriodDuration).endDate,
      billingPeriodDuration * 2,
    ),
    new Date(billingEntityDataStartDate),
  );
};

/**
 * This function checks if the customers account was active during the same period last year
 * param - yearAgoPeriodStartDate - year ago period start date, can be undefined
 * param - billingEntityDataStartDate - date a customer's account becomes active upon signing up, can be undefined
 */
export const yearAgoDataAvailable = (
  yearAgoPeriodStartDate?: Date,
  billingEntityDataStartDate?: Date,
): boolean => {
  if (!yearAgoPeriodStartDate || !billingEntityDataStartDate) return false;
  return isAfter(yearAgoPeriodStartDate, new Date(billingEntityDataStartDate));
};

export const dateUsageDataAvailableFrom = (
  billingEntityDataStartDate?: Date | string,
): string | null => {
  if (!billingEntityDataStartDate) {
    return null;
  }
  return format(
    addDays(new Date(billingEntityDataStartDate), DAY_DELAY_FOR_USAGE_DATA + 7),
    "d/MM/yyyy",
  );
};
