import "styles/LoadingSection.scss";

import { Loader } from "components/Loader";
import React from "react";

type LoadingSectionProps = {
  cssClass?: string;
};

export const LoadingSection: React.FC<LoadingSectionProps> = ({
  cssClass = "",
}) => {
  return (
    <div className={`loading-section ${cssClass}`}>
      <Loader />
    </div>
  );
};
