/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable import/no-default-export */
export default function InputLabel() {
  var il = this;

  function _init() {
    _setup(document);
  }

  function _setup(scope) {
    var i,
      inputs = scope.querySelectorAll(
        "div.form_field input:not(.input_unlabeled), div.form_field textarea:not(.input_unlabeled), div.form_field select:not(.input_unlabeled)",
      );

    for (i = 0; i < inputs.length; i++) {
      _applyActiveClasses(inputs[i]);
      _toggleActiveClass(inputs[i]);
    }
  }

  function _applyActiveClasses(input) {
    // keep the label collapsed uo for date and time inputs
    if (
      input.type === "date" ||
      input.type === "datetime-local" ||
      input.type === "time"
    ) {
      input.classList.add("input_active");
      return;
    }

    // items with placeholders are always collapsed up
    if (input.placeholder) {
      input.classList.add("input_active");
      return;
    }

    if (!input.value || input.value === "") {
      input.classList.remove("input_active");
    } else {
      input.classList.add("input_active");
    }
  }

  function _toggleActiveClass(input) {
    input.addEventListener("keyup", (event) => {
      if (event.isComposing || event.keyCode === 229) {
        return;
      }
      _applyActiveClasses(event.target);
    });

    // note keyup will not detect number input spin box clicks
    // so add special treatment for number inputs
    if (input.type === "number") {
      input.addEventListener("blur", (event) => {
        if (input.value) {
          _applyActiveClasses(event.target);
        }
      });
    }

    // select dropdowns and inputs with an associated <datalist> need to respond to change rather than keyup
    if (input.type === "select-one" || input.list) {
      input.addEventListener("change", (event) => {
        _applyActiveClasses(event.target);
      });
    }
  }

  il.start = () => {
    _init();
  };

  return il;
}
