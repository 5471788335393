import { ReactComponent as CartIconSvg } from "assets/icon_cart.svg";
import React, { useMemo } from "react";
import { calculatePercentageChangeDelta } from "utils/calculatePercentageChangeDelta";
import { BRAND_COLOURS, UNIT_CODE_TYPES } from "utils/constants";
import { prepareComparisonChartData } from "utils/progressComparisonChart/prepareComparisonChartData";

import { renderDeltaMessage } from "../DeltaMessage";
import { ProgressComparisonChart } from "../ProgressComparisonChart";
import {
  getPeriodTotalImportCost,
  periodTotalImportCost,
} from "./BoughtSection.utils";

type BoughtSectionProps = {
  isCustomDatePeriod?: boolean;
  isPreviousPeriodAggregatesSuccess: boolean;
  isThisPeriodAggregatesSuccess: boolean;
  isYearAgoAggregatesSuccess: boolean;
  periodName: string;
  previousPeriodAggregatesData: Aggregates;
  thisPeriodAggregatesData: Aggregates;
  yearAgoAggregatesData: Aggregates;
};

/**
 * @description Bought Section displays on the Usage Page for home generation/solar customers.
 */
export const BoughtSection: React.FC<BoughtSectionProps> = ({
  isCustomDatePeriod = false,
  isPreviousPeriodAggregatesSuccess,
  isThisPeriodAggregatesSuccess,
  isYearAgoAggregatesSuccess,
  periodName,
  previousPeriodAggregatesData,
  thisPeriodAggregatesData,
  yearAgoAggregatesData,
}) => {
  const previousPeriodPercentageChange = useMemo(() => {
    if (isCustomDatePeriod) return undefined;

    return calculatePercentageChangeDelta(
      getPeriodTotalImportCost(thisPeriodAggregatesData),
      getPeriodTotalImportCost(previousPeriodAggregatesData),
    );
  }, [
    thisPeriodAggregatesData,
    previousPeriodAggregatesData,
    isCustomDatePeriod,
  ]);

  const yearAgoPercentageChange = useMemo(() => {
    if (isCustomDatePeriod) return undefined;

    return calculatePercentageChangeDelta(
      getPeriodTotalImportCost(thisPeriodAggregatesData),
      getPeriodTotalImportCost(yearAgoAggregatesData),
    );
  }, [thisPeriodAggregatesData, yearAgoAggregatesData, isCustomDatePeriod]);

  const displayPreviousPeriodDeltaMessage =
    !isCustomDatePeriod &&
    isPreviousPeriodAggregatesSuccess &&
    previousPeriodAggregatesData &&
    isThisPeriodAggregatesSuccess;

  const displayYearAgoPeriodDeltaMessage =
    !isCustomDatePeriod &&
    isYearAgoAggregatesSuccess &&
    yearAgoAggregatesData &&
    isThisPeriodAggregatesSuccess;

  return (
    <section>
      <section className="header_section">
        <CartIconSvg data-testid="icon" />
        <h2 className="heading h4">
          {periodTotalImportCost({
            thisPeriodAggregatesData,
            periodName,
            isCustomDatePeriod,
          })}
        </h2>
        {displayPreviousPeriodDeltaMessage &&
          renderDeltaMessage({
            delta: previousPeriodPercentageChange,
            periodLabel: `last ${periodName}`,
          })}
        {displayYearAgoPeriodDeltaMessage &&
          renderDeltaMessage({
            delta: yearAgoPercentageChange,
            periodLabel: "last year",
          })}
      </section>
      {!isCustomDatePeriod && (
        <ProgressComparisonChart
          data={prepareComparisonChartData({
            isPreviousPeriodAggregatesSuccess,
            isThisPeriodAggregatesSuccess,
            isYearAgoAggregatesSuccess,
            periodName,
            thisPeriodData: getPeriodTotalImportCost(thisPeriodAggregatesData),
            previousPeriodData: getPeriodTotalImportCost(
              previousPeriodAggregatesData,
            ),
            yearAgoData: getPeriodTotalImportCost(yearAgoAggregatesData),
            barColour: BRAND_COLOURS.yellow,
            unitType: UNIT_CODE_TYPES.cost,
          })}
        />
      )}
    </section>
  );
};
