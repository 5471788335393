import { Form, FormRow } from "@flick/fep-library";
import { CheckBoxInput } from "components/CheckBoxInput";
import { errorReasonDetails } from "components/NotificationBanner/utils/errorReasons";
import { SubmitButton } from "components/SubmitButton";
import { TextInput } from "components/TextInput";
import { useNewPaymentMethodFormContext } from "pages/NewPaymentMethodPage/NewPaymentMethodPageContext";
import { useAddDirectDebitPaymentMutation } from "queries/customerApi";
import React, { FormEvent } from "react";
import ReactMarkdown from "react-markdown";
import { useLocation, useNavigate } from "react-router-dom";
import { setNewPaymentMethod } from "reducers/accountDetails";
import { setNotificationBanner } from "reducers/notificationBanner";
import { useAppDispatch, useAppSelector } from "reduxUtils/hook";
import {
  NotificationBannerNames,
  NotificationBannerTypes,
} from "utils/constants";
import { jwtClient } from "utils/jwtClient";

import content from "./static/content.json";
const {
  accountNameLabel,
  bankAccountNumberLabel,
  codeLabel,
  confirmAuthorityLabel,
  particularsLabel,
  referenceLabel,
  submitLabel,
  termsAndConditionsLabel,
} = content;

export const DirectDebitPaymentForm: React.FC = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { accountNumber } = useAppSelector((store) => store.currentAccount);

  const {
    accountName: {
      fieldValue: { value: valueAccountName, error: errorAccountName },
      handleOnChange: setAccountName,
      handleOnBlur: handleBlurAccountName,
    },
    bankAccountNumber: {
      fieldValue: {
        value: valueBankAccountNumber,
        error: errorBankAccountNumber,
      },
      handleOnChange: setBankAccountNumber,
      handleOnBlur: handleBlurBankAccountNumber,
    },
    code: {
      fieldValue: { value: valueCode },
      handleOnChange: setCode,
    },
    confirmAuthority: {
      fieldValue: {
        value: valueConfirmAuthority,
        error: errorConfirmAuthority,
      },
      handleOnChange: setConfirmAuthority,
    },
    particulars: {
      fieldValue: { value: valueParticulars },
      handleOnChange: setParticulars,
    },
    reference: {
      fieldValue: { value: valueReference },
      handleOnChange: setReference,
    },
    termsAndConditions: {
      fieldValue: {
        value: valueTermsAndConditions,
        error: errorTermsAndConditions,
      },
      handleOnChange: setTermsAndConditions,
    },
    isDirectDebitValid,
    resetFormFields,
  } = useNewPaymentMethodFormContext();

  const [addDirectDebitPayment] = useAddDirectDebitPaymentMutation();

  const handleAddDirectPayment = async ({
    accountName,
    agreedToTerms,
    bankAccountNumber,
    code,
    confirmedAuthority,
    particulars,
    reference,
  }) => {
    try {
      const data = await addDirectDebitPayment({
        accountIdentifier: accountNumber,
        jwtClient,
        accountName,
        agreedToTerms,
        bankAccountNumber,
        code,
        confirmedAuthority,
        particulars,
        reference,
      }).unwrap();
      dispatch(setNewPaymentMethod({ paymentMethod: data }));
      resetFormFields();
      // show banner on billing details screen
      navigate("/account/billing", { state: { showSuccessBanner: true } });
    } catch (err) {
      dispatch(
        setNotificationBanner({
          type: NotificationBannerTypes.error,
          name: NotificationBannerNames.addDirectDebitPayment,
          page: pathname,
          additionalContent: errorReasonDetails(err),
        }),
      );
    }
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleAddDirectPayment({
      accountName: valueAccountName,
      agreedToTerms: valueTermsAndConditions,
      bankAccountNumber: valueBankAccountNumber.replaceAll("-", ""),
      code: valueCode,
      confirmedAuthority: valueConfirmAuthority,
      particulars: valueParticulars,
      reference: valueReference,
    });
  };

  return (
    <div className="payment-method-container">
      <Form onSubmit={handleSubmit}>
        <FormRow>
          <div className="spacer--medium">
            <TextInput
              errorMessage={errorAccountName}
              handleOnBlur={handleBlurAccountName}
              inputWidth="full"
              label={accountNameLabel}
              name="account-name"
              required={true}
              setValue={setAccountName}
              value={valueAccountName}
            />
          </div>
        </FormRow>
        <FormRow>
          <div className="spacer--large">
            <TextInput
              errorMessage={errorBankAccountNumber}
              handleOnBlur={handleBlurBankAccountNumber}
              inputMode="numeric"
              inputWidth="full"
              label={bankAccountNumberLabel}
              name="bank-account-number"
              required={true}
              setValue={setBankAccountNumber}
              value={valueBankAccountNumber}
            />
          </div>
        </FormRow>
        <FormRow>
          <div className="spacer--large">
            <TextInput
              inputWidth="full"
              label={particularsLabel}
              name="particulars"
              setValue={setParticulars}
              value={valueParticulars}
            />
          </div>
        </FormRow>
        <FormRow>
          <div className="spacer--large">
            <TextInput
              inputWidth="full"
              label={codeLabel}
              name="code"
              setValue={setCode}
              value={valueCode}
            />
          </div>
        </FormRow>
        <FormRow>
          <div className="spacer--large">
            <TextInput
              inputWidth="full"
              label={referenceLabel}
              name="reference"
              setValue={setReference}
              value={valueReference}
            />
          </div>
        </FormRow>
        <FormRow>
          <CheckBoxInput
            errorMessage={errorConfirmAuthority}
            label={confirmAuthorityLabel}
            handleOnChange={setConfirmAuthority}
            isChecked={valueConfirmAuthority}
            name="confirm-authority"
          />
        </FormRow>
        <FormRow>
          <CheckBoxInput
            errorMessage={errorTermsAndConditions}
            label={<ReactMarkdown>{termsAndConditionsLabel}</ReactMarkdown>}
            handleOnChange={setTermsAndConditions}
            isChecked={valueTermsAndConditions}
            name="terms-and-conditions"
          />
        </FormRow>
        <SubmitButton
          isSubmitDisabled={!isDirectDebitValid}
          label={submitLabel}
        />
      </Form>
    </div>
  );
};
