import {
  getMaxEndDate,
  getMinDate,
} from "components/CustomPeriodSelector/CustomPeriodSelector.utils";

/**
 * @param value selected date
 * @returns true if the selected date between the minimum date and today. Where minimum date is the earliest of billingStartDate and 2 years back
 */
export const dateWithinMinDateAndTodayValidation = ({
  value,
  billingStartDate,
}): boolean => {
  const { minDate } = getMinDate(billingStartDate);
  const today = new Date();

  const selectedDate = new Date(value);
  selectedDate.setHours(0, 0, 0, 0);

  return selectedDate >= minDate && selectedDate <= today;
};

/**
 * @param value selected date
 * @param endDate current end date value
 * @returns true if the selected date less then end date
 */
export const startDateValidation = ({
  startDate,
  endDate,
}: {
  startDate: string;
  endDate: string;
}): boolean => {
  const selectedDate = new Date(startDate);
  selectedDate.setHours(0, 0, 0, 0);

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  return selectedDate < new Date(endDate) && selectedDate < today;
};

/**
 * @param value selected end date
 * @param startDate current start date value
 * @returns true if the selected date is within 31 days from the start date
 */
export const endDateValidation = ({
  endDate,
  startDate,
}: {
  endDate: string;
  startDate: string;
}): boolean => {
  const minDate = new Date(startDate);
  const maxDate = new Date(minDate);
  maxDate.setDate(minDate.getDate() + 31);

  const { maxEndDate } = getMaxEndDate(startDate);
  const selectedDate = new Date(endDate);
  selectedDate.setHours(0, 0, 0, 0);

  return selectedDate > minDate && selectedDate <= maxEndDate;
};
