import { createSlice } from "@reduxjs/toolkit";
import { pricingRateCardApi } from "queries/pricingRateCardApi";

const rateCardsSlice = createSlice({
  name: "rateCards",
  initialState: {
    rateCards: [],
    isRateCardLoading: false,
    isRateCardError: false,
  },
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addMatcher(
        pricingRateCardApi.endpoints.getRateCards.matchPending,
        (state, {}) => {
          Object.assign(state, {
            isRateCardLoading: true,
            isRateCardError: false,
            rateCards: [],
          });
        },
      )
      .addMatcher(
        pricingRateCardApi.endpoints.getRateCards.matchRejected,
        (state, {}) => {
          Object.assign(state, {
            isRateCardLoading: false,
            isRateCardError: true,
          });
        },
      )
      .addMatcher(
        pricingRateCardApi.endpoints.getRateCards.matchFulfilled,
        (state, { payload }) => {
          Object.assign(state, {
            isRateCardLoading: false,
            isRateCardError: false,
            rateCards: payload || [],
          });
        },
      ),
});

export const {} = rateCardsSlice.actions;
export const rateCardsReducer = rateCardsSlice.reducer;
