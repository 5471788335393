import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { customerApi } from "queries/customerApi";
import { identityApi } from "queries/identityApi";

import { setStoreCurrentAccount } from "./currentAccount";
import {
  setCurrentDefaultToNonDefault,
  setNewPaymentMethodToDefault,
} from "./utils/accountDetails.utils";

const accountDetailsSlice = createSlice({
  name: "accountDetails",
  initialState: {
    email: undefined,
    currentICPAddress: undefined,
    preferredName: undefined,
    unconfirmedEmail: undefined,
    userSub: undefined,
    paymentMethods: [],
    primaryContact: undefined,
    vulnerabilityState: "none",
    referralLink: undefined,
  },
  reducers: {
    setAccountDetails: (
      state,
      action: PayloadAction<{
        preferredName: string;
        email: string;
      }>,
    ) => {
      state.email = action.payload.email;
      state.preferredName = action.payload.preferredName;
    },
    setDefaultPaymentMethod: (
      state,
      action: PayloadAction<{ paymentMethodId: string }>,
    ) => {
      const paymentMethodsCopy = [...state.paymentMethods];
      // helpers to toggle the old and new default values
      setCurrentDefaultToNonDefault(paymentMethodsCopy);
      setNewPaymentMethodToDefault(
        action.payload.paymentMethodId,
        paymentMethodsCopy,
      );
      state.paymentMethods = [...paymentMethodsCopy];
    },
    setNewPaymentMethod: (
      state,
      action: PayloadAction<{ paymentMethod: AccountDetailPaymentMethod }>,
    ) => {
      const paymentMethodsCopy = [...state.paymentMethods];
      state.paymentMethods = [
        ...paymentMethodsCopy,
        action.payload.paymentMethod,
      ];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setStoreCurrentAccount, (state, action) => {
        const selectedAccount =
          action.payload.accountDatas[action.payload.accountIndex]?.attributes;
        state.currentICPAddress = selectedAccount?.address || undefined;
      })
      .addMatcher(
        identityApi.endpoints.getUserDetails.matchFulfilled,
        (state, { payload }) => {
          state.email = payload.email;
          state.preferredName = payload.preferredName;
        },
      )
      .addMatcher(
        customerApi.endpoints.getAccountDetails.matchFulfilled,
        (state, { payload }) => {
          state.vulnerabilityState = payload.vulnerabilityState;
          state.paymentMethods = payload.paymentMethods;
          state.primaryContact = payload.primaryContact;
          state.userSub = payload.userSub;
          state.currentICPAddress = payload.currentICPAddress;
          state.referralLink = payload.referralLink;
        },
      )
      .addMatcher(
        identityApi.endpoints.updateUserDetails.matchFulfilled,
        (state, { payload }) => {
          const updatedPreferredName = payload?.attributes?.preferred_name;
          const unconfirmedEmail = payload?.attributes?.unconfirmed_email;
          const unconfirmedEmailUpdated =
            unconfirmedEmail !== state.unconfirmedEmail;

          if (updatedPreferredName) state.preferredName = updatedPreferredName;
          if (unconfirmedEmailUpdated)
            state.unconfirmedEmail = unconfirmedEmail;
        },
      )
      .addMatcher(
        customerApi.endpoints.updatePhoneNumbers.matchFulfilled,
        (state, { payload }) => {
          const formattedPhoneNumbers = payload?.attributes?.phone_numbers.map(
            ({ id, number, number_type }: PhoneNumberAttributes) => {
              return { id, number, numberType: number_type };
            },
          );
          if (formattedPhoneNumbers) {
            state.primaryContact = {
              ...state.primaryContact,
              phoneNumbers: formattedPhoneNumbers,
            };
          }
        },
      );
  },
});

export const {
  setAccountDetails,
  setDefaultPaymentMethod,
  setNewPaymentMethod,
} = accountDetailsSlice.actions;
export const accountDetailsSliceReducer = accountDetailsSlice.reducer;
