export const REGEX_EMAIL =
  /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;

export const REGEX_PASSWORD =
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*?([^\w\s]|[_])).{8,}$/;

export const REGEX_TAGS = /<|>/;

export const REGEX_URL =
  /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;

// matches first two characters (02), then expects 7 - 9 digits
export const MOBILE_PHONE_NUMBER_REGEX = /^02\d{7,9}$/;

/**
 * For more information about home number formatting see the [Home phone number formatting](ttps://github.com/FlickElectric/how-to-big-success/blob/main/FAQ/Home-phone-number-formatting.md) section in `how-to-big-success`.
 */
export const REGEX_PHONE_NUMBER = /^\+?[1-9]\d{1,14}$/; // this is a JS version of the ruby regex used on the API home number - E_DOT_164_REGEX = /\A\+?[1-9]\d{1,14}\z/
