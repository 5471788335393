export const friendlyPaymentMethod = (code: string): string => {
  let statement = null;
  switch (code) {
    case "urn:flick:bill:paymth:directdebit":
      statement = "direct debit";
      break;
    case "urn:flick:bill:paymth:creditcard":
      statement = "credit card";
      break;
    case "urn:flick:bill:paymth:acct_credit":
      statement = "account credit";
      break;
    case "urn:flick:bill:paymth:selfservicecc":
      statement = "credit card";
      break;
    case "urn:flick:bill:paymth:manualpayment":
      statement = "manual payment";
      break;
    case "urn:flick:bill:paymth:otherpayment":
      statement = "other method";
      break;
    default:
      break;
  }
  if (statement) return `via ${statement}`;
};
