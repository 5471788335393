import { LoadingSection } from "components/LoadingSection";
import { OffpeakUsageSection } from "components/OffpeakUsageSection";
import { useGetInvoiceQuery } from "queries/billingApi";
import React from "react";
import { jwtClient } from "utils/jwtClient";

type BillOffpeakUsageSectionProps = {
  bill: Bill;
};

export const BillOffpeakUsageSection: React.FC<
  BillOffpeakUsageSectionProps
> = ({ bill }) => {
  const {
    data: previousPeriodInvoiceData,
    isLoading: isPreviousPeriodInvoiceLoading,
  } = useGetInvoiceQuery(
    { invoiceNumber: bill.previous_invoice_id, jwtClient },
    { skip: !bill.previous_invoice_id, refetchOnMountOrArgChange: true },
  );

  if (isPreviousPeriodInvoiceLoading) return <LoadingSection />;

  const offpeakRatio =
    bill.time_of_use_consumption?.offpeak_usage_ratio || null;
  const previousPeriodOffpeakRatio =
    previousPeriodInvoiceData?.bill?.time_of_use_consumption
      ?.offpeak_usage_ratio || null;

  return (
    <>
      {Boolean(offpeakRatio) && (
        <OffpeakUsageSection
          offpeakRatio={offpeakRatio}
          previousPeriodOffpeakRatio={previousPeriodOffpeakRatio}
          duration={bill.billing_period.duration}
        />
      )}
    </>
  );
};
