import { SelectInput } from "components/SelectInput";
import { SingleSelectRadioGroup } from "components/SingleSelectRadioGroup";
import { useBillingDetailsFormContext } from "pages/BillingDetailsPage/BillingDetailsPageContext";
import React, { ChangeEvent, useEffect, useMemo } from "react";
import ReactMarkdown from "react-markdown";
import { useAppSelector } from "reduxUtils/hook";
import { BILLING_OFFSET_WEEKS, PERIOD_CODES } from "utils/constants";
import { hasInputValueChanged } from "utils/forms/forms";
import { replaceContentParams } from "utils/stringFormat/contentParams";

import { getNextBillDate } from "./BillingDetailsPage.utils";
import content from "./static/content.json";

const {
  billingFrequencyHintP7D,
  billingFrequencyHintP2W,
  billingFrequencyHintP1M,
  billingFrequencyLabel,
  nextBill,
  weeksLabel,
} = content;

type BillingFrequencyOption = {
  hint: string;
  label: string;
  value: string;
};

const billingFrequencyOptions = [
  { label: "Weekly", value: PERIOD_CODES.P7D, hint: billingFrequencyHintP7D },
  {
    label: "Fortnightly",
    value: PERIOD_CODES.P2W,
    hint: billingFrequencyHintP2W,
  },
  { label: "Monthly", value: PERIOD_CODES.P1M, hint: billingFrequencyHintP1M },
];

const fortnightlyWeeks = [
  {
    name: "this-week",
    label: "This week",
    value: BILLING_OFFSET_WEEKS.thisWeek,
  },
  {
    name: "next-week",
    label: "Next week",
    value: BILLING_OFFSET_WEEKS.nextWeek,
  },
];

type BillingDetailsPageBillingFrequencyProps = {
  setBillingFrequencyHasChanged: (value: boolean) => void;
  setFrequencyWeekHasChanged: (value: boolean) => void;
};

export const BillingDetailsPageBillingFrequency: React.FC<
  BillingDetailsPageBillingFrequencyProps
> = ({ setBillingFrequencyHasChanged, setFrequencyWeekHasChanged }) => {
  const {
    billingFrequency: {
      fieldValue: { value: billingFrequencyValue },
      handleOnChange: handleOnChangeBillingFrequency,
    },
    frequencyWeek: {
      fieldValue: { value: frequencyWeekValue },
      handleOnChange: handleOnChangeFrequencyWeek,
    },
  } = useBillingDetailsFormContext();
  const { billingEntityData } = useAppSelector(
    (store) => store.billingEntityData,
  );
  const upcomingInvoiceDates =
    billingEntityData?.next_invoice_dates?.all_upcoming_invoiced_at_dates || {};

  const defaultOptionFrequency: BillingFrequencyOption = useMemo(() => {
    if (!billingEntityData) return {} as BillingFrequencyOption;
    const { nominated_billing_period } = billingEntityData as BillingEntity;
    return billingFrequencyOptions.find(
      (bf) => bf.value === nominated_billing_period,
    );
  }, [billingEntityData]);

  useEffect(() => {
    if (defaultOptionFrequency?.value) {
      handleOnChangeBillingFrequency(defaultOptionFrequency.value);
    }
  }, [defaultOptionFrequency?.value]);

  useEffect(() => {
    if (billingEntityData?.nominated_billing_period_offset_weeks) {
      handleOnChangeFrequencyWeek(
        billingEntityData.nominated_billing_period_offset_weeks,
      );
    }
  }, [billingEntityData?.nominated_billing_period_offset_weeks]);

  const currentFrequency = billingFrequencyOptions.find(
    (bf) => bf.value === billingFrequencyValue,
  );
  const frequencyHint = currentFrequency?.hint || "";

  const handleOnChangeFrequency = (event: ChangeEvent<HTMLSelectElement>) => {
    handleOnChangeBillingFrequency(event.target.value);
  };

  const handleOnChangeWeeks = (value: string) => {
    handleOnChangeFrequencyWeek(value);
  };

  const nextBillText = replaceContentParams(nextBill || "", {
    billDate: getNextBillDate(
      billingFrequencyValue,
      upcomingInvoiceDates,
      +(frequencyWeekValue || BILLING_OFFSET_WEEKS.thisWeek),
    ),
    frequency: currentFrequency?.label?.toLowerCase(),
  });

  // parent component needs to know when these form values have changed (compared to the values in redux state)
  useEffect(() => {
    if (billingFrequencyValue && defaultOptionFrequency?.value) {
      setBillingFrequencyHasChanged(
        hasInputValueChanged(
          defaultOptionFrequency?.value,
          billingFrequencyValue,
        ),
      );
    }
  }, [billingFrequencyValue, defaultOptionFrequency?.value]);

  useEffect(() => {
    if (
      frequencyWeekValue &&
      billingEntityData?.nominated_billing_period_offset_weeks
    ) {
      setFrequencyWeekHasChanged(
        hasInputValueChanged(
          billingEntityData.nominated_billing_period_offset_weeks,
          frequencyWeekValue,
        ),
      );
    }
  }, [
    frequencyWeekValue,
    billingEntityData?.nominated_billing_period_offset_weeks,
  ]);

  return (
    <>
      <div className="form_row spacer spacer--medium">
        <SelectInput
          defaultOption={defaultOptionFrequency}
          handleOnChange={handleOnChangeFrequency}
          label={billingFrequencyLabel}
          name="billing-frequency"
          options={billingFrequencyOptions}
          value={billingFrequencyValue}
          extraClasses="spacer spacer--small"
        />
      </div>
      <p className="h6">{frequencyHint}</p>
      {currentFrequency?.value === PERIOD_CODES.P2W && (
        <div className="form_row spacer spacer--medium">
          <span>{weeksLabel}</span>
          <SingleSelectRadioGroup
            handleOnChange={handleOnChangeWeeks}
            name="billing-weeks"
            options={fortnightlyWeeks}
            valueSelect={frequencyWeekValue}
            extraClasses="spacer spacer--small"
          />
        </div>
      )}
      <span>
        <ReactMarkdown components={{ p: "span" }}>{nextBillText}</ReactMarkdown>
      </span>
    </>
  );
};
