import React, { ReactNode, useContext, useState } from "react";
import { PERIOD_CODES } from "utils/constants";
import { isValidInput } from "utils/forms/forms";
import { emailValidation } from "utils/validations/emailValidation";
import { validationFunctionHelper } from "utils/validations/validationFunctionHelper";

import content from "./static/content.json";
const { emailInvalidError, emailRequiredError } = content;

type BillingDetailsContext = {
  billingFrequency?: FormField;
  email?: FormField;
  frequencyWeek?: FormField;
  isFormValid?: boolean;
  paymentMethod?: FormField;
};

const initialContext: BillingDetailsContext = {};

const BillingDetailsFormContext = React.createContext(initialContext);

const initialFormFieldState = {
  error: "",
  isRequired: true,
  isValid: false,
  value: "",
};

const validationEmail = (value: string) => {
  return validationFunctionHelper({
    value,
    validationFunction: emailValidation,
    requiredError: emailRequiredError,
    invalidError: emailInvalidError,
  });
};

export const useBillingDetailsFormContext = () =>
  useContext(BillingDetailsFormContext);

type BillingDetailsFormContextProviderProps = {
  children: ReactNode;
};

export const BillingDetailsFormContextProvider: React.FC<
  BillingDetailsFormContextProviderProps
> = ({ children }) => {
  const [billingFrequency, setBillingFrequency] = useState(
    initialFormFieldState,
  );
  const [email, setEmail] = useState(initialFormFieldState);
  const [frequencyWeek, setFrequencyWeek] = useState(initialFormFieldState);
  const [paymentMethod, setPaymentMethod] = useState(initialFormFieldState);

  const setValueEmail = (value: string, setError?: boolean) => {
    const fieldInError = email.error;
    const error = validationEmail(value);
    setEmail((prev) => ({
      ...prev,
      isValid: !error,
      error: fieldInError || setError ? error : "",
      value,
    }));
  };

  const setValuePaymentMethod = (value: string) => {
    setPaymentMethod((prev) => ({
      ...prev,
      isValid: true,
      error: "",
      value,
    }));
  };

  const setErrorPaymentMethod = (errorMessage: string) => {
    setPaymentMethod((prev) => ({
      ...prev,
      isValid: false,
      error: errorMessage,
    }));
  };

  const setValueBillingFrequency = (value: string) => {
    setBillingFrequency((prev) => ({
      ...prev,
      isValid: true,
      error: "",
      value,
    }));
  };

  const setValueFrequencyWeek = (value: string) => {
    setFrequencyWeek((prev) => ({
      ...prev,
      isValid: true,
      error: "",
      value,
    }));
  };

  /**
   * @description Checking if all the inputs are valid to enable the submit button
   */
  const allInputsValid = [
    isValidInput(billingFrequency),
    isValidInput(email),
    billingFrequency.value === PERIOD_CODES.P2W
      ? isValidInput(frequencyWeek)
      : true,
    isValidInput(paymentMethod),
  ].every(Boolean);

  return (
    <BillingDetailsFormContext.Provider
      value={{
        billingFrequency: {
          fieldValue: billingFrequency,
          handleOnChange: setValueBillingFrequency,
        },
        email: {
          fieldValue: email,
          handleOnChange: (value) => setValueEmail(value, false),
          handleOnBlur: () => setValueEmail(email.value, true),
        },
        frequencyWeek: {
          fieldValue: frequencyWeek,
          handleOnChange: setValueFrequencyWeek,
        },
        paymentMethod: {
          fieldValue: paymentMethod,
          handleOnChange: setValuePaymentMethod,
          handleError: setErrorPaymentMethod,
        },
        isFormValid: allInputsValid,
      }}
    >
      {children}
    </BillingDetailsFormContext.Provider>
  );
};
