import {
  MOBILE_PHONE_NUMBER_REGEX,
  REGEX_PHONE_NUMBER,
} from "./helpers/validationRegex";

export const phoneNumberValidation = (value: string): boolean => {
  if (!value) return true;
  return REGEX_PHONE_NUMBER.test(value.replace(/[\s-]/g, ""));
};

export const mobilePhoneNumberValidation = (value: string): boolean => {
  if (!value) return true;
  return MOBILE_PHONE_NUMBER_REGEX.test(value.replace(/[\s-]/g, ""));
};
