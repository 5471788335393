/**
 * Calculates the percentage change between the current period and a period that has passed.
 **/
export const calculatePercentageChangeDelta = (
  thisPeriodValue: string,
  comparisonPeriodValue: string,
): number => {
  const percentageDifference =
    Number(comparisonPeriodValue) !== 0
      ? ((Number(thisPeriodValue) - Number(comparisonPeriodValue)) /
          Math.abs(Number(comparisonPeriodValue))) *
        100
      : 100;
  return percentageDifference;
};
