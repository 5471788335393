/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Dropdown from '@flick/front_end_patterns/src/js/forms/form_fields_select';
import Tooltips from '@flick/front_end_patterns/src/js/dna/tooltips';
import { LOW, STANDARD } from '../../utils/constants';
import sendAnalytics from '../../utils/analytics';

const UserType = ({ userType, setUserType }) => {
  useEffect(() => {
    new Dropdown().start();
    new Tooltips().start();
  }, [userType]);

  return (
    <div className="form_field">
      <div className="form_field_tooltip">
        <select
          name="user_type"
          id="user_type"
          value={userType}
          onChange={(event) => {
            setUserType(event.target.value);
            sendAnalytics('User Type: Toggle', 'Toggle');
          }}
          data-choices
        >
          <option value={LOW}>Low power user</option>
          <option value={STANDARD}>Standard power user</option>
        </select>
        <label id="user_type_label" htmlFor="user_type" className="sr-only">
          Usage profile
        </label>
      </div>
      <span
        data-tooltip={`Typically ${userType} users spend ${
          userType === LOW ? 'under' : 'over'
        } $215 a month on power bills using ${
          userType === LOW ? 'less' : 'more'
        } than 8,000-9,000 kWh per year, depending on your region.`}
        className="tooltip_inline"
      />
    </div>
  );
};

UserType.propTypes = {
  userType: PropTypes.string.isRequired,
  setUserType: PropTypes.func.isRequired,
};

export default UserType;
